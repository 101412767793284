import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
@Pipe({
    name: 'filter'
})
export class DataFilterPipe implements PipeTransform {
    simpleU: string;
    systemAdmin: string;
    managerU: string;
    securityAdmin: string;
    appU: string;

    constructor(private translate : TranslateService){
        this.translate.get(["Simple User","System Administrator","Manager","Security Administrator","Application User"])
        .subscribe((text : any) => {
            this.simpleU = text["Simple User"];
            this.systemAdmin = text["System Administrator"];
            this.managerU = text["Manager"];
            this.securityAdmin = text["Security Administrator"];
            this.appU = text["Application User"];
        });
    }
    public transform(value, keys: string, term: string, isAnd: boolean) {
        if (!term) return value;
        if (isAnd || undefined === isAnd) {
            return this.whenTrue(value, keys, term);
        }
        return (value || []).filter(item => keys.split(',').some(key => ((item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])) ||
            (key.indexOf(".") > 0 && new RegExp(term, 'gi').test(item[key.split(".")[0]][key.split(".")[1]])) ||
            (this.extractedRoleBasedCheck(key, term, item)))
        ));
    }

    private whenTrue(value, keys: string, term: string) {
        return (value || []).filter(item => keys.split(',').some(key => ((item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])) ||
            (key.split(".").length > 2 && new RegExp(term, 'gi').test(item[key.split(".")[0]][key.split(".")[1]])) ||
            (this.extractedRoleBasedCheck(key, term, item)))
        ));
    }

    /**
     * we need this function cause original table row object doesnt have translated value.
     * LogTracer table has traslation cols but obj logDesc is already modified accordingly in .ts.
     * @param key column name
     * @param term searchText
     * @param item row record; here its User obj
     * @returns true if term matches
     */
    private extractedRoleBasedCheck(key: string, term: string, item): boolean {
        if (key != 'role') return false;
        
        let roleVal: string = '';
        switch (item[key]) {
            case 'USER': roleVal = this.simpleU;
                break;
            case 'SYSTEM_ADMIN': roleVal = this.systemAdmin;
                break;
            case 'MANAGER': roleVal = this.managerU;
                break;
            case 'SECURITY_ADMIN': roleVal = this.securityAdmin;
                break;
            case 'APPLICATION': roleVal = this.appU;
                break;
            default: roleVal = item[key];
                break;
        }
        return new RegExp(term, 'gi').test(roleVal);
    }
}
