import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { Observable, throwError } from 'rxjs';
import { ChildrenItems, MenuItems } from 'app/shared/menu-items/menu-items';
import { Router } from '@angular/router';
import { Buffer } from "buffer";
import { Data } from 'app/entities/data.model';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BacProcessSaveRequest } from 'app/entities/bacProcessSaveRequest';
import { CommitProcessRequest } from 'app/entities/commitProcessRequest';
import { CreateProcess } from 'app/entities/CreateProcessEvoRequest';
import { SaveProcessRequest } from 'app/entities/SaveProcessRequest';
import { CompareLayoutRequest } from 'app/entities/CompareLayoutRequest';
import { StorageDetails } from './user-details.service';
import { ResMap } from 'app/entities/resmap';
import { TreeObject } from 'app/entities/treeNode.model';
import { S1ProcessInfo } from 'app/entities/s1-processInfo';
import { S1BacProcess } from './../../entities/bac-process';
import { S1ProcessID } from 'app/entities/s1-processid';
import { S1Process } from 'app/entities/s1process.model';
import { S1DeployRequest } from 'app/entities/s1-deployrequest';
import { ProcessUserRequest } from 'app/entities/reassign-user';
import { AlertsService } from 'app/shared/alert/alert-service';


@Injectable({
  providedIn: 'root'
})
export class ProcessManagementService {
  isOpen: boolean = true;
  openProcessNode: S1ProcessInfo;
  selectedNode: TreeObject; //selected node from tree
  public static openProcessDetailsArray: Data[] = []; //open processes in sidebar
  bacProcessNode: S1BacProcess; //Current process base64
  currentProcessDetails: Data; //current process
  currentProcessName: string;
  public static currentProcessName: string;
  currentProcessVersion: number;
  currentProcessVerId: number;
  processInfoDetails: Array<ResMap> = [];
  sidebarProcName: string;
  processNameImg: string;
  isDeployed: boolean;
  processID: any;
  ioType: string;
  processType: string;

  isDisabled: boolean = false;
  public showAppLoader: boolean = false; //not static. modified in process.component.ts and sidebar
  errMsgPermissionDenied: string;
  errMsgPermissionDeniedDetails: string;

  isHistoryProcess: boolean = false;

  constructor(private http: HttpClient, private userInfo: StorageDetails, private menuItem: MenuItems, private router: Router
    , private datepipe: DatePipe, private translate: TranslateService, private alert: AlertsService) {
    this.translate.get([
      "Permission denied!",
      "You do not have permission to access this page."
    ]).subscribe((text: any) => {
      this.errMsgPermissionDenied = text["Permission denied!"];
      this.errMsgPermissionDeniedDetails = text["You do not have permission to access this page."];
    });
  }

  //hierarchy - installation->groups->institute
  authGetProcessesOfInstallation(installationId: number): Observable<any> {
    let sessionUID = this.userInfo.getUserToken();
    return this.http.get<any>("rs/private/installation/processes?sessionUID=" + sessionUID + "&installationId=" + installationId);
  }

  authGetProcessesOfGroup(groupId: number): Observable<ResMap> {
    let token = this.userInfo.getUserToken();
    return this.http.get<ResMap>('rs/private/group/processes?sessionUID=' + token + '&groupId=' + groupId);
  }

  //institute = org
  authGetProcessesOfInstitute(instituteId: number): Observable<ResMap> {
    let token = this.userInfo.getUserToken();
    return this.http.get<ResMap>('rs/private/institute/processes?sessionUID=' + token + '&instituteId=' + instituteId);
  }

  getProcessList(containerId: number): Observable<any> {
    return this.http.get('private/processes?container_id=' + containerId);
  }

  validateProcessWithXSD(xsdVersion: number, procXml: string): Observable<any> {
    let localblob = Buffer.from(procXml).toString('base64');
    let s1Process: S1Process = new S1Process(localblob);
    return this.http.post<any>('private/bac/process/validate?xsdVersion=' + xsdVersion, s1Process);
  }

  docExportProcess(id: S1ProcessID): Observable<any> {
    return this.http.get<any>('private/bac/process/export?processID=' + id.processID + "&versionID=" + id.versionID);
  }
  private docOpenProcess(ids: S1ProcessID): Observable<any> {
    return this.http.post<any>('private/bac/process/open', ids);
  }

  deployProcess(ids: S1ProcessID, deployenvs: string[]): Observable<any> {
    let s1DeployRequest = new S1DeployRequest(ids, deployenvs);
    return this.http.put<any>('private/processes/deploy', s1DeployRequest);
  }

  setProcessDeployed(processId: S1ProcessID): Observable<any> {
    return this.http.get<any>('private/processes/deployed?processID=' + processId.processID + '&versionID=' + processId.versionID);
  }
  saveProcessBac(processId: S1ProcessID, processObj: S1BacProcess, isCheckinTrue: boolean): Observable<any> {
    let bacProcessSaveRequest: BacProcessSaveRequest = new BacProcessSaveRequest();
    bacProcessSaveRequest.processID = processId;
    bacProcessSaveRequest.processIn = processObj;
    return this.http.put<any>('private/bac/process/save' + '?isCheckinTrue=' + isCheckinTrue, bacProcessSaveRequest);
  }
  saveProcess(processId: S1ProcessID, procXml: string): Observable<any> {
    let saveProcReq: SaveProcessRequest = new SaveProcessRequest();
    saveProcReq.id = processId;
    let localblob = Buffer.from(procXml).toString('base64');
    saveProcReq.processIn = new S1Process(localblob);
    saveProcReq.references = null;
    return this.http.put<any>('private/processes/save', saveProcReq);
  }

  docCheckOutProcess(processId: S1ProcessID): Observable<any> {
    return this.http.put<any>('private/processes/lock', processId);
  }

  docUndoCheckOutProcess(processId: S1ProcessID): Observable<any> {
    return this.http.put<any>('private/processes/rollback', processId);
  }
  getAssignableUsers(processId: any): Observable<any> {
    return this.http.get<any>('private/processes/assignable?process_id=' + processId.processID);
  }


  docCheckInProcess(processID: S1ProcessID, comment: string, layoutVersion: number) {
    let commitProcReq: CommitProcessRequest = new CommitProcessRequest();
    commitProcReq.processID = processID;
    commitProcReq.comment = comment;
    commitProcReq.layoutVersion = layoutVersion;
    return this.http.put<any>('private/processes/commit', commitProcReq);
  }

  setSelectedProcessData(processData: Data) {
    this.setProcessData_displayVars(processData);
    this.currentProcessDetails = processData;
    this.currentProcessName = this.currentProcessDetails.s1ProcessInfo.nameDisplay;
    ProcessManagementService.currentProcessName = this.currentProcessName;
    this.currentProcessVersion = this.currentProcessDetails.s1ProcessInfo.revision;
    this.currentProcessVerId = this.currentProcessDetails.s1ProcessInfo.id.versionID;

    if (!this.currentProcessDetails.s1ProcessInfo.historic
      && this.currentProcessDetails.s1ProcessInfo.lock === 'LOCKEDBYME') {
      this.isDisabled = false; //to be used at process inner components level; not at process operation buttons level
    } else {
      this.isDisabled = true;
    }
  }

  getSelectedProcessInfo(ids: S1ProcessID): Promise<any> {
    localStorage.removeItem('sharedInBac');
    return new Promise(_resolve => {
      this.docOpenProcess(ids).subscribe(response => {
        localStorage.setItem('sharedInBac', response.bacProcess.sharedInBac);
        this.bacProcessNode = response['bacProcess'];
        this.currentProcessDetails.s1ProcessInfo = response['info'];
        this.openProcessNode = response['info'];
        this.processID = this.openProcessNode['id'].processID;
        this.ioType = this.openProcessNode['ioType'];
        this.processType = this.openProcessNode['processType'];

        this.setProcessData_displayVars(this.currentProcessDetails);
        //set permission for simple users
        if (this.userInfo.getUserRole() === "USER") this.setProcessData_permissions();
        //changes cause of history API bug
        /**todo : bug from API - GetAllProcessHistoryCommand 
                 * - lock,lockedBy,lockedById,since=checkout, committedAt/By=checkin, updatedAt/By=save; should be cleaned
                 * - fix is inside code of how history is formed. because openProcess call also gives wrong for history proc
                */
        if (this.isHistoryProcess) {
          this.currentProcessDetails.s1ProcessInfo.historic = true;
          this.openProcessNode.historic = true;
          this.isDisabled = true;
          this.currentProcessDetails.s1ProcessInfo.lock = "UNLOCKED";
          this.currentProcessDetails.s1ProcessInfo.lockedBy = "";
          this.currentProcessDetails.s1ProcessInfo.lockedByID = null;
          this.currentProcessDetails.s1ProcessInfo.since = null;
        }else{
          this.openProcessNode.historic=false
          this.currentProcessDetails.s1ProcessInfo.historic = false;
        }
      });
      _resolve(true);
    });
  }

  setProcessData_displayVars(data: Data) {
    //set display dates
    if (data.s1ProcessInfo.since) {
      let tempDate = new Date(data.s1ProcessInfo.since);
      data.s1ProcessInfo.sinceDisplay = this.datepipe.transform(tempDate, 'yyyy-MM-dd HH:mm:ss');
    }
    if (data.s1ProcessInfo.updatedAt) {
      let tempDate = new Date(data.s1ProcessInfo.updatedAt);
      data.s1ProcessInfo.updatedAtDisplay = this.datepipe.transform(tempDate, 'yyyy-MM-dd HH:mm:ss');
    }
    if (data.s1ProcessInfo.committedAt) {
      let tempDate = new Date(data.s1ProcessInfo.committedAt);
      data.s1ProcessInfo.committedAtDisplay = this.datepipe.transform(tempDate, 'yyyy-MM-dd HH:mm:ss');
    }
    //set display name
    data.s1ProcessInfo.nameDisplay = data.s1ProcessInfo.name
      + " (" + data.s1ProcessInfo.revision + ")";
  }

  private setProcessData_permissions() {
    ProcessManagementService.openProcessDetailsArray.forEach(node => {
      if (node.s1ProcessInfo.name === this.currentProcessDetails.s1ProcessInfo.name && node.permission) {
        this.currentProcessDetails.permission = node.permission;
        this.currentProcessDetails.userProfileId = node.userProfileId;
      }
    });
    if (!this.currentProcessDetails.permission || this.currentProcessDetails.permission.length === 0) {
      console.log("ERROR: no permission found");
    }
  }

  docReassignProcessToUser(id: S1ProcessID, userid: any): Observable<any> {
    let reassginuser: ProcessUserRequest = new ProcessUserRequest(id, userid);
    return this.http.post<any>('private/processes/user', reassginuser);
  }

  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => new Error(errorMessage));
  }

  addSubMenu(flag: boolean, processName) {
    this.currentProcessName = processName + " (" + this.currentProcessVersion + ")";
    ProcessManagementService.currentProcessName = this.currentProcessName;
    var children: ChildrenItems;
    if (flag) {
      children = {
        name: processName + " (" + this.currentProcessVersion + ")",
        state: '/process-management/process',
        expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
        icon: 'icon-coloured-process.svg',
        children: [
          {
            name: 'Operations',
            state: '/process-management/operation',
            expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
            icon: 'icon-coloured-operations.svg'
          },
          {
            name: 'Libraries',
            state: '/process-management/liabraries',
            expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
            icon: 'icon-coloured-library.svg'
          },
          {
            name: 'Tables',
            state: '/process-management/tables',
            expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
            icon: 'icon-coloured-tables.svg'
          },
          {
            name: 'Campaigns',
            state: '/process-management/campaign',
            expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
            icon: 'icon-coloured-campaign.svg'
          },
          {
            name: 'PMML Models',
            state: '/process-management/pmml',
            expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
            icon: 'icon-coloured-pmml-models.svg'
          },
          {
            name: 'Python Models',
            state: '/process-management/python',
            expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
            icon: 'icon-coloured-python.svg'
          },
          {
            name: 'Whatif',
            state: '/process-management/whatif',
            expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
            icon: 'icon-coloured-whatif.svg'
          }
        ]
      };
    }
    return children;
  }

  addSubChildMenu(name: string) {
    let children = [
      {
        name: 'Operations',
        state: '/process-management/operation',
        expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
        icon: 'icon-coloured-operations.svg'
      },
      {
        name: 'Libraries',
        state: '/process-management/liabraries',
        expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
        icon: 'icon-coloured-library.svg'
      },
      {
        name: 'Tables',
        state: '/process-management/tables',
        expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
        icon: 'icon-coloured-tables.svg'
      },
      {
        name: 'Campaign',
        state: '/process-management/campaign',
        expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
        icon: 'icon-coloured-campaign.svg'
      },
      {
        name: 'PMML',
        state: '/process-management/pmml',
        expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
        icon: 'icon-coloured-pmml-models.svg'
      },
      {
        name: 'Python',
        state: '/process-management/python',
        expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
        icon: 'icon-coloured-python.svg'
      },
      {
        name: 'Whatif',
        state: '/process-management/whatif',
        expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
        icon: 'icon-coloured-whatif.svg'
      }
    ];

    this.menuItem.MENUITEMS[0]['main'][0]['children'][0]['children'].forEach(data => {
      if (data.name === name) {
        data.children = children;
      } else {
        data.children = [];
      }
    });
  }

  getProcessID(): S1ProcessID {
    var processID: S1ProcessID = null;
    if (ProcessManagementService.openProcessDetailsArray && ProcessManagementService.openProcessDetailsArray.length > 0) {
      ProcessManagementService.openProcessDetailsArray.forEach(val => {
        if (this.currentProcessVerId === val.s1ProcessInfo.id.versionID) {
          processID = {
            "processID": val['s1ProcessInfo']['id']['processID'],
            "versionID": val['s1ProcessInfo']['id']['versionID']
          };
        }
      });
    }
    return processID;
  }

  docGetAllProcessVersionsForBac(id: S1ProcessID): Observable<any> {
    return this.http.get<any>('private/bac/process/history?processID=' + id.processID + "&versionID=" + id.versionID);
  }

  showSuccessResposeMessage(data, msg) {
    this.alert.success(msg, data);
  }

  showErrorResposeMessageWithoutRedirect(data, msg) {
    this.alert.error(msg, data);
  }

  showErrorResposeMessage(data, msg?) {
    this.alert.error(msg,data)
    this.router.navigate(['/process-management/process-list']);
  }

  getImageAgainstProcessStatus(s1processinfo: S1ProcessInfo): string {
    let imgSrc: string = "";
    if (s1processinfo)
      if (s1processinfo.lock === "LOCKEDBYME") {
        imgSrc = "assets/img/icon-coloured-checkoutByUser.svg";
      } else if (s1processinfo.lock === "LOCKEDBYOTHER") {
        imgSrc = "assets/img/icon-coloured-checkoutByOtherUser.svg";
      } else if (s1processinfo.lock === "UNLOCKED" && s1processinfo.revisionDeployed === false) {
        imgSrc = "assets/img/icon-coloured-checkinNotDeployed.svg";
      } else if (s1processinfo.lock === "UNLOCKED" && s1processinfo.revisionDeployed === true) {
        imgSrc = "assets/img/icon-coloured-checkinAndDeployed.svg";
      }
    //imgSrc = "assets/img/icon-coloured-process.svg" - this is red proc icon same as checkinNotDeployed.
    return imgSrc;
  }

  createProcess(createProcReq: CreateProcess): Observable<any> {
    return this.http.post<any>('private/processes', createProcReq);
  }

  createXml(request): Observable<any>{
    return this.http.post<any>('private/processes/factory', request);
  }

  getEnvironmentList(): Observable<any> {
    return this.http.get<any>('private/server/deploy/envs');
  }

  compareLayout(oldProcId: S1ProcessID, newProcId: S1ProcessID): Observable<any> {
    let clr: CompareLayoutRequest = new CompareLayoutRequest();
    clr.oldProcessID = oldProcId;
    clr.newProcessID = newProcId;
    return this.http.post<any>('private/processes/compare/layout', clr);
  }

  getProcessHistory(procId: S1ProcessID): Observable<any> {
    return this.http.get<any>('private/processes/revisions?showAllVersions=false&processID=' + procId.processID + '&versionID=' + procId.versionID);
  }

  getMaxLayoutVersion(procId: S1ProcessID): Observable<any> {
    return this.http.get<any>('private/processes/layout/max/version?processID=' + procId.processID + '&versionID=' + procId.versionID);
  }
}
