import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { S1LogQueryParameters } from 'app/entities/s1-logqueryparameters';

@Injectable({
    providedIn: 'root'
})
export class LogTracerService {
    constructor(private http: HttpClient) { }

    docGetLogReport(formVal: UntypedFormGroup): Observable<any> {
        let queryModel: S1LogQueryParameters = new S1LogQueryParameters(formVal);
        return this.http.post<any>( "private/logs/reports", queryModel);
    }
}
