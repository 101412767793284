import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { StorageDetails } from '../../../core/services/user-details.service';
import { AuthenticationService } from './../../../core/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ProcessManagementService } from 'app/core/services/process-management.service';
import { MenuItems } from 'app/shared/menu-items/menu-items';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OidcSecurityService } from 'angular-auth-oidc-client';
const MODAL_OPTIONS: NgbModalOptions = { size: 'lg', backdrop: 'static', keyboard: true, windowClass: 'modal-full-height modal-full-width' };


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public pushRightClass: string;
    public loginUserId: string;

    titleVal: string;
    textVal: string;
    okBtn: string;
    closeBtn: string;
    modalRef: NgbModalRef;
    isImgToggle: boolean = false;
    showAppLoader: boolean = false;
    authenticationMode: string;

    constructor(private authenticationService: AuthenticationService, private userDetails: StorageDetails, public processManagement: ProcessManagementService, private router: Router, private translate: TranslateService
        , public menuItems: MenuItems, private modalService: NgbModal,public oidcSecurityService: OidcSecurityService) {
        this.loginUserId = userDetails.getLoginUserId();

        this.translate.get(['Do you wish to disconnect?', 'All unsaved data will be lost!', 'Ok, Log out', 'Close']).subscribe((text: any) => {
            this.titleVal = text['Do you wish to disconnect?'];
            this.textVal = text['All unsaved data will be lost!'];
            this.okBtn = text['Ok, Log out'];
            this.closeBtn = text['Close'];
        });
    }


    ngOnInit() {
        this.authenticationMode = localStorage.getItem("AuthenticationMode");
        this.pushRightClass = 'push-right';
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }
    
    logout() {
        let data = {
            "titleVal": this.titleVal,
            "textVal": this.textVal,
            "okBtn": this.okBtn,
            "closeBtn": this.closeBtn
        };
        this.authenticationService.logout(data);
        localStorage.clear();
    }

    logoutOIDC() {
        console.log('start logoff');
        //***TEST***ADDED FOR COGNITO IMPLEMENTATION
        if(localStorage.getItem('IdpType') == 'COGNITO'){
            this.oidcSecurityService.logoffLocal()
            this.router.navigate(['/login'])
        }else{
            this.oidcSecurityService.logoff().subscribe((result) => console.log(result));
        }
      }

    getOpenPage(templateInfoPage: TemplateRef<any>) {
        this.modalRef = this.modalService.open(templateInfoPage,MODAL_OPTIONS)
    }

    cancelWindow() {
        this.modalRef.close();
        this.showAppLoader = false;
    }
    ngOnDestroy(){}
}