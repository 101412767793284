import {AfterContentChecked, Component, OnInit, TemplateRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {UserManagementService} from 'app/core/services/user-management.service';
import {StorageDetails} from 'app/core/services/user-details.service';
import {TranslateService} from '@ngx-translate/core';
import {S1UserInfo} from 'app/entities/s1-userInfo';
import {DatePipe, formatDate} from '@angular/common';
import {SpringErrorPayload} from 'app/entities/SpringErrorPayload';
import {S1AuthMode} from 'app/entities/s1-authmode.enum';
import {AlertsService} from 'app/shared/alert/alert-service';
import {NgbCalendar, NgbDateParserFormatter, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmService} from 'app/shared/confirm/confirm.service';
import * as XLSX from 'xlsx';

const MODAL_OPTIONS: NgbModalOptions = {
  size: 'lg',
  backdrop: 'static',
  keyboard: true,
  windowClass: 'modal-full-height modal-full-width'
};

export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

@Component({
  selector: 'app-user-creation',
  templateUrl: './user-creation.component.html',
  styleUrls: ['./user-creation.component.scss'],
})

export class UserCreationComponent implements OnInit,AfterContentChecked {
  userForm: UntypedFormGroup;
  product: any[];
  userDetails: S1UserInfo[] = [];
  activeUserDetails: S1UserInfo[] = [];
  expiredUserDetails: S1UserInfo[] = [];
  modalRef: NgbModalRef;
  selectedUser: any;
  type: string;
  modalTitle: string;
  userId: string;
  language: any;
  role: any;
  isDisableExpiryDate: boolean = false;
  isError: boolean = false;
  passwordPoliciesArray: any;
  errorMessage: string = '';
  expiryOptions: { value: boolean, viewValue: string }[];
  shown: boolean = false;
  textType: any = "password";
  iconClass: any = "fa fa-eye";
  domain: string;
  searchText: string = "";
  okBtn: string;
  titleMsg: string;
  closeBtn: string;
  textVal: string;
  todaydate: Date;
  userIdMsg: string;
  pwdMsg: string;
  nameMsg: string;
  expiryDateMsg: string;
  passwordPolicy: string;
  fetchExpired: boolean = false;
  currentDateTime: Date = new Date();
  isWindowsUserPolicy: boolean = false;
  showAppLoader: boolean = false;
  text_httpError: string;
  text_deleteSuccess: string;
  text_deleteError: string;
  text_createSuccess: string;
  text_updateSuccess: string;
  text_CANNOT_DELETE_LOGGED_USER: string;
  text_CANNOT_DELETE_LOGGED_USER_WITH_LOCKS: string;
  text_userid_required: string;
  text_userid_duplicate: string;
  keysAuthMode = Object.values(S1AuthMode).filter(item => typeof (item) == "string");
  currentUserAuthMode: string;
  syncRoles: string;
  roleChanged: boolean = false;
  // IMPLEMENTAZIONE CIMB
  listaUtentiDaStampareProva = [];
  isAllChecked: boolean = false;
  checkboxIds: string[] = [];
  selectedCheckboxes: { [key: string]: boolean } = {};


  constructor(private router: Router, private modalService: NgbModal, private fb: UntypedFormBuilder, private datePipe: DatePipe,
              private translate: TranslateService, private userInfoService: UserManagementService, private loggedUserInfo: StorageDetails,
              private calendar: NgbCalendar, private parserFormatter: NgbDateParserFormatter, private alert: AlertsService, private confirmService: ConfirmService
  ) {
    this.language = [
      {value: 'en', viewValue: 'English', imgPath: 'assets/img/icon-coloured-lang-english.png'},
      {value: 'es', viewValue: 'Español', imgPath: 'assets/img/icon-coloured-lang-spanish.png'},
      {value: 'fr', viewValue: 'Français', imgPath: 'assets/img/icon-coloured-lang-french.png'},
      {value: 'de-DE', viewValue: 'Deutsche', imgPath: 'assets/img/icon-coloured-lang-german.png'},
      {value: 'it', viewValue: 'Italiano', imgPath: 'assets/img/icon-coloured-lang-italian.png'},
      {value: 'tr-TR', viewValue: 'Türk', imgPath: 'assets/img/icon-coloured-lang-turkish.png'},
      {value: 'ru', viewValue: 'русский', imgPath: 'assets/img/icon-coloured-lang-russian.png'},
      {value: 'zh-CN', viewValue: '中文', imgPath: 'assets/img/icon-coloured-lang-chinese.png'}
    ];
    this.role = loggedUserInfo.getUserRole() === 'MANAGER' ? [
      {value: "USER", viewValue: "Simple User"}] : [
      {value: "USER", viewValue: "Simple User"},
      {value: "SYSTEM_ADMIN", viewValue: "System Administrator"},
      {value: "MANAGER", viewValue: "Manager"},
      {value: "SECURITY_ADMIN", viewValue: "Security Administrator"}
    ];
    this.passwordPoliciesArray = [{
      value: "Password Never Expire",
      viewValue: "Password Never Expire"
    }, {
      value: "Change Password to Next Login",
      viewValue: "Change Password to Next Login"
    }, {value: "Exclude from password security policies", viewValue: "Exclude from password security policies"}];
    this.expiryOptions = [{value: true, viewValue: "Never Expire"}, {value: false, viewValue: "Set Expiry Date"}]
    this.todaydate = new Date();
    this.translate.use(loggedUserInfo.getLanguage());
    this.getValueAgainstKeyForTranslate();
  }

  ngOnInit() {
    this.currentUserAuthMode = JSON.parse(localStorage.getItem('userInfo')).loginCredentials.authMode
    this.syncRoles = localStorage.getItem('syncRoles');
    this.fetchAll(true);
  }

  private getValueAgainstKeyForTranslate() {
    this.translate.get(['Ok',
      'Are you sure to delete user -',
      'Close',
      'Please provide valid and unique UserId under size 20. (Only Letters (a-z)(A-Z), Numbers(0-9), Currency characters are allowed.)',
      'The password and confirmation password do not match. Re-enter password in both fields.',
      'Letters (a-z) (A-Z), numbers and space (except leading and trailing spaces) are allowed.',
      "Password Policies failed. You can continue with the same password by selecting 'exclude' option from Password Settings OR update your password as per policy.",
      "Expiry Date is required",
      "Server communication error occurred. Please reload the page or Try again later.",
      "User deleted successfully!",
      "User delete failed!",
      "Can not delete logged in user.",
      "User created successfully!",
      "User updated successfully!",
      "UserID is required",
      "UserID already exists"
    ])
      .subscribe((text: any) => {
        this.okBtn = text['Ok'];
        this.titleMsg = text['Are you sure to delete user -'];
        this.closeBtn = text['Close'];
        this.userIdMsg = text['Please provide valid and unique UserId under size 20. (Only Letters (a-z)(A-Z), Numbers(0-9), Currency characters are allowed.)'];
        this.pwdMsg = text['The password and confirmation password do not match. Re-enter password in both fields.'];
        this.nameMsg = text['Letters (a-z) (A-Z), numbers and space (except leading and trailing spaces) are allowed.'];
        this.passwordPolicy = text["Password Policies failed. You can continue with the same password by selecting 'exclude' option from Password Settings OR update your password as per policy."];
        this.expiryDateMsg = text["Expiry Date is required"];
        this.text_httpError = text["Server communication error occurred. Please reload the page or Try again later."];
        this.text_deleteSuccess = text["User deleted successfully!"];
        this.text_deleteError = text["User delete failed!"];
        this.text_createSuccess = text["User created successfully!"];
        this.text_updateSuccess = text["User updated successfully!"];
        this.text_CANNOT_DELETE_LOGGED_USER = text["Can not delete logged in user."];
        this.text_CANNOT_DELETE_LOGGED_USER_WITH_LOCKS = text["Can not delete User with locks on a Decision Process."];
        this.text_userid_required = text["UserID is required"];
        this.text_userid_duplicate = text["UserID already exists"];
      });
  }

  fetchAll(isReloadData: boolean) {
    this.showAppLoader = true;
    let oldSearchText = this.searchText;
    this.searchText = '';
    if (isReloadData) {
      this.userDetails = [];
      this.userInfoService.authGetAllUsers().subscribe({
        next: data => {
          let receivedUsers: S1UserInfo[] = data['s1UserInfo'];
          //update dates for search,sort purpose
          this._fetchAll_setDates(receivedUsers);
          //form expired
          this.currentDateTime = new Date();
          this.expiredUserDetails = receivedUsers
            .filter(s1user => s1user.expiredOn && s1user.expiredOn.getTime() < this.currentDateTime.getTime());
          //form active
          this.activeUserDetails = receivedUsers
            .filter(s1user => s1user.expiredOn === null || (s1user.expiredOn && s1user.expiredOn.getTime() > this.currentDateTime.getTime()));
          if (this.fetchExpired) {
            this.userDetails = this.expiredUserDetails;
          } else {
            this.userDetails = this.activeUserDetails;
          }
          this.searchText = oldSearchText;
          this.showAppLoader = false;
        }, error: errdata => {
          this.searchText = oldSearchText;
          this.handleHttpError(errdata);
        }
      });
    } else {
      if (this.fetchExpired) {
        this.userDetails = this.expiredUserDetails;
      } else {
        this.userDetails = this.activeUserDetails;
      }
      this.searchText = oldSearchText;
      this.showAppLoader = false;
    }
  }

  fetchAllExpired() {
    const checkbox = document.getElementById('checkbox') as HTMLInputElement;
     checkbox.checked = false;

      this.fetchExpired = !this.fetchExpired;
    this.fetchAll(false);
  }

  _fetchAll_setDates(receivedUsers: S1UserInfo[]) {
    for (let receivedUser of receivedUsers) {
      if (receivedUser.loggedAt) {
        let tempdate: Date = new Date(receivedUser.loggedAt);
        receivedUser.loggedAt = tempdate; //NOTE: re-assignment of Date is must for java.util.Date->Angular Date. Else fails.
        receivedUser.loggedAtDisplay = this.datePipe.transform(tempdate, 'yyyy-MM-dd HH:mm:ss');
      }
      if (receivedUser.expiredOn) {
        let tempdate: Date = new Date(receivedUser.expiredOn);
        receivedUser.expiredOn = tempdate;
        receivedUser.expiredOnDisplay = this.datePipe.transform(tempdate, 'yyyy-MM-dd HH:mm:ss');
      }
    }
  }

  open(template: TemplateRef<any>, type, user: S1UserInfo) {
    this.type = type;
    this.isError = true;
    this.isWindowsUserPolicy = false;
    this.errorMessage = '';
    switch (type) {
      case "Edit":
        this.roleChanged = false;
        this.modalTitle = 'Edit User';
        this.isDisableExpiryDate = true;
        this.modalRef = this.modalService.open(template, MODAL_OPTIONS);
        this.editUser(user);
        this.isDisabled(type, user);
        break;
      case "Delete":
        this.deleteUser(user);
        break;
      default:
        if (type === "New") {
          this.modalTitle = "Create User";
          this.modalRef = this.modalService.open(template, MODAL_OPTIONS);
          this.newUser();
          this.isDisableExpiryDate = true;
          this.isDisabled(type, user);
        }
        break;
    }
    this.getValueAgainstKeyForTranslate();
  }

  decline(): void {
    this.isError = false;
    this.showAppLoader = false;
    this.errorMessage = '';
    this.modalRef.close();
  }

  newUser() {
    this.userForm = this.fb.group({
      'authmode': '',
      'username': ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$')])],
      'password': ['', Validators.compose([Validators.required])],
      'confirmPassword': ['', Validators.compose([Validators.required])],
      'startDate': [this.todaydate],
      'creationDate': '',
      'staffID': '',
      'passwordPolicies': '',
      'name': ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$')])],
      'surName': ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$')])],
      'language': '',
      'role': '',
      'expiredOn': '',
      'expiryDate': '',
      'organization': '',
      'company': '',
      'description': '',
      'email': ['', Validators.compose([Validators.required, Validators.pattern('^[A-Za-z0-9_+-]+(\.[A-Za-z0-9_+-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$')])],
      'sid': null
    });
    this.isError = true;
    const nameControl = this.userForm.get('name');
    nameControl.setErrors({
      "name": true
    });
    const surNameControl = this.userForm.get('surName');
    surNameControl.setErrors({
      "surName": true
    });
    this.userForm.controls['role'].setValue(this.role[0]['value']);
    this.userForm.controls['language'].setValue(this.language[0]['value']);
    this.userForm.controls['expiredOn'].setValue(this.expiryOptions[0]['value']);
  }

  editUser(user: S1UserInfo) {
  //  console.log(user);
    this.isWindowsUserPolicy = user.loginCredentials.authMode === this.keysAuthMode[S1AuthMode.WINDOWS] ? true : false;
    this.isDisableExpiryDate = user.expiredOn == null ? true : false;

    let passwordPoliciesArrayMine = [];
    if (user.loginCredentials.passwordPolicies['neverExpire']) {
      passwordPoliciesArrayMine.push("Password Never Expire");
    }
    if (user.loginCredentials.passwordPolicies['changeAtLogin']) {
      passwordPoliciesArrayMine.push("Change Password to Next Login");
    }
    if (!user.loginCredentials.passwordPolicies['enforcePolicies']) {
      passwordPoliciesArrayMine.push("Exclude from password security policies");
    }

    let expDate = user.expiredOn ? {
      day: new Date(user.expiredOn).getDate(),
      month: new Date(user.expiredOn).getMonth() + 1,
      year: new Date(user.expiredOn).getFullYear()
    } : null;

    this.userForm = this.fb.group({
      "authmode": user.loginCredentials.authMode,
      "id": user['id'].userId,
      'username': user.loginCredentials.userId,
      'creationDate':  this.datePipe.transform(new Date(user.createdOn), 'yyyy-MM-dd HH:mm:ss'),
      'staffID': user.staffID,
      'password': user.loginCredentials.password,
      'confirmPassword': user.loginCredentials.newPassword,
      'passwordPolicies': [passwordPoliciesArrayMine],
      'name': [user.name, Validators.compose([
        Validators.required,
        Validators.pattern('^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$')])],
      'surName': [user.surname, Validators.compose([
        Validators.required,
        Validators.pattern('^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$')])],
      'language': user.language,
      'organization': user.organization,
      'company': user.company,
      'description': user.notes,
      'role': user.role,
      'expiredOn': user.expiredOn == null ? this.expiryOptions[0]['value'] : this.expiryOptions[1]['value'],
      'expiryDate': expDate,
      'startDate': [this.todaydate],
      'email': user.email,
      'sid': null
    });
  }

  resetControlls(value: any) {
    this.isError = false;
    const pwdControl = this.userForm.get('password');
    pwdControl.setErrors(null);
    const confirmPwdControl = this.userForm.get('confirmPassword');
    confirmPwdControl.setErrors(null);
    const passwordPolicyControl = this.userForm.get('passwordPolicies');
    passwordPolicyControl.setErrors(null);
    const expiryDateControl = this.userForm.get('expiryDate');
    expiryDateControl.setErrors(null);
  }

  saveUser() {
    if (!this._userFormValidations(true)) return;
    this.errorMessage = '';
    this.showAppLoader = true;
    this.userForm.disable();
    if (this.userForm.value.expiredOn) {
      this.userForm.value.expiryDate = null;
    }
    //fixed auth mode
    this.userForm.patchValue({'authmode': this.keysAuthMode[S1AuthMode.STRATEGYONE]});
    //call API
    this.userForm.controls['expiryDate'].value != '' ? this.userForm.controls['expiryDate'].setValue(new Date(this.parserFormatter.format(this.userForm.controls['expiryDate'].value)).toISOString()) : null;
    this.userInfoService.authCreateUser(this.userForm).subscribe({
      next: resObject => {
        let tempuser: S1UserInfo = resObject;
        if (tempuser.expiredOn) {
          let tempdate: Date = new Date(tempuser.expiredOn);
          tempuser.expiredOnDisplay = this.datePipe.transform(tempdate, 'yyyy-mm-dd HH:mm:ss');
        }
        if (tempuser.expiredOn && new Date(tempuser.expiredOn).getTime() < new Date().getTime()) {
          this.expiredUserDetails.push(tempuser);
        } else {
          this.activeUserDetails.push(tempuser);
        }
        this.fetchAll(false);
        this.alert.success(this.text_createSuccess);
        this.showAppLoader = false;
        this.userForm.enable();
        this.modalRef.close();
        this.isError = false;
        this.errorMessage = '';

      }, error: errObject => {
        let resultObj: SpringErrorPayload = errObject['error'];
        if (resultObj.code === 'WRONG_PASSWORD_POLICIES') {
          this.isError = true;
          this.errorMessage = this.passwordPolicy;
          //Highlighting text box
          const pwdControl = this.userForm.get('password');
          pwdControl.setErrors({
            "password": true
          });
          const confirmPwdControl = this.userForm.get('confirmPassword');
          confirmPwdControl.setErrors({
            "confirmPassword": true
          });
          const passwordPolicyControl = this.userForm.get('passwordPolicies');
          passwordPolicyControl.setErrors({
            "passwordPolicies": true
          });
        } else {
          this.userForm.enable();
       //   console.log("authCreateUser =");
         // console.log(errObject);
          this.handleHttpError(errObject);
        }
        this.showAppLoader = false;
        this.userForm.enable();
      }
    }); //API call end.
  }

  private _userFormValidations(isNewUser: boolean): boolean {
    //check form validity
    if (this.userForm.invalid) {
      return false;
    }
    //check expiry situation
    if (!this.userForm.value.expiredOn && this.userForm.value.expiryDate === null) {
      this.userForm.get('expiryDate').setErrors({"expiryDate": true});
      this.isError = true;
      this.errorMessage = this.expiryDateMsg;
      return false;
    }
    //check password mismatch
    if (this.userForm.controls['password'].value !== this.userForm.controls['confirmPassword'].value) {
      const pwdControl = this.userForm.get('password');
      pwdControl.setErrors({
        "password": true
      });
      const confirmPwdControl = this.userForm.get('confirmPassword');
      confirmPwdControl.setErrors({
        "confirmPassword": true
      });
      this.isError = true;
      this.errorMessage = this.pwdMsg;
      return false;
    }
    //check duplicate userid
    let isDuplicate: boolean = false;
    if (isNewUser) {
      this.expiredUserDetails.forEach(user => {
        if (user.loginCredentials.userId.toUpperCase() === String(this.userForm.value.username).toUpperCase()) isDuplicate = true;
      });
      this.activeUserDetails.forEach(user => {
        if (user.loginCredentials.userId.toUpperCase() === String(this.userForm.value.username).toUpperCase()) isDuplicate = true;
      });
    }
    if (isDuplicate) {
      this.userForm.get('username').setErrors({"username": true});
      this.isError = true;
      this.errorMessage = this.text_userid_duplicate;
      return false;
    }
    return true;
  }

  updateUser() {
    if (!this._userFormValidations(false)) return;
    this.errorMessage = '';
    this.showAppLoader = true;
    this.userForm.disable();
    if (this.userForm.value.expiredOn) {
      this.userForm.value.expiryDate = null;
    }
    if (!this.roleChanged && this.userForm.controls['authmode'].value == "OAUTH2" && this.currentUserAuthMode == "OAUTH2") {
      this.userForm.controls['role'].setValue(null)
    }
    //fixed auth mode
    // this.userForm.value.authmode = this.keysAuthMode[S1AuthMode.STRATEGYONE];
    //call API
    this.userForm.controls['expiryDate'].value != '' && this.userForm.controls['expiryDate'].value != null ? this.userForm.controls['expiryDate'].setValue(new Date(this.parserFormatter.format(this.userForm.controls['expiryDate'].value)).toISOString()) : null;
    this.userInfoService.updateUsers(this.userForm, false).subscribe({
      next: resObject => {
        //update existing users list manually
        let updatedUser: S1UserInfo = resObject;
        let tempArr: S1UserInfo[] = [];
        tempArr.push(updatedUser);
        this._fetchAll_setDates(tempArr);
        this.fetchAll(true);
        this.currentDateTime = new Date();
        this.alert.success(this.text_updateSuccess);
        this.showAppLoader = false;
        this.userForm.enable();
        this.modalRef.close();
        this.isError = false;
        this.errorMessage = '';
      }, error: errObject => {
        let resultObj: SpringErrorPayload = errObject['error'];
        if (resultObj.code === 'WRONG_PASSWORD_POLICIES') {
          this.isError = true;
          this.errorMessage = this.passwordPolicy;
          //Highlighting text box
          const pwdControl = this.userForm.get('password');
          pwdControl.setErrors({
            "password": true
          });
          const confirmPwdControl = this.userForm.get('confirmPassword');
          confirmPwdControl.setErrors({
            "confirmPassword": true
          });
          const passwordPolicyControl = this.userForm.get('passwordPolicies');
          passwordPolicyControl.setErrors({
            "passwordPolicies": true
          });
        } else {
          this.userForm.enable();
          this.isError = true;
          this.handleHttpError(errObject);
          console.log("authUpdateUser =");
          console.log(errObject);
        }
        this.showAppLoader = false;
        this.userForm.enable();
      }
    });  //API call end.

  }

  deleteUser(user: S1UserInfo) {
    this.isError = false;
    this.confirmService.confirm(this.titleMsg + user.loginCredentials.userId + '?').subscribe(confirmed => {
      if (confirmed) {
        this.userInfoService.deleteSelectedDataByUserId(user.id.userId).subscribe({
          next: _data => {
            //this.router.navigate(['/installation-management/user-creation']);
            this.alert.success(this.text_deleteSuccess);
            this.currentDateTime = new Date();
            this.fetchAll(true);
            this.showAppLoader = false;
          },
          error: errdata => {
           if  (errdata['error'].code  === 'CANNOT_DELETE_LOGGED_USER_WITH_LOCKS'){
              this.alert.error('Can not delete User with locks on a Decision Process.')
              this.showAppLoader = false;
            } else
            if (errdata['error'].code  === 'CANNOT_DELETE_LOGGED_USER') {
              this.alert.error('Can not delete logged in user.')
              this.showAppLoader = false;
            }   else {
              this.handleHttpError(errdata);
              this.alert.error(errdata.name + ": " + errdata.status, this.text_deleteError,)
              this.showAppLoader = false;
            }
          }
        });
      }
    });
  }

  handleHttpError(errdata: any) {
    this.isError = true;
    this.errorMessage = errdata.name + ": " + errdata.status + ". "
      + this.text_httpError;
    this.showAppLoader = false;
  }

  isDisabled(type, s1user: S1UserInfo) {
    if (type === 'Edit') {
      this.userForm.controls['username'].disable();
      this.userForm.controls['creationDate'].disable();

      // windows users' password shouldnt be changed.
      if (s1user.loginCredentials.authMode === this.keysAuthMode[S1AuthMode.WINDOWS]) {
        this.userForm.controls['password'].disable();
        this.userForm.controls['confirmPassword'].disable();
        this.userForm.controls['passwordPolicies'].disable();
      }
      //only password, related fields and language change allowed for self
      if (s1user.id.userId === this.loggedUserInfo.getUserId()) {
        this.userForm.controls['name'].disable();
        this.userForm.controls['surName'].disable();
        this.userForm.controls['company'].disable();
        this.userForm.controls['organization'].disable();
        this.userForm.controls['description'].disable();
        this.userForm.controls['role'].disable();
        this.userForm.controls['staffID'].disable();
        this.userForm.controls['expiredOn'].disable();
        this.userForm.controls['expiryDate'].disable();
      }
      /* everything else enabled for all other logged in and logged off users SAME as designer !
      */

      if (this.currentUserAuthMode == this.keysAuthMode[S1AuthMode.OAUTH2] && s1user.loginCredentials.authMode == this.keysAuthMode[S1AuthMode.STRATEGYONE]) {
        for (let control in this.userForm.controls) {
          this.userForm.controls[control].disable()
        }
      } else if (this.currentUserAuthMode == this.keysAuthMode[S1AuthMode.OAUTH2] && s1user.loginCredentials.authMode.toString() == this.keysAuthMode[S1AuthMode.OAUTH2]) {
        this.userForm.controls['password'].disable();
        this.userForm.controls['confirmPassword'].disable();
        this.userForm.controls['passwordPolicies'].disable();
        this.syncRoles == 'TRUE' ? this.userForm.controls['role'].disable() : null;
      }

    } else {
      this.userForm.controls['username'].enable();
      this.userForm.controls['creationDate'].disable();
    }
  }

  roleIsChanged() {
    this.roleChanged = !this.roleChanged
  }

  showPassword() {
    this.shown = !this.shown;
    if (this.shown) {
      this.textType = "text";
      this.iconClass = "fa fa-eye-slash";
    } else {
      this.textType = "password";
      this.iconClass = "fa fa-eye";
    }
  }

  changeValueOfExpiryDate(event: boolean) {
    this.isDisableExpiryDate = !this.isDisableExpiryDate;
    if (event) {//never expire
      this.userForm.get('expiryDate').reset(null);
    } else {
      this.userForm.get('expiryDate').setErrors({"expiryDate": true});
    }
  }

  exportAsXLSX() {
   // console.log('LISTA UTENTE ->   ', this.listaUtentiDaStampareProva);

    const arraydiArray =  this.listaUtentiDaStampareProva.map(obj => Object.values(obj));
    const workbook = XLSX.utils.book_new();
    const name = this.loggedUserInfo.getLoginName()
    const lastName = this.loggedUserInfo.getLoginSurname()
    const userName = this.loggedUserInfo.getLoginUserId()
    const data = [
      ['User Listing Report'],
      ['Generate By:', userName + ' ' + '(' + name + ',' + lastName + ')'],
      ['Generate Date:', formatDate(new Date(), 'dd/MM/yyyy, hh:mm:ss a', 'en-US')],
      [''],
      // funzione today  TODO  Authentication = login-credential   -     User_status TBD TO BE DEFINED :)   -     Last_Successful_Login_Date = loggedAt
      ['Authentication', 'Domain', 'User_ID', 'Name', 'Last_Name', 'Staff_Number', 'Email_Address', 'Company', 'Organization', 'Role', 'User_Status', 'Expiry_Date', 'Last_Successful_Login_Date', 'Creation_Date'],
      ...arraydiArray
    ]

    const worksheet = XLSX.utils.aoa_to_sheet(data);

    var wscols = [
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 15},
      {wch: 25},
      {wch: 15},

    ];
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'User Listing Report');
    XLSX.writeFile(workbook, 'User Listing Report.xlsx');

  }


  utenteSelezionato(ev: Event, user) {
    const target = ev.target as HTMLInputElement;
    const isChecked = target.checked;
   // console.log('isChecked', isChecked);
    if (isChecked) {
      const newUser = this.createUser(
        user.loginCredentials.authMode,
        user.loginCredentials.domain,
        user.loginCredentials.userId,
        user.name,
        user.surname,
        user.staffID,
        user.email,
        user.company,
        user.organization,
        user.role,
        user.userState,
        user.expiredOn ? formatDate(new Date(user.expiredOn), 'dd/MM/yyyy, hh:mm:ss a', 'en-US'):'',
        user.loggedAt ? formatDate(new Date(user.loggedAt), 'dd/MM/yyyy, hh:mm:ss a', 'en-US'):'',
        user.createdOn ? formatDate(new Date(user.createdOn), 'dd/MM/yyyy, hh:mm:ss a', 'en-US'):'');
      this.listaUtentiDaStampareProva.push(newUser)
    } else {

      this.listaUtentiDaStampareProva = this.removeUser(user.loginCredentials.userId);
    }

  }

  createUser(authMode: string,
             domain: string,
             id: string,
             name: any,
             surname: string,
             staffID: string,
             email: string,
             company: string,
             organization: string,
             role: string,
             userState: string,
             expiredOn: string,
             loggedAt: string,
             createdOn: string,
  ): User {

    return {
      authMode,
      domain,
      id,
      name,
      surname,
      staffID,
      email,
      company,
      organization,
      role,
      userState,
      expiredOn,
      loggedAt,
      createdOn
    };
  }

  removeUser(userId: number): User[] {
    return this.listaUtentiDaStampareProva.filter(user => user.id !== userId);
  }
  isCheckedAllButton:boolean;
  ngAfterContentChecked(): void {
    this.updateCheckboxes();
  }
  updateCheckboxes() {

    if(this.checkboxIds.length !==0){
      this.checkboxIds.forEach(id => {
        const checkbox = document.getElementById(id) as HTMLInputElement;
        if (checkbox) {
          checkbox.checked = this.isAllChecked;

        }
      });
    }}
  toggleAllCheckboxes(users,userDetails,isCheckedAllButton) {
    this.checkboxIds = [];

    this.listaUtentiDaStampareProva = []
    this.generateCheckboxIds(userDetails);


    this.isAllChecked = !this.isAllChecked;
    this.isCheckedAllButton = isCheckedAllButton;
    if (this.isAllChecked) {
      this.makeUserListForPrint(userDetails);
      this.updateCheckboxes();

    } else {
      this.updateCheckboxes();

      this.listaUtentiDaStampareProva = []
      this.checkboxIds=[]
    }
  }

  generateCheckboxIds(userDetails) {
    for (let i = 0; i <= userDetails.length-1; i++) {
      this.checkboxIds.push(this.userDetails[i].loginCredentials.userId+this.userDetails[i].loginCredentials.domain);
      this.checkboxIds.sort((a, b) => {
        const nameA = a.toLowerCase(); // Presuppone che l'oggetto abbia una proprietà 'Name'
        const nameB = b.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

    }
  }

  private makeUserListForPrint(userDetails: any[]): User[] {

    userDetails.map(user =>{
      const newUser = this.createUser(
        user.loginCredentials.authMode,
        user.loginCredentials.domain,
        user.loginCredentials.userId,
        user.name,
        user.surname,
        user.staffID,
        user.email,
        user.company,
        user.organization,
        user.role,
        user.userState,
        user.expiredOn ? formatDate(new Date(user.expiredOn), 'dd/MM/yyyy, hh:mm:ss a', 'en-US'):'',
        user.loggedAt ? formatDate(new Date(user.loggedAt), 'dd/MM/yyyy, hh:mm:ss a', 'en-US'):'',
        user.createdOn ? formatDate(new Date(user.createdOn), 'dd/MM/yyyy, hh:mm:ss a', 'en-US'):'');
      this.listaUtentiDaStampareProva.push(newUser)
    }

  )
    return this.listaUtentiDaStampareProva
  }
}

interface User {
  authMode: string;
  domain: string;
  id: string;
  name: string;
  surname: string;
  staffID: string;
  email: string;
  company: string;
  organization: string;
  role: string;
  userState: string;
  expiredOn: string;
  loggedAt: string;
  createdOn: string;
}
