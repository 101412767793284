import { Pipe, PipeTransform } from '@angular/core';
import { StorageDetails } from "app/core/services/user-details.service";
@Pipe({
    name: 'inputFilter'
})
export class DataInputFilterPipe implements PipeTransform {
    constructor(private userService: StorageDetails) { }
    public transform(value, keys: string, term: string) {
        let input = JSON.parse(this.userService.getInputData());
        let result = "";
        input.forEach(element => {
            element.values.valueState.forEach(element1 => {
                if (element1["-code"] === keys || element1["_code"] === keys || element1["code"] === keys) {
                    if (element1["value"] !== null && element1["value"]["value"] !== null) {
                        result = element1["value"]["value"];
                    } else if (element1["status"] === "N") {
                        result = "[Not Available]";
                    } else if (element1["status"] === "A") {
                        result = "[Available]";
                    } else if(element1["status"] === "M" ){
                        result = "[Missing]";
                    } else if (element1["value"] === null || (element1["else"] != null && element1["else"] == "")) {
                        result = "[Else]";
                    } 
                }
            });
        });
        return result;
    }
}