import { Inject, inject, Injectable, InjectionToken, InjectOptions } from '@angular/core';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

export type alertsPopType = 'info' | 'success' | 'warning' | 'error' | undefined;

export const ALERTS_POPPER: InjectionToken<AlertsPopper> = new InjectionToken('AlertsPopper', {
    providedIn: 'root',
    factory: () =>
        inject(ALERTS_POPPER, <InjectOptions>{ optional: true, skipSelf: true }) || getAlertsDefaultPopper(),
});

export interface AlertsPopper {
    pop(type: alertsPopType, message: string, title: string): void;
}

export function getAlertsDefaultPopper(): AlertsPopper {
    return new AlertsDefaultPopper();
}

@Injectable()
export class AlertsDefaultPopper implements AlertsPopper {

    popList: HTMLDivElement[] = [];

    pop(type: alertsPopType, message: string, title: string): void {
      let p = document.createElement('div');
      switch(type) {
        case 'error': p.classList.add('alert','alert-danger','cds-alert'); p.insertAdjacentHTML('beforeend', '<div class="cds-alert-icon"><i class="fa fa-exclamation-circle"></i></div>'); break;
        case 'warning': p.classList.add('alert','alert-warning','cds-alert'); p.insertAdjacentHTML('beforeend', '<div class="cds-alert-icon"><i class="fa fa-exclamation-triangle"></i></div>'); break;
        case 'success': p.classList.add('alert','alert-success','cds-alert'); p.insertAdjacentHTML('beforeend', '<div class="cds-alert-icon"><i class="fa fa-check-circle"></i></div>'); break;
        default: p.classList.add('alert','alert-primary','cds-alert'); p.insertAdjacentHTML('beforeend', '<div class="cds-alert-icon"><i class="fa fa-info-circle"></i></div>'); break;
      }
      p.style.position = 'absolute';
      p.style.zIndex = '9999';
      p.style.right = '20px';
      p.style.top = this.popList.length === 0 ? '20px' : `${this.popList[this.popList.length-1].getBoundingClientRect().bottom + 20}px`;
  
      if(!!title && title.length > 0) {
        let t = document.createElement('div');
        t.innerText = title;
        t.style.fontWeight = '700';
		t.style.marginRight = '10px'
        p.appendChild(t);
      }
      if(!!message && message.length > 0) {
        let m = document.createElement('div');
        m.innerText = message;
        p.appendChild(m);
      }
      this.add(p);
    }
  
    add(p: HTMLDivElement) {
      this.popList.push(p);
      document.body.appendChild(p);
      setTimeout(() => { this.remove(p) }, 5000);
    }
  
    remove(p: HTMLDivElement) {
      this.popList.splice(this.popList.indexOf(p), 1);
      document.body.removeChild(p);
    }
      
}

@Injectable()
export class AlertsService {

    constructor(@Inject(ALERTS_POPPER) private popper: AlertsPopper) {
    }

    private pop(type: alertsPopType, messageKey: string, titleKey: string) {
            this.popper.pop(type, messageKey, titleKey);
    }

    info(messageKey: string, titleKey: string = 'INFO') {
        this.pop('info', messageKey, titleKey);
    }

    success(messageKey: string, titleKey: string = 'SUCCESS') {
        this.pop('success', messageKey, titleKey);
    }

    warning(messageKey: string, titleKey: string = 'WARNING') {
        this.pop('warning', messageKey, titleKey);
    }

    error(messageKey: string, titleKey: string = 'ERROR') {
        this.pop('error', messageKey, titleKey);
    }

    log(error: HttpResponse<any> | any, showAlert: boolean = true) {
        if (!error)
            return;
        if (error instanceof HttpErrorResponse) {
            if (showAlert)
                this.error('SERVER_ERROR');
            console.error(`HTTP Error! Status: ${error.status}. Message: ${error.message}`);
        } else {
            if (showAlert)
                this.error('GENERIC_ERROR');
            console.error(error);
        }
    }

}
