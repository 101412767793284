<div class="col-sm-12" *ngIf="isROLE_SECURITY_ADMIN">
    <br />
    <h5>
        <mat-error>{{"Security administrators have no permissions to work with processes." | translate}}</mat-error>
    </h5>
</div>
<div class="row" *ngIf="!isROLE_SECURITY_ADMIN">
    <div class="col-sm-12">
        <mat-error></mat-error>
    </div>
    <div class="col-sm-12">
        <h6 class="mb-2">{{ 'Available processes for user :' | translate }} 
            <h6 class="badge cds-badge active text-bg-info m-0">
                <i class="fa fa-user"></i>
                {{showLoginUserName}}
            </h6>
        </h6>
    </div>
</div>
<div class="row" *ngIf="!isROLE_SECURITY_ADMIN">
    <div class="col-sm-5">
        <div class="col-sm-12">
            <appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>
        </div>
        <div class="col-sm-12">
            <div class="card cds-card-shadow p-3">
                <div *ngIf="files1 && files1.length>0">
                    <p-treeTable [value]="files1" [columns]="cols" selectionMode="single" dataKey="name"
                        (onNodeSelect)="nodeSelect($event)" *ngIf="files1 && files1.length>0">
                        <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                            <span>
                                <p [ttRow]="rowNode" [ttSelectableRow]="rowNode">
                                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                    <img *ngIf="rowData.type=='installation'" alt="i-installation"
                                        src="assets/img/icon-coloured-installation.svg"
                                        style="width: 20px; height: 20px;" />
                                    <img *ngIf="rowData.type=='group'" alt="i-group"
                                        src="assets/img/icon-coloured-group.svg" style="width: 20px; height: 20px;" />
                                    <img *ngIf="rowData.type=='institute'" alt="i-organization"
                                        src="assets/img/icon-coloured-organization.svg"
                                        style="width: 20px; height: 20px;" />
                                    <img *ngIf="rowData.type=='process' && rowData.s1ProcessInfo" alt="i-process"
                                        src="{{nodeService.getImageAgainstProcessStatus(rowData.s1ProcessInfo)}}"
                                        style="width: 20px; height: 20px;" />
                                    <span [ngStyle]="{'color': getColorForGroup(rowData.name), 'cursor' : getCursor(rowData) }">
                                        <span *ngIf="rowData.s1ProcessInfo">({{rowData.s1ProcessInfo.revision}})</span>
                                        {{rowData.name}}
                                    </span>
                                </p>
                            </span>
                        </ng-template>
                    </p-treeTable>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-7">
        <app-process></app-process>
    </div>
</div>