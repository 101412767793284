import { Component, HostListener, OnInit } from '@angular/core';
import { S1PythonModel } from 'app/entities/s1-pythonmodel';
import { PythonManagementService } from './../../../core/services/python-management.service';
import { S1PythonCommand } from 'app/entities/s1-pythoncommand.enum';
import { S1PythonActivityReport } from 'app/entities/s1-pythonactivityreport';
import { S1PythonModelState } from 'app/entities/s1-pythonmodelstate.enum';
import { ResMap } from 'app/entities/resmap';
import { TranslateService } from '@ngx-translate/core';
import { SpringErrorPayload } from 'app/entities/SpringErrorPayload';
import { Buffer } from 'buffer';
import { AlertsService } from 'app/shared/alert/alert-service';
import { ConfirmService } from 'app/shared/confirm/confirm.service';

@Component({
  selector: 'app-python-management',
  templateUrl: './python-management.component.html',
  styleUrls: ['./python-management.component.scss']
})
export class PythonManagementComponent implements OnInit {

  pythonModelArray: S1PythonModel[];
  isEnableInstall: boolean;
  isEnableUninstall: boolean;
  isEnableStart: boolean;
  isEnableStop: boolean;
  isEnableTestConnection: boolean;
  currentRow: S1PythonModel;
  currentPythonActivityReport: S1PythonActivityReport;
  apiResMap: ResMap;
  isError: boolean = false;
  clickedPort: number;
  showAppLoader: boolean = false;
  errorMessage: string = "";
  searchText: string = "";
  isDirty: boolean = false;
  okBtn: string;
  refreshTitleValue: string;
  refreshTextValue: string;
  refreshOkValue: string;
  refreshCloseValue: string;
  exePythonFailOnConnectionTitle: string;
  exePythonPassOnConnectionTitle: string;
  exePythonFailOnOperationTitle: string;
  exePythonPassOnOperationTitle: string;
  exePythonFailOnAutoStartTitle: string;
  exePythonPassOnAutoStartTitle: string;
  exePythonText: string;
  text_err_pythonConnection: string;
  text_err_contactAdmin: string;


  constructor(private pythonManagementService: PythonManagementService, private translate: TranslateService, private alert: AlertsService, private confirmService: ConfirmService) {
    this.isEnableInstall = false;
    this.isEnableUninstall = false;
    this.isEnableStart = false;
    this.isEnableStop = false;
    this.isEnableTestConnection = false;
    this.pythonModelArray = [];
    this.currentRow = new S1PythonModel();
    this.currentPythonActivityReport = new S1PythonActivityReport();
    this.apiResMap = new ResMap();
    this.getValueAgainstKeyForTranslate();
  }

  private getValueAgainstKeyForTranslate() {
    this.translate.get(['Ok'
      , 'Do you sure to refresh all records?'
      , 'This might take time!', 'Ok, Refresh all'
      , 'Close'
      , 'Current record status will be refreshed now...'
      , 'Failed to turn on Auto Start'
      , 'Failed to turn off Auto Start'
      , 'Operation failed!'
      , 'Connection test failed!'
      , 'Connection tested successfully!'
      , 'Operation successfully completed!'
      , 'Unable to connect with python server.'
      , 'Please contact the administrator.'])
      .subscribe((text: any) => {
        this.okBtn = text['Ok'];
        this.refreshTitleValue = text['Do you sure to refresh all records?'];
        this.refreshTextValue = text['This might take time!'];
        this.refreshOkValue = text['Ok, Refresh all'];
        this.refreshCloseValue = text['Close'];
        this.exePythonText = text['Current record status will be refreshed now...'];
        this.exePythonPassOnAutoStartTitle = text['Failed to turn on Auto Start'];
        this.exePythonFailOnAutoStartTitle = text['Failed to turn off Auto Start'];
        this.exePythonFailOnOperationTitle = text['Operation failed!'];
        this.exePythonFailOnConnectionTitle = text['Connection test failed!'];
        this.exePythonPassOnConnectionTitle = text['Connection tested successfully!'];
        this.exePythonPassOnOperationTitle = text['Operation successfully completed!'];
        this.text_err_pythonConnection = text['Unable to connect with python server.'];
        this.text_err_contactAdmin = text['Please contact the administrator.'];
      });
  }

  ngOnInit() {
    this.isDirty = false;
    this.loadTable();
  }

  private loadTable(): void {
    this.showAppLoader = true;
    this.pythonManagementService.getAllPythonModels(false).subscribe(
      resObj => {
        console.log("docGetAllPythonModels=");
        console.log(resObj);

        if (undefined !== resObj) {
          this.pythonModelArray = resObj;
          this.pythonModelArray?.forEach(element => {
            element.viewState = "...";
          });
          this.doHighlightRow(false);
        }
        this.showAppLoader = false;
      }, errObj => {
        this.isError = true;
        console.log(errObj);
        let errResp: SpringErrorPayload = errObj.error;
        if (errResp.status === 400 && errResp.exception && errResp.exception.includes("java.net.ConnectException")) {
          this.errorMessage = this.text_err_pythonConnection + " " + this.text_err_contactAdmin;
        } else {
          this.errorMessage = errResp.exception;
        }
        this.showAppLoader = false;
      });
  }//end loadTable()

  private doHighlightRow(isHighlight: boolean) {
    if (!isHighlight) {
      this.clickedPort = 0;
    }
  }//end doHighlightRow()

  private onChange(pythonModel: S1PythonModel): void {
    this.showAppLoader = true;
    this.enableButton(pythonModel);
    if (pythonModel.autoStart === true) {
      this.calldocExecPythonModelCtrl(S1PythonCommand.AUTOSTART_ON, pythonModel, true, false);
    } else {
      this.calldocExecPythonModelCtrl(S1PythonCommand.AUTOSTART_OFF, pythonModel, true, false);
    }
  }//end onChange()

  enableButton(index: S1PythonModel) {
    if (!this.showAppLoader) {
      this.clickedPort = index.port;
      this.currentRow = index;
      this.isEnableTestConnection = true;
      if (this.currentRow.viewState === 'INSTALLED') {
        this.isEnableInstall = false;
        this.isEnableUninstall = true;
        this.isEnableStart = true;
        this.isEnableStop = false;
      } else if (this.currentRow.viewState === 'RUNNING') {
        this.isEnableInstall = false;
        this.isEnableUninstall = false;
        this.isEnableStart = false;
        this.isEnableStop = true;
      } else if (this.currentRow.viewState === 'INSTALL_ERROR'
        || this.currentRow.viewState === 'SAVED'
        || this.currentRow.viewState === 'MISSING') {
        this.isEnableInstall = true;
        this.isEnableUninstall = false;
        this.isEnableStart = false;
        this.isEnableStop = false;
      } else {
        this.isEnableInstall = false;
        this.isEnableUninstall = false;
        this.isEnableStart = false;
        this.isEnableStop = false;
      }
    } else {
      this.disableButton(index);
    }
  }//end enableButton()

  disableButton(index: S1PythonModel): boolean {
    this.isEnableTestConnection = false;
    this.isEnableInstall = false;
    this.isEnableUninstall = false;
    this.isEnableStart = false;
    this.isEnableStop = false;
    return true;
  }//disableButton()

  install() {
    this.showAppLoader = true;
    this.enableButton(this.currentRow);
    this.calldocExecPythonModelCtrl(S1PythonCommand.INSTALL, this.currentRow, false, true);
  }
  uninstall() {
    this.showAppLoader = true;
    this.enableButton(this.currentRow);
    this.calldocExecPythonModelCtrl(S1PythonCommand.UNINSTALL, this.currentRow, false, true);
  }
  start() {
    this.showAppLoader = true;
    this.enableButton(this.currentRow);
    this.calldocExecPythonModelCtrl(S1PythonCommand.START, this.currentRow, false, true);
  }
  stop() {
    this.showAppLoader = true;
    this.enableButton(this.currentRow);
    this.calldocExecPythonModelCtrl(S1PythonCommand.STOP, this.currentRow, false, true);
  }
  testConnection() {
    this.showAppLoader = true;
    this.enableButton(this.currentRow);
    this.calldocExecPythonModelCtrl(S1PythonCommand.PING, this.currentRow, false, true);
  }

  refresh(index) {
    this.showAppLoader = true;
    this.enableButton(index);
    this.calldocExecPythonModelCtrl(S1PythonCommand.GET, index, true, false);
  }
  refreshAll() {
    this.confirmService.confirm(this.refreshTextValue,this.refreshTitleValue,this.refreshOkValue,this.refreshCloseValue).subscribe(confirmed=>{
      if(confirmed){
        //calling API in loop from front-end is bad practice. 
        //Following call may crash server in extreme conditions. (multi-logins calling refresh all for huge array at-a-time)
        this.pythonModelArray.forEach(element => {
          this.calldocExecPythonModelCtrl(S1PythonCommand.GET, element, false, false);
        });
      }
    })
    // Swal.fire({
    //   title: this.refreshTitleValue,//'Are you sure to refresh all records?',
    //   text: this.refreshTextValue,//"This might take time!",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonText: this.refreshOkValue,//'Ok, Refresh all',
    //   cancelButtonText: this.refreshCloseValue //'Close'
    // }).then((result) => {
    //   if (result.value) {
    //     this.doHighlightRow(false);
    //     //calling API in loop from front-end is bad practice. 
    //     //Following call may crash server in extreme conditions. (multi-logins calling refresh all for huge array at-a-time)
    //     this.pythonModelArray.forEach(element => {
    //       this.calldocExecPythonModelCtrl(S1PythonCommand.GET, element, false, false);
    //     });
    //   }
    // });
  }//end refreshAll()

  calldocExecPythonModelCtrl(command: S1PythonCommand, pythonModel: S1PythonModel, isHighlightRow: boolean, showPopup: boolean) {
    this.isDirty = true;
    this.pythonManagementService.docExecPythonModelCtrl(command, pythonModel).subscribe({
      next: async resObj => {
        console.log("docExecPythonModelCtrl " + S1PythonCommand[command]);
        console.log(resObj);

        let oldSearchText = this.searchText;
        this.searchText = '';
        this.currentPythonActivityReport = resObj;
        let titleText: string = "";
        //informative pop-up for specific commands
        if (showPopup) {
          this._calldocExecPythonModelCtrl_Popup(command, pythonModel);
        }

        //auto start handling. can shift to block above if pop-up required
        if (command === S1PythonCommand.AUTOSTART_ON || command === S1PythonCommand.AUTOSTART_OFF) {
          if (this.currentPythonActivityReport.failed) {
            titleText = (command === S1PythonCommand.AUTOSTART_ON) ? this.exePythonPassOnAutoStartTitle : this.exePythonFailOnAutoStartTitle;
            this.alert.info(titleText);
          }
          //TODO if currentPythonActivityReport.log[0].text="DQo=" means not running. so should we call _calldocExecPythonModelCtrl_GET?
          //handle full logic for err conditions in _calldocExecPythonModelCtrl_GET
          //also handle if(this.currentPythonActivityReport.failed)
          await this._calldocExecPythonModelCtrl_GET(pythonModel).then(_value => {
            console.log("await for _calldocExecPythonModelCtrl_GET 2");
            this.showAppLoader = false;
            this.enableButton(pythonModel);
          });
        }
        //at the end of all commands call GET. It updates state and stops appLoader
        console.log("---the GET command");

        if (command === S1PythonCommand.GET) {
          await this._calldocExecPythonModelCtrl_GET(pythonModel).then(value => {
            console.log(value);

            if (isHighlightRow) {
              this.showAppLoader = false;
              this.enableButton(pythonModel);
            }
          });
          if (this.showAppLoader) {
            console.log("apploader running ");
          }
          console.log("---the GET command end");
        }

        this.searchText = oldSearchText;

        this.isDirty = false;
      }, error: err => {
        let resultObj: SpringErrorPayload = err['error'];
        this.alert.error(resultObj.code,resultObj.error)
      }
    });
  }//end calldocExecPythonModelCtrl()

  _calldocExecPythonModelCtrl_GET(pythonModel: S1PythonModel): Promise<any> {
    return new Promise(_resolve => {
      this.pythonManagementService.docExecPythonModelCtrl(S1PythonCommand.GET, pythonModel).subscribe(innerResObject => {
        if (innerResObject['log']) {
          let innerPythonActivityReport: S1PythonActivityReport = innerResObject;
          innerPythonActivityReport.log.forEach(value => {
            let base64Str = Buffer.from(value.text, 'base64').toString('utf8');
            console.log(base64Str);
          });
          this.pythonManagementService.getAllPythonModels(false).subscribe(finalInnerResObject => {
            if (finalInnerResObject) {
              let innerPythonModelArray: S1PythonModel[] = finalInnerResObject;
              let innerPythonModel: S1PythonModel = innerPythonModelArray.find(value => value.port === pythonModel.port);
              console.log(innerPythonModel.state + " -state and " + innerPythonModel.executionState + " -exec state");
              if (parseInt(S1PythonModelState[innerPythonModel.state]) === 2 && parseInt(S1PythonModelState[innerPythonModel.executionState]) === -3) {
                console.log("should get installed");

                pythonModel.viewState = String(innerPythonModel.state);
              } else if (parseInt(S1PythonModelState[innerPythonModel.state]) === 2) {
                pythonModel.viewState = String(innerPythonModel.executionState);
              } else {
                pythonModel.viewState = String(innerPythonModel.state);
              }
            }
            _resolve(true);
          });
        } else {
          _resolve(true);
          console.log(innerResObject);
          this.isError = true;
          this.errorMessage = this.text_err_pythonConnection;
        }
      });
    });
  }

  _calldocExecPythonModelCtrl_Popup(command: S1PythonCommand, pythonModel: S1PythonModel) {
    let titleText: string = this._calldocExecPythonModelCtrl_popupTitle(command, this.currentPythonActivityReport.failed);
    let pythonModelState: S1PythonModelState = this._calldocExecPythonModelCtrl_popupPythonState(command, this.currentPythonActivityReport.failed);
    pythonModel.state = pythonModelState === null ? pythonModel.state : pythonModelState;
    this.confirmService.confirm(this.exePythonText,titleText).subscribe(async confirmed=>{
      if(confirmed){
        await this._calldocExecPythonModelCtrl_GET(pythonModel).then(_value => {
        console.log("await for _calldocExecPythonModelCtrl_GET 1");
        this.showAppLoader = false;
        this.enableButton(pythonModel);
      });
      }
    })
  }

  _calldocExecPythonModelCtrl_popupTitle(command: S1PythonCommand, isFailed: boolean): string {
    if (isFailed)
      if (command === S1PythonCommand.PING) {
        return this.exePythonFailOnConnectionTitle;
      } else {
        return this.exePythonFailOnOperationTitle;
      }
    else
      if (command === S1PythonCommand.PING) {
        return this.exePythonPassOnConnectionTitle;
      } else {
        return this.exePythonPassOnOperationTitle;
      }
  }
  _calldocExecPythonModelCtrl_popupPythonState(command: S1PythonCommand, isFailed: boolean): S1PythonModelState {
    if (isFailed)
      if (command === S1PythonCommand.UNINSTALL) {
        return S1PythonModelState.MISSING;
      } else if (command === S1PythonCommand.INSTALL) {
        return S1PythonModelState.INSTALL_ERROR;
      } else {
        return null;
      }
    else
      if (command === S1PythonCommand.UNINSTALL) {
        return S1PythonModelState.SAVED;
      } else if (command === S1PythonCommand.INSTALL) {
        return S1PythonModelState.INSTALLED;
      } else {
        return null;
      }
  }


  @HostListener('document:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.isDirty) {
      $event.returnValue = true;
    }
  }
}
