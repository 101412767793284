import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'imagePipe'
})
export class ImagePipe implements PipeTransform {

    public transform(dbvalue, keys: string) {
        let imgPath: string;
        switch (dbvalue) {

            //language 
            case "en":
                imgPath = "assets/img/icon-coloured-lang-english.png";
                break;
            case "es":
                imgPath = "assets/img/icon-coloured-lang-spanish.png";
                break;
            case "fr":
                imgPath = "assets/img/icon-coloured-lang-french.png";
                break;
            case "de-DE":
                imgPath = "assets/img/icon-coloured-lang-german.png";
                break;
            case "it":
                imgPath = "assets/img/icon-coloured-lang-italian.png";
                break;
            case "tr-TR":
                imgPath = "assets/img/icon-coloured-lang-turkish.png";
                break;
            case "ru":
                imgPath = "assets/img/icon-coloured-lang-russian.png";
                break;
            case "zh-CN":
                imgPath = "assets/img/icon-coloured-lang-chinese.png";
                break;

            //user role string-based and number based
            case "MANAGER":
            case 5:
                imgPath = "assets/img/icon-coloured-role-manager.png";
                break;
            case "SECURITY_ADMIN":
            case 6:
                imgPath = "assets/img/icon-coloured-role-security-admin.png";
                break;
            case "SYSTEM_ADMIN":
            case 2:
                imgPath = "assets/img/icon-coloured-role-system-admin.png";
                break;
            case "USER":
            case 1:
                imgPath = "assets/img/icon-coloured-role-user.png";
                break;
            case "APPLICATION":
            case 7:
                imgPath = "assets/img/icon-coloured-role-application.svg";
                break;
            case "All":
                imgPath = "assets/img/icon-coloured-users.svg";
                break;
            default:
                imgPath = dbvalue;
        }

        return imgPath;
    }
}
