import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class S1PrivateService {
  params: any;
  constructor(private http: HttpClient) {}
  
  getparams(): Observable<any> {
    return this.http.get("private/server/params");
  }

}