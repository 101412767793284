import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StorageDetails } from 'app/core/services/user-details.service';
import { ProcessManagementService } from 'app/core/services/process-management.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Operation, Operations } from 'app/entities/operation.model';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService } from 'app/shared/alert/alert-service';
const MODAL_OPTIONS: NgbModalOptions = { size: 'lg', backdrop: 'static', keyboard: true, windowClass: 'modal-full-height modal-full-width' };

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit, OnDestroy {
  tablesData: Operation[]=[];
  searchText: string;
  operations: [];
  categoryVariableMap: any;
  operationForm: UntypedFormGroup;
  selectedRow: Operation;
  description: string;
  expression: string;
  processVersion: number;
  clickedValueId: string = "";
  //TODO remove public static vars n related logic of interrupted pop-up into proc-mngt-component
  public static url: boolean = false;
  public static scrollenable: boolean = false;
  public static isCheckProc: boolean = false;
  public static selectedElement: string = null;
  public static finalElement: string = null;
  oldSelectedRow: Operation;
  titleValue: string;
  textValue: string;
  okValue: string;
  closeValue: string;
  isDisableEdit: boolean = true;
  modalRef: NgbModalRef;
  expressionValidationMsg: string;
  expressionRequiredMsg: string;
  operationsaveSuccessMsg: string;
  operationsaveFailMsg: string;
  errorMessage: string = "";
  isError: boolean = false;
  isPermissionError: boolean = true;
  shared: string = localStorage.getItem('sharedInBac');

  constructor(private fb: UntypedFormBuilder, public processManagementService: ProcessManagementService, private alert: AlertsService,
    private userDetails: StorageDetails, private router: Router, private translate: TranslateService, private modalService: NgbModal) { }

  ngOnInit() {
    if (!this.processManagementService.currentProcessDetails) {
      this.router.navigate(['/process-management']);
    }
    if (this.userDetails.getUserRole() !== "USER"
      || (this.userDetails.getUserRole() === "USER"
        && this.processManagementService.currentProcessDetails
        && this.processManagementService.currentProcessDetails.permission
        && this.processManagementService.currentProcessDetails.permission
          .filter(val => {
            if ((val.permission === "OPERATION" && val.level === "LEVEL_WRITE")
              || (val.permission === "OPERATION" && val.level === "LEVEL_READ"))
              return true;
            else
              return false;
          }).length === 1
      )
    ) {
      this.isPermissionError = false;
      this.reloadPageData();
    } else {
      this.isPermissionError = true;
      this.errorMessage = this.processManagementService.errMsgPermissionDeniedDetails;
    }

  }

  ngOnDestroy(){
    localStorage.removeItem('OriginalTable');
  }

  reloadPageData() {
    let operations =  this.processManagementService.bacProcessNode.operations.operations? this.processManagementService.bacProcessNode.operations.operations:[];
    this.tablesData = operations.filter(operation => operation["bac"] === true);
    this.categoryVariableMap = this.processManagementService.bacProcessNode && this.processManagementService.bacProcessNode.categoryVariablesMap ? this.processManagementService.bacProcessNode.categoryVariablesMap : [];
    this.processVersion = this.processManagementService.currentProcessVersion;

    this.translate.get(['Operation details changed!'
      , 'Are you sure to discard the changes?'
      , 'Ok'
      , 'Close'
      , 'Expression value is required'
      , 'Please provide expression with only number or text string.'
      , "Operation saved successfully!"
      , "Operation failed to save!"])
      .subscribe((text: any) => {
        this.titleValue = text['Operation details changed!'];
        this.textValue = text['Are you sure to discard the changes?'];
        this.okValue = text['Ok'];
        this.closeValue = text['Close'];
        this.expressionValidationMsg = text['Please provide expression with only number or text string.'];
        this.expressionRequiredMsg = text['Expression value is required'];
        this.operationsaveSuccessMsg = text["Operation saved successfully!"];
        this.operationsaveFailMsg = text["Operation failed to save!"];
      });

    this.isDisableEdit = this.processManagementService.isDisabled ? true : false;
    if (!this.isDisableEdit
      && (this.userDetails.getUserRole() === "USER"
        && this.processManagementService.currentProcessDetails
        && this.processManagementService.currentProcessDetails.permission
        && this.processManagementService.currentProcessDetails.permission
          .filter(val => (val.permission === "OPERATION" && val.level !== "LEVEL_WRITE")).length > 0
      )) {
      this.isDisableEdit = true;
    }
  }

  enablingWhatIf(description: string, expression: string){
    var originalTable = JSON.parse(localStorage.getItem('OriginalTable'));
      if((description != originalTable.description) || (expression != originalTable.expression)){
        localStorage.setItem('whatIfIsEnabled', 'false')
        return
      }
  }

  save() {
    let oldSearchText = this.searchText;
    this.searchText = '';
    if (this.operationForm.invalid) {
      this.isError = true;
      this.errorMessage = 'Invalid input. Close this form and try again.';
      return;
    }
    this.expression = this.operationForm.controls['expressionField'].value;
    this.description = this.operationForm.controls['descriptionField'].value;
    this.enablingWhatIf(this.description, this.expression);
    OperationsComponent.scrollenable = true;
    OperationsComponent.url = true;
    if (this.expression && this.expression.trim().length > 0) {
      this.selectedRow.description = this.description;
      this.shared != 'true' || this.selectedRow.editable==true? this.selectedRow.expression = this.expression : null;

      if(this.shared == 'true' && this.selectedRow.editable==false){
        this.selectedRow.override.enabled = true;
        this.selectedRow.override.expression = this.expression
      }

      this.alert.success(this.operationsaveSuccessMsg);
      this.isError = false;
      this.modalRef.close();
    } else {
      this.alert.error(this.operationsaveFailMsg)
      this.modalRef.close();
    }
    this.searchText = oldSearchText;
  }
  loadData(value: Operation) {
    this.clickedValueId = value.id;
    this.selectedRow = value;
    this.oldSelectedRow = this.selectedRow;
    this.description = value.description;
    this.expression = value.expression;
  }

  openTemplate(template: TemplateRef<any>, type: string, value: Operation) {
    localStorage.setItem('OriginalTable', JSON.stringify(value))
    if (type === "Edit") {
      this.loadData(value);
      this.modalRef = this.modalService.open(template,MODAL_OPTIONS);
      this.setOperationForm();
    } else {
      console.log("Error: No template reference given.");
    }
  }

  setOperationForm() {
    this.operationForm = this.fb.group({
      'expressionField': [this.selectedRow.override.expression

      , Validators.compose([
          Validators.required,
          Validators.pattern("^[0-9]+([.][0-9]+)?$|^[\"]([^\"]+)[\"]$")
          //^[0-9]+([.][0-9]+)?$ = numbers or decimal numbers
          //^[\"]([^\"]+)[\"]$ = any char but the ' " ' in double quotes
        ])],
      'descriptionField': [this.selectedRow.description]
    });
    const expressionFieldControl = this.operationForm.get('expressionField');
    expressionFieldControl.setErrors({
      "expressionField": true
    });

  }

  cancelWindow() {
    this.isError = true;
    this.modalRef.close();
  }
}
