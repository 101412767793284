import { Component, OnInit, TemplateRef } from '@angular/core';
import { Validators, NgModel, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from "lodash";
import * as XLSX from 'xlsx';
import { DataInputFilterPipe } from 'app/shared/custom-pipe/inputfilter.pipe';
import { OperatorMappingFilter } from 'app/shared/custom-pipe/operatorFilter-pipe';
import { OperatorNameMappingFilter } from 'app/shared/custom-pipe/operationNameFilter-pipe';
import { StorageDetails } from 'app/core/services/user-details.service';
import { ProcessManagementService } from 'app/core/services/process-management.service';
import { ExcelService } from 'app/core/services/excel.service';
import { Table } from 'app/entities/bac-table';
import { TableInputVariables } from 'app/entities/bac-tableInputVariables';
import { TableOutputValues } from 'app/entities/bac-tableOutputValues';
import { TableVariable } from 'app/entities/bac-tableVariable';
import { ProcessIOType } from 'app/entities/bac-processiotype.enum';
import { TableOutputVariables } from 'app/entities/bac-tableOutputVariables';
import { TableOutputObjectRefId } from 'app/entities/bac-tableOutputObjRefId';
import { OperationsComponent } from '../operations/operations.component';
import { TableInputValue } from 'app/entities/bac-tableInputValue';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService } from 'app/shared/alert/alert-service';
const MODAL_OPTIONS: NgbModalOptions = { size: 'lg', backdrop: 'static', keyboard: true, windowClass: 'modal-full-height modal-full-width' };

type AOA = any[][];

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss']
})
export class TablesComponent implements OnInit {

  inputData: TableInputVariables[] = [];
  outputData: TableOutputValues[] = [];
  tablesData: Table[] = [];
  currentTablesData: Table;
  searchText: string;
  variablesData: Array<any> = [];
  modalRef: NgbModalRef;
  tableForm: UntypedFormGroup;
  //tableType: any;
  childTable: boolean = false;
  inputParamData: TableVariable[] = [];
  outputParamData: Array<any> = [];
  outputObject: TableOutputVariables;
  tableName: string;
  selectedTableData: any;
  categoryVariablesData: any;
  rowspan = [];
  clmName = [];
  keyName: string;
  inputVar = ["Available", "Missing", "Not Available", "Else", "Values"];
  newInputVariable = [];
  outputVar = ["Missing", "Not Available", "Values"];
  newOutputVariable = [];
  inputSelect = [];
  outputSelect = [];
  inputSelectedValues = [];
  outputSelectedValues = [];
  selectedChildRecord = null;
  selectedIndex = null;
  file: File;
  idWithValriableNameMapping = {};

  currentTableObj: any = [];
  hideAndShow = true;

  tblName: string;
  error: boolean = true;
  errorMsg: string = "";

  tableStruct: Array<string> = [];
  inputMappingVariable: Array<any> = [];
  outputMappingVariable: Array<any> = [];
  descreteVariables: Array<any> = [];
  domainVariables: Array<any> = [];
  descreteOutputVariables: Array<any> = [];

  disabled: boolean = false;
  processVersion: number;

  operatorList: any;
  pageNum: number = 1;
  numOfRecordPerPage: number = 15;
  index: number;
  data: AOA = [];


  storedInputData = [];
  otherInput = [];
  otherOutput = [];
  storedOutputData = [];
  storedInputType = [];
  storedOutputType = [];
  outputCounter: number = 0;
  inputCounter: number = 0;
  inputErrorMsg: string[] = [];
  outputErrorMsg: string[] = [];


  componentList: NgModel[] = [];

  updateOnIndex: number;
  isError: boolean[] = [];
  modelTitle: string;
  importNumberTypeValue: string;
  importDateTypeValue: string;
  importInvalidDataValue: string;
  text_import_var_invalid_data: string;
  importInvalidateDataValueSecondhalf: string;
  isPermissionError: boolean = true;
  errorMessage: string = "";
  operatorNames = ["GT", "GE", "LT", "LE", "EQ", "NE"];
  operators = [">", ">=", "<", "<=", "=", "<>"];
  selectedOperators = [];
  orderInputIndex;
  orderInputValue;
  orderInputType;
  text_form_type_invalid: string;
  text_val_nonunique: string;
  text_import_success: string;
  text_import_invalid_file: string;
  text_import_var_mismatch: string;
  text_import_var_invalid_type: string;
  text_import_var_nonDomain: string;
  text_import_var_invalid_length_1: string;
  text_import_var_invalid_length_2: string;
  text_import_multipleOrNoFiles: string;
  text_import_validFormats: string;
  text_import_var_blank: string;
  text_import_var_invalid_operator: string;
  text_import_invalid_row: string;
  inputValueAndColumnNameMapping: Array<any> = [];
  invalidOperatorErrorMessage: string;

  continuousVariable: Array<any> = [];

  oldNameNewNamePair: any = {};
  continousDomainMsg: string;
  isCutOffTable: boolean;
  isDisableImportInPopup: boolean = true;
  notSlave: boolean = true;

  //priya - in create table feature in future, refer proc xml of FLAT, EVO.
  //designer takes length 32000 for text and length 15 for number in Variables section so it affects overall
  //designer doesnt allow multi-occurence vars in table structure. find out more such conditions.
  constructor(private translate: TranslateService, private modalService: NgbModal, private fb: UntypedFormBuilder, private userDetails: StorageDetails,
    public nodeService: ProcessManagementService, private excelService: ExcelService, private router: Router,private alert: AlertsService) {
    this.operatorList = [{ value: ">", viewValue: ">" }, { value: ">=", viewValue: ">=" }, { value: "<", viewValue: "<" }, { value: "<=", viewValue: "<=" }, { value: "=", viewValue: "=" }, { value: "<>", viewValue: "<>" }];

    this.initForm();
    this.translate.get(['Number value allowed.'
      , 'Date value allowed.'
      , 'Invalid data'
      , 'in excel file'
      , 'Invalid data in excel file'
      , 'Wrong comparison operator'
      , "Variable out of domain"
      , "Table imported successfully!"
      , "Enter the proper value of required variable type"
      , 'The combination of input variables shall be unique and shall never be repeated.'
      , 'The input and output variables in the import table file are not matching with the current table input and output variables.'
      , 'Wrong column type for variable'
      , 'Domain variable allows domain values only.'
      , 'The value can not exceed length'
      , 'for variable'
      , 'Multiple or no files found!'
      , 'Invalid file!'
      , 'Valid formats: '
      , 'Blank values are not allowed.'
      , 'in first 3 rows'
    ]).subscribe((text: any) => {
      this.importNumberTypeValue = text['Number value allowed.'];
      this.importDateTypeValue = text['Date value allowed.'];
      this.text_import_var_invalid_data = text['Invalid data'];
      this.importInvalidateDataValueSecondhalf = text['in excel file'];
      this.importInvalidDataValue = text['Invalid data in excel file'];
      this.text_import_var_invalid_operator = text['Wrong comparison operator'];
      this.continousDomainMsg = text["Variable out of domain"];
      this.text_import_success = text["Table imported successfully!"];
      this.text_form_type_invalid = text['Enter the proper value of required variable type'];
      this.text_val_nonunique = text['The combination of input variables shall be unique and shall never be repeated.'];
      this.text_import_var_mismatch = text['The input and output variables in the import table file are not matching with the current table input and output variables.'];
      this.text_import_var_invalid_type = text['Wrong column type for variable'];
      this.text_import_var_nonDomain = text['Domain variable allows domain values only.'];
      this.text_import_var_invalid_length_1 = text['The value can not exceed length'];
      this.text_import_var_invalid_length_2 = text['for variable'];
      this.text_import_multipleOrNoFiles = text['Multiple or no files found!'];
      this.text_import_invalid_file = text['Invalid file!'];
      this.text_import_validFormats = text['Valid formats: '];
      this.text_import_var_blank = text['Blank values are not allowed.'];
      this.text_import_invalid_row = text['in first 3 rows'];
    });
  }
  // 'otherInput': ['', Validators.compose([Validators.required,Validators.pattern('^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$')])],//[A-Za-z0-9]* //\d{4}\-\d{1,2}\-\d{1,2} ///\D/g //[0-9]*
  // 'otherDateInput': ['', Validators.compose([
  //   Validators.required,
  //   Validators.pattern('^(\d{4})\-(\d{2})\-(\d{2})$')])]
  // 'inputVariableMap': ['', Validators.compose([Validators.required])],
  // 'outputVariableMap': ['', Validators.compose([Validators.required])],

  private initForm() {
    this.tableForm = this.fb.group({
      'storedInputType': ['', Validators.compose([Validators.required])],
      'storedOutputType': ['', Validators.compose([Validators.required])],
      'otherInput': ['', Validators.compose([Validators.required])],//, Validators.pattern('^[a-zA-Z0-9][0-9]{2}/[0-9]{2}/[0-9]{4}$') , Validators.pattern('^[0-9\]*$')
      'otherOutput': ['', Validators.compose([Validators.required])]
    });
  }

  ngOnDestroy(){
    localStorage.removeItem('OriginalTable');
  }

  ngOnInit() {
    localStorage.setItem('OriginalTable', JSON.stringify(this.nodeService.bacProcessNode?.tables.table))
    if (!this.nodeService.currentProcessDetails) {
      this.router.navigate(['/process-management']);
    }
    if (this.userDetails.getUserRole() !== "USER"
      || (this.userDetails.getUserRole() === "USER"
        && this.nodeService.currentProcessDetails
        && this.nodeService.currentProcessDetails.permission
        && this.nodeService.currentProcessDetails.permission
          .filter(val => {
            if ((val.permission === "SIMPLETABLE" && val.level === "LEVEL_WRITE")
              || (val.permission === "SIMPLETABLE" && val.level === "LEVEL_READ")
              || (val.permission === "CUTOFFTABLE" && val.level === "LEVEL_WRITE")
              || (val.permission === "CUTOFFTABLE" && val.level === "LEVEL_READ"))
              return true;
          }).length > 0
      )
    ) {
      this.isPermissionError = false;
      this.reloadPageData();
    } else {
      this.isPermissionError = true;
      this.errorMessage = this.nodeService.errMsgPermissionDeniedDetails;
    }
  }
  reloadPageData() {
    this.tablesData = this.nodeService.bacProcessNode && this.nodeService.bacProcessNode.tables && this.nodeService.bacProcessNode.tables.table ? this.nodeService.bacProcessNode.tables.table : [];
    this.tablesData = this.tablesData.filter(table => null !== table.bac && table.bac === true);
    //hide tables as per permission
    if (this.userDetails.getUserRole() === "USER") {
      if (this.nodeService.currentProcessDetails.permission
        .filter(val => val.permission === "SIMPLETABLE" && val.level === "LEVEL_NONE").length > 0) {
        this.tablesData = this.tablesData.filter(table => table.tableType !== "GENERIC");
      }
      if (this.nodeService.currentProcessDetails.permission
        .filter(val => val.permission === "CUTOFFTABLE" && val.level === "LEVEL_NONE").length > 0) {
        this.tablesData = this.tablesData.filter(table => table.tableType !== "CUT_OFF");
      }
    }
    this.selectedTableData = this.nodeService.bacProcessNode && this.nodeService.bacProcessNode["variables"] && this.nodeService.bacProcessNode["variables"]['variable'] ? this.nodeService.bacProcessNode["variables"]['variable'] : '';
    this.categoryVariablesData = this.nodeService.bacProcessNode
      && this.nodeService.bacProcessNode['categoryVariablesMap'] ?
      this.nodeService.bacProcessNode['categoryVariablesMap'] : '';
    this.processVersion = this.nodeService.currentProcessVersion;

  }
  open(template: TemplateRef<any>, type: string) {
    this.componentList = [];
    this.inputMappingVariable = [];
    this.outputMappingVariable = [];
    this.inputSelect = [];
    this.inputSelectedValues = [];
    this.outputSelect = [];
    this.outputSelectedValues = [];
    this.errorMsg = "";
    this.initForm();
    this.modalRef = this.modalService.open(template,MODAL_OPTIONS);

    this.setInputAndOutVariableMappings(type);
  }

  updateInputFunction(index) {
    this.newInputVariable[index] = JSON.parse(JSON.stringify(this.inputVar));
    if (this.descreteVariables && this.descreteVariables[index] != null) {
      this.descreteVariables[index].forEach(element => {
        this.newInputVariable[index].push(element['value']);
      });
    }
  }

  updateOutputFunction(index) {
    this.newOutputVariable[index] = JSON.parse(JSON.stringify(this.outputVar));
    if (this.descreteOutputVariables && this.descreteOutputVariables[index] != null) {
      this.descreteOutputVariables[index].forEach(element => {
        this.newOutputVariable[index].push(element['value']);
      });
    }
  }

  private setInputAndOutVariableMappings(type: string) {
    this.storedOutputData = [];
    this.outputSelect = [];
    this.storedOutputType = [];
    this.storedInputData = [];
    this.otherInput = [];
    this.otherOutput = [];
    this.inputSelect = [];
    this.storedInputType = [];
    this.inputErrorMsg = [];
    this.outputErrorMsg = [];

    if (type === "New") {
      this.modelTitle = "Add new row for :";
      this.selectedChildRecord = null;
      this.inputParamData.forEach(element => {
        if (this.categoryVariablesData[element.variableRefID]['restrictionType'] === "Continuous") {
          this.continuousVariable.push({
            "varName": this.categoryVariablesData[element.variableRefID]['categoryId'] + "." + this.categoryVariablesData[element.variableRefID]['objectsVariableId'],
            "minValue": this.categoryVariablesData[element.variableRefID]['minValue'],
            "maxValue": this.categoryVariablesData[element.variableRefID]['maxValue']
          });
        }

        this.inputMappingVariable.push(this.findByName(element.variableRefID));
        this.descreteVariables.push(this.categoryVariablesData[element.variableRefID]['domainValues'] ? this.categoryVariablesData[element.variableRefID]['domainValues']['domainValue'] : null)
        this.inputSelect.push(false);
        this.isError.push(true);
        this.inputSelectedValues.push("");
        this.storedInputType.push(undefined);
        this.inputErrorMsg.push("");
      });
      this.outputObject["variableRefID"].forEach(element => {
        this.outputMappingVariable.push(this.findByName(element.value));
        this.descreteOutputVariables.push(this.categoryVariablesData[element.value]['domainValues'] ? this.categoryVariablesData[element.value]['domainValues']['domainValue'] : null)
        this.outputSelect.push(false);
        this.otherOutput.push(null);
        this.outputSelectedValues.push("");
        this.outputErrorMsg.push("");
      });
      //this.tableForm.markAllAsTouched();
    } else if (type === "Edit") {
      this.modelTitle = "Edit row for :";
      this.inputParamData.forEach(element => {
        this.inputMappingVariable.push(this.findByName(element.variableRefID));
        if (this.categoryVariablesData[element.variableRefID]['restrictionType'] === "Continuous") {
          this.continuousVariable.push({
            "varName": this.categoryVariablesData[element.variableRefID]['categoryId'] + "." + this.categoryVariablesData[element.variableRefID]['objectsVariableId'],
            "minValue": this.categoryVariablesData[element.variableRefID]['minValue'],
            "maxValue": this.categoryVariablesData[element.variableRefID]['maxValue']
          });
        }
        this.descreteVariables.push(this.categoryVariablesData[element.variableRefID]['domainValues'] ? this.categoryVariablesData[element.variableRefID]['domainValues']['domainValue'] : null)
        this.inputErrorMsg.push("");
        //this.inputSelect.push(false);
      });
      let j = 0;
      this.selectedChildRecord.name.split(".").forEach(element => {
        let value = new DataInputFilterPipe(this.userDetails).transform(null, element, null);
        if (value === '[Else]' || value === 'E') {
          this.inputSelectedValues.push(null);
          this.storedInputData.push(null);
          this.otherInput.push(null);
          this.storedInputType.push("Else");
          this.inputSelect.push(false);
          this.updateInputFunction(j);
        } else if (value === '[Missing]' || value === 'M') {
          this.inputSelectedValues.push(null);
          this.storedInputData.push(null);
          this.otherInput.push(null);
          this.storedInputType.push("Missing");
          this.inputSelect.push(false);
          this.updateInputFunction(j);
        } else if (value === '[Available]' || value === 'A') {
          this.inputSelectedValues.push(null);
          this.storedInputData.push(null);
          this.otherInput.push(null);
          this.storedInputType.push("Available");
          this.inputSelect.push(false);
          this.updateInputFunction(j);
        } else if (value === '[Not Available]' || value === '[NotAvailable]' || value === 'N') {
          this.inputSelectedValues.push(null);
          this.storedInputData.push(null);
          this.otherInput.push(null);
          this.storedInputType.push("Not Available");
          this.inputSelect.push(false);
          this.updateInputFunction(j);
        } else if (this.descreteVariables && this.descreteVariables[j] != null) {
          this.inputSelectedValues.push(value);
          this.storedInputData.push(null);
          this.storedInputType.push(value);
          this.inputSelect.push(false);
          this.otherInput.push(value);
          this.updateInputFunction(j);
        } else {
          this.inputSelectedValues.push(value);
          this.storedInputData.push(value);
          this.storedInputType.push("Values");
          this.inputSelect.push(true);
          this.otherInput.push(value);
          this.updateInputFunction(j);
        }
        j++;
      });
      this.outputObject["variableRefID"].forEach(element => {
        this.outputMappingVariable.push(this.findByName(element.value));
        this.descreteOutputVariables.push(this.categoryVariablesData[element.value]['domainValues'] ? this.categoryVariablesData[element.value]['domainValues']['domainValue'] : null)
        this.outputErrorMsg.push("");
        //this.outputSelect.push(false);
      });

      let i = 0;

      this.selectedChildRecord.variableValues.variableValue.forEach(element => {
        if (element.valueState && element.valueState.status === 'M') {
          this.storedOutputData.push(null);
          this.outputSelect.push(false);
          this.otherOutput.push(null);
          this.storedOutputType.push("Missing");
          this.updateOutputFunction(i);
        } else if (element.valueState && element.valueState.status === 'N') {
          this.storedOutputData.push(null);
          this.outputSelect.push(false);
          this.otherOutput.push(null);
          this.storedOutputType.push("Not Available");
          this.updateOutputFunction(i);
        } else if (element.valueState && element.valueState.status === 'A') {
          this.storedOutputData.push(null);
          this.outputSelect.push(false);
          this.storedOutputType.push("Available");
          this.otherOutput.push(null);
          this.updateOutputFunction(i);
        } else if (this.descreteOutputVariables && this.descreteOutputVariables[i] != null) {
          this.storedOutputData.push(element.valueState.value.value);
          this.outputSelect.push(false);
          this.storedOutputType.push(element.valueState.value.value);
          this.otherOutput.push(null);
          this.updateOutputFunction(i);
        } else {
          this.storedOutputData.push(element.valueState.value.value);
          this.outputSelect.push(true);
          this.storedOutputType.push("Values");
          this.otherOutput.push(element.valueState.value.value);
          this.updateOutputFunction(i);
        }
        i++;
      })

      this.selectedChildRecord.variableValues.variableValue.forEach(element => {
        this.outputSelectedValues.push(null !== element.valueState.value ? element.valueState.value.value : null);
      });
    }
  }

  addComponentIntoList(form: NgModel) {
    if (this.componentList.indexOf(form) < 0) {
      this.componentList.push(form);
    }
    this.errorMsg = "";
    return true;
  }

  removeComponent(removeIndicator: boolean, form: NgModel, name, index, type) {
    if (!removeIndicator) {
      for (let i = 0; i < this.componentList.length; i++) {
        if (this.componentList[i].name === name) {
          this.componentList.splice(i, 1);
          break;
        }
      }
    }

    if (type === "INPUT" && this.inputSelect[index]) {
      this.storedInputData[index] = null;
      this.otherInput[index] = null;
    } else if (type === "OUTPUT" && this.outputSelect[index]) {
      this.storedOutputData[index] = null;
      this.otherOutput[index] = null;
    }
  }

  markComponentToTouch(form: NgModel) {
    form.control.markAsTouched();
    return true;
  }

  markComponentToUnTouch(form: NgModel) {
    form.control.markAsUntouched();
    return true;
  }

  compareValues(val1, val2) {
    if (val1 && val2 && val1 === val2) {
      return true;
    }
    this.outputCounter++;

    return false;
  }

  compareInputCombinationValues() {
    for (let j = 0; j < this.outputParamData.length; j++) {
      let combination = this.outputParamData[j].name.split(".");
      let alreadyPresent = true;

      if (null !== this.selectedChildRecord &&
        this.outputParamData[j].name === this.selectedChildRecord.name) {
        continue;
      }

      for (let i = 0; i < combination.length; i++) {
        this.inputParamData[i].values.valueState.forEach(ele => {
          if (ele.code === combination[i]) {
            //TODO here we need to write missing not available available and else logic

            //null != ele.status ? new DataInputFilterPipe(this.userDetails).transform(null, ele.status, null) :
            let saveValue = null != ele.value ? ele.value.value : null != ele.status && ele.status === 'N' ? 'Not Available' : null != ele.status && ele.status === 'M' ? 'Missing' : null != ele.status && ele.status === 'A' ? 'Available' : 'Else';

            if (this.inputSelectedValues[i] !== saveValue) {
              alreadyPresent = false;
            }
          }
        });
      }

      if (alreadyPresent) {
        return true;
      }
    }
  }

  enablingWhatIf(rows?: number){
    var originalTable = JSON.parse(localStorage.getItem('OriginalTable'))[0].outputValues.output;
    for (let tableRow = 0; tableRow < this.currentTablesData.outputValues.output.length; tableRow++) {
      var currentTableRow = this.currentTablesData.outputValues.output[tableRow].variableValues.variableValue[0].valueState.value.value;
      var orginalTableRow = originalTable[tableRow].variableValues.variableValue[0].valueState.value.value;
      if((currentTableRow != orginalTableRow) || (rows != originalTable.length)){
        localStorage.setItem('whatIfIsEnabled', 'false')
        return
      }
    }
  }

  saveWindow() {
    let str = "";
    this.errorMsg = "";

    this.componentList.forEach(element => {
      this.markComponentToTouch(element);
    });

    let isInvalid = false;


    for (let c = 0; c < this.inputParamData.length; c++) {

      if (this.findTypeById(this.inputParamData[c].variableRefID).indexOf("N") >= 0) {
        this.continuousVariable.forEach(element => {
          if (element.varName.toLowerCase() === this.findByName(this.inputParamData[c].variableRefID).toLowerCase()) {
            let minLen = parseInt(element.minValue);
            let maxLen = parseInt(element.maxValue);

            if ((parseInt(this.inputSelectedValues[c]) < minLen || parseInt(this.inputSelectedValues[c]) > maxLen)) {
              this.componentList.forEach(elemtn => {
                if (("othrInput" + c) === elemtn.name) {
                  elemtn.control.setErrors({ 'incorrect': true });
                  this.inputErrorMsg[c] = this.continousDomainMsg;
                }
              });
            }
          }
        });
      }
    }

    for (let c = 0; c < this.outputObject["variableRefID"].length; c++) {
      if (this.findTypeById(this.outputObject["variableRefID"][c].value).indexOf("N") >= 0) {
        this.continuousVariable.forEach(element => {
          if (element.varName.toLowerCase() === this.findByName(this.outputObject["variableRefID"][c].value).toLowerCase()) {
            let minLen = parseInt(element.minValue);
            let maxLen = parseInt(element.maxValue);

            if ((parseInt(this.outputSelectedValues[c]) < minLen || parseInt(this.outputSelectedValues[c]) > maxLen)) {
              this.componentList.forEach(elemtn => {
                if (("othrOutput" + c) === elemtn.name) {
                  elemtn.control.setErrors({ 'incorrect': true });
                  this.outputErrorMsg[c] = this.continousDomainMsg;
                }
              });
            }
          }
        });
      }
    }

    this.componentList.forEach(element => {
      if (!element.valid && !isInvalid) {
        isInvalid = true;
      }
    });


    if (isInvalid) {
      this.errorMsg = this.text_form_type_invalid;
      return;
    }

    isInvalid = this.compareInputCombinationValues();
    if (isInvalid) {
      this.errorMsg = this.text_val_nonunique;
      return;
    }

    OperationsComponent.url = true;

    if (this.selectedChildRecord !== null) {
      this.editRecord();
      this.ascAndDescOrderData(null, null, null);//this.orderByInput(this.orderInputValue, this.orderInputIndex, this.orderInputType);
      this.componentList.forEach(element => {
        this.markComponentToUnTouch(element);
      });
      this.enablingWhatIf(this.currentTablesData.outputValues.output.length);
      return;
    }

    let inputNames = [];

    console.log("- LOOP START");
    console.log("- original this.inputSelectedValues");
    console.log(this.inputSelectedValues);


    this.inputParamData.forEach(element => {
      console.log("-- inputParamData -> element = ");
      console.log(element);


      for (let i = 0; i < this.inputMappingVariable.length; i++) {
        console.log("-- inner loop -- inputMappingVariable" + i + "=" + this.inputMappingVariable[i]);

        if (this.findByName(element.variableRefID) === this.inputMappingVariable[i]) {

          this.findMaxIndexAndPushValue(element.values.valueState, this.inputSelectedValues[i], inputNames, element.index);
          this.enablingWhatIf(element.values.valueState.length);
          break;
        }
      }
    });
    console.log("- LOOP END");


    this.userDetails.setInputData(JSON.stringify(this.inputParamData));
    // localStorage.setItem("inputdata", JSON.stringify(this.inputParamData));
    let variableValue = [];
    var varRefId: string;
    let tckr = 0;

    let mcTrc = 0;
    this.outputSelectedValues.forEach(element => {
      //this.outputObject['variableRefID'].forEach(val => {
      varRefId = this.outputObject['variableRefID'][mcTrc].value;
      mcTrc++;
      //});

      if (!element) {
        element = this.storedOutputType[tckr];
      }

      if (element !== "Missing" && element !== "Available" && element !== "Not Available" && element !== "NotAvailable" && element !== "Values") {
        this.otherOutput[tckr] = element;
      }


      variableValue.push({
        valueState: {
          color: null,
          status: element === "Missing" || element === "Available" || element === "Not Available" || element === "NotAvailable" ? element.charAt(0) : null,
          value: element === "Missing" || element === "Available" || element === "Not Available" || element === "NotAvailable" || element === "Ëlse" ? null : { value: this.otherOutput[tckr] }
        },
        variableRefID: varRefId
      });
      tckr++;
    });

    let separatorStr: string;
    //in case of empty outputParamData fill dummy value in it. Assuming dot indicator(A1.B1) for all procs.
    if (this.outputParamData.length === 0) {
      separatorStr = ".";
    } else {
      separatorStr = (this.outputParamData[0].key.indexOf(".") === -1) ? "" : ".";
    }
    this.outputParamData.push({
      color: null,
      key: inputNames.join(separatorStr),
      name: inputNames.join("."),
      scenarios: { scenarioRefID: [] },
      variableValues: {
        "variableValue": variableValue
      }
    });


    this.errorMsg = "";
    this.modalRef.close();
    this.ascAndDescOrderData(null, null, null); //this.orderByInput(this.orderInputValue, this.orderInputIndex, this.orderInputType);
    this.componentList.forEach(element => {
      this.markComponentToUnTouch(element);
    });

  }

  //NOTE : BAC only displays "output values" tab from designer regarding child table, but edits "input values" tab too.
  //NOTE : its necessary due to linked table structure and maintaining "add row", "delete row" features in sync with designer.
  deleteRow() {
    OperationsComponent.url = true;


    this.outputParamData.splice(this.selectedIndex, 1);

    this.inputParamData.forEach(element => {
      element.values.valueState.splice(this.selectedIndex, 1);
    });
    this.disabled = true;
    this.selectedChildRecord = null;
    this.keyName = null;
    this.selectedIndex = null;
    this.enablingWhatIf(this.currentTablesData.outputValues.output.length)
  }

  getIPCodeChar(index: number): string {
    return String.fromCharCode(65 + index);
  }

  getValueFromName(name) {
    return new DataInputFilterPipe(this.userDetails).transform(null, name, null);
  }

  getOutPutValue(element1) {
    let result = "";
    if (element1["value"] !== null && element1["value"]["value"] !== null) {
      result = element1["value"]["value"];
    } else if (element1["value"] === null && element1["else"] != null && element1["else"] == "") {
      result = "[Else]";
    } else if (element1["status"] === "N") {
      result = "[Not Available]";
    } else if (element1["status"] === "A") {
      result = "[Available]";
    } else if (element1["status"] === "M") {
      result = "[Missing]";
    }
    return result;
  }

  editRecord() {
    this.hideAndShow = false;

    let desVariables = [];

    for (let i = 0; i < this.inputParamData.length; i++) {
      desVariables.push(this.categoryVariablesData[this.inputParamData[i].variableRefID]['domainValues'] ? this.categoryVariablesData[this.inputParamData[i].variableRefID]['domainValues']['domainValue'] : null)
    }

    for (let i = 0; i < this.outputObject['variableRefID'].length; i++) {
      desVariables.push(this.categoryVariablesData[this.outputObject['variableRefID'][i].value]['domainValues'] ? this.categoryVariablesData[this.outputObject['variableRefID'][i].value]['domainValues']['domainValue'] : null)
    }

    let otCnt = this.inputParamData.length;
    this.selectedChildRecord.variableValues.variableValue.forEach(element => {
      for (let iTrc = 0; iTrc < this.outputMappingVariable.length; iTrc++) {
        if (this.outputMappingVariable[iTrc] === this.findByName(element.variableRefID)) {

          if (this.storedOutputType[iTrc] === "Missing" || this.storedOutputType[iTrc] === "Available" || this.storedOutputType[iTrc] === "Not Available" || this.storedOutputType[iTrc] === "NotAvailable") {
            element.valueState.value = null;
            element.valueState.status = this.storedOutputType[iTrc].charAt(0);
          } else {
            element.valueState.value = { "value": (desVariables[otCnt] ? this.storedOutputType[iTrc] : this.outputSelectedValues[iTrc]) };
            // null !== element.valueState.value ? element.valueState.value.value = this.outputSelectedValues[i] : null;
            element.valueState.status = null;
          }
        }
      }
      otCnt++;
    });

    let inCnt = -1;
    this.inputParamData.forEach(element => {
      inCnt++;
      for (let i = 0; i < this.inputMappingVariable.length; i++) {
        if ((this.findByName(element["variableRefID"]) === this.inputMappingVariable[i])) {

          let j = 0;
          this.selectedChildRecord.name.split(".").forEach(element1 => {
            element.values.valueState.forEach(element2 => {
              if (element1 === element2.code) {
                _.omit(this.inputValueAndColumnNameMapping[j], element2.status === "M" ? "[Missing]" : element2.status === "N"
                  ? "[Not Available]" : element2.status === "A" ? "[Available]" : element2.else === "" ? "[Else]" : element2.value.value);

                if (this.storedInputType[i] === "Missing" || this.storedInputType[i] === "Available" || this.storedInputType[i] === "Not Available" || this.storedInputType[i] === "NotAvailable") {
                  element2.value = null; //= {value : null};
                  element2.status = this.storedInputType[i].charAt(0);
                  this.inputValueAndColumnNameMapping[j]["[" + this.storedInputType[i] + "]"] = element2.code;
                } else if (this.storedInputType[i] === "Else") {
                  element2.value = null;
                  element2.status = null;
                  //element2.else = "";
                  this.inputValueAndColumnNameMapping[j]["[Else]"] = element2.code;
                } else {
                  element2.value = { "value": (desVariables[inCnt] ? this.storedInputType[i] : this.otherInput[i]) };
                  element2.status = null;
                  element2.else = null;
                  this.inputValueAndColumnNameMapping[j][this.storedInputType[i]] = element2.code;
                }
              }
            });
            j++;
          });
        }
      }
    });


    //localStorage.setItem("inputdata", JSON.stringify(this.inputParamData));
    this.userDetails.setInputData(JSON.stringify(this.inputParamData));
    this.hideAndShow = true;
    // this.makeSelectedChildRecordToNull();
    this.modalRef.close();
  }

  makeSelectedChildRecordToNull() {
    this.selectedChildRecord = null;
  }

  findMaxIndexAndPushValue(values, value, inputNames: any[], index: number) {
    let maxIndex = -1;
    values.forEach(element => {
      if (maxIndex < element.index) {
        maxIndex = element.index;
      }
    });
    console.log("-- inner loop -- maxIndex=" + maxIndex);

    //let name = this.inputValueAndColumnNameMapping[index][value === "Missing" ? "[Missing]" : value === "Available" ? "[Available]" : value === "Not Available" ? "[Not Available]" : value === "Else" ? "[Else]" : value];
    //if(!name) {
    let name: string = this.getIPCodeChar(index) + (maxIndex + 2);
    values.push({
      "value": value === "Missing" || value === "Available" || value === "Not Available" || value === "NotAvailable" || value === "Else" ? null : { "value": value },
      "status": value === "Missing" || value === "Available" || value === "Not Available" || value === "NotAvailable" ? value.charAt(0) : null,
      "code": name,
      "index": maxIndex + 1,
      "else": value === "Missing" || value === "Available" || value === "Not Available" || value === "NotAvailable" || value !== "Else" ? null : ""
    });
    //}
    inputNames.push(name);
    console.log("-- inner loop -- values and inputNames ");
    console.log(values);
    console.log(inputNames);


  }

  setInputValue(value: string, type: string, index) {
    if (type === 'input') {
      this.inputSelect[index] = value === 'Values' ? true : false
      this.inputSelectedValues[index] = value === 'Values' ? "" : value;
    } else {
      this.outputSelect[index] = value === 'Values' ? true : false
      this.outputSelectedValues[index] = value;
    }
  }
  selectedValueKeyUpEvent(index, value, type, fieldName) {
    if (type === 'input') {
      this.inputSelectedValues[index] = value;
      this.checkAndApplyValidation("othrInput" + index, value, this.findTypeById(this.inputParamData[index].variableRefID), index, this.inputErrorMsg, fieldName);
    } else {
      this.outputSelectedValues[index] = value;
      this.checkAndApplyValidation("othrOutput" + index, value, this.findTypeById(this.outputObject["variableRefID"][index].value), index, this.outputErrorMsg, fieldName);
    }
  }

  checkAndApplyValidation(name, value, type, index, msgArray: string[], fieldName) {

    if (name.indexOf("othrOutput") === 0 && !value) {
      return;
    }

    for (let i = 0; i < this.componentList.length; i++) {
      if (this.componentList[i].name === name) {
        if (!value) {
          this.componentList[i].control.setErrors({ 'incorrect': true });
          msgArray[index] = "";
          return;
        }

        if (type.charAt(0) === 'N') {
          let len = 15;
          if (value.indexOf(".") >= 0) {
            len = 16;
          }
          if (isNaN(value.trim()) || value.length > len || value.indexOf(".") === 0) {
            this.componentList[i].control.setErrors({ 'incorrect': true });
            msgArray[index] = this.importNumberTypeValue;
          } else {
            this.componentList[i].control.setErrors(undefined);
            msgArray[index] = "";
          }
        } else if (type.charAt(0) === 'D') {
          let dateData = (value + "").split("-");
          if (!(Date.parse(value.trim())) || dateData.length !== 3 || dateData[0].length !== 4 || dateData[1].length !== 2 || dateData[2].length !== 2) {
            this.componentList[i].control.setErrors({ 'incorrect': true });
            msgArray[index] = this.importDateTypeValue;
          } else {
            this.componentList[i].control.setErrors(undefined);
            msgArray[index] = "";
          }
        }
      }
    }
  }

  cancelWindow() {
    this.modalRef.close();
    this.file = null;
    this.errorMsg = "";
  }

  selectedRecord(index, outputRecord) {
    console.log("selectedRecord => currentkey=" + this.keyName);
    console.log("selectedRecord => currentselectedindex=" + this.selectedIndex);

    this.disabled = this.keyName ? false : true;
    this.selectedChildRecord = outputRecord;
    this.keyName = this.selectedChildRecord.key;
    this.selectedIndex = index;
    console.log("selectedRecord => index=" + index + " outputrecord=" + outputRecord);
    console.log("selectedRecord => newkey=" + this.keyName);
  }

  showVariablesDetails(index, position) {
    this.notSlave = index.editable;
    this.childTable = true;
    this.tableName = index.id;
    this.tblName = this.tableName;
    this.disabled = true;
    this.selectedChildRecord = null;
    this.keyName = null;
    this.selectedIndex = null;
    this.index = position;
    this.currentTablesData = index;
    this.updateOnIndex = position;
    this.selectedOperators = [];
    this.inputParamData = [];
    this.outputParamData = [];
    this.currentTableObj = index;
    this.rowspan = [];
    this.clmName = [];

    this.outputParamData = index.outputValues.output;
    this.outputParamData.forEach(val => {
      if (null != val.key && val.key.indexOf(".") == -1) {
        let varName = "";
        for (var i = 0; i < val.key.length; i++) {
          if (i != 0 && isNaN(val.key.charAt(i))) {
            varName = varName + "." + val.key.charAt(i);
          } else {
            varName = varName + val.key.charAt(i);
          }
        }
        val.name = varName;
      } else {
        val.name = val.key;
      }
    });

    this.inputParamData = Object.assign([], index.inputVariables.variable);
    this.createMultipleRecords();//this updates inputParamData and refers outputParamData
    this.inputValueAndColumnNameMapping = [];
    let indexOfCount = 0;
    this.inputParamData.forEach(element => {
      this.selectedOperators.push(this.operators[this.operatorNames.indexOf(element.operator)]);
      this.inputValueAndColumnNameMapping.push({});
      element.length = parseInt(this.categoryVariablesData[element.variableRefID]['length']);
      element.decimalLength = parseInt(this.categoryVariablesData[element.variableRefID]['decimalLength']);
      element.values.valueState.forEach(subelement => {
        this.inputValueAndColumnNameMapping[indexOfCount][subelement.status == 'M'
          ? "[Missing]" : subelement.status == "N" ? "[Not Available]" : subelement.status == "A"
            ? "[Available]" : subelement.else == "" ? "[Else]" : subelement.value.value] = subelement.code;
      });
      indexOfCount++;
    });

    this.outputObject = index.outputObjects.variables;
    this.outputObject.variableRefID.forEach(element => {
      element.length = parseInt(this.categoryVariablesData[element.value]['length']);
      element.decimalLength = parseInt(this.categoryVariablesData[element.value]['decimalLength']);
    });

    this.userDetails.setInputData(JSON.stringify(this.inputParamData));
    console.log("-- the op data post parent row selection -");
    console.log(this.outputParamData);

    this.variablesData = this.outputParamData;
    this.ascAndDescOrderData(null, null, null);

  }

  createMultipleRecords() {
    let inputValueMap = {};
    this.inputParamData.forEach(element => {
      for (let index = 0; index < element.values.valueState.length; index++) {
        const ele = element.values.valueState[index];
        inputValueMap[ele["code"]] = ele;
      }
    });

    let trckIndex = 1;
    this.outputParamData.forEach(element => {
      let indexTrcker = 0;
      let newName = [];
      let newNamesArray = [];
      element.name.split(".").forEach(elemts => {
        if (newNamesArray.indexOf(elemts) < 0) {
          newNamesArray.push(elemts);
        }
      });

      newNamesArray.forEach(element1 => {
        if (trckIndex == 1) {
          this.inputParamData[indexTrcker].values.valueState = Object.assign([], []);
        }
        let varName = "";
        for (var i = 0; i < element1.length; i++) {
          if (isNaN(element1.charAt(i))) {
            varName = varName + element1.charAt(i);
          }
        }

        varName = varName + trckIndex;
        let ele = Object.assign({}, inputValueMap[element1]);
        ele["code"] ? ele["code"] = varName : ele["_code"] ? ele["_code"] = varName : ele["_code"] = varName;
        ele["index"] = trckIndex - 1;
        this.inputParamData[indexTrcker].values.valueState.push(ele);
        newName.push(varName);
        this.oldNameNewNamePair[varName] = element1;
        indexTrcker++;
      });
      element.name = newName.join(".");
      element.key = element.key.indexOf(".") >= 0 ? newName.join(".") : newName.join("");
      trckIndex++;
    });
  }

  findTypeById(id): string {
    let varType: string = "";
    if (this.nodeService.bacProcessNode
      && ((this.nodeService.bacProcessNode.ioType === ProcessIOType.COBOL)
        || (this.nodeService.bacProcessNode.ioType === ProcessIOType.XML)
        || (this.nodeService.bacProcessNode.ioType === ProcessIOType.FLAT))) {
      console.log("inputName would be like <type><length>.<decimalLength>. Eg - N10.2, T4");

      if (null !== this.categoryVariablesData && id) {
        let categoryVariableRefID = this.categoryVariablesData[id].categoryVariableRefID;
        if (this.nodeService.bacProcessNode['variables'] && this.nodeService.bacProcessNode['variables']['variable']) {
          for (var i = 0; i < this.nodeService.bacProcessNode['variables']['variable'].length; i++) {
            if (this.nodeService.bacProcessNode['variables']['variable'][i]['guid'] === categoryVariableRefID) {
              if (this.nodeService.bacProcessNode['variables']['variable'][i]['type'].charAt(0) === 'N'
                && this.nodeService.bacProcessNode['variables']['variable'][i]['decimalLength'] !== 0) {
                varType = this.nodeService.bacProcessNode['variables']['variable'][i]['type'].charAt(0)
                  + this.nodeService.bacProcessNode['variables']['variable'][i]['length']
                  + "."
                  + this.nodeService.bacProcessNode['variables']['variable'][i]['decimalLength'];
              } else {
                varType = this.nodeService.bacProcessNode['variables']['variable'][i]['type'].charAt(0)
                  + this.nodeService.bacProcessNode['variables']['variable'][i]['length'];
              }
              break;
            }
          }
        }
      }
    } else {
      if (null !== this.categoryVariablesData && id) {
        let categoryVariableRefID = this.categoryVariablesData[id].categoryVariableRefID;
        console.log(categoryVariableRefID);
        //TODO replace bacProcessNode['variables'] with map vars.
        if (this.nodeService.bacProcessNode['variables'] && this.nodeService.bacProcessNode['variables']['variable']) {
          for (var i = 0; i < this.nodeService.bacProcessNode['variables']['variable'].length; i++) {
            if (this.nodeService.bacProcessNode['variables']['variable'][i]['guid'] === categoryVariableRefID) {
              varType = this.nodeService.bacProcessNode['variables']['variable'][i]['type'].charAt(0);
              break;
            }
          }
        }
      }
    }

    return varType;
  }

  findByName(id) {
    let inputName = "";
    if (null !== this.categoryVariablesData) {
      if (this.nodeService.bacProcessNode.ioType === ProcessIOType.COBOL ||
        this.nodeService.bacProcessNode.ioType === ProcessIOType.XML) {
        inputName = this.categoryVariablesData[id].objectsVariableId;
      } else if (this.nodeService.bacProcessNode.ioType === ProcessIOType.XML_EVO ||
        this.nodeService.bacProcessNode.ioType === ProcessIOType.JSON_EVO) {
        if (this.categoryVariablesData[id].objectsVariableId.split(".")[0] === this.categoryVariablesData[id].categoryId) {
          inputName = this.categoryVariablesData[id].objectsVariableId;
        } else {
          inputName = this.categoryVariablesData[id].categoryId + "." + this.categoryVariablesData[id].objectsVariableId;
        }
      }
    } else {
      inputName = "UNDEFINED";
    }
    return inputName;
  }

  rowDown(i) {
    const current = this.outputParamData[i];
    const lower = this.outputParamData[i + 1];
    this.outputParamData[i] = lower;
    this.outputParamData[i + 1] = current;
  }

  rowUp(i) {
    const current = this.outputParamData[i];
    const upper = this.outputParamData[i - 1];
    this.outputParamData[i] = upper;
    this.outputParamData[i - 1] = current;
  }

  pagination() {
    if (this.userDetails.getDataLength() === null) {
      this.userDetails.setDataLength(15);
    }
    this.pageNum = parseInt(this.userDetails.getPageNumber());
    this.numOfRecordPerPage = parseInt(this.userDetails.getDataLength());
  }

  ascAndDescOrderData(value, index, type) {


    if (null !== value) {
      this.inputParamData[index].operator = this.operatorNames[this.operators.indexOf(value)];
    }

    let columnOrderData = [];
    for (let i = 0; i < this.inputParamData.length; i++) {
      columnOrderData.push(this.orderByInput(this.operators[this.operatorNames.indexOf(this.inputParamData[i].operator)], i, type));
    }

    this.inputParamData = columnOrderData;
    this.createOrderCombinations(columnOrderData);
  }

  combineInputOutput() {
    let crrInput = this.inputParamData;
    let crrOutput = this.outputParamData;
    let valueKeyMap = [];
    let maxNumber = [];
    if (crrOutput && crrOutput[0] && crrOutput[0].name) {
      let couterTrck = 0;
      crrOutput.forEach(outelement => {
        let trck = 0;
        let nameArr = [];
        outelement.name.split(".").forEach(name => {
          if (!valueKeyMap[trck]) {
            valueKeyMap[trck] = {};
          }
          let inName = "";
          for (let s = 0; s < name.length; s++) {
            if (isNaN(name.charAt(s))) {
              inName = inName + name.charAt(s);
            }
          }
          crrInput[trck].values.valueState.forEach(inputData => {
            if (!maxNumber[trck]) {
              maxNumber[trck] = 1;
            }
            if (inputData["-code"] === name || inputData["_code"] === name || inputData["code"] === name) {
              if (inputData.status === 'M' || inputData.status === 'N' || inputData.status === 'A') {
                if (valueKeyMap[trck][inputData.status]) {
                  inputData["-code"] ? inputData["-code"] = valueKeyMap[trck][inputData.status] : inputData["_code"] ? inputData["_code"] = valueKeyMap[trck][inputData.status] : inputData["code"] = valueKeyMap[trck][inputData.status];
                  nameArr.push(valueKeyMap[trck][inputData.status]);
                  inputData["value"] = null;
                } else {
                  inputData["-code"] ? inputData["-code"] = inName + maxNumber[trck] : inputData["_code"] ? inputData["_code"] = inName + maxNumber[trck] : inputData["code"] = inName + maxNumber[trck];
                  valueKeyMap[trck][inputData.status] = inName + maxNumber[trck];
                  maxNumber[trck] = maxNumber[trck] + 1;
                  nameArr.push(valueKeyMap[trck][inputData.status]);
                  inputData["value"] = null;
                }
              } else if (inputData["value"] === null || (inputData["else"] != null && inputData["else"] == "")) {
                if (valueKeyMap[trck]["Else"]) {
                  inputData["-code"] ? inputData["-code"] = valueKeyMap[trck]["Else"] : inputData["_code"] ? inputData["_code"] = valueKeyMap[trck]["Else"] : inputData["code"] = valueKeyMap[trck]["Else"];
                  nameArr.push(valueKeyMap[trck]["Else"]);
                  inputData["value"] = null;
                } else {
                  inputData["-code"] ? inputData["-code"] = inName + maxNumber[trck] : inputData["_code"] ? inputData["_code"] = inName + maxNumber[trck] : inputData["code"] = inName + maxNumber[trck];
                  valueKeyMap[trck]["Else"] = inName + maxNumber[trck];
                  maxNumber[trck] = maxNumber[trck] + 1;
                  nameArr.push(valueKeyMap[trck]["Else"]);
                  inputData["value"] = null;
                }
              } else {
                if (valueKeyMap[trck][inputData["value"]["value"]]) {
                  inputData["-code"] ? inputData["-code"] = valueKeyMap[trck][inputData["value"]["value"]] : inputData["_code"] ? inputData["_code"] = valueKeyMap[trck][inputData["value"]["value"]] : inputData["code"] = valueKeyMap[trck][inputData["value"]["value"]];
                  nameArr.push(valueKeyMap[trck][inputData["value"]["value"]]);
                } else {
                  inputData["-code"] ? inputData["-code"] = inName + maxNumber[trck] : inputData["_code"] ? inputData["_code"] = inName + maxNumber[trck] : inputData["code"] = inName + maxNumber[trck];
                  valueKeyMap[trck][inputData["value"]["value"]] = inName + maxNumber[trck];
                  maxNumber[trck] = maxNumber[trck] + 1;
                  nameArr.push(valueKeyMap[trck][inputData["value"]["value"]]);
                }
              }
            }
          });

          trck++;
        });
        outelement.key = outelement.key.indexOf(".") >= 0 ? nameArr.join(".") : nameArr.join("");
        outelement.name = nameArr.join(".")
        couterTrck++;
      });
    }
    this.removeDublicateAndSetToInput(crrInput);
  }

  removeDublicateAndSetToInput(crrInput) {

    crrInput.forEach(element => {
      let input = [];
      let found = [];

      element.values.valueState.forEach(inelement => {
        if (found.indexOf(inelement["-code"]) < 0 && found.indexOf(inelement["_code"]) < 0 && found.indexOf(inelement["code"]) < 0) {
          input.push(inelement);
          found.push(inelement["-code"] ? inelement["-code"] : inelement["_code"] ? inelement["_code"] : inelement["code"]);
        }
      });

      element.values.valueState = input;
    });
  }

  createOrderCombinations(columnOrderData: TableVariable[]) {
    let nameMapping = [];
    let i = 0;
    let latestOutput = [];
    columnOrderData.forEach(element => {
      let childArry = [];
      element.values.valueState.forEach(childElement => {
        childArry.push(childElement.code);
      });
      nameMapping.push(childArry);
      i++;
    });

    let result = nameMapping[0];
    for (let i = 1; i < nameMapping.length; i++) {
      result = this.cartesianProductOf(result, nameMapping[i]);
    }


    result.forEach(element => {
      this.outputParamData.forEach(outputelement => {
        if (element === outputelement.name) {
          latestOutput.push(outputelement);
        }
      });
    });

    this.outputParamData = latestOutput;

    this.createMultipleRecords();
    this.currentTablesData.inputVariables.variable = this.inputParamData;
    this.currentTablesData.outputValues.output = this.outputParamData;
    this.userDetails.setInputData(JSON.stringify(this.inputParamData));
  }

  cartesianProductOf(array1, array2) {
    let array = [];
    array1.forEach(element1 => {
      array2.forEach(element2 => {
        array.push(element1 + "." + element2);
      });
    });
    return array;
  }

  orderByInput(value, index, type) {
    this.orderInputIndex = index;
    this.orderInputType = type;
    this.orderInputValue = value;

    this.combineInputOutput();
    if (value === "<>" || value === "=") {
      return this.orderByInputEqual(value, index, type);
    } else if (undefined !== value && value.indexOf("<") > -1) {
      return this.orderByInputAsc(value, index, type);
    } else if (undefined !== value && value.indexOf(">") > -1) {
      return this.orderByInputDesc(value, index, type);
    }
  }

  orderByOutput(value, index, type) {
    if (undefined !== value && value.indexOf("<") > -1) {
      this.outputParamData.sort(this.orderByOutputAsc(index, "value"));
    } else {
      this.outputParamData.sort(this.orderByOutputDesc(index, "value"));
    }
  }

  orderByInputAsc(value, index, type) {
    let currentSelectedColumn = Object.assign({}, this.inputParamData[index]);
    let lateshOutPut = [];


    currentSelectedColumn.values.valueState.sort(this.getSortOrder("value"));
    currentSelectedColumn.values.valueState.forEach(element => {
      this.outputParamData.forEach(element1 => {
        if (element1["name"].split(".")[index] == element.code) {
          lateshOutPut.push(element1);
        }
      });
    });

    return currentSelectedColumn;
    //this.outputParamData = lateshOutPut;
    //this.currentTablesData.outputValues.output = this.outputParamData;
    //this.inputParamData[index].operator = this.operatorNames[this.operators.indexOf(value)];

    //this.userDetails.setInputData(JSON.stringify(this.inputParamData));
  }

  getSortOrderForEqualAndNotEqual(prop) {
    return function (a, b) {
      if (a["value"] && (b['status'] === 'M' || b['status'] === 'N' || b['status'] === 'A' || b['else'] === '')) {
        return -1;
      } else if (b["value"] && (a['status'] === 'M' || a['status'] === 'N' || a['status'] === 'A' || a['else'] === '')) {
        return 1;
      } else if (a['status'] === 'M' && (b['status'] === 'N' || b['status'] === 'A' || b['else'] === '')) {
        return -1;
      } else if (a['status'] === 'N' && (b['status'] === 'A' || b['else'] === '')) {
        return -1;
      } else if (a['status'] === 'A' && b['else'] === '') {
        return -1;
      } else if ((a['status'] || a['else'] == '') && (b['status'] || b['else'] == '')) {
        return 1;
      }

      return 0;
    }
  }

  getSortOrder(prop) {
    return function (a, b) {
      var numbers = /^[0-9]+$/;

      if (a['status'] === 'A' && (b['status'] === 'M' || b['status'] === 'N' || b['else'] === '')) {
        return 1;
      } else if (a['status'] === 'M' && (b['status'] === 'N' || b['else'] === '')) {
        return 1;
      } else if (a['status'] === 'N' && b['else'] === '') {
        return 1;
      }

      if ((a["value"] == null && b["value"] != null)
        || (a["value"] && b["value"] && (a["value"][prop] && a["value"][prop].match(numbers) ? parseInt(a["value"][prop]) : a["value"][prop]) > (b["value"][prop] && b["value"][prop].match(numbers) ? parseInt(b["value"][prop]) : b["value"][prop]))) {
        return 1;
      } else if ((a["value"] != null && b["value"] == null)
        || (a["value"] && b["value"] && (a["value"][prop] && a["value"][prop].match(numbers) ? parseInt(a["value"][prop]) : a["value"][prop]) < (b["value"][prop] && b["value"][prop].match(numbers) ? parseInt(b["value"][prop]) : b["value"][prop]))) {
        return -1;
      }
      return 0;
    }
  }

  getDescSortOrder(prop) {
    return function (a, b) {
      var numbers = /^[0-9]+$/;

      if (a['status'] === 'A' && (b['status'] === 'M' || b['status'] === 'N' || b['else'] === '')) {
        return -1;
      } else if (a['status'] === 'M' && (b['status'] === 'N' || b['else'] === '')) {
        return -1;
      } else if (a['status'] === 'N' && b['else'] === '') {
        return -1;
      }

      if ((a["value"] != null && b["value"] == null)
        || (a["value"] && b["value"] && (a["value"][prop] && a["value"][prop].match(numbers) ? parseInt(a["value"][prop]) : a["value"][prop]) > (b["value"][prop] && b["value"][prop].match(numbers) ? parseInt(b["value"][prop]) : b["value"][prop]))) {
        return -1;
      } else if ((a["value"] == null && b["value"] != null)
        || (a["value"] && b["value"] && (a["value"][prop] && a["value"][prop].match(numbers) ? parseInt(a["value"][prop]) : a["value"][prop]) < (b["value"][prop] && b["value"][prop].match(numbers) ? parseInt(b["value"][prop]) : b["value"][prop]))) {
        return 1;
      }
      return 0;
    }
  }

  orderByInputEqual(value, index, type) {
    let currentSelectedColumn = this.inputParamData[index];
    let lateshOutPut = [];

    currentSelectedColumn.values.valueState.sort(this.getSortOrderForEqualAndNotEqual("value"));
    currentSelectedColumn.values.valueState.forEach(element => {
      this.outputParamData.forEach(element1 => {
        if (element1["name"].split(".")[index] == element.code) {
          lateshOutPut.push(element1);
        }
      });
    });

    return currentSelectedColumn;
    //this.outputParamData = lateshOutPut;

    //this.currentTablesData.outputValues.output = this.outputParamData;
    //this.inputParamData[index].operator = this.operatorNames[this.operators.indexOf(value)];
    //this.userDetails.setInputData(JSON.stringify(this.inputParamData));
  }


  orderByInputDesc(value, index, type) {
    let currentSelectedColumn = this.inputParamData[index];
    let lateshOutPut = [];

    currentSelectedColumn.values.valueState.sort(this.getDescSortOrder("value"));
    currentSelectedColumn.values.valueState.forEach(element => {
      this.outputParamData.forEach(element1 => {
        if (element1["name"].split(".")[index] == element.code) {
          lateshOutPut.push(element1);
        }
      });
    });

    return currentSelectedColumn;
    //this.outputParamData = lateshOutPut;

    //this.currentTablesData.outputValues.output = this.outputParamData;
    //this.inputParamData[index].operator = this.operatorNames[this.operators.indexOf(value)];
    //this.userDetails.setInputData(JSON.stringify(this.inputParamData));
  }

  orderByOutputAsc(index, prop) {
    return function (a, b) {
      var numbers = /^[0-9]+$/;
      if ((a["variableValues"]["variableValue"][index]["valueState"]["value"] == null && b["variableValues"]["variableValue"][index]["valueState"]["value"] != null)
        || (a["variableValues"]["variableValue"][index]["valueState"]["value"] && b["variableValues"]["variableValue"][index]["valueState"]["value"]
          && (a["variableValues"]["variableValue"][index]["valueState"]["value"][prop].match(numbers) ? parseInt(a["variableValues"]["variableValue"][index]["valueState"]["value"][prop]) : a["variableValues"]["variableValue"][index]["valueState"]["value"][prop]) >
          (b["variableValues"]["variableValue"][index]["valueState"]["value"][prop].match(numbers) ? parseInt(b["variableValues"]["variableValue"][index]["valueState"]["value"][prop]) : b["variableValues"]["variableValue"][index]["valueState"]["value"][prop]))) {
        return 1;
      } else if ((a["variableValues"]["variableValue"][index]["valueState"]["value"] != null && b["variableValues"]["variableValue"][index]["valueState"]["value"] == null)
        || (a["variableValues"]["variableValue"][index]["valueState"]["value"] && b["variableValues"]["variableValue"][index]["valueState"]["value"]
          && (a["variableValues"]["variableValue"][index]["valueState"]["value"][prop].match(numbers) ? parseInt(a["variableValues"]["variableValue"][index]["valueState"]["value"][prop]) : a["variableValues"]["variableValue"][index]["valueState"]["value"][prop]) <
          (b["variableValues"]["variableValue"][index]["valueState"]["value"][prop].match(numbers) ? parseInt(b["variableValues"]["variableValue"][index]["valueState"]["value"][prop]) : b["variableValues"]["variableValue"][index]["valueState"]["value"][prop]))) {
        return -1;
      }
      return 0;
    }
  }

  orderByOutputDesc(index, prop) {
    return function (a, b) {
      var numbers = /^[0-9]+$/;
      if ((a["variableValues"]["variableValue"][index]["valueState"]["value"] != null && b["variableValues"]["variableValue"][index]["valueState"]["value"] == null)
        || (a["variableValues"]["variableValue"][index]["valueState"]["value"] && b["variableValues"]["variableValue"][index]["valueState"]["value"]
          && (a["variableValues"]["variableValue"][index]["valueState"]["value"][prop].match(numbers) ? parseInt(a["variableValues"]["variableValue"][index]["valueState"]["value"][prop]) : a["variableValues"]["variableValue"][index]["valueState"]["value"][prop]) >
          (b["variableValues"]["variableValue"][index]["valueState"]["value"][prop].match(numbers) ? parseInt(b["variableValues"]["variableValue"][index]["valueState"]["value"][prop]) : b["variableValues"]["variableValue"][index]["valueState"]["value"][prop]))) {
        return -1;
      } else if ((a["variableValues"]["variableValue"][index]["valueState"]["value"] == null && b["variableValues"]["variableValue"][index]["valueState"]["value"] != null)
        || (a["variableValues"]["variableValue"][index]["valueState"]["value"] && b["variableValues"]["variableValue"][index]["valueState"]["value"]
          && (a["variableValues"]["variableValue"][index]["valueState"]["value"][prop].match(numbers) ? parseInt(a["variableValues"]["variableValue"][index]["valueState"]["value"][prop]) : a["variableValues"]["variableValue"][index]["valueState"]["value"][prop]) <
          (b["variableValues"]["variableValue"][index]["valueState"]["value"][prop].match(numbers) ? parseInt(b["variableValues"]["variableValue"][index]["valueState"]["value"][prop]) : b["variableValues"]["variableValue"][index]["valueState"]["value"][prop]))) {
        return 1;
      }
      return 0;
    }
  }

  exportAsXLSX() {

    let columnData: any[] = [];
    let columnHeader = [];
    let columnOperator = [];
    let typeArray = [];

    this.inputParamData.forEach(prop => {

      columnHeader.push(this.findByName(prop.variableRefID));
      typeArray.push(this.findTypeById(prop.variableRefID));
      let operator = new OperatorMappingFilter().transform(prop.operator).replace("(", "");
      operator = operator.replace(")", "");
      columnOperator.push(operator);
    })

    this.outputObject["variableRefID"].forEach(prop => {
      columnHeader.push(this.findByName(prop.value));
      columnOperator.push("Variable");
      typeArray.push(this.findTypeById(prop.value));
    });

    columnData.push(columnHeader);
    columnData.push(typeArray);
    columnData.push(columnOperator);

    this.outputParamData.forEach(prop => {
      let columnTempData = [];
      prop.name.split('.').forEach(prop1 => {
        columnTempData.push(this.getValueFromName(prop1));
      });

      prop.variableValues.variableValue.forEach(prop1 => {
        columnTempData.push(this.getOutPutValue(prop1.valueState));
      });

      columnData.push(columnTempData);
    });

    this.excelService.exportAsExcelFile(columnData, this.tableName);
    this.ascAndDescOrderData(null, null, null);
  }

  openImport(template: TemplateRef<any>) {
    this.modalRef = this.modalService.open(template,MODAL_OPTIONS);
  }

  validateEachRecordValue(type: string[], record: string[], columnNames: string[]) {
    for (let m = 0; m < columnNames.length; m++) {
      let value: string = record[m];

      //blank check
      if (undefined === value || value.toString().trim() === "") return this.text_import_var_blank + ". " + this.text_import_var_invalid_data + " " + this.text_import_var_invalid_length_2 + " '" + columnNames[m] + "'.";

      //get length, decimalLength; skip [special] values
      let varlength: number;
      let vardecimalLength: number;
      if (m < this.inputParamData.length) {
        if (value === "[Else]" || value === "[Missing]" || value === "[Not Available]" || value === "[Available]") continue;
        varlength = this.inputParamData[m].length;
        vardecimalLength = this.inputParamData[m].decimalLength;
      } else {
        if (value === "[Missing]" || value === "[Not Available]") continue;
        varlength = this.outputObject.variableRefID[m - this.inputParamData.length].length;
        vardecimalLength = this.outputObject.variableRefID[m - this.inputParamData.length].decimalLength;
      }

      //text check
      if (type[m].charAt(0) === 'T' && value.length > varlength) return this.text_import_var_invalid_length_1 + " '" + varlength + "'. " + this.text_import_var_invalid_data + " '" + value + "' " + this.text_import_var_invalid_length_2 + " '" + columnNames[m] + "'.";


      //number check
      if (type[m].charAt(0) === 'N') {
        let partValue: string[] = String(value).split(".");
        if (isNaN(+value) || partValue[0].length > varlength) return this.text_import_var_invalid_length_1 + " '" + varlength + "'. " + this.text_import_var_invalid_data + " '" + value + "' " + this.text_import_var_invalid_length_2 + " '" + columnNames[m] + "'.";
        if (vardecimalLength > 0 && partValue.length === 2 && partValue[1].length > vardecimalLength) return this.text_import_var_invalid_length_1 + " '" + varlength + "." + vardecimalLength + "'. " + this.text_import_var_invalid_data + " '" + value + "' " + this.text_import_var_invalid_length_2 + " '" + columnNames[m] + "'.";
      }

      //date check
      if (type[m].charAt(0) === 'D') {
        if (!(Date.parse(value.trim()))) return this.text_import_var_invalid_data + " '" + value + "' " + this.text_import_var_invalid_length_2 + " '" + columnNames[m] + "'.";
        let datePart = (value + "").split("-");
        if (datePart.length !== 3 || datePart[0].length !== 4 || datePart[1].length !== 2 || datePart[2].length !== 2) return this.text_import_var_invalid_data + " '" + value + "' " + this.text_import_var_invalid_length_2 + " '" + columnNames[m] + "'.";
      }
    }
    return null;
  }

  checkDuplicateColumns(data) {
    for (let i = 3; i < data.length; i++) {
      for (let j = i + 1; j < data.length; j++) {
        let sameRow = false;
        for (let k = 0; k < data[i].length; k++) {
          if (data[i][k] === data[j][k]) {
            sameRow = true;
          } else {
            sameRow = false;
            break;
          }
        }

        if (sameRow) {
          return true;
        }
      }
    }
    return false;
  }

  lenghtCheckValidationForImport({ typeis, index }: { typeis: string; index: number; }): boolean {
    console.log("--- lenghtCheckValidationForImport");
    console.log(typeis);
    console.log(index);

    if (this.nodeService.bacProcessNode.ioType === ProcessIOType.COBOL
      || this.nodeService.bacProcessNode.ioType === ProcessIOType.FLAT
      || this.nodeService.bacProcessNode.ioType === ProcessIOType.XML) {
      let lengthis: number = parseInt(typeis.substring(1, typeis.length));
      console.log(lengthis);
      console.log(this.data[1][index].toString());
      console.log(this.data[2][index].toString());
      console.log(this.data[3][index].toString());

      for (let k = 3; k < this.data.length; k++) {
        if (this.data[k][index].toString().length > lengthis) { //TODO add condition to ignore else, missing etc
          return true;
        }
      }
    } else {
      if (typeis.charAt(0) === "N") {
        for (let k = 3; k < this.data.length; k++) {
          if (undefined !== this.data[k][index] && this.data[k][index].toString().length > 15) { //TODO add condition to ignore else, missing etc
            return true;
          }
        }
      }
    }
    console.log("returning false. end lenghtCheckValidationForImport");

    return false;
  }

  fileChanged(event: any) {
    this.errorMsg = "";
    let fileList: FileList = event.target.files;
    if (fileList.length !== 1) {
      this.file = null;
      this.isDisableImportInPopup = true;
      this.errorMsg = this.text_import_multipleOrNoFiles;
      return;
    }
    if (fileList[0].type
      && (fileList[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileList[0].type === 'application/vnd.ms-excel')) {
      this.isDisableImportInPopup = false;
      this.file = fileList[0];
    } else {
      this.file = null;
      this.isDisableImportInPopup = true;
      this.errorMsg = this.text_import_invalid_file + " " + this.text_import_validFormats + "xls, xlsx";
      return;
    }
  }
  importTableExcel() {
    OperationsComponent.url = true;
    const reader: FileReader = new FileReader();
    reader.readAsArrayBuffer(this.file);
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

      var isValid: boolean = true;
      var isVarLengthFlag: boolean = true;
      var inputCount: number = 0;
      var outputCount: number = 0;
      let wrongColName: string;

      //validations start
      //1. blank check in first 3 rows      
      if (this.data[0].length !== this.data[1].length || this.data[0].length !== this.data[2].length) {
        return this._importTableExcelStop(this.text_import_var_invalid_data + " " + this.text_import_invalid_row + ".");
      }
      //2. total ip/op count > 0
      for (let i = 0; i < this.data[2].length; i++) {
        if (this.data[2][i] === undefined) {
          wrongColName = this.data[0][i];
          return this._importTableExcelStop(this.text_import_var_blank + " " + this.text_import_var_invalid_data
            + " " + this.text_import_var_invalid_length_2 + " '" + wrongColName + "'.");
        }
        if (this.data[2][i] === 'Variable') outputCount++;
        else inputCount++;
      }
      if (outputCount === 0 || inputCount === 0) {
        return this._importTableExcelStop(this.text_import_var_blank);
      }
      //3 existing ip/op names = imported ip/op names
      //3.1 existing ip/op count = imported ip/op count
      if (this.inputParamData.length === inputCount && Object.keys(this.outputObject['variableRefID']).length === outputCount) {
        //3.2 ip names should match
        for (let i = 0; i < this.inputParamData.length; i++) {
          if (this.findByName(this.inputParamData[i].variableRefID).trim().toUpperCase() !== (this.data[0][i] + "").trim().toUpperCase()) {
            isValid = false;
            break;
          }
        }
        //3.3 op names should match
        for (let i = this.inputParamData.length; i < this.inputParamData.length + this.outputObject.variableRefID.length && isValid; i++) {
          if (this.findByName(this.outputObject.variableRefID[i - this.inputParamData.length].value).trim().toUpperCase() !== (this.data[0][i] + "").trim().toUpperCase()) {
            isValid = false;
            break;
          }
        }
      } else {
        isValid = false;
      }
      if (!isValid) {
        return this._importTableExcelStop(this.text_import_var_mismatch);
      }

      //4. type should match
      //4.1 ip type should match
      for (let i = 0; i < this.inputParamData.length; i++) {
        let typeis: string = this.findTypeById(this.inputParamData[i].variableRefID).trim();
        if (typeis !== ((this.data[1][i] + "").trim()).replace("(", "").replace(")", "")) {
          isValid = false;
          wrongColName = this.findByName(this.inputParamData[i].variableRefID);
          break;
        }
      }
      //4.2 op type should match
      for (let i = this.inputParamData.length; i < this.inputParamData.length + this.outputObject.variableRefID.length && isValid; i++) {
        let typeis: string = this.findTypeById(this.outputObject.variableRefID[i - this.inputParamData.length].value).trim();
        if (typeis !== ((this.data[1][i] + "").trim()).replace("(", "").replace(")", "")) {
          isValid = false;
          wrongColName = this.findByName(this.outputObject.variableRefID[i - this.inputParamData.length].value);
          break;
        }
      }
      if (!isValid) {
        return this._importTableExcelStop(this.text_import_var_invalid_type + " '" + wrongColName + "'");
      }

      //5. operators
      for (let i = 0; i < this.data[2].length; i++) {
        if (this.operators.includes(this.data[2][i].toString()) || this.data[2][i].toString() === "Variable") {
          //5.1 ip operator
          if (i < this.inputParamData.length) {
            const element = new OperatorMappingFilter().transform(this.inputParamData[i].operator).replace("(", "").replace(")", "").trim();
            if (element !== this.data[2][i]) {
              wrongColName = this.data[0][i];
              isValid = false;
              break;
            }
          } else { //5.2 op operator
            if (this.data[2][i].toString() !== "Variable") {
              wrongColName = this.data[0][i];
              isValid = false;
              break;
            }
          }
        } else {
          wrongColName = this.data[0][i];
          isValid = false;
          break;
        }
      }
      if (!isValid) {
        return this._importTableExcelStop(this.text_import_var_invalid_operator + " "
          + this.text_import_var_invalid_length_2 + " '" + wrongColName + "'.");
      }

      //6. domain validation
      this.domainVariables = [];
      for (let i = 0; i < this.inputParamData.length; i++) {
        this.domainVariables.push(this.categoryVariablesData[this.inputParamData[i].variableRefID]['domainValues'] ? this.categoryVariablesData[this.inputParamData[i].variableRefID]['domainValues']['domainValue'] : null)
      }
      for (let i = 0; i < this.outputObject['variableRefID'].length; i++) {
        this.domainVariables.push(this.categoryVariablesData[this.outputObject['variableRefID'][i].value]['domainValues'] ? this.categoryVariablesData[this.outputObject['variableRefID'][i].value]['domainValues']['domainValue'] : null)
      }
      for (let i = 0; i < this.domainVariables.length; i++) {
        if (this.domainVariables[i]) {
          let domainlist = [];
          for (let k = 0; k < this.domainVariables[i].length; k++) {
            domainlist.push(this.domainVariables[i][k].value + "");
          }
          for (let j = 3; j < this.data.length; j++) {
            if (domainlist.indexOf(this.data[j][i] + "") < 0 && this.data[j][i] !== "[Else]" && this.data[j][i] !== "[Not Available]" && this.data[j][i] !== "[NotAvailable]"
              && this.data[j][i] !== "[Available]" && this.data[j][i] !== "[Missing]") {
              wrongColName = this.data[0][i];
              let wrongValue = this.data[j][i];
              return this._importTableExcelStop(this.text_import_var_nonDomain + " " + this.text_import_var_invalid_data + " '" + wrongValue + "' " + + this.text_import_var_invalid_length_2 + " '" + wrongColName + "'.");
            }
          }
        }
      }

      //7. values validations
      let alertMsg: string;
      for (let j = 0; j < this.data.length; j++) {
        if (j > 2) {
          alertMsg = this.validateEachRecordValue(this.data[1], this.data[j], this.data[0]);
          if (alertMsg)
            return this._importTableExcelStop(alertMsg);
        }
      }
      alertMsg = this.checkForContinuousVariable(this.data, this.continuousVariable);
      if (alertMsg)
        return this._importTableExcelStop(alertMsg);

      if (this.checkDuplicateColumns(this.data))
        return this._importTableExcelStop(this.text_val_nonunique);


      //8. cutoff special
      if (this.isCutOffTable) {
        console.log("-- about to perform cutoff table validation. this.data=");
        if (!this.validateCutoffTable(this.data)) return this._importTableExcelStop(this.text_import_invalid_file);
        console.log("-- cutoff table validation end.");
      }
      //validations end

      this.updateSelectedTableJson(this.data);
      this.createMultipleRecords();
      this.alert.success(this.text_import_success);
      this.errorMsg = "";
      this.file = null;
      this.isDisableImportInPopup = false;
      this.modalRef.close();
      this.enablingWhatIf(this.currentTablesData.outputValues.output.length)
    };
  }
  _importTableExcelStop(finalErrorMsg: string) {
    this.errorMsg = finalErrorMsg;
    this.file = null;
    this.isDisableImportInPopup = true;
  }
  findLengthById(variableRef: TableVariable | TableOutputObjectRefId): number {
    let varLength: number = 0;
    varLength = variableRef.length;
    if (variableRef.decimalLength > 0) {
      let varLengthExtension: string = String(varLength) + variableRef.decimalLength;
      varLength = parseInt(varLengthExtension);
    }
    return varLength;
  }

  checkForContinuousVariable(data, cntVariables) {

    for (let j = 3; j < data.length; j++) {
      for (let i = 0; i < this.inputParamData.length; i++) {
        if (this.categoryVariablesData[this.inputParamData[i].variableRefID]['restrictionType'] === "Continuous") {
          let minValue = parseInt(this.categoryVariablesData[this.inputParamData[i].variableRefID]['minValue']);
          let maxValue = parseInt(this.categoryVariablesData[this.inputParamData[i].variableRefID]['maxValue']);

          let value = data[j][i]
          if (value === "[Else]" || value === "[Missing]" || value === "[Not Available]" || value === "[Available]") {
            continue;
          }

          if (!value || parseInt(value) < minValue || parseInt(value) > maxValue) {
            return "The value should be mininum = " + minValue + " & maximum = " + maxValue + " in field " + this.findByName(this.inputParamData[i].variableRefID);
          }
        }
      }
    }
  }

  /**
   * 
   * @param sheetdata excel sheet data
   * @return isValidCutoff true if verification pass
   */
  validateCutoffTable(sheetdata: AOA[]): boolean {
    let isValidCutoff: boolean = true;
    let inputvarCount: number = 0;
    let outputvarCount: number = 0;
    let inputvarValues: AOA = [];
    inputvarValues.length = inputvarCount;
    let outputvarValues: AOA = [];
    outputvarValues.length = outputvarCount;

    //collect unique inputs from sheet rows from 3-end; index per row starts at 0.
    for (let i = 3; i < sheetdata.length; i++) {
      for (let ipvar = 0; ipvar < inputvarCount; ipvar++) {
        if (!inputvarValues[ipvar]) { //init inner arr
          inputvarValues[ipvar] = [];
        }
        if (inputvarValues[ipvar].indexOf(sheetdata[i][ipvar].toString().trim()) === -1) {
          inputvarValues[ipvar].push(sheetdata[i][ipvar].toString().trim());
        }
      }
    }
    //collect all outputs from sheet rows 3-end; index per row starts at inputvarCount
    for (let i = 3; i < sheetdata.length; i++) {
      for (let opvar = 0; opvar < outputvarCount; opvar++) {
        if (!outputvarValues[opvar]) { //init inner arr
          outputvarValues[opvar] = [];
        }
        outputvarValues[opvar].push(sheetdata[i][opvar + inputvarCount].toString().trim());
      }
    }

    let expectedValuesPerOutput: number = 1;
    for (let ipvar = 0; ipvar < inputvarCount; ipvar++) {
      expectedValuesPerOutput *= inputvarValues[ipvar].length;
    }
    console.log("-- newly created data. ip=");
    console.log(inputvarValues);
    console.log("-- op=");
    console.log(outputvarValues);
    console.log("-- expected op count per op =" + expectedValuesPerOutput);
    for (let i = 0; i < outputvarValues.length; i++) {
      if (outputvarValues[i].length !== expectedValuesPerOutput) {
        console.log("-- expected count validation failed.");
        isValidCutoff = false;
        break;
      }
    }

    return isValidCutoff;
  }

  updateSelectedTableJson(data: AOA[]) {

    let parentindex = 0;
    this.inputValueAndColumnNameMapping = [];
    console.log("-- received inputParamData =");
    console.log(this.inputParamData);


    this.inputParamData.forEach(element => {

      this.idWithValriableNameMapping[element.variableRefID] = data[0][parentindex];

      var name: string = this.getIPCodeChar(element.index);
      console.log("-- inputParamData loop. name=" + name);


      element.values.valueState = [];
      let innerLoopIndex = 0;
      let dataCounter = 0;
      let concatValue = 0;
      this.inputValueAndColumnNameMapping.push({});
      //mayuri changed index: dataCounter + "" into index: dataCounter below in apr 21. Last table change was in dec 20
      data.forEach(innerelement => {
        if (innerLoopIndex > 2 && !this.inputValueAndColumnNameMapping[parentindex][innerelement[parentindex].toString()]) {
          element.values.valueState.push({
            code: this.inputValueAndColumnNameMapping[parentindex][innerelement[parentindex].toString()] ? this.inputValueAndColumnNameMapping[parentindex][innerelement[parentindex].toString()] : name + (++concatValue),
            else: innerelement[parentindex].toString() === "[Else]" ? "" : null,
            index: dataCounter,
            status: innerelement[parentindex].toString() === "[Missing]" || innerelement[parentindex].toString() === "[Available]" || innerelement[parentindex].toString() === "[Not Available]" || innerelement[parentindex].toString() === "[NotAvailable]" ? innerelement[parentindex].toString().substring(1, 2) : null,
            value: innerelement[parentindex].toString() === "[Missing]" || innerelement[parentindex].toString() === "[Available]" || innerelement[parentindex].toString() === "[Not Available]" || innerelement[parentindex].toString() === "[NotAvailable]" || innerelement[parentindex].toString() === "[Else]" ? null : { value: innerelement[parentindex] + "" }
          });
          dataCounter++;

          if (!this.inputValueAndColumnNameMapping[parentindex][innerelement[parentindex].toString()]) {
            this.inputValueAndColumnNameMapping[parentindex][innerelement[parentindex].toString()] = name + concatValue;
          }

        }
        innerLoopIndex++;
      });
      parentindex++;
    });

    this.selectedOperators = [];
    this.inputParamData.forEach(ele => {
      ele['operator'] = new OperatorNameMappingFilter().transform(data[2][ele.index].toString());
      this.selectedOperators.push(data[2][ele.index].toString());
    });

    this.inputParamData = this.inputParamData;
    this.userDetails.setInputData(JSON.stringify(this.inputParamData));

    let outPutKeys = [];
    let outputStartIndex = parentindex;
    this.outputObject["variableRefID"].forEach(element => {
      this.idWithValriableNameMapping[element.value] = data[0][parentindex];
      outPutKeys.push(element.value);
      parentindex++;
    });

    //in case of empty outputParamData fill dummy ele in it. Assuming dot indicator(A1.B1) for all procs.
    let separatorStr: string = ".";
    if (this.outputParamData.length === 0) {
      let dummyElement = {
        color: null,
        key: this.getCellIndicatorAtStart(separatorStr),
        name: this.getCellIndicatorAtStart(separatorStr),
        scenarios: { scenarioRefID: [] },
        variableValues: { variableValue: [] }
      };
      this.outputParamData.push(dummyElement);
    }
    let dotIndicator = this.containDotsInName(this.outputParamData[0].key);

    this.outputParamData = [];
    let innerLoopIndex = 0;
    let nameCounter = 1;
    data.forEach(element => {

      if (innerLoopIndex > 2) {
        let dummyElement = {
          color: null,
          key: dotIndicator ? this.createNameOfVariableFromMap(element) : this.createNameOfVariableFromMap(element).replace(/\./g, ''),
          name: this.createNameOfVariableFromMap(element),
          scenarios: { scenarioRefID: [] },
          variableValues: { variableValue: [] }
        };
        console.log("-- dummy element-");
        console.log(dummyElement);


        for (let i = outputStartIndex; i < element.length; i++) {
          dummyElement.variableValues.variableValue.push({
            valueState: {
              color: null,
              // status: null,
              // value: {value: element[i]}
              status: element[i] + "" === "[Missing]" || element[i] + "" === "[Available]" || element[i] + "" === "[Not Available]" || element[i] + "" === "[NotAvailable]" ? (element[i] + "").substring(1, 2) : null,
              value: element[i] + "" === "[Missing]" || element[i] + "" === "[Available]" || element[i] + "" === "[Not Available]" || element[i] + "" === "[NotAvailable]" ? null : { value: (element[i] + "") }
            },
            variableRefID: outPutKeys[i - outputStartIndex]
          });
        }

        this.outputParamData.push(dummyElement);
        nameCounter++;
      }
      innerLoopIndex++;
    });

    Object.keys(this.idWithValriableNameMapping).forEach(id => {
      this.categoryVariablesData[id].objectsVariableId = this.idWithValriableNameMapping[id];
    });
    this.variablesData = this.outputParamData;
    this.tablesData.forEach(tblData => {
      if (tblData.id === this.currentTablesData.id) {
        tblData.outputValues.output = this.outputParamData;
        tblData.inputVariables.variable = this.inputParamData;
      }
    });

    this.ascAndDescOrderData(null, null, null);
    this.modalRef.close();
  }

  //this function only created to avoid critical fortify scan alert
  private getCellIndicatorAtStart(separatorStr: string): string {
    return "A1" + separatorStr + "B1";
  }

  createNameOfVariableFromMap(dataArray) {
    let i = 0;
    let name = "";
    this.inputParamData.forEach(element => {
      name = name + this.inputValueAndColumnNameMapping[i][dataArray[i]] + ".";
      i++;
    })
    return name.substring(0, name.length - 1);
  }

  containDotsInName(name) {
    if (name.indexOf(".")) {
      return true;
    }
    return false;
  }

  createNameForArray(nameList, counter) {
    let createdName = "";
    nameList.forEach(element => {
      createdName = createdName + (element + counter + ".");
    });
    return createdName.substring(0, createdName.length - 1);
  }

  isDisableButton(): boolean {
    let isdisable: boolean = this.nodeService.isDisabled;

    if (!isdisable
      && (this.userDetails.getUserRole() === "USER"
        && this.nodeService.currentProcessDetails
        && this.nodeService.currentProcessDetails.permission
      )) {
      if (this.currentTablesData.tableType === "GENERIC"
        && this.nodeService.currentProcessDetails.permission
          .filter(val => val.permission === "SIMPLETABLE" && val.level !== "LEVEL_WRITE").length > 0
      ) {
        isdisable = true;
      } else if (this.currentTablesData.tableType === "CUT_OFF"
        && this.nodeService.currentProcessDetails.permission
          .filter(val => val.permission === "CUTOFFTABLE" && val.level !== "LEVEL_WRITE").length > 0
      ) {
        isdisable = true;
      }

    }
    //disable some buttons for cutoff table
    if (this.currentTablesData.tableType === "CUT_OFF") {
      this.isCutOffTable = true;
    } else {
      this.isCutOffTable = false;
    }

    return isdisable;
  }
}
