import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
@Component({
  selector: 'app-session-timeout',
  template: `
     <div *ngIf="myFlag">
    <div class="modal-header" >
      <h4 class="modal-title">Session Expired !</h4>
     
    </div>
    <div class="modal-body">
      <p>{{name}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="clickOnOk()">Ok</button>
    </div>
    </div>
  `
})
export class SessionTimeoutComponent implements OnInit {

  @Input() name;
  myFlag: boolean = true;
  constructor(private router: Router, public activeModal: NgbActiveModal) {
  }
  clickOnOk() {
    this.activeModal.close('Close click');
    this.router.navigate(['/']);
  }
  ngOnInit() {
    //do nothing
  }
}