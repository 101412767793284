import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { S1UserInfo } from 'app/entities/s1-userInfo';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  constructor(private http: HttpClient) {
  }

  deleteSelectedDataByUserId(userId: number): Observable<any> {
    return this.http.delete( 'private/users?userId=' + userId)
  }

  authGetAllUsers(): Observable<any> {
    return this.http.get<any>( "private/users");
  }

  authCreateUser(formVal): Observable<any> {
    let userInfoModel: S1UserInfo = new S1UserInfo(formVal);

    return this.http.post( "private/users", userInfoModel);
  }

  updateUsers(formVal: UntypedFormGroup, setNull?: boolean): Observable<any> {
    let userInfoDetails: S1UserInfo = new S1UserInfo(formVal);
    //only for OAUTH2 userInfoDetails must be null
    return this.http.put( "private/users", userInfoDetails);
  }
  // Error handling
  errorHandl(error) {
    console.log("service level local err handling.");

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => errorMessage);
  }

}
