import { S1PythonActivityReport } from './s1-pythonactivityreport';
import { S1ProcessID } from 'app/entities/s1-processid';
export class S1CreatePythonModelRequest {
    report?: S1PythonActivityReport;
    port?: number;
    processID?: S1ProcessID;
    uploadedPackageGUID?: string;
    packageFilename?: string;
    pythonObjectGuid?: string;
    pythonObjectCode?: string;
}