import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { S1DataTransferInfo } from "app/entities/s1-datatransferinfo";
import { StorageDetails } from "./user-details.service";
import { Observable, of, throwError } from 'rxjs';
import { S1ProcessID } from "app/entities/s1-processid";
import { S1WhatifScheduleID } from "app/entities/s1-scheduledId";
import { S1WhatifScheduleInfo } from "app/entities/s1-whatifScheduleInfoArray";
import { S1DownloadRequestInfo } from "app/entities/s1-whatifDownloadInfo";
import { catchError, map } from "rxjs/operators";
import { UntypedFormGroup } from "@angular/forms";
import { ServerDownloadRequest } from "app/entities/ServerDownloadRequest";

@Injectable({
    providedIn: 'root'
})
export class ServerManagementService {
    constructor(private http: HttpClient, private userInfo: StorageDetails) {
    }

    //Get WhatIf list
    serverGetWhatIfScheduleList(processId: S1ProcessID): Observable<any> {
        return this.http.get<any>('private/whatif/id?' + "processID=" + processId.processID + "&versionID=" + processId.versionID);
    }

    serverStartWhatifSchedule(scheduleId: S1WhatifScheduleID): Observable<any> {
        return this.http.put<any>('private/whatif/start', scheduleId);
    }

    serverStopWhatifSchedule(scheduleId: S1WhatifScheduleID): Observable<any> {
        return this.http.put<any>('private/whatif/stop', scheduleId);
    }

    serverDeleteWhatifSchedule(scheduleId: S1WhatifScheduleID): Observable<any> {
        return this.http.delete<any>('private/whatif?scheduleID=' + scheduleId.scheduleID);
    }

    serverCreateWhatifSchedule(whatIfObj: S1WhatifScheduleInfo): Observable<any> {
        return this.http.post<any>('private/whatif', whatIfObj).pipe(
            map((res) => {
                return res;
            }),
            catchError(ex => {
                return of ('');
            })
        );
    }

    serverExtractDownload(extractDownload: UntypedFormGroup, scheduleID: S1WhatifScheduleID): Observable<any> {

        let sdr: ServerDownloadRequest = new ServerDownloadRequest();
        sdr.scheduleId = scheduleID;
        sdr.requestInfo = new S1DownloadRequestInfo(extractDownload);
        return this.http.post<any>('private/whatif/extract', sdr);
    }

    getServerCount(extractDownload: UntypedFormGroup): Observable<any> {
        let extractDownloadForm = new S1DownloadRequestInfo(extractDownload);
        return this.http.post<any>('private/whatif/extract/count', extractDownloadForm);
    }

    uploadTheFile(payload: FormData, s1DataTransferInfo: S1DataTransferInfo, fileType: string): Observable<any> {
        payload.append('fileName', new Blob([s1DataTransferInfo.filename], { type: "application/json; charset=UTF-8" }));
        return this.http
            .post<any>('private/bac/file/upload', payload, {
                reportProgress: true,
                observe: 'events'
            }
            ).pipe(
                catchError(this.errorMgmt)
            );
    }

    downloadTheFile(s1DataTransferInfo: S1DataTransferInfo, fileType: string): Observable<any> {

        let headers: HttpHeaders = new HttpHeaders({
            'Accept': 'application/octet-stream'
        });
        const httpOptions = {
            responseType: 'arraybuffer' as 'json',
            headers: headers
        };
        return this.http.post<any>("private/bac/file/download?fileType=" + fileType, s1DataTransferInfo, httpOptions);
    }

    errorMgmt(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}