import { Directive, DoCheck, EventEmitter, Input, IterableDiffer, IterableDiffers, OnChanges, Output, SimpleChange } from '@angular/core';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';

export interface SortEvent {
    sortBy: string | string[];
    sortOrder: string;
}

export interface PageEvent {
    activePage: number;
    rowsOnPage: number;
    dataLength: number;
}

export interface DataEvent {
    length: number;
}

export interface FetchDataEvent {
    activePage: number;
    rowsOnPage: number;
    sortBy: string | string[];
    sortOrder: string;
}

@Directive({
    selector: 'table[mfData]',
    exportAs: 'mfDataTable'
})
export class DataTable implements OnChanges, DoCheck {

    private diff: IterableDiffer<any>;
    @Input('mfData') public inputData: any[] = [];

    @Input("mfSortBy") public sortBy: string | string[] = "";
    @Input("mfSortOrder") public sortOrder = "asc";
    @Output("mfSortByChange") public sortByChange = new EventEmitter<string | string[]>();
    @Output("mfSortOrderChange") public sortOrderChange = new EventEmitter<string>();

    @Input("mfRowsOnPage") public rowsOnPage = 1000;
    @Input("mfActivePage") public activePage = 1;

    @Input("mfIsServerSide") public isServerSide = false;
    @Input("mfTotalRows") public totalRows = -1;
    @Output("mfFetchData") public fetchData = new EventEmitter<FetchDataEvent>();

    private mustRecalculateData: boolean = false;
    private newServerSideFirstPage: boolean = true;

    public data: any[];

    public onSortChange = new ReplaySubject<SortEvent>(1);
    public onPageChange = new EventEmitter<PageEvent>();

    public constructor(private differs: IterableDiffers) {
        this.diff = differs.find([]).create(null);
    }

    public getSort(): SortEvent {
        return { sortBy: this.sortBy, sortOrder: this.sortOrder };
    }

    public setSort(sortBy: string | string[], sortOrder: string): void {
        if (this.sortBy !== sortBy || this.sortOrder !== sortOrder) {
            let dataLength = this.getDataLength();
            this.activePage = 1;
            this.sortBy = sortBy;
            this.sortOrder = _.includes(["asc", "desc"], sortOrder) ? sortOrder : "asc";
            this.mustRecalculateData = true;
            this.onSortChange.next({ sortBy: sortBy, sortOrder: sortOrder });
            this.sortByChange.emit(this.sortBy);
            this.sortOrderChange.emit(this.sortOrder);
            this.onPageChange.emit({
                activePage: this.activePage,
                rowsOnPage: this.rowsOnPage,
                dataLength: dataLength
            });
            if (this.isServerSide) {
                // if first page of server-side pagination is already provided,
                // there is no need to fetch data
                if (this.newServerSideFirstPage) {
                    this.newServerSideFirstPage = false;
                } else {
                    this.fetchData.emit({ activePage: this.activePage, rowsOnPage: this.rowsOnPage, sortBy: sortBy, sortOrder: sortOrder });
                }
            }
        }
    }

    private getDataLength(): number {
        if(this.isServerSide) {
            return this.totalRows;
        } else if(this.inputData) {
            return this.inputData.length;
        } else {
            return 0;
        }
    }

    public getPage(): PageEvent {
        let dataLength = this.getDataLength();
        return { activePage: this.activePage, rowsOnPage: this.rowsOnPage, dataLength: dataLength };
    }

    public setPage(activePage: number, rowsOnPage: number): void {
        if (this.rowsOnPage !== rowsOnPage || this.activePage !== activePage) {
            let dataLength = this.getDataLength();
            this.activePage = this.activePage !== activePage ? activePage : this.calculateNewActivePage(this.rowsOnPage, rowsOnPage);
            this.rowsOnPage = rowsOnPage;
            this.mustRecalculateData = true;
            this.onPageChange.emit({
                activePage: this.activePage,
                rowsOnPage: this.rowsOnPage,
                dataLength: dataLength
            });
            if (this.isServerSide) {
                // if first page of server-side pagination is already provided,
                // there is no need to fetch data
                if (this.newServerSideFirstPage) {
                    this.newServerSideFirstPage = false;
                } else {
                    this.fetchData.emit({ activePage: activePage, rowsOnPage: rowsOnPage, sortBy: this.sortBy, sortOrder: this.sortOrder });
                }
            }
        }
    }

    private calculateNewActivePage(previousRowsOnPage: number, currentRowsOnPage: number): number {
        let firstRowOnPage = (this.activePage - 1) * previousRowsOnPage + 1;
        return Math.ceil(firstRowOnPage / currentRowsOnPage);
    }

    private recalculatePage() {
        let dataLength = this.getDataLength();
        let lastPage = Math.ceil(dataLength / this.rowsOnPage);
        this.activePage = lastPage < this.activePage ? lastPage : this.activePage;
        this.activePage = this.activePage || 1;
        this.onPageChange.emit({
            activePage: this.activePage,
            rowsOnPage: this.rowsOnPage,
            dataLength: dataLength
        });
    }

    public ngOnChanges(changes: { [key: string]: SimpleChange }): any {
        if (changes["rowsOnPage"]) {
            this.rowsOnPage = changes["rowsOnPage"].previousValue;
            this.setPage(1, changes["rowsOnPage"].currentValue);
            this.mustRecalculateData = true;
        }
        if (changes["sortBy"] || changes["sortOrder"]) {
            if (!_.includes(["asc", "desc"], this.sortOrder)) {
                console.warn("angular2-datatable: value for input mfSortOrder must be one of ['asc', 'desc'], but is:", this.sortOrder);
                this.sortOrder = "asc";
            }
            if (this.sortBy) {
                this.onSortChange.next({ sortBy: this.sortBy, sortOrder: this.sortOrder });
            }
            this.mustRecalculateData = true;
        }
        if (changes["totalRows"]) {
            if (this.isServerSide) {
                // totalRows === undefined prepares the table for new server-paginated data
                this.activePage = 1; // new server-paginated data
                this.totalRows = changes["totalRows"].currentValue;
                this.newServerSideFirstPage = this.totalRows === undefined || isNaN(this.totalRows);
            }
        }
        if (changes["inputData"]) {
            if (!this.isServerSide) {
                this.activePage = 1; // new client-paginated data
            }
            this.inputData = changes["inputData"].currentValue || [];
            this.recalculatePage();
            this.mustRecalculateData = true;
        }
    }

    public ngDoCheck(): any {
        let changes = this.diff.diff(this.inputData);
        if (changes) {
            this.recalculatePage();
            this.mustRecalculateData = true;
        }
        if (this.mustRecalculateData) {
            this.fillData();
            this.mustRecalculateData = false;
        }
    }

    private fillData(): void {
        let data = this.inputData;
        if (!this.isServerSide) {
            // sort/slice on the client
            let offset = (this.activePage - 1) * this.rowsOnPage;
            var sortBy = this.sortBy;
            if (typeof sortBy === 'string' || sortBy instanceof String) {
                data = _.orderBy(data, this.caseInsensitiveIteratee(<string>sortBy), [this.sortOrder]);
            } else {
                data = _.orderBy(data, sortBy, [this.sortOrder]);
            }
            data = _.slice(data, offset, offset + this.rowsOnPage);
        }
        this.data = data;
    }

    private caseInsensitiveIteratee(sortBy: string) {
        return (row: any): any => {
            var value = row;
            for (let sortByProperty of sortBy.split('.')) {
                if (value) {
                    value = value[sortByProperty];
                }
            }
            if (value && typeof value === 'string' || value instanceof String) {
                return value.toLowerCase();
            }
            return value;
        };
    }
}