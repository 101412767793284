import { UntypedFormGroup } from '@angular/forms';
import { S1PasswordPolicies } from './s1-passwordpolicy';
import { S1AuthMode } from './s1-authmode.enum';

export class S1LoginCredentials {
    userId: string;
    password: string;
    authMode: S1AuthMode;
    passwordPolicies: S1PasswordPolicies;
    newPassword: string;
    domain: string;
    constructor(formVal: UntypedFormGroup) {
        this.userId = formVal.value.username;
        this.password = formVal.value.password;
        this.newPassword = formVal.value.newPassword;
        this.authMode = formVal.value.authmode;        
        this.passwordPolicies = new S1PasswordPolicies(formVal);
        if (this.authMode === S1AuthMode.WINDOWS) {
            this.domain = formVal.value.domain;
        }
    }
}