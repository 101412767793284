import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class S1PublicService {
  params: any;
  constructor(private http: HttpClient) {}
  
  serverGetSystemVersion(): Observable<any> {
    return this.http.get<any>('private/server/version');
  }

  getparams(): Observable<any> {
    return this.http.get("private/server/params");
  }

}