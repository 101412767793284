
import { Injectable } from "@angular/core";
import { UserPermissionComponent } from "app/screens/installation-management/user-permission/user-permission.component";

@Injectable({ providedIn: 'root' })
export class CanDeactivateGuard  {
  canDeactivate(component: UserPermissionComponent): boolean {
    if(component.isDirty){
        if (confirm("Running activities will be interrupted and changes (if any) will be lost. Leave anyway?")) { //You have unsaved changes! If you leave, your changes will be lost.
            return true;
        } else {
            return false;
        }
    }
    return true;
  }
}