import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import * as _ from "lodash";
import { Buffer } from "buffer";
import { StorageDetails } from 'app/core/services/user-details.service';
import { ProcessManagementService } from 'app/core/services/process-management.service';
import { Library } from 'app/entities/bac-liabrary';
import { Rule } from 'app/entities/bac-rule';
import { LiabraryDetail } from 'app/entities/bac-liabraryDetail';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { S1DataTransferInfo } from 'app/entities/s1-datatransferinfo';
import { S1ChunkInfo } from 'app/entities/s1-chunkinfo';
import { OperationsComponent } from '../operations/operations.component';
import { Data } from 'app/entities/data.model';

@Component({
  selector: 'app-liabraries',
  templateUrl: './liabraries.component.html',
  styleUrls: ['./liabraries.component.scss']
})
export class LiabrariesComponent implements OnInit {
  liabraries: Library[] = [];
  searchText: string;
  liabraryName: string;
  rule: Rule[] = [];
  rulesInfo: LiabraryDetail[] = [];
  ruleObj: Rule[] = [];
  categoryVariableMap: any;
  currentLiabrary: Library;
  processVersion: number;
  public uploader: FileUploader = new FileUploader({url:'', maxFileSize: 51200 * 1024 * 100 });
  shared: string = localStorage.getItem('sharedInBac');

  //File upload
  uploadForm: UntypedFormGroup;
  fileContent: string = '';
  bacFileUpload: S1DataTransferInfo[] = [];
  chunkInfo: S1ChunkInfo[] = [];
  errorMessage: string = "";

  selectedFile = null;
  isPermissionError: boolean = true;
  isDisableToggleButton: boolean = true;
  notSlave: boolean = true;

  constructor(public nodeService: ProcessManagementService, private userDetail: StorageDetails, private fb: UntypedFormBuilder, private router: Router) {
    this.initForm();
  }

  ngOnDestroy(){
    localStorage.removeItem('OriginalTable');
  }

  ngOnInit() {
    if (!this.nodeService.currentProcessDetails) {
      this.router.navigate(['/process-management']);
      return;
    }
    if (this.userDetail.getUserRole() !== "USER"
      || _ngOnInit_simpleUserPermission(this.userDetail, this.nodeService.currentProcessDetails)
    ) {
      this.isPermissionError = false;
      this.reloadPageData();
    } else {
      this.isPermissionError = true;
      this.errorMessage = this.nodeService.errMsgPermissionDeniedDetails;
    }
  }

  reloadPageData() {
    this.liabraries = this.nodeService.bacProcessNode.libraries.libraries ? this.nodeService.bacProcessNode.libraries.libraries : [];
    this.liabraries = this.liabraries.filter(liabrary => null !== liabrary.bac && liabrary.bac === true);
    //hide tables as per permission
    if (this.userDetail.getUserRole() === "USER") {
      if (this.nodeService.currentProcessDetails.permission
        .filter(val => val.permission === "GENERIC_LIBRARY" && val.level === "LEVEL_NONE").length > 0) {
        this.liabraries = this.liabraries.filter(lib => lib.libraryType !== "Generic");
      }
      if (this.nodeService.currentProcessDetails.permission
        .filter(val => val.permission === "EXCLUSION_LIBRARY" && val.level === "LEVEL_NONE").length > 0) {
        this.liabraries = this.liabraries.filter(table => table.libraryType !== "Exclusion");
      }
      if (this.nodeService.currentProcessDetails.permission
        .filter(val => val.permission === "TEST_LIBRARY" && val.level === "LEVEL_NONE").length > 0) {
        this.liabraries = this.liabraries.filter(table => table.libraryType !== "Test");
      }
    }
    this.rule = this.nodeService.bacProcessNode.rules
      && this.nodeService.bacProcessNode.rules.rule ? this.nodeService.bacProcessNode.rules.rule : [];
    this.categoryVariableMap = this.nodeService.bacProcessNode.categoryVariablesMap;
    this.processVersion = this.nodeService.currentProcessVersion;
  }

  private initForm() {
    this.uploadForm = this.fb.group({
      avatar: null
    });
  }

  showVariablesDetails(value) {
    this.notSlave = value.editable;
    this.currentLiabrary = value;
    this.liabraryName = value.id;
    this.rulesInfo = value.details.detail;
    var ruleDetails: Rule[] = [];
    this.rulesInfo.forEach(element => {
      let categoryID = this.categoryVariableMap[element.variableRefID]?.categoryId!=undefined? this.categoryVariableMap[element.variableRefID]?.categoryId+'.' : '';
      let variableID =  this.categoryVariableMap[element.variableRefID]?.objectsVariableId!=undefined? this.categoryVariableMap[element.variableRefID]?.objectsVariableId: '';
      let variable =  categoryID+variableID;
      _.each(this.rule, function (ele) {
        if (ele.guid === element.ruleRefID) {
          ruleDetails.push({
            id: ele.id,
            description: ele.description,
            condition: ele.condition,
            guid: ele.guid,
            scoreValue: element.variableValue,
            disabled: element.disabled,
            variable : variable,
            override: element.override
          });
          return ruleDetails;
        }
      });
    });

    this.ruleObj = ruleDetails;
    localStorage.setItem('OriginalTable', JSON.stringify(ruleDetails))
    this.isDisableToggle();
  }

  getVariableName(strVar) {
    let temp = "";
    let key = "";
    let val = "";
    let varNames = "";
    while (strVar.indexOf("$GUID") >= 0) {
      temp = strVar.substring(strVar.indexOf("$GUID"), strVar.indexOf("}") + 1);
      key = temp.substring(temp.indexOf("{") + 1, temp.indexOf("}"));
      if (this.categoryVariableMap[key].categoryId) {
        val = this.categoryVariableMap[key].categoryId + "." + this.categoryVariableMap[key].objectsVariableId;
      } else {
        val = this.categoryVariableMap[key].objectsVariableId;
      }
      strVar = strVar.replace(temp, val);
      if (varNames.indexOf(val + ",") < 0) {
        if (strVar.indexOf("$GUID") >= 0) {
          varNames = varNames + val + ", ";
        } else {
          varNames = varNames + val;
        }
      }
    }

    if (varNames.charAt(varNames.length - 2) === ",") {
      varNames = varNames.slice(0, -2);
    }
    return varNames;
  }

  onChange(subValue, index) {
    OperationsComponent.scrollenable = true;
    OperationsComponent.url = true;
    if(this.shared == 'true' && this.notSlave==false){
      let firstOverride = subValue.override.enabled? false : true;
      subValue.override.enabled = true;
      firstOverride ? subValue.override.value = !subValue.disabled: subValue.override.value = !subValue.override.value
    }else{
      subValue.disabled = !subValue.disabled;   
      this.liabraries.forEach(element => {
        if (this.liabraryName === element.id) {
          element.details.detail[index].disabled = subValue.disabled;
        }
      });
    }


    this.enablingWhatIf(subValue);
  }

  enablingWhatIf(selectedRow){
    var originalTable = JSON.parse(localStorage.getItem('OriginalTable'));
    if(originalTable.find(tableRow => tableRow.id == selectedRow.id).disabled != selectedRow.disabled){
      localStorage.setItem('whatIfIsEnabled', 'false')
      return
    }
  }

  public getBlobFromBase64(base64: string, type: string = '') {
    return new Blob([new Uint8Array(this.getBytes(base64))], { type: type });
  }

  public getBytes(base64: string) {
    var sliceSize = 1024;
    var byteCharacters = Buffer.from(base64, 'base64').toString('utf8'); //todo atob was here. test.    
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return byteArrays;
  }

  isDisableToggle() {
    this.isDisableToggleButton = this.nodeService.isDisabled;
    if (!this.isDisableToggleButton
      && (this.userDetail.getUserRole() === "USER"
        && this.nodeService.currentProcessDetails
        && this.nodeService.currentProcessDetails.permission
      )) {
      if ((this.currentLiabrary.libraryType === "Generic"
        && this.nodeService.currentProcessDetails.permission
          .filter(val => val.permission === "GENERIC_LIBRARY")
          .filter(val => val.level !== "LEVEL_WRITE").length > 0)
        || (this.currentLiabrary.libraryType === "Exclusion"
          && this.nodeService.currentProcessDetails.permission
            .filter(val => val.permission === "EXCLUSION_LIBRARY")
            .filter(val => val.level !== "LEVEL_WRITE").length > 0)
        || (this.currentLiabrary.libraryType === "Test"
          && this.nodeService.currentProcessDetails.permission
            .filter(val => val.permission === "TEST_LIBRARY")
            .filter(val => val.level !== "LEVEL_WRITE").length > 0)) {
        this.isDisableToggleButton = true;
      }

    }
  }
}

function _ngOnInit_simpleUserPermission(ud: StorageDetails, procData: Data): boolean {
  return ud.getUserRole() === "USER"
    && procData.permission
    && procData.permission.filter(val => {
      if ((val.permission === "GENERIC_LIBRARY" && val.level === "LEVEL_WRITE")
        || (val.permission === "GENERIC_LIBRARY" && val.level === "LEVEL_READ")
        || (val.permission === "EXCLUSION_LIBRARY" && val.level === "LEVEL_WRITE")
        || (val.permission === "EXCLUSION_LIBRARY" && val.level === "LEVEL_READ")
        || (val.permission === "TEST_LIBRARY" && val.level === "LEVEL_WRITE")
        || (val.permission === "TEST_LIBRARY" && val.level === "LEVEL_READ")
      )
        return true;
    }).length > 0
}
