<div class="col-sm-12" *ngIf="isPermissionError">
    <div class="alert cds-alert alert-danger" role="alert">
        <div class="cds-alert-icon">
            <i class="fa-solid fa-triangle-exclamation"></i>
        </div>
        <div class="cds-alert-content">>{{"You do not have permission to access this page." | translate}}</div>
    </div>
</div>
<appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>

<h6 class=" mb-1">{{ 'Available tables for process :' | translate }} 
    <h6 class="badge cds-badge active text-bg-info">{{nodeService.currentProcessName}}</h6>
</h6>

<div class="row" *ngIf="!nodeService.showAppLoader && !isPermissionError">
    <div class="card cds-card-shadow mb-4">
        <div class="card-body">
            <div class="col-12 mb-1">
                <input class="form-control searchBox" placeholder="{{ 'Quick Filter' | translate}}" [(ngModel)]="searchText">
            </div>
            <div class="col-sm-12">
                <table aria-describedby="table-proc-tablesParent"
                    class="table table-striped"
                    [mfData]="tablesData | filter :'id,description,tableType,reporting': searchText" #mf="mfDataTable"
                    [mfRowsOnPage]="15" mfSortBy="id">
                    <thead>
                        <tr>
                            <th>
                                <mfDefaultSorter by="id">{{ 'Code' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="description">{{ 'Notes' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="tableType">{{ 'Type' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="reporting">{{ 'Append Mode' | translate }}</mfDefaultSorter>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="tablesData.length > 0">
                        <tr class="cursor" *ngFor="let value of mf.data let i = index"
                            (click)="showVariablesDetails(value, i)"
                            [ngStyle]="{'background-color': i === index ? 'antiquewhite' : ''}">
                            <td>{{value.id}}</td>
                            <td>{{value.description}}</td>
                            <td>{{value.tableType}}</td>
                            <td>{{value.calcMode === "APPEND" ? true : false}}</td>
                        </tr>
                    </tbody>
                    <tfoot *ngIf="(tablesData | filter :'id,description,tableType,reporting': searchText).length > 15">
                        <tr>
                            <td colspan="10">
                                <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <div class="col-sm-12 text-center" *ngIf="tablesData.length === 0">
                    <h5>
                        <mat-error>{{ 'No records found.' | translate }}</mat-error>
                    </h5>
                </div>
            </div>
        </div>    
    </div>
    <ng-container *ngIf="childTable">
        <h6 class=" mb-1">{{ 'Details of table :' | translate }} 
            <h6 class="badge cds-badge active text-bg-primary">{{tableName}}</h6>
        </h6>
        <div class="card cds-card-shadow">
            <div class="card-body">
                <div class="col-12 text-left">
                    <button class="btn btn-primary btn-sm m-1" [disabled]="false" (click)="exportAsXLSX()"
                        title="{{ 'Export' | translate }}">
                        <i class="fa fa-download p-1"></i>{{ 'Export' | translate }}
                    </button>
                    <ng-container *ngIf="notSlave">
                        <button class="btn btn-primary btn-sm m-1" [disabled]="isDisableButton()"
                        (click)="openImport(templateImport)" title="{{ 'Import' | translate }}">
                        <i class="fa fa-upload p-1"></i>{{ 'Import' || translate }}
                        </button>
                        <button *ngIf="!isCutOffTable" class="btn btn-primary btn-sm m-1"
                            [disabled]="keyName && !isDisableButton() ? false : true" (click)="open(template, 'Edit')"
                            title="{{ 'Edit row' | translate }}">
                            <i class="fa fa-pencil p-1"></i>{{ 'Edit' | translate }}
                        </button>
                        <button *ngIf="!isCutOffTable" class="btn btn-primary btn-sm m-1"
                            [disabled]="keyName && !isDisableButton() ? false : true" (click)="deleteRow()"
                            title="{{ 'Delete row' | translate }}">
                            <i class="fa fa-minus-circle p-1"></i>{{ 'Delete' | translate }}
                        </button>
                        <button *ngIf="!isCutOffTable" class="btn btn-primary btn-sm m-1" [disabled]="isDisableButton()"
                            (click)="open(template, 'New')" title="{{ 'Add row' | translate }}">
                            <i class="fa fa-plus-circle p-1"></i>{{ 'Add' | translate }}
                        </button>
                    </ng-container>
                </div>
                <!--TABLE-->
                <div class="col-12 mt-2" *ngIf="hideAndShow">
                    <div class="col-12" *ngIf="childTable && variablesData.length >= 0">
                        <div class="scroll-only-x">
                            <table aria-describedby="table-proc-tablesChild"
                                class="table table-striped" [mfData]="variablesData" #mf="mfDataTable"
                                [mfRowsOnPage]="15">
                                <thead>
                                    <tr>
                                        <th scope="col" [attr.colspan]="inputParamData.length">{{ 'Input variables' | translate }}</th>
                                        <th scope="col" [attr.colspan]="outputObject.variableRefID.length">{{ 'Output variables' | translate }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="row" *ngFor="let prop of inputParamData">
                                            {{ findByName(prop.variableRefID) }}
                                            {{prop.operator | operatorPipe}}
                                        </th>
                                        <th scope="row" *ngFor="let prop of outputObject.variableRefID">
                                            {{ findByName(prop.value) }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="row" *ngFor="let prop of inputParamData; let i = index">
                                            <select (change)="ascAndDescOrderData($event.target.value, i, 'INPUT')"
                                                [(ngModel)]="selectedOperators[i]">
                                                <option *ngFor="let ops of operatorList; let j = index" value="{{ops.value}}">
                                                    {{ops.viewValue}}
                                                </option>
                                            </select>
                                        </th>
                                        <th scope="row" *ngFor="let prop of outputObject.variableRefID; let i = index">
                                            <!-- same selectlive above without ngmodel can be used. check tfs history is needed. -->
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let prop of outputParamData;let i = index">
                                        <tr *ngIf="(((pageNum - 1 )* numOfRecordPerPage + 1) <= i+1)  && ((pageNum * numOfRecordPerPage)  > i)"
                                            (click)="selectedRecord(i,prop)"
                                            [ngStyle]="{'background-color': prop.key === keyName && !nodeService.isDisabled ? 'antiquewhite' : ''}">
                                            <ng-container *ngIf="prop.name.split('.').length == 1">
                                                <td *ngFor="let prop1 of prop.name.split('.') let index">
                                                    {{getValueFromName(prop1) | translate}}
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="prop.name.split('.').length > 1">
                                                <td *ngFor="let prop1 of prop.name.split('.') let index">
                                                    {{getValueFromName(prop1) | translate}}
                                                </td>
                                            </ng-container>
                                            <td *ngFor="let prop1 of prop.variableValues.variableValue">
                                                {{ getOutPutValue(prop1.valueState) | translate}}</td>
                                            <!-- rowUp(i) and rowDown(i) can be added here.-->
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tfoot *ngIf="variablesData.length > 15">
                                    <tr>
                                        <td colspan="10" (click)="pagination()">
                                            <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>


<!-- Add/Edit Input/Output parameter for Tables -->
<ng-template #template tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class=" mb-1">{{modelTitle}} 
                    <h6 class="badge cds-badge active text-bg-info m-0">{{tableName}}</h6>
                </h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <div class="col-sm-12">
                    <div *ngIf="errorMsg!=''" class="alert cds-alert alert-danger col-12" role="alert" >
                        <div class="cds-alert-icon">
                          <i class="fa-solid fa-triangle-exclamation"></i>
                        </div>
                        <div class="cds-alert-content">{{errorMsg}}</div>
                    </div>
                    <form [formGroup]="tableForm" ngNativeValidate>
                        <div class="col-sm-12">
                            <h6 class="heading-text">{{ 'Input Variables' | translate }} :</h6>
                            <hr>
                        </div>
                        <div class="col-sm-12">
                            <div class="scroll-only-x">
                                <table aria-describedby="table-proc-tablesFormIp" class="table table-striped"
                                    [mfData]="inputMappingVariable" #mf="mfDataTable" [mfRowsOnPage]="15">
                                    <thead>
                                        <tr>
                                            <th>
                                                <mfDefaultSorter by="">{{'Variable Code' | translate}}</mfDefaultSorter>
                                            </th>
                                            <th>
                                                <mfDefaultSorter by="">{{ 'Variable Value' | translate }}</mfDefaultSorter>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        *ngIf="inputMappingVariable.length > 0 && inputSelect.length == inputMappingVariable.length">
                                        <tr *ngFor="let value1 of inputMappingVariable; let i = index">
                                            <td class="col-6">{{value1}} </td>
                                            <td>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="row">
                                                            <div class="col-sm-6" (click)='updateInputFunction(i)'>
                                                                <ng-select [ngModelOptions]="{standalone: true}" [compareWith]="compareValues"
                                                                    (change)="setInputValue(storedInputType[i], 'input', i);removeComponent(inputSelect[i], othrInput, 'othrInput'+i, i, 'INPUT')"
                                                                    placeholder="{{ 'Input Variables Mapping' | translate }}"
                                                                    [(ngModel)]="storedInputType[i]" #strInputType="ngModel" required>
                                                                    <ng-option *ngFor="let inputMap of newInputVariable[i]" [value]="inputMap"
                                                                    [disabled]="newInputVariable[i].length > 5 && inputMap === 'Values'">
                                                                        {{inputMap}}
                                                                    </ng-option>
                                                                </ng-select>
                                                                <div class="cds-form-control-error" *ngIf="addComponentIntoList(strInputType) && !strInputType.valid">
                                                                    {{!strInputType.touched ? '': 'Input value is required' | translate }}
                                                                </div>
                                                                <!-- <mat-form-field appearance="outline">
                                                                    <mat-label>{{ 'Input Variables Mapping' | translate }}
                                                                    </mat-label>
                                                                    <mat-select [compareWith]="compareValues"
                                                                        placeholder="{{ 'Input Variables Mapping' | translate }}"
                                                                        [(ngModel)]="storedInputType[i]" #strInputType="ngModel"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        (selectionChange)="setInputValue($event.value, 'input', i);removeComponent(inputSelect[i], othrInput, 'othrInput'+i, i, 'INPUT')"
                                                                        required>
                                                                        <mat-option *ngFor="let inputMap of newInputVariable[i]"
                                                                            [disabled]="newInputVariable[i].length > 5 && inputMap === 'Values'"
                                                                            [value]="inputMap">
                                                                            {{inputMap}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                    <mat-error
                                                                        *ngIf="addComponentIntoList(strInputType) && !strInputType.valid">
                                                                    </mat-error>
                                                                </mat-form-field> -->
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <ng-container *ngIf="storedInputType[i] === 'Values'">
                                                                    <input type="text" placeholder="{{ 'Input Variables Mapping' | translate }}" 
                                                                    (keyup)="selectedValueKeyUpEvent(i, otherInput[i], 'input', value1)"
                                                                    class="form-control" value="{{storedInputData[i]}}" name="othrInput{{i}}" 
                                                                    [(ngModel)]="otherInput[i]" [ngModelOptions]="{standalone: true}"
                                                                    #othrInput="ngModel" required>
                                                                    <div class="cds-form-control-error" *ngIf="addComponentIntoList(othrInput) && !othrInput.valid">
                                                                        {{!othrInput.valid && inputErrorMsg[i] ? inputErrorMsg[i] : !othrInput.touched ? '': 'Input value is required' | translate}}
                                                                    </div>
                                                                </ng-container>

                                                                <!-- <mat-form-field appearance="outline" *ngIf="inputSelect[i]">
                                                                    <input [disabled]="newInputVariable[i].length > 5"
                                                                        placeholder="{{ 'Input Variables Mapping' | translate }}"
                                                                        matInput value="{{storedInputData[i]}}"
                                                                        name="othrInput{{i}}"
                                                                        (keyup)="selectedValueKeyUpEvent(i, $event.target.value, 'input', value1)"
                                                                        [(ngModel)]="otherInput[i]" #othrInput="ngModel"
                                                                        [ngModelOptions]="{standalone: true}" required>
                                                                    <mat-error
                                                                        *ngIf="addComponentIntoList(othrInput) && !othrInput.valid">
                                                                        {{!othrInput.valid ? inputErrorMsg[i] : 'Input value is
                                                                        required' | translate }}
                                                                    </mat-error>
                                                                </mat-form-field> -->
                                                                <!-- (\d{4})-(\d{1,2})-(\d{1,2}) -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-sm-12"><br></div>
                        <div class="col-sm-12">
                            <h6 class="heading-text">{{ 'Output Variables' | translate }} :</h6>
                            <hr>
                        </div>
                        <div class="col-sm-12">
                            <table aria-describedby="table-proc-tablesFormOp" class="table table-striped"
                                [mfData]="outputMappingVariable" #mf="mfDataTable" [mfRowsOnPage]="15">
                                <thead>
                                    <tr>
                                        <th>
                                            <mfDefaultSorter by="">{{'Variable Code' | translate}}</mfDefaultSorter>
                                        </th>
                                        <th>
                                            <mfDefaultSorter by="">{{'Variable Value' | translate }}</mfDefaultSorter>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="outputMappingVariable.length > 0 || newOutputVariable.length">
                                    <tr *ngFor="let value2 of outputMappingVariable; let i = index">
                                        <td class="col-6">{{value2}}</td>
                                        <td>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-sm-6" (click)='updateOutputFunction(i)'>
                                                            <ng-select [ngModelOptions]="{standalone: true}" [compareWith]="compareValues"
                                                                (change)="setInputValue(storedOutputType[i], 'output', i);removeComponent(outputSelect[i], othrOutput, 'othrOutput'+i, i, 'OUTPUT')"
                                                                placeholder="{{ 'Output Variables Mapping' | translate }}"
                                                                [(ngModel)]="storedOutputType[i]" #strOutputType="ngModel" required>
                                                                <ng-option *ngFor="let outputMap of newOutputVariable[i]" [value]="outputMap"
                                                                [disabled]="newOutputVariable[i].length > 3 && outputMap === 'Values'">
                                                                    {{outputMap}}
                                                                </ng-option>
                                                            </ng-select>
                                                            <div class="cds-form-control-error" *ngIf="addComponentIntoList(strOutputType) && !strOutputType.valid">
                                                                {{!strOutputType.touched ? '': 'Input value is required' | translate }}
                                                            </div>
                                                        <!--<mat-form-field appearance="outline">
                                                                <mat-label>{{ 'Output Variables Mapping' | translate }} </mat-label>
                                                                <mat-select [compareWith]="compareValues"
                                                                    placeholder="{{ 'Output Variables Mapping' | translate }}"
                                                                    [(ngModel)]="storedOutputType[i]" #strOutputType="ngModel"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (selectionChange)="setInputValue($event.value, 'output', i);removeComponent(outputSelect[i], othrOutput, 'othrOutput'+i, i, 'OUTPUT')"
                                                                    required>
                                                                    <mat-option *ngFor="let outputMap of newOutputVariable[i]"
                                                                        [disabled]="newOutputVariable[i].length > 3 && outputMap === 'Values'"
                                                                        [value]="outputMap">
                                                                        {{outputMap}}
                                                                    </mat-option>
                                                                </mat-select>
                                                                <mat-error
                                                                    *ngIf="addComponentIntoList(strOutputType) && !strOutputType.valid">
                                                                </mat-error>
                                                            </mat-form-field> -->
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <ng-container *ngIf="storedOutputType[i] === 'Values'">
                                                                <input type="text" placeholder="{{ 'Output Variables Mapping' | translate }}" 
                                                                (keyup)="selectedValueKeyUpEvent(i, $event.target.value, 'output', value2)"
                                                                class="form-control" value="{{storedOutputData[i]}}" name="othrOutput{{i}}" 
                                                                [(ngModel)]="otherOutput[i]" [ngModelOptions]="{standalone: true}"
                                                                #othrOutput="ngModel" required>
                                                                <div class="cds-form-control-error" *ngIf="addComponentIntoList(othrOutput) && !othrOutput.valid">
                                                                    {{!othrOutput.valid && outputErrorMsg[i] ? outputErrorMsg[i] : !othrOutput.touched ? '': 'Output value is
                                                                    required' | translate }}
                                                                </div>
                                                            </ng-container>
                                                           <!--<mat-form-field appearance="outline" *ngIf="outputSelect[i]">
                                                                <input placeholder="{{ 'Output Variables Mapping' | translate }}"
                                                                    matInput [(ngModel)]="otherOutput[i]" #othrOutput="ngModel"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    value="{{storedOutputData[i]}}" name="othrOutput{{i}}"
                                                                    (keyup)="selectedValueKeyUpEvent(i, $event.target.value, 'output', value2)"
                                                                    [disabled]="newOutputVariable[i].length > 3">
                                                                <mat-error
                                                                    *ngIf="addComponentIntoList(othrOutput) && !othrOutput.valid">
                                                                    {{!othrOutput.valid ? outputErrorMsg[i] : 'Output value is
                                                                    required' | translate }}
                                                                </mat-error>
                                                            </mat-form-field> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm  btn-primary m-b-0" (click)="saveWindow()">
                    {{ 'Save' | translate }}
                </button>
                <button type="button" class="btn btn-sm  btn-light m-b-0" (click)="cancelWindow()">
                    {{ 'Close' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<!-- Import table -->
<ng-template #templateImport tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>{{ "Import the table :" | translate }}</h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <div class="alert cds-alert alert-danger" role="alert" *ngIf="errorMsg!=''">
                    <div class="cds-alert-icon">
                      <i class="fa-solid fa-triangle-exclamation"></i>
                    </div>
                    <div class="cds-alert-content">{{errorMsg | translate }}</div>
                </div>
                <div class="col-sm-12"><br></div>
                <div class="row col-sm-12">
                    <h6> Choose file: &nbsp; </h6>
                    <input #fileImport name="fileImport" type="file" onclick="value = null" (change)="fileChanged($event)"
                        accept=".xls, .xlsx" />
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm  btn-primary m-b-0" (click)="importTableExcel()" [disabled]='isDisableImportInPopup'>
                    {{ 'Import' | translate }}
                </button>
                <button type="button" class="btn btn-sm  btn-light m-b-0" (click)="cancelWindow()">
                    {{ 'Close' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
<!-- <ng-template #templateImport>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ "Import the table :" | translate }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancelWindow()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row col-sm-12">
            <mat-error style="font-size: 14px;">{{errorMsg | translate }}</mat-error>
        </div>
        <div class="col-sm-12"><br></div>
        <div class="row col-sm-12">
            <h5> Choose file: &nbsp; </h5>
            <input #fileImport name="fileImport" type="file" onclick="value = null" (change)="fileChanged($event)"
                accept=".xls, .xlsx" />
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="importTableExcel()"
            [disabled]='isDisableImportInPopup'>{{ 'Import' | translate }}</button>
        <button type="button" class="btn btn-default" (click)="cancelWindow()">{{ 'Close' | translate }}</button>
    </div>
</ng-template> -->
