import { UntypedFormGroup } from "@angular/forms";
import { S1LoginCredentials } from './s1-logincredentials';
import { S1UserID } from '../entities/userid';

export class S1UserInfo {
    id: S1UserID;
    role: string;
    name: string;
    surname: string;
    language?: string;
    company?: string;
    organization?: string;
    staffID?: string;
    expiredOn?: Date;
    loginCredentials: S1LoginCredentials;
    notes?: string;
    logged?: boolean;
    loggedAt?: Date;
    expiredOnDisplay?: string;
    loggedAtDisplay?: string;
    email:string
    createdOn?: string;
    groupID?: string;
    sid: string

    constructor(formVal: UntypedFormGroup) {
        this.id = formVal.value.id;
        this.role = formVal.value.role;
        this.name = formVal.value.name;
        this.surname = formVal.value.surName;
        this.language = formVal.value.language;
        this.company = formVal.value.company;
        this.staffID = formVal.value.staffID;
        this.organization = formVal.value.organization;
        this.expiredOn = formVal.value.expiryDate ? formVal.value.expiryDate : null;
        this.loginCredentials = new S1LoginCredentials(formVal);
        this.notes = formVal.value.description;
        this.email = formVal.value.email
    }
}
