import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  expectedRole?: Array<string>;
  children?: ChildrenItems[];
  icon: string;
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  expectedRole?: Array<string>;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMSCONST = [
  {
    label: 'process',
    main: [
      {
        state: '/process',
        name: 'Process Management',
        type: 'link',
        icon: 'icon-coloured-world.svg',
        expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
        children: [
          {
            name: 'My Processes',
            state: '/process-management/process-list',
            expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
            icon: 'icon-coloured-users.svg'
          }
        ]
      },
    ],
  },
  {
    label: 'installation',
    main: [
      {
        state: '/installation-management',
        name: 'Installation Management',
        type: 'link',
        icon: 'icon-coloured-skyscrapers.svg',
        expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
        children: [
          {
            name: 'User Management',
            state: '/installation-management/user-creation',
            expectedRole: ['SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
            icon: 'icon-coloured-users.svg'
          },
          {
            name: 'User Permission',
            state: '/installation-management/user-permission',
            expectedRole: ['SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
            icon: 'icon-coloured-permission.svg',
          },
          {
            name: 'Log Tracer',
            state: '/installation-management/log-tracer',
            expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
            icon: 'icon-coloured-logtracer.svg',
          },
          {
            name: 'Python Management',
            state: '/installation-management/python-management',
            expectedRole: ['SYSTEM_ADMIN','SECURITY_ADMIN'],
            icon: 'icon-coloured-python.svg',
          }
        ]
      },
    ],
  },

];

@Injectable()
export class MenuItems {
  MENUITEMS: Menu[];
  constructor() {
    this.MENUITEMS = MENUITEMSCONST;
  }
  getAll(): Menu[] {
    return this.MENUITEMS;
  }
}