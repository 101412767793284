import { DomainValues } from "./DomainValues";

export class VariableTo {
    categoryId?:string;
	categoryVariableRefID?:string;
	categoryVariableGuid?:string;

	objectsVariableId?:string;
	objectsVariableDescription?:string;
	objectsVariableType?:string;
	restrictionType?:string;
	minValue?:string;
	maxValue?:string;
	length?:number;
	decimalLength?:number;
	domainValues?:DomainValues;
}