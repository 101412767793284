import { Component, OnInit, TemplateRef } from '@angular/core';
import { Buffer } from "buffer";
import { ProcessManagementService } from 'app/core/services/process-management.service';
import { VariableTo } from 'app/entities/bac-variableto';
import { S1DataTransferInfo } from 'app/entities/s1-datatransferinfo';
import { saveAs } from 'file-saver';
import { PmmlModel } from 'app/entities/bac-pmmlmodel';
import { UsageType } from 'app/entities/bac-usagetype.enum';
import { PmmlDataField } from 'app/entities/bac-pmmldatafield';
import { StorageDetails } from 'app/core/services/user-details.service';
import xml2js from 'xml2js';
import { PmmlSourceType } from 'app/entities/bac-pmmlsourcetype.enum';
import { ModelType } from 'app/entities/bac-modeltype.enum';
import { ProcessIOType } from 'app/entities/bac-processiotype.enum';
import { OperationsComponent } from '../operations/operations.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService } from 'app/shared/alert/alert-service';
const MODAL_OPTIONS: NgbModalOptions = { size: 'lg', backdrop: 'static', keyboard: true, windowClass: 'modal-full-height modal-full-width' };


export class PmmlPageModel {
  pmmlModel: PmmlModel = new PmmlModel();
  pInputVariables: PmmlVariableModel[] = [];
  pOutputVariables: PmmlVariableModel[] = [];
}
export class PmmlVariableModel {
  pmmlDataField: PmmlDataField = new PmmlDataField();
  pmmlVariable: VariableTo = new VariableTo();
}
@Component({
  selector: 'app-pmml',
  templateUrl: './pmml.component.html',
  styleUrls: ['./pmml.component.scss']
})
export class PmmlComponent implements OnInit {

  pmmlPageModels: PmmlPageModel[] = [];
  allVariables: VariableTo[] = [];
  error: boolean = false;
  errorMessage: string = "";
  searchText: string = "";
  showAppLoader: boolean = false;
  currentRow: PmmlPageModel;
  clickedPmmlId: string;
  isEnableImport: boolean;
  isEnableExport: boolean;

  modalRef: NgbModalRef;
  modalTitle: string;
  datatransferinfo: S1DataTransferInfo;
  isEnableFinalImport: boolean;
  uploadingFileContents: string;
  uploadingFileName: string;
  isExportRunning: boolean = false;

  CHUNKSIZE: number = 1024 * 1024; // 1 MB
  text_exportFailed: string;
  text_importSuccessful: string;
  text_importFailed: string;
  text_fileValidationError: string;
  text_invalidFileError: string;
  text_chooseValidFile: string;
  text_invalidFileError2: string;
  isPermissionError: boolean = true;
  notSlave: boolean = true;

  constructor(public nodeService: ProcessManagementService,
    private modalService: NgbModal, private router: Router,
    private userDetails: StorageDetails,private translate: TranslateService, 
    private alert: AlertsService) {

    //empty currentRow at init as no row cliked yet
    this.currentRow = new PmmlPageModel();
    this.currentRow.pInputVariables = [];
    this.currentRow.pOutputVariables = [];
    this.translate.get([
      'PMML model failed to export!'
      , 'PMML Model loaded sucecssfully!'
      , 'PMML Model import failed!'
      , 'PMML file validation failed against existing variable values.'
      , 'Invalid file found!'
      , 'Please choose a valid pmml file.'
      , 'Invalid PMML file!'
    ]).subscribe((text: any) => {
      this.text_exportFailed = text['PMML model failed to export!'];
      this.text_importSuccessful = text['PMML Model loaded sucecssfully!'];
      this.text_importFailed = text['PMML Model import failed!'];
      this.text_fileValidationError = text['PMML file validation failed against existing variable values.'];
      this.text_invalidFileError = text['Invalid file found!'];
      this.text_chooseValidFile = text['Please choose a valid pmml file.'];
      this.text_invalidFileError2 = text['Invalid PMML file!'];
    });
  }


  ngOnInit(): void {
    if (!this.nodeService.currentProcessDetails) {
      this.router.navigate(['/process-management']);
    }
    if (this.userDetails.getUserRole() !== "USER"
      || (this.userDetails.getUserRole() === "USER"
        && this.nodeService.currentProcessDetails
        && this.nodeService.currentProcessDetails.permission
        && this.nodeService.currentProcessDetails.permission
          .filter(val => {
            if ((val.permission === "PMML_MODEL" && val.level === "LEVEL_WRITE")
              || (val.permission === "PMML_MODEL" && val.level === "LEVEL_READ")){
            return true;
            }else{
              return false
            }
          }).length === 1
      )
    ) {
      this.isPermissionError = false;
      this.reloadPageData();
      //keep disabled buttons until row clicked
      this.disableButton();
    } else {
      this.isPermissionError = true;
      this.errorMessage = this.nodeService.errMsgPermissionDeniedDetails;
    }
  }

  reloadPageData() {
    this.pmmlPageModels = [];
    //save all variables
    if (this.nodeService.bacProcessNode
      && this.nodeService.bacProcessNode.categoryVariablesList) {
      this.allVariables = this.nodeService.bacProcessNode.categoryVariablesList;
    }

    //initiate pmmlModels by creating one pmml model at-a-time 
    if (this.nodeService.bacProcessNode
      && this.nodeService.bacProcessNode.pmmlModels
      && this.nodeService.bacProcessNode.pmmlModels.pmmlModel
      && this.allVariables) {

      this.nodeService.bacProcessNode.pmmlModels.pmmlModel
        .filter(pModel => pModel.bac)
        .forEach(pModel => {

          let pmmlPageModel: PmmlPageModel = new PmmlPageModel();
          pmmlPageModel.pmmlModel = pModel;

          //initiate input and output variables
          pmmlPageModel.pmmlModel.pmmlDataDictionary.pmmlDataField.forEach(dataField => {
            let pmmlVariableModel: PmmlVariableModel = new PmmlVariableModel();
            //init StrategyOne table values if any
            this.allVariables.forEach(variable => {
              if (variable.categoryVariableGuid === dataField.variableRefId) {
                pmmlVariableModel.pmmlVariable = variable;
              }
            });
            //init PmmlValues table values and insert them into variables array instantly
            if (dataField.usage === UsageType.ACTIVE) {
              pmmlVariableModel.pmmlDataField = dataField;
              pmmlPageModel.pInputVariables.push(pmmlVariableModel);
            } else if (dataField.usage === UsageType.PREDICTED
              || dataField.usage === UsageType.TARGET) {
              pmmlVariableModel.pmmlDataField = dataField;
              pmmlPageModel.pOutputVariables.push(pmmlVariableModel);
            }
          });

          //add newly created single pmml model
          this.pmmlPageModels.push(pmmlPageModel);
        });
    }
  }

  savePageData(): boolean {
    let returnValue: boolean = false;
    let updatePmmlModel: PmmlModel = new PmmlModel();
    updatePmmlModel.fileName = this.uploadingFileName;
    updatePmmlModel.pmmlFileContent = this.uploadingFileContents;
    updatePmmlModel.pmmlDataDictionary = this.currentRow.pmmlModel.pmmlDataDictionary;
    //parse pmml file to update PmmlPageModel.pmmlModel
    let parser = new xml2js.Parser();
    let result;
    parser.parseString(Buffer.from(this.uploadingFileContents, 'base64').toString('utf8')
      , function (err, res) {
        result = res;
      });

    //start - part seperated from inside parseString function() above for sonar 
    let modelTagFound: string = "";
    if (result.PMML.AssociationModel) {
      modelTagFound = "AssociationModel";
      updatePmmlModel.modelType = ModelType.ASSOCIATION_MODEL;
    } else if (result.PMML.BayesianNetworkModel) {
      modelTagFound = "BayesianNetworkModel";
      updatePmmlModel.modelType = ModelType.BAYESIAN_NETWORK_MODEL;
    } else if (result.PMML.BaselineModel) {
      modelTagFound = "BaselineModel";
      updatePmmlModel.modelType = ModelType.BASELINE_MODEL;
    } else if (result.PMML.ClusteringModel) {
      modelTagFound = "ClusteringModel";
      updatePmmlModel.modelType = ModelType.CLUSTERING_MODEL;
    } else if (result.PMML.GaussianProcessModel) {
      modelTagFound = "GaussianProcessModel";
      updatePmmlModel.modelType = ModelType.GAUSSIAN_PROCESS_MODEL;
    } else if (result.PMML.GeneralRegressionModel) {
      modelTagFound = "GeneralRegressionModel";
      updatePmmlModel.modelType = ModelType.GENERAL_REGRESSION_MODEL;
    } else if (result.PMML.MiningModel) {
      modelTagFound = "MiningModel";
      updatePmmlModel.modelType = ModelType.MINING_MODEL;
    } else if (result.PMML.NaiveBayesModel) {
      modelTagFound = "NaiveBayesModel";
      updatePmmlModel.modelType = ModelType.NAIVE_BAYES_MODEL;
    } else if (result.PMML.NearestNeighborModel) {
      modelTagFound = "NearestNeighborModel";
      updatePmmlModel.modelType = ModelType.NEAREST_NEIGHBOR_MODEL;
    } else if (result.PMML.NeuralNetwork) {
      modelTagFound = "NeuralNetwork";
      updatePmmlModel.modelType = ModelType.NEURAL_NETWORK;
    } else if (result.PMML.RegressionModel) {
      modelTagFound = "RegressionModel";
      updatePmmlModel.modelType = ModelType.REGRESSION_MODEL;
    } else if (result.PMML.RuleSetModel) {
      modelTagFound = "RuleSetModel";
      updatePmmlModel.modelType = ModelType.RULE_SET_MODEL;
    } else if (result.PMML.SequenceModel) {
      modelTagFound = "SequenceModel";
      updatePmmlModel.modelType = ModelType.SEQUENCE_MODEL;
    } else if (result.PMML.Scorecard) {
      modelTagFound = "Scorecard";
      updatePmmlModel.modelType = ModelType.SCORECARD;
    }
    returnValue = this._savePageData_parseResult0(result, modelTagFound, updatePmmlModel, modelTagFound.length > 0);
    //end - part seperated.

    if (returnValue) {
      this.currentRow.pmmlModel.fileName = updatePmmlModel.fileName;
      this.currentRow.pmmlModel.pmmlFileContent = updatePmmlModel.pmmlFileContent;
      this.currentRow.pmmlModel.modelType = updatePmmlModel.modelType;
      this.nodeService.bacProcessNode.pmmlModels.pmmlModel
        .filter(pModel => pModel.bac)
        .filter(pModel => pModel.id === this.currentRow.pmmlModel.id)[0] = this.currentRow.pmmlModel;
    }
    return returnValue;
  }//savePageData() ends
  private _savePageData_parseResult0(result: any, modelTagFound: string, updatePmmlModel: PmmlModel, isModelExist: boolean)
    : boolean {
    let returnValue: boolean = false;
    //discard as validating against existing values ! updatePmmlModel.pmmlDataDictionary.pmmlDataField = [];
    //update model
    if (!isModelExist) {
      if (result.PMML.SupportVectorMachineModel) {
        modelTagFound = "SupportVectorMachineModel";
        updatePmmlModel.modelType = ModelType.SUPPORT_VECTOR_MACHINE_MODEL;
      } else if (result.PMML.TextModel) {
        modelTagFound = "TextModel";
        updatePmmlModel.modelType = ModelType.TEXT_MODEL;
      } else if (result.PMML.TimeSeriesModel) {
        modelTagFound = "TimeSeriesModel";
        updatePmmlModel.modelType = ModelType.TIME_SERIES_MODEL;
      } else if (result.PMML.TreeModel) {
        modelTagFound = "TreeModel";
        updatePmmlModel.modelType = ModelType.TREE_MODEL;
      }
    }

    //validate DataDictionary->DataField arr of xml
    if (result.PMML.DataDictionary) {
      returnValue = result.PMML.DataDictionary[0].DataField.every(dfield => {
        let newpmmlDataField: PmmlDataField = new PmmlDataField();
        newpmmlDataField.name = dfield.$.name.toString();
        newpmmlDataField.dataType = dfield.$.dataType.toString().toUpperCase();
        newpmmlDataField.opType = dfield.$.optype.toString().toUpperCase();

        //get values from MiningField of modelTagFound
        result.PMML[modelTagFound][0].MiningSchema[0].MiningField
          .filter(mfield => mfield.$.name === newpmmlDataField.name)
          .forEach(mfield => {
            if (mfield.$.usageType) {
              newpmmlDataField.usage = mfield.$.usageType.toString().toUpperCase();
            } else {
              newpmmlDataField.usage = UsageType.ACTIVE;
            }
          });

        //source is always noticed as MINING
        newpmmlDataField.source = PmmlSourceType.MINING;
        return this._savePageData_parseResult1(updatePmmlModel, newpmmlDataField);
        //discard as validating against existing values ! updatePmmlModel.pmmlDataDictionary.pmmlDataField.push(newpmmlDataField);
      });
    } else if (result.PMML.TransformationDictionary) {
      //TODO : validate TransformationDictionary->DerivedField. Ref - PMMLDataForm.cs CheckPMMLPrerequisites()
      returnValue = true;
    }
    return returnValue;
  }

  _savePageData_parseResult1(updatePmmlModel, newpmmlDataField): boolean {
    let existing: number = -1;
    existing = updatePmmlModel.pmmlDataDictionary.pmmlDataField.findIndex(currentpmmlDataField => {
      if (newpmmlDataField.name === currentpmmlDataField.name
        && newpmmlDataField.dataType === currentpmmlDataField.dataType
        && newpmmlDataField.opType === currentpmmlDataField.opType
        && newpmmlDataField.source === currentpmmlDataField.source
        && newpmmlDataField.usage === currentpmmlDataField.usage) {
        return true;
      }else{
        return false
      }
    });
    if (existing === -1) {
      return false;
    } else {
      return true;
    }
  }

  nameToUpperCase(name) {
    return name.toUpperCase();
  }
  enableButton(index: PmmlPageModel) {
    this.notSlave = index.pmmlModel.editable;
    if (!this.showAppLoader) {
      this.clickedPmmlId = index.pmmlModel.id;
      this.currentRow = index;
      this.isEnableImport = this.nodeService.isDisabled ? false : true;
      if (this.isEnableImport
        && this.userDetails.getUserRole() === "USER"
        && this.nodeService.currentProcessDetails
        && this.nodeService.currentProcessDetails.permission
        && this.nodeService.currentProcessDetails.permission
          .filter(val => (val.permission === "PMML_MODEL" && val.level !== "LEVEL_WRITE")).length === 1
      ) {
        this.isEnableImport = false;
      }
      this.isEnableExport = true;
      this.isExportRunning = false;
    } else {
      this.disableButton();
    }
  }

  disableButton() {
    this.isEnableImport = false;
    this.isEnableExport = false;
    this.isEnableFinalImport = false;
  }

  open(template: TemplateRef<any>, type: string) {
    switch (type) {
      case "Import":
        this.modalTitle = 'Import Package';
        this.modalRef = this.modalService.open(template,MODAL_OPTIONS);
        break;
      case "Export":
        this.export_download();
        break;
      default:
        this.modalTitle = "ERROR";
        this.modalRef = this.modalService.open(template,MODAL_OPTIONS);
        break;
    }
  }

  export_download(): void {
    this.showAppLoader = true;
    this.isExportRunning = true;
    this.disableButton();
    let dataUrl = "data:application/octet-binary;base64," + this.currentRow.pmmlModel.pmmlFileContent;
    fetch(dataUrl)
      .then(res => res.arrayBuffer())
      .then(buffer => {
        let blobdata = new Blob([buffer]);
        saveAs(blobdata, this.currentRow.pmmlModel.fileName);
        this.showAppLoader = false;
        this.enableButton(this.currentRow);
      }).catch((reason) => {
        console.log(reason);//DONT REMOVE THIS LOG. useful for troubleshoot.
        this.showSwalPopup(this.text_exportFailed, "error");
        this.showAppLoader = false;
        this.enableButton(this.currentRow);
      });
  }

  uploadAttachment(event: any) {
    OperationsComponent.scrollenable = true;
    OperationsComponent.url = true;
    this.showAppLoader = true;
    let oldSearchText = this.searchText;
    this.searchText = '';
    this.disableButton();
    if (this.savePageData()) {
      localStorage.setItem('whatIfIsEnabled', 'false')
      this.showSwalPopup(this.text_importSuccessful, "success")
      this._uploadAttachment_swalAction(oldSearchText);
    } else {
      this.showSwalPopup(this.text_importFailed, "error", this.text_fileValidationError);
      this._uploadAttachment_swalAction(oldSearchText);
    }

  }
  _uploadAttachment_swalAction(oldSearchText: string) {
    this.cleanupPageData();
    this.enableButton(this.currentRow);
    this.searchText = oldSearchText;

  }

  validateUpload(event: any) {
    this.showAppLoader = true;
    this.isEnableFinalImport = false;
    let fileList: FileList = event.target.files;
    let XMLTYPE: string = ".xml";
    let PMMLTYPE: string = ".pmml";

    if (fileList.length !== 1) {
      this.showSwalPopup(this.text_invalidFileError, "error", this.text_chooseValidFile);
      this.cleanupPageData();
    } else if (fileList[0].name.toLowerCase().endsWith(XMLTYPE) || fileList[0].name.toLowerCase().endsWith(PMMLTYPE)) {

      this._pmmlUploadHelper(fileList[0]).then((value) => {
        this.uploadingFileName = fileList[0].name;
        this.isEnableFinalImport = true;
        this.showAppLoader = false;
      }).catch((reason) => {
        console.log(reason);//DONT REMOVE THIS LOG. useful for troubleshoot.
        this.showSwalPopup(this.text_invalidFileError2, "error", this.text_chooseValidFile);
        this.cleanupPageData();
      });
    } else {
      console.log("Ëxtentions invalid");
      this.showSwalPopup(this.text_invalidFileError2, "error", this.text_chooseValidFile);
      this.cleanupPageData();
    }

  } //validateUpload() ends

  _pmmlUploadHelper(pmmlFile: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(pmmlFile);
      reader.onload = () => {
        let commasplit = reader.result.toString().indexOf(',');

        this.uploadingFileContents = reader.result.toString().substring(commasplit + 1);
        //TODO: validate the file against XSD, buffer calls due to replaced atob
        let parser = new xml2js.Parser();
        parser.parseString(Buffer.from(this.uploadingFileContents, 'base64').toString('utf8')
          , function (err, result) {
            if (result && result.PMML) {
              resolve(reader.result);
            } else {
              reject("Invalid PMML file!");
            }
          });
      }
      reader.onerror = error => reject(error);
    });
  }

  cancelWindow() {
    this.cleanupPageData();
  }

  showSwalPopup(titleStr: string, typeStr: any, textStr?: string) {
    typeStr=='succes'? this.alert.success(textStr,titleStr) : this.alert.error(textStr,titleStr);
  }

  isEVO(): boolean {
    return this.nodeService.bacProcessNode.ioType === ProcessIOType.JSON_EVO || this.nodeService.bacProcessNode.ioType === ProcessIOType.XML_EVO;
  }

  cleanupPageData() {
    this.showAppLoader = false;
    this.datatransferinfo = null;
    this.uploadingFileName = null;
    this.uploadingFileContents = null;
    this.isEnableFinalImport = false;
    this.isExportRunning = false;
    this.modalRef.close();
    this.enableButton(this.currentRow);
  }
}
