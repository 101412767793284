import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { S1LoginCredentials } from '../../entities/s1-logincredentials';
import { StorageDetails } from "./user-details.service";
import { ProcessManagementService } from "./process-management.service";
import { MenuItems } from "app/shared/menu-items/menu-items";
import { Router } from "@angular/router";
import { OperationsComponent } from "app/screens/process-management/operations/operations.component";
import { LoginRequest } from "app/entities/s1-loginrequest";
import { AlertsService } from 'app/shared/alert/alert-service';
import { ConfirmService } from 'app/shared/confirm/confirm.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public static s1StandardInfo: any;

  constructor(private http: HttpClient, private userInfo: StorageDetails, public nodeService: ProcessManagementService
    , private menuItems: MenuItems, private router: Router, private confirmService:ConfirmService, private alert: AlertsService) {
      //do nothing
  }

  authLogin(formVal: UntypedFormGroup): Observable<any> {
    let loginRequest: LoginRequest = new LoginRequest();
    loginRequest.clientInfo = JSON.parse(this.userInfo.getClientInfo());
    loginRequest.loginCredentials = new S1LoginCredentials(formVal);
    return this.http.post("private/login", loginRequest);
  }
  authLogoff(): Observable<any> {
    return this.http.get("private/logoff");
  }

  
  authLoginOIDC(authorization: string, authentication: string, loginRequest: any): Observable<any> {
    let headers = new HttpHeaders({
      'Authorization': authorization,
      'Authentication': authentication
      });
    return this.http.post('private/oauth2/login', loginRequest, {headers: headers});
  }

  getSystemInfo(): Observable<any> {
    return this.http.get<any>('private/installation/systeminfo');
  }

  logout(data: any) {
    this.confirmService.confirm(data.textVal,data.titleVal,data.okBtn,data.closeBtn).subscribe(confirmed=>{
      if(confirmed){
        this.nodeServiceUnset();
      }
    })
  }

  private nodeServiceUnset() {
    ProcessManagementService.openProcessDetailsArray = [];
    if (this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children']) {
      this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'].forEach(val => {
        this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'] = [];
      });
    } else {
      this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'] = [];
    }
    this.nodeService.isOpen = false;
    this.nodeService.selectedNode = null;
    this.nodeService.openProcessNode = null;
    this.nodeService.currentProcessDetails = null;
    this.nodeService.currentProcessName = null;
    OperationsComponent.url = false;
    OperationsComponent.scrollenable = false;
    OperationsComponent.isCheckProc = false;
    this.authLogoff().subscribe({
      next: resObject => {
        console.log(resObject);
        
          this.userInfo.clearStorage();
          this.router.navigate(['/']);
        
      }, error: err => {
        console.log(err);
        
        if (err.status === 401) {
          this.userInfo.clearStorage();
          this.router.navigate(['/']);
        }
      }
    });
  }

  nodeServiceSet() {
    this.nodeService.isOpen = true;
    this.nodeService.selectedNode = null;
    this.nodeService.openProcessNode = null;
    this.nodeService.currentProcessName = null;
    this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'] = [];
    ProcessManagementService.openProcessDetailsArray = [];
    this.router.navigate(['/installation-management']);
  }

}
