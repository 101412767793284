import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageDetails } from '../services/user-details.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(public _router: Router,public userDetails:StorageDetails) {
  }
  hasPermission(routedRole) {
 
   if(routedRole && routedRole.includes(this.userDetails.getUserRole())){
     return true;
   }
   return false ;
  }

 
}
