<div class="col-sm-12" *ngIf="isPermissionError">
    <br />
    <h5>
        <mat-error>{{"You do not have permission to access this page." | translate}}</mat-error>
    </h5>
</div>
<appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>
<div class="row" *ngIf="!nodeService.showAppLoader && !isPermissionError">
    <h6 class=" mb-1">{{ 'Available libraries for process :' | translate }} 
        <h6 class="badge cds-badge active text-bg-info">{{nodeService.currentProcessName}}</h6>
    </h6>
    <div class="card cds-card-shadow mb-4">
        <div class="card-body">
            <div class="col-sm-12">
                <div class="col-12 mb-2">
                    <input class="form-control searchBox" placeholder="{{ 'Quick Filter' | translate}}" [(ngModel)]="searchText">
                </div>
            </div>
            <div class="col-sm-12">
                <div class="col-sm-12"><br /></div>
                <div class="col-sm-12">
                    <div class="scroll-only-x">
                        <table aria-describedby="table-proc-libParent" class="table table-striped"
                            [mfData]="liabraries | filter :'id,libraryType,description,connection,processAll,reporting': searchText"
                            #mf="mfDataTable" mfSortBy="id" [mfRowsOnPage]="15">
                            <thead>
                                <tr>
                                    <th>
                                        <mfDefaultSorter by="id">{{ 'Code' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="libraryType">{{ 'Type' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="description">{{ 'Notes' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="connection">{{ 'Logical Operator' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="processAll">{{ 'Process All' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="reporting">{{ 'Append' | translate }}</mfDefaultSorter>
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="liabraries.length > 0">
                                <tr class="cursor" *ngFor="let value of mf.data let index" (click)="showVariablesDetails(value)"
                                    [ngStyle]="{'background-color': value.id === liabraryName ? 'antiquewhite' : ''}">
                                    <td>{{value.id}}</td>
                                    <td>{{value.libraryType}}</td>
                                    <td>{{value.description}}</td>
                                    <td>{{value.connection}}</td>
                                    <td>{{value.processAll}}</td>
                                    <td>{{value.calcMode && value.calcMode === 'APPEND' ? true : false}}</td>
                                </tr>
                            </tbody>
                            <tfoot
                                *ngIf="(liabraries | filter :'id,libraryType,description,connection,processAll,reporting': searchText).length > 15">
                                <tr>
                                    <td colspan="10">
                                        <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="col-sm-12 text-center" *ngIf="liabraries.length === 0">
                            <h5>
                                <mat-error>{{ 'No records found.' | translate }}</mat-error>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="rulesInfo.length > 0">
        <h6 class=" mb-1">{{ 'Details of library :' | translate }} 
            <h6 class="badge cds-badge active text-bg-primary">{{liabraryName}}</h6>
        </h6>
        <div class="card cds-card-shadow">
            <div class="card-body">
                <div class="col-12">
                    <table aria-describedby="table-proc-libChild" class="table table-striped"
                        [mfData]="ruleObj" #mf="mfDataTable" [mfRowsOnPage]="15">
                        <thead>
                            <tr>
                                <th>
                                    <mfDefaultSorter by="id">{{ 'Code' | translate }}</mfDefaultSorter>
                                </th>
                                <th>
                                    <mfDefaultSorter by="getVariableName(condition)">{{ 'Variable' | translate }}
                                    </mfDefaultSorter>
                                </th>
                                <th>
                                    <mfDefaultSorter by="scoreValue">{{ 'Value' | translate }}</mfDefaultSorter>
                                </th>
                                <th>
                                    <mfDefaultSorter by="condition">{{ 'Condition' | translate }}</mfDefaultSorter>
                                </th>
                                <th class="actions-col" *ngIf="shared == 'true'">
                                    Override
                                </th>
                                <th>
                                    <mfDefaultSorter by="disabled">{{ 'Status' | translate }}</mfDefaultSorter>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let subValue of mf.data let i = index">
                                <td>{{ subValue.id }}</td>
                                <td>{{ subValue.variable }}</td>
                                <td>{{ subValue.scoreValue }}</td>
                                <td>{{ subValue.condition | VariableNamefilter: categoryVariableMap }}</td>
                                <td class="text-center text-secondary" *ngIf="shared == 'true'"> 
                                    <i  *ngIf="subValue.override.enabled" class="fa fa-info-circle" aria-hidden="true"  data-toggle="tooltip" data-placement="top" title="Original value: {{!subValue.disabled}}"></i> 
                                </td>
                                <td class="text-center">
                                    <div class="form-check form-switch">
                                        <input  class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault{{i}}"
                                        [checked]="subValue.override.enabled? !subValue.override.value : !subValue.disabled" [disabled]="isDisableToggleButton"
                                        (change)="onChange(subValue, i)">
                                    </div>
                                    <!-- <mat-slide-toggle [ngClass]=" subValue.disabled==true ? 'mat-checked' : '' "
                                        [checked]="!subValue.disabled" [disabled]="isDisableToggleButton"
                                        (change)="onChange(subValue, i)">
                                    </mat-slide-toggle> -->
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="ruleObj.length > 15">
                            <tr>
                                <td colspan="10">
                                    <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>
</div>