import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FileUploader } from 'ng2-file-upload';
import { v4 as uuid } from 'uuid';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Buffer } from "buffer";
import { StorageDetails } from './../../../core/services/user-details.service';
import { ProcessManagementService } from 'app/core/services/process-management.service';
import { PythonManagementService } from './../../../core/services/python-management.service';
import { ServerManagementService } from './../../../core/services/server-management.service';
import { OperationsComponent } from '../operations/operations.component';
import { S1CreatePythonModelRequest } from 'app/entities/s1-createpythonmodelrequest';
import { VariableTo } from 'app/entities/bac-variableto';
import { S1DataTransferInfo } from 'app/entities/s1-datatransferinfo';
import { S1PythonModel } from 'app/entities/s1-pythonmodel';
import { Connector } from 'app/entities/bac-connector';
import { ProcessIOType } from 'app/entities/bac-processiotype.enum';
import { S1PythonModelState } from 'app/entities/s1-pythonmodelstate.enum';
import { S1DataTransferFolder } from 'app/entities/S1DataTransferFolder.enum';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmService } from 'app/shared/confirm/confirm.service';
import { AlertsService } from 'app/shared/alert/alert-service';
const MODAL_OPTIONS: NgbModalOptions = { size: 'lg', backdrop: 'static', keyboard: true, windowClass: 'modal-full-height modal-full-width' };

export class PythonPageModel {
  pConnector: Connector = new Connector();
  pInputVariables: VariableTo[] = [];
  pOutputVariables: VariableTo[] = [];
}
@Component({
  selector: 'app-python',
  templateUrl: './python.component.html',
  styleUrls: ['./python.component.css']
})
export class PythonComponent implements OnInit {

  pythonModels: PythonPageModel[] = [];
  allVariables: VariableTo[] = [];
  error: boolean = false;
  errorMessage: string = "";
  searchText: string = "";
  showAppLoader: boolean = false;
  currentRow: PythonPageModel;
  clickedPythonId: string;
  processName: string;
  processVersion: number;
  connectorModel: Connector;
  isEnableImport: boolean;
  isEnableExport: boolean;
  isExportRunning: boolean = false;
  uploadPackage: File;
  modalRef: NgbModalRef;
  modalTitle: string;
  datatransferinfo: S1DataTransferInfo;
  anythingInprocess = false;
  isProcessEVO = false;
  isPermissionError: boolean = true;
  notSlave: boolean = true;

  //uploading file
  formData: FormData;
  public uploader: FileUploader = new FileUploader({url:'', maxFileSize: 100000000000 });
  fileName: string = "";

  @ViewChild('fileImport', { static: true }) fileImport: ElementRef;
  isEnableFinalImport: boolean;
  pythonerrorlog: string;

  CHUNKSIZE: number = 1024 * 1024; // 1 MB

  text_nonEvoError: string;
  text_unableToDownload: string;
  text_pythonModelState: string;
  text_uploadSuccessful: string;
  text_uploadFailed: string;
  text_buttonClose: string;
  text_downloadErrorLogs: string;
  text_httpFailure: string;
  text_importFailed: string;
  text_multipleOrNoFiles: string;
  text_uploadValidPackage: string;
  text_invalidPackage: string;
  text_referUserGuide: string;
  text_invalidZip: string;
  text_buttonOk: string;
  text_noFileFound: string;


  constructor(public nodeService: ProcessManagementService,
    private pythonManagementService: PythonManagementService,
    private serverManagementService: ServerManagementService,
    private modalService: NgbModal,
    private userDetail: StorageDetails,
    private router: Router,
    private translate: TranslateService, 
    private confirmService: ConfirmService, 
    private alert: AlertsService) {
    //do nothing
  }

  reloadPageData() {

    if (!this.nodeService.currentProcessDetails) {
      this.router.navigate(['/process-management']);
    }

    let oldSearchText = this.searchText;
    this.searchText = '';
    this.pythonModels = [];
    this.allVariables = [];

    //check for flat/classic XML
    this.isProcessEVO = true;
    if (this.nodeService.bacProcessNode?.ioType === ProcessIOType.COBOL
      || this.nodeService.bacProcessNode?.ioType === ProcessIOType.XML) {
      this.isProcessEVO = false;
      this.errorMessage = this.text_nonEvoError;
      return;
    }

    //save all variables
    this.allVariables = this.nodeService.bacProcessNode?.categoryVariablesList;
    //save python connectors in custom pythonpagemodel  
    if (this.nodeService.bacProcessNode?.connectors
      && this.nodeService.bacProcessNode?.connectors.connector) {
      this.nodeService.bacProcessNode?.connectors.connector.filter(connector => connector.bac)
        .forEach(connector => {

          let pythonModel: PythonPageModel = new PythonPageModel();
          pythonModel.pConnector = connector;
          //initiate input variables
          pythonModel.pConnector.inputVariables.variableRefID.forEach(inputVariableRef => {
            this.allVariables.forEach(variable => {
              if (variable.categoryVariableGuid === inputVariableRef.value) {
                pythonModel.pInputVariables.push(variable);
              }
            });
          });
          //initiate output variables
          pythonModel.pConnector.outputVariables.variableRefID.forEach(outputVariableRef => {
            this.allVariables.forEach(variable => {
              if (variable.categoryVariableGuid === outputVariableRef.value) {
                pythonModel.pOutputVariables.push(variable);
              }
            });
          });
          //add newly created single python model
          this.pythonModels.push(pythonModel);
        });
    }
    this.searchText = oldSearchText;
  }
  ngOnInit(): void {
    this.initInternationalization();

    if (this.userDetail.getUserRole() !== "USER"
      || (this.userDetail.getUserRole() === "USER"
        && this.nodeService.currentProcessDetails
        && this.nodeService.currentProcessDetails.permission
        && this.nodeService.currentProcessDetails.permission
          .filter(val => {
            if ((val.permission === "PYTHON" && val.level === "LEVEL_WRITE")
              || (val.permission === "PYTHON" && val.level === "LEVEL_READ")){
                return true;
              }
              else{
                return false
              }
          }).length === 1
      )
    ) {
      this.isPermissionError = false;
      //save process details for headline
      this.processName = this.nodeService.currentProcessName;
      this.processVersion = this.nodeService.currentProcessVersion;

      //initiate pythonModels by creating one python model at-a-time 
      this.reloadPageData();

      //empty currentRow at init as no row cliked yet
      this.currentRow = new PythonPageModel();
      this.currentRow.pInputVariables = [];
      this.currentRow.pOutputVariables = [];

      //keep disabled buttons until row clicked
      this.disableButton();
    } else {
      this.isPermissionError = true;
      this.errorMessage = this.nodeService.errMsgPermissionDeniedDetails;
    }
  }

  initInternationalization() {
    this.translate.get([
      'This process does not support python feature.'
      , 'Unable to download :'
      , 'Python model state is :'
      , 'Python package uploaded successfully!'
      , 'Python package uploaded with errors!'
      , 'Close'
      , 'Download Error Logs'
      , 'Server communication error occurred. Please reload the page or try again later.'
      , 'Python package import failed!'
      , 'Multiple or no files found!'
      , 'Please choose a valid python package.'
      , 'Invalid Python package!'
      , 'Please check the User Guide for more information.'
      , 'Error: Corrupted zip or bug in contents'
      , 'Ok'
    ]).subscribe(text => {
      this.text_unableToDownload = text['Unable to download :'];
      this.text_pythonModelState = text['Python model state is :'];
      this.text_uploadSuccessful = text['Python package uploaded successfully!'];
      this.text_uploadFailed = text['Python package uploaded with errors!'];
      this.text_downloadErrorLogs = text['Download Error Logs'];
      this.text_httpFailure = text['Server communication error occurred. Please reload the page or try again later.'];
      this.text_importFailed = text['Python package import failed!'];
      this.text_multipleOrNoFiles = text['Multiple or no files found!'];
      this.text_uploadValidPackage = text['Please choose a valid python package.'];
      this.text_invalidPackage = text['Invalid Python package!'];
      this.text_referUserGuide = text['Please check the User Guide for more information.'];
      this.text_invalidZip = text['Error: Corrupted zip or bug in contents'];
      this.text_buttonClose = text['Close'];
      this.text_buttonOk = text['Ok'];
      this.text_nonEvoError = text['This process does not support python feature.'];
    });
  }
  enableButton(index: PythonPageModel) {
    this.notSlave = index.pConnector.editable
    if (!this.showAppLoader) {
      this.clickedPythonId = index.pConnector.id;
      this.currentRow = index;
      this.isEnableImport = this.nodeService.isDisabled ? false : true;
      if (this.isEnableImport
        && this.userDetail.getUserRole() === "USER"
        && this.nodeService.currentProcessDetails
        && this.nodeService.currentProcessDetails.permission
        && this.nodeService.currentProcessDetails.permission
          .filter(val => (val.permission === "PYTHON" && val.level !== "LEVEL_WRITE")).length === 1
      ) {
        this.isEnableImport = false;
      }
      this.isEnableExport = true;
      this.isExportRunning = false;
    } else {
      this.disableButton();
    }
  }

  disableButton() {
    this.isEnableImport = false;
    this.isEnableExport = false;
    this.isEnableFinalImport = false;
  }

  open(template: TemplateRef<any>, type: string) {

    switch (type) {
      case "Import":
        this.modalTitle = 'Import Package';
        this.modalRef = this.modalService.open(template,MODAL_OPTIONS)
        break;
      case "Export":
        this.export_downloadZip();
        break;
      case "PythonErrorLog":
        this.modalTitle = 'Python Import Error Logs';
        this.modalRef = this.modalService.open(template,MODAL_OPTIONS)
        break;
      default:
        this.modalTitle = "ERROR";
        this.modalRef = this.modalService.open(template,MODAL_OPTIONS)
        break;
    }
  }

  export_downloadZip(): void {
    this.showAppLoader = true;
    this.isExportRunning = true;
    this.disableButton();
    let portidstr: string = this.currentRow.pConnector.url.substring(this.currentRow.pConnector.url.lastIndexOf(':') + 1);
    let portid: number = +portidstr;

    this.pythonManagementService.getPythonModelByPort(portid).subscribe(resObj => {
      if (resObj['processID']
        && resObj['state'] !== "MISSING") {

        let s1datatransferinfo: S1DataTransferInfo = new S1DataTransferInfo();
        s1datatransferinfo.filename = resObj['uploadedPackageGUID'];
        s1datatransferinfo.folder = S1DataTransferFolder.FTP;
        this.serverManagementService.downloadTheFile(s1datatransferinfo, "zip").subscribe(data => {
          let blobdata = new Blob([new Uint8Array(data)], { type: 'application/zip' });
          saveAs(blobdata, this.currentRow.pConnector.resourceID);
          this.showAppLoader = false;
          this.enableButton(this.currentRow);
        });
      } else {
        this.showSwalPopup(this.text_unableToDownload + " " + this.currentRow.pConnector.resourceID, "error", resObj['state'] ? this.text_pythonModelState + " " + resObj['state'] : null);
        this.showAppLoader = false;
        this.enableButton(this.currentRow);
      }
    });// docGetPythonModelByPort end
  }

  uploadZip(): void {
    this.showAppLoader = true;
    this.disableButton();
    OperationsComponent.url = true;
    OperationsComponent.scrollenable = true;
    this.datatransferinfo = new S1DataTransferInfo();
    this.datatransferinfo.filename = uuid();
    this.formData = new FormData();
    this.fileName = "";
    for (const item of this.uploader.queue) {
      this.formData.append("file", item._file, item.file.name);
      this.datatransferinfo.fileSize = item.file.size;
      this.fileName = item.file.name;
      this.uploader.queue.splice(0, 1);
    }
    this.datatransferinfo.overwrite = true;
    this.datatransferinfo.folder = S1DataTransferFolder.FTP;

    console.log("-- calling uploadTheFile");

    this.serverManagementService.uploadTheFile(this.formData, this.datatransferinfo, "zip").subscribe((event: HttpEvent<any>) => {

      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          break;
        case HttpEventType.Response:
          let response = event.body;
          if (response === "OK") {
            this.sentDataToServer();
          } else {
            this.showSwalPopup(this.text_importFailed, "error", this.text_uploadFailed);
            this.cleanupPageData();
          }
      }
    });

  }

  private sentDataToServer() {
    localStorage.setItem('whatIfIsEnabled', 'false');
    let createPythonModelRequest: S1CreatePythonModelRequest = new S1CreatePythonModelRequest();
    createPythonModelRequest.report = null;
    createPythonModelRequest.port = 0;
    createPythonModelRequest.processID = this.nodeService.openProcessNode['id'];
    createPythonModelRequest.uploadedPackageGUID = this.datatransferinfo.filename;
    createPythonModelRequest.packageFilename = this.fileName;
    createPythonModelRequest.pythonObjectGuid = this.currentRow.pConnector.guid; //python guid in process xml
    createPythonModelRequest.pythonObjectCode = this.currentRow.pConnector.guid; //python name in process xml (do not trust on this!)

    this.pythonManagementService.docCreatePythonModel(createPythonModelRequest).subscribe({
      next: createPythonData => {
        console.log("IMPORT Result-" + JSON.stringify(createPythonData));

        if (createPythonData['response']['report']['failed'] === false) {

          createPythonModelRequest = createPythonData['response'];
          let s1pythonmodel: S1PythonModel = createPythonData['model'];
          //validation : TODO rework here for negative tests from v71 onwards

          if (!createPythonModelRequest.report.failed
            && Number.parseInt(S1PythonModelState[s1pythonmodel.state]) === S1PythonModelState.INSTALLED.valueOf()) {
            this.showSwalPopup(this.text_uploadSuccessful, "success")
              //update current highlighted row as well as bac node
              this.currentRow.pConnector.resourceID = this.uploadPackage.name;
              let portidstr: string = this.currentRow.pConnector.url.substring(this.currentRow.pConnector.url.lastIndexOf(':') + 1);
              this.currentRow.pConnector.url = this.currentRow.pConnector.url.replace(portidstr, createPythonModelRequest.port.toString());
              this.nodeService.bacProcessNode?.connectors.connector
                .filter(connector => connector.bac)
                .filter(connector => connector.id === this.currentRow.pConnector.id)
                .forEach(connector => {
                  connector.resourceID = this.uploadPackage.name;
                  connector.url = this.currentRow.pConnector.url;
                });
              //reset
              this.reloadPageData();
              this.cleanupPageData();
          } else {
            this._sentDataToServer_elseErr(createPythonModelRequest, s1pythonmodel)
          }
        } else {
          this.showSwalPopup(this.text_uploadFailed, "error", null);
          this.cleanupPageData();
        }
      },
      error: errorData => {
        this.errorMessage = errorData.name + ': ' + errorData.status + '.'
          + ' ' + this.text_httpFailure;
        this.showSwalPopup(this.text_importFailed, "error", this.errorMessage);
        this.cleanupPageData();
      }
    });
  }
  private _sentDataToServer_elseErr(createPythonModelRequest: S1CreatePythonModelRequest, s1pythonmodel: S1PythonModel) {
    this.confirmService.confirm(this.text_uploadFailed,'',this.text_downloadErrorLogs,this.text_buttonClose).subscribe(confirmed=>{
      if(confirmed){
        //download error logs
        let errorLog: string = "STATUS \n\n";
        //validation : part success. failed=false and running=false
        if (!createPythonModelRequest.report.failed && s1pythonmodel.state !== S1PythonModelState.RUNNING) {

          errorLog += "\t Model successfully installed on server "
            + "but actually it is not running possibly because of an incorrect Python Script "
            + "or requires a very long model loading time.\n";
        } else {
          errorLog += "\t Failed.\n";
        }
        errorLog += "\n===========================================================================";
        errorLog += "=========================================================================== \n\n";
        errorLog += "OUTPUT \n\n";

        for (let rLog of createPythonModelRequest.report.log) {
          let filteredLogLine: string = Buffer.from(rLog.text, 'base64').toString('utf8'); //todo atob was here. test.
          if (filteredLogLine.startsWith('[') || filteredLogLine.startsWith('_')) {
            filteredLogLine = filteredLogLine + '  ';
          }
          errorLog += filteredLogLine + '  ';
        }

        errorLog += "\n===========================================================================";
        errorLog += "=========================================================================== \n\n";
        errorLog += "TRACE \n\n";
        errorLog += createPythonModelRequest.report.trace;
        errorLog += "\n===========================================================================";
        errorLog += "=========================================================================== \n\n";

        this.pythonerrorlog = errorLog;
        let blobdata = new Blob([this.pythonerrorlog], { type: 'application/text' });
        saveAs(blobdata, this.currentRow.pConnector.id + "-error.log");
        this.showAppLoader = false;
      }else{
        this.showAppLoader = true;
        //update current highlighted row as well as bac node
        this.currentRow.pConnector.resourceID = this.uploadPackage.name;
        this.nodeService.bacProcessNode?.connectors.connector
          .filter(connector => connector.bac)
          .filter(connector => connector.id === this.currentRow.pConnector.id)
          .forEach(connector => {
            connector.resourceID = this.uploadPackage.name;
          });
      }
      //reset
      this.reloadPageData();
      this.cleanupPageData();
    })
  }

  validateUpload(event: any) {
    this.showAppLoader = true;
    this.disableButton();
    let fileList: FileList = event.target.files;
    console.log("zip file size=" + fileList[0].size);
    let ZIPTYPE: string = ".zip";
    if (fileList.length !== 1) {
      this.showSwalPopup(this.text_multipleOrNoFiles, "error", this.text_uploadValidPackage)
      this.cleanupPageData();
    } else if (fileList[0].name.toLowerCase().endsWith(ZIPTYPE)) {
      /* to ignore big size use fileList[0].size > 500000000 for 500MB */
      let zipFile: JSZip = new JSZip();
      zipFile.loadAsync(fileList[0]).then(zip => {
        let isModelPy: boolean = false;
        let isRequirementsTxt: boolean = false;
        const FILEMODELPY: string = "model.py";
        const FILEREQUIREMENTSTXT: string = "requirements.txt";

        zip.forEach((relativePath, file) => {
          if (file.name == FILEMODELPY) isModelPy = true;
          else if (file.name == FILEREQUIREMENTSTXT) isRequirementsTxt = true;
        });

        console.log(fileList);
        if (isModelPy && isRequirementsTxt) {
          this.isEnableFinalImport = true;
          this.uploadPackage = fileList[0];
          this.showAppLoader = false;
        } else {
          this.showSwalPopup(this.text_invalidPackage, "error", this.text_referUserGuide)
          this.cleanupPageData();
        }
      }).catch((reason) => {
        console.log(reason);//DONT REMOVE THIS LOG. useful for troubleshoot.
        this.showSwalPopup(this.text_invalidPackage, "error", this.text_invalidZip)
        this.cleanupPageData();
      });
    } else {
      console.log("Ëxtentions invalid");
      this.showSwalPopup(this.text_invalidPackage, "error", this.text_referUserGuide);
      this.cleanupPageData();
    }

  } //validateUpload() ends

  _pythonUploadHelper(pythonFile: File) {
    return new Promise((resolve, reject) => {
      let zipFile: JSZip = new JSZip();
      zipFile.loadAsync(pythonFile).then(zip => {
        let isModelPy: boolean = false;
        let isRequirementsTxt: boolean = false;
        const FILEMODELPY: string = "model.py";
        const FILEREQUIREMENTSTXT: string = "requirements.txt";

        zip.forEach((relativePath, file) => {
          if (file.name == FILEMODELPY) isModelPy = true;
          else if (file.name == FILEREQUIREMENTSTXT) isRequirementsTxt = true;
        });

        if (isModelPy && isRequirementsTxt) {
          this.isEnableFinalImport = true;
          this.uploadPackage = pythonFile;
          this.showAppLoader = false;
        } else {
          this.showSwalPopup(this.text_invalidPackage, "error", this.text_referUserGuide);
          this.cleanupPageData();
        }
      });
    });
  }

  cancelWindow() {
    this.cleanupPageData();
  }

  showSwalPopup(titleStr: string, typeStr: any, textStr?: string) {
    typeStr == 'success'? this.alert.success(textStr,titleStr): this.alert.error(textStr,titleStr);
  }

  cleanupPageData() {
    this.showAppLoader = false;
    this.datatransferinfo = null;
    this.uploadPackage = null;
    this.isEnableFinalImport = false;
    this.isExportRunning = false;
    this.modalRef.close();
    this.enableButton(this.currentRow);
  }
}
