import { CanDeactivateGuard } from './../../core/guards/candeactivateguard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserCreationComponent } from './user-creation/user-creation.component';
import { UserPermissionComponent } from './user-permission/user-permission.component';
import { LogTracerComponent } from './log-tracer/log-tracer.component';
import { AuthGuard } from 'app/core/guards/authguard';
import { PythonManagementComponent } from './python-management/python-management.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'user-creation',
        component: UserCreationComponent,
        data: {
          expectedRole: ['SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN'],
        },
        canActivate: [AuthGuard],

      },
      {
        path: 'user-permission',
        component: UserPermissionComponent,
        data: {
          expectedRole: ['SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN']
        },
        canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'log-tracer',
        component: LogTracerComponent,
        data: {
          expectedRole: ['USER', 'SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN']
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'python-management',
        component: PythonManagementComponent,
        data: {
          expectedRole: ['SYSTEM_ADMIN', 'SECURITY_ADMIN']
        },
        canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InstallationManagementRoutingModule { }
