<div class="row">
    <div class="col-12">
        <div class="card cds-card-shadow">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                            <li [ngbNavItem]="1">
                                <button ngbNavLink>Authorization</button>
                                <ng-template ngbNavContent>
                                    <appspinner [startSpinner]="showAppLoader"></appspinner>
                                    <div class="alert cds-alert alert-danger" role="alert" *ngIf="isError && errorMessage">
                                        <div class="cds-alert-icon">
                                          <i class="fa-solid fa-triangle-exclamation"></i>
                                        </div>
                                        <div class="cds-alert-content">{{errorMessage | translate}}</div>
                                    </div>
                                    <div class="row p-2">
                                        <div class="col-4">
                                            <label class="form-label">{{ 'User' | translate }}</label>
                                            <ng-select
                                                (change)="loadPermisions(selected)"
                                                placeholder="{{ 'User' | translate }}"
                                                [(ngModel)]="selected">
                                                <ng-container *ngFor="let group of userGroups">
                                                    <ng-option disabled>
                                                        <img src="{{group.imgPath}}" alt="userGroup" style="width: 20px; height: 20px;" />
                                                        {{group.viewValue}}
                                                    </ng-option>
                                                    <ng-option *ngFor="let username of group.grpUserNames" [value]="username">
                                                        <img class="pl-2" src="{{group.imgPath}}" alt="userGroup"
                                                        style="width: 20px; height: 20px; margin-left: 20px;" />
                                                        {{username.viewValue | translate}}
                                                    </ng-option>
                                                </ng-container>
                                            </ng-select>
                                        </div>
                                        <div class="col-5 mt-4">
                                            <button [disabled]="showAppLoader || isEnableSave2DB" type="button" class="btn btn-sm btn-primary mt-2 m-1" (click)="saveToDb()">
                                                <i class="fa fa-save" title="{{ 'Save authorizations' | translate }}"></i>
                                            </button>
                                          <div class="btn-group me-3 d-inline mt-2 overflow-hidden rounded-pill">
                                            <button type="button"   class="btn btn-primary btn-sm mt-2 rounded-end" [disabled]="showAppLoader || isEnableSave2DB" (click)="exporUserToXlsx();">
                                              <i class="fa fa-print" title="Print User" ></i></button>
                                            <div class="btn-group dropdown rounded-0" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                                              <button type="button" (click)="openDialog(userSelectModal);" class="btn btn-primary btn-sm  mt-2  border-start dropdown-toggle dropdown-toggle-split mt-2 rounded-start" ngbDropdownToggle></button>
                                              <div class="dropdown-menu" hidden ngbDropdownMenu>
                                              </div>
                                            </div>
                                          </div>


                                        </div>
                                        <div *ngIf="files1" class="col-12 mt-4">
                                            <div class="col-md-12">
                                                <p-treeTable [value]="files1" [columns]="cols" selectionMode="single"
                                                    [(selection)]="selectedNode2" dataKey="name" (onNodeUnselect)="nodeUnselect($event)"
                                                    [resizableColumns]="true">

                                                    <ng-template pTemplate="colgroup" let-columns>
                                                        <colgroup>
                                                            <col *ngFor="let col of columns">
                                                        </colgroup>
                                                    </ng-template>

                                                    <ng-template pTemplate="header" let-columns>
                                                        <tr #header style="border-bottom: 2px solid #003671;color: #003671;">
                                                            <th scope="col" ttResizableColumn style="width:50%"  class="p-2">
                                                                {{ 'Explorer' | translate }}
                                                            </th>
                                                            <th scope="col" ttResizableColumn style="width:10%;text-align:center;" class="p-2">
                                                                {{ 'Enable' | translate }}
                                                            </th>
                                                            <th scope="col" ttResizableColumn style="width:40%" class="p-2">
                                                                {{ 'Assigned Profile' | translate }}
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                                                        <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode" style="border-bottom:1px solid #dddddd"
                                                            *ngIf="!(rowData.type==='process' && selectedUser.userRole===5)">
                                                            <td style="width:50%" class="p-2">
                                                                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                                                <img *ngIf="rowData.type=='installation'"
                                                                    src="assets/img/icon-coloured-installation.svg" alt="installation"
                                                                    style="width: 20px; height: 20px;" />
                                                                <img *ngIf="rowData.type=='group'" src="assets/img/icon-coloured-group.svg"
                                                                    alt="group" style="width: 20px; height: 20px;" />
                                                                <img *ngIf="rowData.type=='institute'"
                                                                    src="assets/img/icon-coloured-organization.svg" alt="institute"
                                                                    style="width: 20px; height: 20px;" />
                                                                <img *ngIf="rowData.type=='process'" src="assets/img/icon-coloured-process.svg"
                                                                    alt="process" style="width: 20px; height: 20px;" />
                                                                {{rowData.name}}

                                                            </td>
                                                            <td style="width:10%;text-align:center;" class="p-2">
                                                                <input [disabled]="showAppLoader" id="checkbox3" class="form-check-input"
                                                                    *ngIf="rowData.parentId !==0 || (rowData.parentId ===0 && selectedUser.userRole===5)"
                                                                    type="checkbox" [(ngModel)]="rowData.enabled"
                                                                    [indeterminate]="rowData.indeterminate"
                                                                    (click)="nodeSelect(rowNode,template)">
                                                            </td>
                                                            <td style="width:40%" class="p-2">
                                                                {{displayAssignedProfileValue(rowData.userProfileId)}}
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-treeTable>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                                <button ngbNavLink>Profiles</button>
                                <ng-template ngbNavContent>
                                    <div class="row">
                                        <div class="col-12">
                                            <input class="form-control  mb-3" placeholder="{{ 'Quick Filter' | translate}}" [(ngModel)]="searchText">
                                            <appspinner [startSpinner]="showAppLoader"></appspinner>
                                        </div>
                                        <div class="col-12">
                                            <table aria-describedby="table-userPermission"
                                                class="table table-striped "
                                                [mfData]="userProfiles | filter :'name,description':searchText" mfSortBy="name"
                                                #mf="mfDataTable" [mfRowsOnPage]="15">
                                                <thead>
                                                    <tr>
                                                        <th class="center-align">
                                                            <mfDefaultSorter by="name">{{ 'Name' | translate }}</mfDefaultSorter>
                                                        </th>
                                                        <th>
                                                            <mfDefaultSorter by="description">{{ 'Description' | translate }}
                                                            </mfDefaultSorter>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let userprofile of mf.data">
                                                        <td class="center-align">{{userprofile.name}}</td>
                                                        <td>{{userprofile.description | translate}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot *ngIf="(userProfiles| filter :'name,description':searchText).length > 15">
                                                    <tr>
                                                        <td colspan="10">
                                                            <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                            <div class="alert cds-alert alert-danger" role="alert" *ngIf="!showAppLoader && userProfiles.length===0">
                                                <div class="cds-alert-icon">
                                                <i class="fa-solid fa-triangle-exclamation"></i>
                                                </div>
                                                <div class="cds-alert-content">{{ 'No records found.' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>

                        <div [ngbNavOutlet]="nav" class="mt-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="row">
    <div class="col-sm-12">
        <br />
        <tabset #staticTabs>
            <tab heading="Authorization">
                <br />
                <appspinner [startSpinner]="showAppLoader"></appspinner>
                <h5>
                    <mat-error *ngIf="isError">{{errorMessage | translate}}</mat-error>
                </h5>
                <div class="row">
                    <div class="col-sm-4">
                        <mat-form-field appearance="outline" class="wrapper-without-bottom">
                            <mat-label>{{ 'User' | translate }}</mat-label>

                            <mat-select placeholder="{{ 'User' | translate }}"
                                (selectionChange)="loadPermisions($event.value)" [(value)]="selected">
                                <mat-select-trigger>
                                    <img src="{{selected===undefined?'':selected.userRole | imagePipe}}" alt="userRole"
                                        style="width: 20px; height: 20px;" />
                                    &nbsp;{{selected===undefined?'':selected.viewValue}}
                                </mat-select-trigger>
                                <ng-container *ngFor="let group of userGroups">
                                    <mat-optgroup *ngIf="group.grpUserNames.length >0" [disabled]="group.disabled">
                                        <img src="{{group.imgPath}}" alt="userGroup"
                                            style="width: 20px; height: 20px;" />{{group.viewValue}}

                                        <mat-option *ngFor="let username of group.grpUserNames" [value]="username">
                                            <img src="{{group.imgPath}}" alt="userGroup"
                                                style="width: 20px; height: 20px;" />
                                            {{username.viewValue}}
                                        </mat-option>
                                    </mat-optgroup>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-2">
                        <button [disabled]="showAppLoader || isEnableSave2DB" type="button"
                            class="btn btn-sm btn-primary" style="margin-top: 16px;" (click)="saveToDb()">
                            <span class="fa fa-save" title="{{ 'Save authorizations' | translate }}">
                            </span>
                        </button>
                    </div>
                </div>
                <br />
                <div *ngIf="files1" class="row">
                    <div class="col-md-12">
                        <p-treeTable [value]="files1" [columns]="cols" selectionMode="single"
                            [(selection)]="selectedNode2" dataKey="name" (onNodeUnselect)="nodeUnselect($event)"
                            [resizableColumns]="true">

                            <ng-template pTemplate="colgroup" let-columns>
                                <colgroup>
                                    <col *ngFor="let col of columns">
                                </colgroup>
                            </ng-template>

                            <ng-template pTemplate="header" let-columns>
                                <tr #header>
                                    <th scope="col" ttResizableColumn style="width:50%">
                                        {{ 'Explorer' | translate }}
                                    </th>
                                    <th scope="col" ttResizableColumn style="width:10%;text-align:center;">
                                        {{ 'Enable' | translate }}
                                    </th>
                                    <th scope="col" ttResizableColumn style="width:40%">
                                        {{ 'Assigned Profile' | translate }}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                                <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode"
                                    *ngIf="!(rowData.type==='process' && selectedUser.userRole===5)">
                                    <td style="width:50%">
                                        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                        <img *ngIf="rowData.type=='installation'"
                                            src="assets/img/icon-coloured-installation.svg" alt="installation"
                                            style="width: 20px; height: 20px;" />
                                        <img *ngIf="rowData.type=='group'" src="assets/img/icon-coloured-group.svg"
                                            alt="group" style="width: 20px; height: 20px;" />
                                        <img *ngIf="rowData.type=='institute'"
                                            src="assets/img/icon-coloured-organization.svg" alt="institute"
                                            style="width: 20px; height: 20px;" />
                                        <img *ngIf="rowData.type=='process'" src="assets/img/icon-coloured-process.svg"
                                            alt="process" style="width: 20px; height: 20px;" />
                                        {{rowData.name}}

                                    </td>
                                    <td style="width:10%;text-align:center;">
                                        <input [disabled]="showAppLoader" id="checkbox3"
                                            *ngIf="rowData.parentId !==0 || (rowData.parentId ===0 && selectedUser.userRole===5)"
                                            type="checkbox" [(ngModel)]="rowData.enabled"
                                            [indeterminate]="rowData.indeterminate"
                                            (click)="nodeSelect(rowNode,template)">
                                    </td>
                                    <td style="width:40%">
                                        {{displayAssignedProfileValue(rowData.userProfileId)}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-treeTable>
                    </div>
                </div>
            </tab>
            <tab heading="Profiles">
                <br />
                <div class="col-sm-12">
                    <input class="form-control searchBox" placeholder="{{ 'Quick Filter' | translate}}"
                        [(ngModel)]="searchText">
                    <div class="col-sm-12"><br /></div>
                    <appspinner [startSpinner]="showAppLoader"></appspinner>

                    <div class="scroll-only-x">
                        <table aria-describedby="table-userPermission"
                            class="table table-sm table-bordered table-striped mb-0"
                            [mfData]="userProfiles | filter :'name,description':searchText" mfSortBy="name"
                            #mf="mfDataTable" [mfRowsOnPage]="15">
                            <thead>
                                <tr>
                                    <th class="center-align">
                                        <mfDefaultSorter by="name">{{ 'Name' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="description">{{ 'Description' | translate }}
                                        </mfDefaultSorter>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let userprofile of mf.data">
                                    <td class="center-align">{{userprofile.name}}</td>
                                    <td>{{userprofile.description | translate}}</td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="(userProfiles| filter :'name,description':searchText).length > 15">
                                <tr>
                                    <td colspan="10">
                                        <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="col-sm-12 text-center" *ngIf="!showAppLoader && userProfiles.length===0">
                            <h5>
                                <mat-error>{{ 'No records found.' | translate}}</mat-error>
                            </h5>
                        </div>
                    </div>
                </div>
            </tab>
        </tabset>
    </div>
</div> -->


<ng-template #template tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>{{ modalTitle | translate }}</h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-4">
                        <label class="form-label">{{ 'Node Code' | translate }}</label>
                        <input type="text" placeholder="{{'Node Code' | translate }}" class="form-control"  value="{{selectedNode.data.name}}" [disabled]='true'>
                    </div>
                    <div class="col-2 mt-4">
                        <input class="form-check-input mt-4" type="checkbox" [(ngModel)]="enabled" (ngModelChange)="enable()">
                        <label class="form-check-label mt-3 p-1">{{ 'Enable' | translate }}</label>
                    </div>
                    <div class="col-6"  *ngIf="enabled && selectedNode.data.type=='process'">
                        <label class="form-label">{{ 'Assigned Profile' | translate }}</label>
                        <ng-select placeholder="{{ 'Assigned Profile' | translate }}" [(ngModel)]="userRole" (ngModelChange)="displayAssignedProfileValue(userRole.id.id, userRole.description,userRole); enable()">
                            <ng-option *ngFor="let profile of userProfiles" [value]="profile">
                                {{profile.name}}
                                <!-- {{displayAssignedProfileValue(profile.id.id, profile.description,profile)}} -->
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="isDisableSave" type="submit" class="btn btn-primary" (click)="save()">
                    {{'Save' | translate }}
                </button>
                <button type="button" class="btn btn-default" (click)="decline()">
                    {{ 'Close' |translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
<!-- Popup -->
<!-- <ng-template #template6>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ modalTitle | translate }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="decline()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class='col-md-12' *ngIf="selectedNode">
            <div class="row">
                <div class='col-md-4'>
                    <mat-form-field appearance="outline" class="wrapper-without-bottom">
                        <mat-label>{{ 'Node Code' | translate }}</mat-label>
                        <input matInput placeholder="{{ 'Name' | translate }}" value={{selectedNode.data.name}}
                            [readonly]='true'>
                    </mat-form-field>
                </div>
                <div class='col-md-2' style="align-self: center;">
                    <mat-checkbox color="primary" [(ngModel)]="enabled" (change)=enable()>{{ 'Enable' | translate }}
                    </mat-checkbox>
                </div>
                <div class='col-md-6' *ngIf="enabled && selectedNode.data.type=='process'">
                    <mat-form-field appearance="outline" class="wrapper-without-bottom">
                        <mat-label>{{ 'Assigned Profile' | translate }}</mat-label>
                        <mat-select placeholder="{{ 'Assigned Profile' | translate }}" [(value)]="selectedProfileId">
                            <mat-option *ngFor="let profile of userProfiles" [value]="profile.id.id"
                                matTooltip="profile.name">
                                {{displayAssignedProfileValue(profile.id.id, profile.description)}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="isDisableSave" class="btn btn-primary m-b-0" (click)="save()">
            {{ 'Save' | translate }}
        </button>
        <button type="button" class="btn btn-default m-b-0" (click)="decline()">{{ 'Close' | translate }}</button>
    </div>
</ng-template> -->
<ng-template #userSelectModal tabindex="-1" class="modal fade cds-modal" let-modal>
  <div class="modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" translate>
          Print Users
        </h5>

        <button type="button" class="btn-close" (click)="modal.dismiss();resetAllCheckboxes()"></button>
      </div>
      <div class="modal-body">
        <div class="col-12">
          <table aria-describedby="table-selectedUsersPermission"
                 class="table table-striped "

                 [mfData]="userNames | filter :'viewValue':searchText | roleFilter:[1, 5]"
                 #mf="mfDataTable" [mfRowsOnPage]="15">
            <thead>
            <tr>
              <th class="text-center">
                <input id="checkbox" class="form-check-input hidden"
                       type="checkbox"
                      (click)="toggleAllCheckboxes($event,userNames,true)"
                       [disabled]="isLoading"
                >
              </th>
              <th>
                <mfDefaultSorter by="description">Users List
                </mfDefaultSorter>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of mf.data let i = index">
              <td class="center-align text-nowrap" style="width:10%;text-align:center;" class="p-2">
                <input   id="{{user.viewValue}}" class="form-check-input" [(ngModel)]='selectedCheckboxes[user.viewValue]'
                       type="checkbox" (click)="utenteSelezionatoCheckbox($event,user,mf.data, false )" [disabled]="isLoading || isAllChecked"
                >
              </td>
              <td class="center-align">{{ user.viewValue }}
              </td>
            </tr>
            </tbody>
            <tfoot *ngIf="(s1userInfoArray.s1UserInfo| filter :'name,description':searchText).length > 15">
            <tr>
              <td colspan="10">
                <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
              </td>
            </tr>
            </tfoot>
          </table>
          <div class="alert cds-alert alert-danger" role="alert" *ngIf="!showAppLoader && userProfiles.length===0">
            <div class="cds-alert-icon">
              <i class="fa-solid fa-triangle-exclamation"></i>
            </div>
            <div class="cds-alert-content">{{ 'No records found.' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button  type="submit" class="btn btn-sm  btn-primary" [disabled]="isLoading" (click)="exportUserToXlsxFromModal();resetAllCheckboxes()">
          <span *ngIf="!isLoading">Print</span>
          <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
        <button type="button" class="btn btn-sm  btn-light m-b-0"
                (click)="modal.dismiss();resetAllCheckboxes()">
          Cancel

        </button>
      </div>
    </div>
  </div>
</ng-template>
