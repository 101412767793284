import { S1Buffer } from "./S1Buffer";
import { S1BufferContent } from "./S1BufferContent.enum";

export class S1Process {

  processXML: S1Buffer;
  
  constructor(xml: any) {
    this.processXML = new S1Buffer();
    this.processXML.contentType = S1BufferContent[0];
    this.processXML.data = xml;
  }
}