export enum LogTracerDescription {
    NONE = "None",
    CREATE_PD = "Process created",
    SAVE_PD = "Process saved",
    CHECKOUT_PD = "Check-out process",
    CHECKIN_PD = "Check-in process",
    UNDO_PD_CHECKOUT = "Check-out undone",
    DEPLOY_PD = "Process deployed",
    DELETE_PD = "Process deleted",
    REASSIGN_PD = "Process reassigned",
    CHECKOUT_PD_FROM_HISTORY = "Check-out from history",
    CREATE_PYTHON_MODEL = "Create Python Model",
    DROP_PYTHON_MODEL = "Drop Python Model",
    UNDO_PYTHON_PENDING = "Undo Python Pending",
    PROCESS_PERMISSION = "Process permissions changed",
    LOGIN = "Login",
    LOGOFF = "Logoff",
    USER_CREATE = "User created",
    USER_DELETE = "User deleted",
    USER_EDIT = "User updated",
    ORG_CREATE = "Organization created",
    ORG_EDIT = "Organization updated",
    ORG_DELETE = "Organization deleted",
    GROUP_CREATE = "Group created",
    GROUP_EDIT = "Group updated",
    GROUP_DELETE = "Group deleted",
    INSTALLATION_EDIT = "Installation options modified",
    GROUP_ADD_PERMISSION = "Group granted to user",
    ORG_ADD_PERMISSION = "Organization granted to user",
    TEMPLATE_ADD_PERMISSION = "Template granted to user",
    INSTALLATION_ADD_PERMISSION = "Installation granted to user",
    GROUP_REMOVE_PERMISSION = "Group revoked from user",
    ORG_REMOVE_PERMISSION = "Organization revoked from user",
    TEMPLATE_REMOVE_PERMISSION = "Template revoked from user",
    INSTALLATION_REMOVE_PERMISSION = "Installation revoked from user",
    EXECUTED_DEBUG = "Executed debug",
    NEW_WHATIF = "Created What-if",
    START_WHATIF = "Started What-if",
    STOP_WHATIF = "Stopped What-if",
    DELETE_WHATIF = "Deleted What-if",
    DESIGNER_PRINT_OBJ = "Designer - Printed Object",
    DESIGNER_PRINT_STRATEGY = "Designer - Printed Strategy",
    DESIGNER_PRINT_SEGMENTATION = "Designer - Printed Segmentation",
    DESIGNER_PRINT_PROCESS = "Designer - Printed Process",
    SERVER_STARTUP = "Server startup",
    SERVER_SHUTDOWN = "Server shutdown"
}
