import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from './confirm.component';

@Injectable()
export class ConfirmService {

    constructor(private modalService: NgbModal) { }

    public confirm(messageKey: string = 'common-ARE_YOU_SURE', titleKey: string = 'This action needs confimation :', acceptKey: string = 'YES', cancelKey: string = 'NO'): Observable<boolean> {
        let modalRef = this.modalService.open(ConfirmComponent, { size: 'sm', backdrop: 'static', keyboard: false });
        let component: ConfirmComponent = modalRef.componentInstance;
        component.bsModalRef = modalRef;
        component.messageKey = messageKey;
        component.titleKey = titleKey;
        component.acceptKey = acceptKey;
        component.cancelKey = cancelKey;
        return component.closed;
    }

}

