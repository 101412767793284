import { Component, OnInit, TemplateRef, HostListener, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItems, ChildrenItems } from 'app/shared/menu-items/menu-items';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import * as _ from "lodash";
import { StorageDetails } from 'app/core/services/user-details.service';
import { ProcessManagementService } from 'app/core/services/process-management.service';
import { TreeObject } from 'app/entities/treeNode.model';
import { S1ProcessID } from 'app/entities/s1-processid';
import { S1BacProcess } from 'app/entities/bac-process';
import { OperationsComponent } from '../../operations/operations.component';
import { RouteCheck } from 'app/core/guards/route-check.service';
import { SidebarComponent } from 'app/layout/components/sidebar/sidebar.component';
import { Data } from 'app/entities/data.model';
import { IOTypeValuePipe } from 'app/shared/custom-pipe/ioTypeFilter.pipe';
import { ProcessTypeValuePipe } from 'app/shared/custom-pipe/processTypeFilter.pipe';
import { S1UserInfo } from 'app/entities/s1-userInfo';
import { ProcessIOType } from 'app/entities/bac-processiotype.enum';
import { S1ProcessInfo } from 'app/entities/s1-processInfo';
import { S1ClientInfo } from 'app/entities/s1-clientinfo';
import { S1ProcessContainerID } from 'app/entities/S1ProcessContainerID';
import { CreateProcess, CreateProcessXML, S1Process2, S1ProcessXML } from 'app/entities/CreateProcessEvoRequest';
import { ProcessType } from 'app/entities/bac-processtype.enum';
import { SpringErrorPayload } from 'app/entities/SpringErrorPayload';
import { S1DeployEnv } from 'app/entities/S1DeployEnv';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService } from 'app/shared/alert/alert-service';
import { MissingAllType } from 'app/entities/MissingAllType.enum';

const MODAL_OPTIONS: NgbModalOptions = { size: 'lg', backdrop: 'static', keyboard: true, windowClass: 'modal-full-height modal-full-width' };

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit {

  files1: TreeObject[];

  open: boolean;
  userId: number;
  showLoginUserName: string;
  data: any;
  cols: any[];
  selectedProcess: any[];
  processHistoryData: Data[] = [];
  currentNode: TreeObject;
  processProp: any = [];
  processid: number;
  processID: S1ProcessID;
  text_buttonOk: string;
  modalRef: NgbModalRef;
  s1ProcessInfo: any;
  layoutVersion: number;
  lock: string;
  checkInComment: string;
  file: any;
  xml: string;
  selectedUser: any;
  users: S1UserInfo[];
  url: boolean;
  processObj: Array<ChildrenItems> = [];
  selectedRowNameDisplay: string;
  processIdObj: S1ProcessID;
  backup4selectedHistoryData: Data;
  isEnableHistoryOpen: boolean = false;
  isUnsafeHistoryClick: boolean = false;
  isEnableShowAppLoader: boolean = true;
  createProcForm: UntypedFormGroup;
  isCreateProcErr: boolean = false;
  isCreateProcShared: boolean;
  isCreateProcEncrypted: boolean;
  isCreateProcDisableMissing: boolean = false;
  sampleProcXML: string;
  pTypeArray: any;
  pIoTypeArray: any;
  pMissingTypeArray: any
  checkinForm: UntypedFormGroup;
  deployForm: UntypedFormGroup;
  deployEnvArray: S1DeployEnv[];
  text_createProc_success: string;
  text_createProc_fail: string;
  text_createProc_failExists: string;
  text_createProc_name: string;
  text_duplicateVal: string;
  text_nullError: string;
  text_checkinFailed: string;
  text_processAccessError: string;
  text_unexpectedProcessError: string;
  text_importSuccessful: string;
  text_importFailed: string;
  text_exportFailed: string;
  text_reassignSuccessful: string;
  text_reassignFailed: string;
  text_checkoutSuccessful: string;
  text_processLockedError: string;
  text_processNotLockedError: string;
  text_deploySuccessful: string;
  text_saveFailed: string;
  text_unexpectedProcessError2: string;
  text_yesValue: string;
  text_noValue: string;
  text_deployFailed: string;
  text_deploy_missingEnv: string;
  text_checkinSuccessful: string;
  text_undoCheckoutSuccessful: string;
  text_invalidTypeFormat: string;
  text_invalidLayoutFormat: string;
  text_invalidTemplateFormat: string;
  text_layoutVersion_required: string;
  text_layoutVersion_invalid: string;
  text_layoutVersion_nonzero: string;
  text_proc_invalid: string;
  text_proc_validationFailed: string;
  text_invalidEncryptionFormat: string;
  isCheckoutAction: boolean = false
  @ViewChild('templateHistory', { static: true }) templateRef: TemplateRef<any>;

  constructor(public nodeService: ProcessManagementService, public userDetails: StorageDetails
    , private translate: TranslateService, public menuItems: MenuItems, private router: Router, 
    private fb: UntypedFormBuilder,private modalService: NgbModal, private alert: AlertsService) {
    this.userId = this.userDetails.getUserId();
    this.showLoginUserName = this.userDetails.getLoginUserId();
    this.pTypeArray = [{ value: "APPLICATION", viewValue: "Application" }, { value: "BEHAVIORAL", viewValue: "Behavioral" }];
    this.pIoTypeArray = [{ value: ProcessIOType.XML, viewValue: 'XML' }, { value: ProcessIOType.FLAT, viewValue: 'Flat' }, { value: ProcessIOType.JSONEVO, viewValue: 'JSON Evolution' }, { value: ProcessIOType.XMLEVO, viewValue: 'XML Evolution' }];
    this.pMissingTypeArray = [{ value: 'Standard', viewValue: 'Standard' }, { value: 'Legacy', viewValue: 'Legacy' }]
    this.setCreateProcForm();
    this.translate.get([
      'Ok'
      , 'Process Id and Version Id is null'
      , 'Process Checkin failed!'
      , 'Process checked-in successfully!'
      , "The logged in user doesn't have access to save/edit process."
      , 'Unexpected error occurred. Please close process now.'
      , 'Process imported successfully!'
      , 'Process import failed!'
      , 'Process export failed!'
      , 'Process reassigned successfully!'
      , 'Process reassign failed!'
      , "Process checked-out successfully!"
      , "Undo check out executed successfully!"
      , "The process is already locked by other user."
      , "The process is not locked."
      , "Process deployed successfully!"
      , "Process deploy failed!"
      , "Process saved successfully!"
      , "Unexpected error occurred. Closing process now"
      , 'Yes'
      , 'No'
      , 'Invalid type format'
      , 'The source and the target processes have different type of layout'
      , 'The source and the target processes have different type of template'
      , 'Layout version is required'
      , 'Please provide valid Layout version in integer format'
      , 'Invalid process!'
      , 'Process validation failed'
      , "Process created successfully!"
      , "Encrypted processes are not supported from BAC"
      , "No environment available to deploy the process."
      , "Duplicate value"
      , "Process name already exists!"
      , "Please provide a valid process name."
      , "Layout version must be greater than 0."

    ]).subscribe((text: any) => {
      this.text_buttonOk = text['Ok'];
      this.text_nullError = text['Process Id and Version Id is null'];
      this.text_processAccessError = text["The logged in user doesn't have access to save/edit process."];
      this.text_processLockedError = text["The process is already locked by other user."]
      this.text_processNotLockedError = text["The process is not locked."];
      this.text_unexpectedProcessError = text['Unexpected error occurred. Please close process now.'];
      this.text_unexpectedProcessError2 = text["Unexpected error occurred. Closing process now"];
      this.text_checkinFailed = text['Process Checkin failed!'];
      this.text_checkinSuccessful = text['Process checked-in successfully!'];
      this.text_importSuccessful = text['Process imported successfully!'];
      this.text_importFailed = text['Process import failed!'];
      this.text_exportFailed = text['Process export failed!'];
      this.text_reassignSuccessful = text['Process reassigned successfully!'];
      this.text_reassignFailed = text['Process reassign failed!'];
      this.text_checkoutSuccessful = text["Process checked-out successfully!"];
      this.text_undoCheckoutSuccessful = text["Undo check out executed successfully!"];
      this.text_deploySuccessful = text["Process deployed successfully!"];
      this.text_deployFailed = text["Process deploy failed!"];
      this.text_saveFailed = text["Process saved successfully!"];
      this.text_yesValue = text['Yes'];
      this.text_noValue = text['No'];
      this.text_invalidTypeFormat = text['Invalid type format'];
      this.text_invalidLayoutFormat = text['The source and the target processes have different type of layout'];
      this.text_invalidTemplateFormat = text["The source and the target processes have different type of template"];
      this.text_layoutVersion_required = text['Layout version is required'];
      this.text_layoutVersion_invalid = text['Please provide valid Layout version in integer format'];
      this.text_layoutVersion_nonzero = text['Layout version must be greater than 0.'];
      this.text_proc_invalid = text['Invalid process!'];
      this.text_proc_validationFailed = text['Process validation failed'];
      this.text_createProc_success = text['Process created successfully!'];
      this.text_createProc_failExists = text["Process name already exists!"];
      this.text_createProc_name = text["Please provide a valid process name."];
      this.text_invalidEncryptionFormat = text["Encrypted processes are not supported from BAC"];
      this.text_deploy_missingEnv = text["No environment available to deploy the process."];
      this.text_duplicateVal = text["Duplicate value"]
    });
  }

  ngOnInit() {
    if (this.router.url === ("/process-management/process-list")) {
      this.isEnableShowAppLoader = false;
    } else {
      this.isEnableShowAppLoader = true;
    }

    if (!this.nodeService.currentProcessDetails) {
      this.router.navigate(['/process-management/process-list']);
    } else {
      if (this.nodeService.currentProcessDetails.s1ProcessInfo &&
        this.nodeService.currentProcessDetails.s1ProcessInfo.historic) {
        this.nodeService.selectedNode = null;
      } else {
        if (!this.nodeService.selectedNode) {
          this.nodeService.selectedNode = new TreeObject();
        }
        this.nodeService.selectedNode.data = this.nodeService.currentProcessDetails;
      }
    }
    this.url = false;

  }

  checkIfExist() {
    var bool = false;
    if (this.nodeService.currentProcessDetails && this.nodeService.getProcessID()) {
      if (ProcessManagementService.openProcessDetailsArray && ProcessManagementService.openProcessDetailsArray.length > 0) {
        for (let procDetail of ProcessManagementService.openProcessDetailsArray) {
          if (this.nodeService.currentProcessDetails.s1ProcessInfo.name === procDetail.s1ProcessInfo.name) {
            bool = true;
          }
        }
      }
    }
    return bool;
  }


  @HostListener('click', ['$event'])
  checkNotification($event: any) {
    if (null != OperationsComponent.finalElement) {
      this.openProcess();
    }
    OperationsComponent.finalElement = null;
  }

  beforeOpenProcess() {
    localStorage.setItem('whatIfIsEnabled', 'true')
    this.nodeService.showAppLoader = true;//stop loader in if/else inner calls
    this.nodeService.isHistoryProcess = false;
    if (OperationsComponent.url && OperationsComponent.selectedElement != this.nodeService.currentProcessName) {
      OperationsComponent.isCheckProc = true;
      new RouteCheck().canDeactivate(null);
      this.nodeService.showAppLoader = false;

    } else {
      this.openProcess();
      OperationsComponent.selectedElement = this.nodeService.currentProcessName;
    }
  }

  async openProcess(nodeService?:ProcessManagementService) {
    if (this.isUnsafeHistoryClick) {
      OperationsComponent.url = false;
      this.isUnsafeHistoryClick = false;
      this.openDialog(this.templateRef, true);
      this.nodeService.showAppLoader = false;
      console.log("openProcess() for historyClick.");
      return;
    }
    //nullify backup of proc data if any; cause openProcess() is called.
    if (this.backup4selectedHistoryData) {
      this.backup4selectedHistoryData = null;
    }

    if(this.isCheckoutAction){
      this.nodeService.currentProcessName = nodeService.currentProcessName;
      this.nodeService.currentProcessDetails = nodeService.currentProcessDetails;
      this.nodeService.isDisabled = nodeService.isDisabled
      this.nodeService.bacProcessNode.version = this.nodeService.currentProcessVersion
    }
    this.nodeService.sidebarProcName = this.nodeService.currentProcessName;
    this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'] = [];
    this.nodeService.isOpen = true;
    this.nodeService.openProcessNode = this.nodeService.currentProcessDetails.s1ProcessInfo;
    ProcessManagementService.openProcessDetailsArray.push(this.nodeService.currentProcessDetails);
    if (ProcessManagementService.openProcessDetailsArray.length > 0) {
      this.processObj = [];
      ProcessManagementService.openProcessDetailsArray.forEach(val => {
        this.nodeService.currentProcessVersion = val.s1ProcessInfo.revision;
        let subMenu = this.nodeService.addSubMenu(true, val.s1ProcessInfo.name);
        this.processObj.push(subMenu);
      });

      for (let index = 0; index < this.processObj.length - 1; index++) {
        const element = this.processObj[index];
        element.children = [];
      }

      this.processObj.forEach(ele => {
        this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'].push(ele);
      });
      var processID: S1ProcessID = this.isCheckoutAction? 
        {processID: nodeService.currentProcessDetails.id, versionID: null} : 
        this.nodeService.getProcessID();

      await this.nodeService.getSelectedProcessInfo(processID).then(value => {
        this.nodeService.setSelectedProcessData(this.nodeService.currentProcessDetails);
        SidebarComponent.showMenu = "Process Management";
        this.router.navigate(['/process-management/process']);
        setTimeout(() => this.nodeService.showAppLoader = false, 1000);
      }).catch(reason => {
        console.log(reason);
        this.nodeService.showAppLoader = false;
      });
    } else {
      this.nodeService.showAppLoader = false;
    }
    this.decline();
    this.selectedRowNameDisplay = null;
    this.isEnableHistoryOpen = false;
    this.isCheckoutAction = false
  }

  openDialog(template: TemplateRef<any>, flag: boolean) {
    this.processIdObj = this.nodeService.getProcessID();
    if (flag) {
      if (!OperationsComponent.url) {
        this.isEnableHistoryOpen = false;
        this.nodeService.showAppLoader = true;
        this.processHistoryData = [];
        this._openDialog_flag(template);
      } else {
        OperationsComponent.isCheckProc = true;
        new RouteCheck().canDeactivate(null);
        this.isUnsafeHistoryClick = true;
      }
    } else if (this.nodeService.currentProcessDetails
      && (this.nodeService.currentProcessDetails.s1ProcessInfo.historic
        || (this.nodeService.currentProcessDetails.s1ProcessInfo.lock !== 'LOCKEDBYME'))) {
      this.closeProcessWithoutSave();
    } else {
      this.createProcForm.reset()
      this.createProcForm.controls['pmissingType'].setValue(this.pMissingTypeArray[0].value)
      this.modalRef = this.modalService.open(template,MODAL_OPTIONS);
    }
  }

  private _openDialog_flag(template) {
    this.nodeService.docGetAllProcessVersionsForBac(this.processIdObj).subscribe(
      response => {

        if (ProcessManagementService.openProcessDetailsArray && ProcessManagementService.openProcessDetailsArray.length > 0) {
          this.processHistoryData = response;

          if (this.processHistoryData && this.processHistoryData.length > 0) {
            this.processHistoryData.forEach(processHistory => {
              if (this.nodeService.currentProcessDetails.s1ProcessInfo.revision > processHistory.s1ProcessInfo.revision) {
                processHistory.s1ProcessInfo.historic = true;
              }
            });
            //remove already opened histories from processHistoryData
            for (let procDetail of ProcessManagementService.openProcessDetailsArray) {
              const openedProcessName = procDetail.s1ProcessInfo.nameDisplay;
              this.processHistoryData = _.reject(this.processHistoryData, function (val) {
                return val.s1ProcessInfo.name + " (" + val.s1ProcessInfo.revision + ")" === openedProcessName;
              });
            }
          }
          //set display vars
          this.processHistoryData.forEach(historydata => {
            this.nodeService.setProcessData_displayVars(historydata);
          });
        }
        setTimeout(() => this.nodeService.showAppLoader = false, 1000);
        this.modalRef = this.modalService.open(template,MODAL_OPTIONS);
      });
  }

  openDialogDeploy(template: TemplateRef<any>) {
    this.nodeService.showAppLoader = true;
    this.nodeService.getEnvironmentList().subscribe({
      next: (res) => {
        if (res && res && res.length > 0) {
          this.deployEnvArray = res;
          this.setDeployForm();
          this.modalRef = this.modalService.open(template,MODAL_OPTIONS);
        } else {
          console.error(res);
          this.alert.error(this.text_deploy_missingEnv, this.text_deployFailed);
          this.decline();
        }
        this.nodeService.showAppLoader = false;
      },
      error: () => {
        this.nodeService.showAppLoader = false;
      }
    });
  }
  setDeployForm() {
    this.deployForm = this.fb.group({
      'fcEnv': ['', Validators.compose([Validators.required])]
    });
    const expressionFieldControl = this.deployForm.get('fcEnv');
    expressionFieldControl.setErrors({
      "fcEnv": true
    });
  }
  resetDeployControls() {
    const passwordPolicyControl = this.deployForm.get('fcEnv');
    passwordPolicyControl.setErrors(null);
  }
  deploy() {
    if (this.deployForm.invalid) return;

    this.nodeService.showAppLoader = true;
    let procId: S1ProcessID = this.nodeService.getProcessID();
    if (procId) {
      this.nodeService.showAppLoader = true;
      this.router.navigate(['/process-management/process']); //req as p-tree refresh bug
      let deployenvs: string[] = [];
      this.deployForm.value.fcEnv.forEach(val => {
        deployenvs.push(val);
      });
      this.nodeService.deployProcess(procId, deployenvs).subscribe(response => {
        let resultObj = response;
        if (resultObj['exitCode'] === 0) {
          let responseDeployRequest = response;
          this.nodeService.setProcessDeployed(procId).subscribe(res => {
            //do nothing. Response is S1ProcessInfo.
          });
          if (responseDeployRequest['exitCode'] === 0) {
            this.alert.success(this.text_deploySuccessful);
            this.removeProcessFromSideBarAfterCheckInOutUndo();
          } else {
            this.alert.error(responseDeployRequest['standardError'],this.text_deployFailed)
          }

        } else {
          this.alert.error(resultObj['standardError'],this.text_deployFailed)
        }
        this.nodeService.showAppLoader = false;
      });
    } else {
      this.nodeService.showAppLoader = false;
      this.nodeService.showErrorResposeMessage("ERROR", this.text_nullError);
    }
    this.decline();
  }
  closeProcessWithSave() {
    this.nodeService.showAppLoader = true;
    this.router.navigate(['/process-management/process']).then(value => { //req as p-tree refresh bug
      this.saveProcess().then(_value => {
        this.removeProcessFromSideBar();
        this.decline();
        this.nodeService.showAppLoader = false;
      }).catch(reason => {
        console.log(reason);
        this.removeProcessFromSideBar();
        this.decline();
        this.nodeService.showAppLoader = false;
      });
    });

  }
  closeProcessWithoutSave() {
    this.nodeService.showAppLoader = true;
    this.router.navigate(['/process-management/process']).then((value) => { //req as p-tree refresh bug
      this.removeProcessFromSideBar();
      this.decline();
      OperationsComponent.scrollenable = false;
      OperationsComponent.url = false;
      OperationsComponent.isCheckProc = false;
      this.nodeService.showAppLoader = false;
    });
  }

  /**
   * this process does not edit showAppLoader
   */
  private removeProcessFromSideBar() {
    ProcessManagementService.openProcessDetailsArray.forEach(val => {
      let name = val.s1ProcessInfo.name + " (" + val.s1ProcessInfo.revision + ")";
      if (this.nodeService.currentProcessName === name) {
        ProcessManagementService.openProcessDetailsArray.splice(ProcessManagementService.openProcessDetailsArray.indexOf(val), 1);
      }
    });

    if (this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'] && this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'].length > 0 && this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'].length === 1) {
      this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'] = [];
    } else if (this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'] && this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'].length > 0) {
      this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'].forEach(ele => {
        if (this.nodeService.currentProcessName === ele.name) {
          this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'].splice(this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'].indexOf(ele), 1);
        }
      });
    }

    this.freezeObject();
  }

  /**
   * this process does not edit showAppLoader
   */
  private freezeObject() {
    this.nodeService.currentProcessDetails = null;
    this.nodeService.currentProcessVerId = null;
    this.nodeService.currentProcessName = null;
    this.nodeService.isOpen = false;
    this.nodeService.openProcessNode = null;
    this.nodeService.selectedNode = null;
    this.nodeService.sidebarProcName = null;
    if (this.router.url !== ("/process-management/process-list")) {
      this.router.navigate(['/process-management/process-list']);
    } else {
      console.log("Re-route as already on process tree page.");
      this.router.navigate(['/process-management/process']);
      this.router.navigate(['/process-management/process-list']);
    }
  }

  /**
   * this process does not edit showAppLoader
   */
  private removeProcessFromSideBarAfterCheckInOutUndo() {
    this._removeProcessFromSideBarAfterCheckInOutUndo_procAllVer();
    var procName = this.nodeService.currentProcessName.split(" ");
    var loopBreak = true;
    if (this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children']
      && this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'].length === 1) {
      this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'] = [];
    } else if (this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children']
      && this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'].length > 1) {
      while (loopBreak) {
        loopBreak = false;
        for (var j = 0; j < this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'].length; j++) {
          let procMenu = this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'][j]["name"].split(" ");
          if (procName[0] === procMenu[0]) {
            this.menuItems.MENUITEMS[0]['main'][0]['children'][0]['children'].splice(j, 1);
            loopBreak = true;
            break;
          }
        }
      }
    }
    OperationsComponent.url = false;
    this.freezeObject();
  }
  private _removeProcessFromSideBarAfterCheckInOutUndo_procAllVer() {
    var procName = this.nodeService.currentProcessName.split(" ");
    var loopBreak = true;
    while (loopBreak) {
      loopBreak = false;
      for (var i = 0; i < ProcessManagementService.openProcessDetailsArray.length; i++) {
        if (procName[0] === ProcessManagementService.openProcessDetailsArray[i].s1ProcessInfo.name) {
          ProcessManagementService.openProcessDetailsArray.splice(i, 1);
          loopBreak = true;
          break;
        }
      }
    }
  }

  checkOut() {
    let procId = this.nodeService.getProcessID();
    if (procId) {
      this.nodeService.showAppLoader = true;
      this.router.navigate(['/process-management/process']); //req as p-tree refresh bug
      this.nodeService.docCheckOutProcess(procId).subscribe(response => {
        let resultObj = response;
        if (resultObj['processOut'] && resultObj['info']) {
          procId = response['info'].id;
          //////////////////////////////////////////CR180
          this.isCheckoutAction = true;
          let nodeService = Object.assign({}, this.nodeService);
          nodeService.openProcessNode.revision = response.info.revision;
          nodeService.currentProcessVersion = response.info.revision;
          nodeService.openProcessNode.nameDisplay = response.info.name+' ('+response.info.revision+')';
          nodeService.currentProcessName = response.info.name+' ('+response.info.revision+')';
          nodeService.isDisabled = false;
          nodeService.currentProcessDetails.s1ProcessInfo.nameDisplay = nodeService.openProcessNode.nameDisplay;
          nodeService.currentProcessDetails.s1ProcessInfo.revision = nodeService.openProcessNode.revision;
          nodeService.currentProcessDetails.s1ProcessInfo.lock = 'LOCKEDBYME';
          nodeService.currentProcessVerId= response.info.id.versionID;
          nodeService.currentProcessDetails.s1ProcessInfo.id.versionID = response.info.id.versionID;

          ////////////////////////////////////////////
          this.removeProcessFromSideBarAfterCheckInOutUndo();
          ////////////////////////////////////////////
          this.openProcess(nodeService);
          /////////////////////////////////////////
          this.alert.success(this.text_checkoutSuccessful);
        } else {
          if (resultObj.errorCode === 'PROCESS_ALREADY_LOCKED') {
            this.alert.error(this.text_processLockedError)
          }
        }
      });
    } else {
      this.nodeService.showErrorResposeMessage("ERROR", this.text_nullError);
    }
  }

  undoCheckOut() {
    let procId = this.nodeService.getProcessID();
    if (procId) {
      this.nodeService.showAppLoader = true;
      this.router.navigate(['/process-management/process']); //req as p-tree refresh bug
      this.nodeService.docUndoCheckOutProcess(procId).subscribe(response => {
        let resultObj = response;
        if (resultObj['id'] && resultObj['name'] && resultObj['lock']) {
          procId = response.id;
          this.removeProcessFromSideBarAfterCheckInOutUndo();
          this.decline();
          this.alert.success(this.text_undoCheckoutSuccessful)
        } else {
          if (resultObj.errorCode === 'PROCESS_NOT_LOCKED') {
            this.alert.error(this.text_processNotLockedError)
          }
        }
        this.nodeService.showAppLoader = false;
      });
    } else {
      this.nodeService.showAppLoader = false;
      this.nodeService.showErrorResposeMessage("ERROR", this.text_nullError);
    }
  }

  async openDialogCheckIn(template: TemplateRef<any>) {
    this.checkInComment = "";
    this.layoutVersion = this.nodeService.currentProcessDetails.s1ProcessInfo.layoutRevision;
    if (this.layoutVersion !== null) {
      let layoutv: number;
      await this._setCheckinForm_setLayout().then(value => { layoutv = value; });
      this.setCheckinForm(layoutv);
      this.modalRef = this.modalService.open(template,MODAL_OPTIONS);
    }
  }
  setCheckinForm(layoutv: number) {
    this.checkinForm = this.fb.group({
      'layoutVersionField': [layoutv
        , Validators.compose([
          Validators.required,
          Validators.pattern("^[1-9]\\d*$")
        ])]
    });
    const expressionFieldControl = this.checkinForm.get('layoutVersionField');
    expressionFieldControl.setErrors({
      "layoutVersionField": true
    });
  }

  private _setCheckinForm_setLayout(): Promise<number> {
    return new Promise((resolve) => {
      if (this.layoutVersion === 0) { //first checkin
        resolve(1);
      } else {
        let procId: S1ProcessID = this.nodeService.getProcessID();
        this.nodeService.getProcessHistory(procId).subscribe({
          next: response => {
            let s1procinfo = response;
            let oldProcId: S1ProcessID = s1procinfo[s1procinfo.length - 1].id;
            this.nodeService.compareLayout(oldProcId, procId).subscribe({
              next: resp => {
                //true means layout changed so increase layout; false or err means keep same layout
                if (resp['changed'] === true) {
                  this.nodeService.getMaxLayoutVersion(procId).subscribe({
                    next: innerResp => { resolve(Number.parseInt(innerResp) + 1); },
                    error: _innerErr => { resolve(this.layoutVersion + 1); }
                  });
                } else { resolve(this.layoutVersion); }
              }, error: _err => { resolve(this.layoutVersion); }
            });
          }, error: _err => { resolve(this.layoutVersion); }
        });
      }
    });
  }
  checkIn() {
    if (this.checkinForm.invalid) {
      return;
    }
    let processInfo = this.nodeService.bacProcessNode;
    processInfo.version = this.nodeService.currentProcessDetails.s1ProcessInfo.revision;
    processInfo.layoutVersion = this.checkinForm.controls['layoutVersionField'].value;
    let procId: S1ProcessID = this.nodeService.getProcessID();
    //start TableWork
    if (processInfo && processInfo.tables && processInfo.tables.table) {
      this.combineInputOutput(processInfo.tables.table);
    }
    //end Tablework
    if (procId) {
      this.nodeService.showAppLoader = true;
      this.router.navigate(['/process-management/process']); //req as p-tree refresh bug
      this.nodeService.saveProcessBac(procId, processInfo, true).subscribe(saveResponse => {
        this._checkin_api(saveResponse, procId, processInfo.layoutVersion);
      });

    } else {
      this.nodeService.showErrorResposeMessage("ERROR", this.text_nullError);
    }
    this.decline();
  }
  private _checkin_api(saveResponse, procId: S1ProcessID, layoutv: number) {
    if (saveResponse['id'] && saveResponse['lock']) {
      this.nodeService.docCheckInProcess(procId, this.checkInComment, layoutv).subscribe(response => {
        let resultObj = response;
        if (resultObj['id'] && resultObj['lock'] && resultObj['lock'] === "UNLOCKED") {
          this.alert.success(this.text_checkinSuccessful);
          this.nodeService.showAppLoader = false;
          this.removeProcessFromSideBarAfterCheckInOutUndo();
        } else {
          this.alert.error(this.text_checkinFailed);
          this.nodeService.showAppLoader = false;
        }
      });
    } else {
      if (saveResponse.errorCode === 'PROCESS_NOT_LOCKED') {
        this.alert.error(this.text_processAccessError);
        this.nodeService.showAppLoader = false;
      } else {
        this.alert.error(saveResponse.description,this.text_unexpectedProcessError);
        this.nodeService.showAppLoader = false;
        this.removeProcessFromSideBarAfterCheckInOutUndo();
      }
    }
  }

  decline(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }
    this.setCreateProcForm();
  }

  fileChanged(e) {
    this.file = e.target.files[0];
  }
  uploadFile(event: any) {
    let fileReader = new FileReader();
    fileReader.readAsText(this.file);
    fileReader.onload = async (e) => {
      this.xml = fileReader.result as string;
      //step - discard encrypt
      if (this.xml.startsWith("S1|XXX|00001|AES|K1|") || this.xml.startsWith("S1|XXX|00001|AES|K2|")) {
        this.nodeService.showErrorResposeMessage("ERROR", this.text_invalidEncryptionFormat);
        this.decline();
        this.xml = null;
        return;
      }
      this.nodeService.showAppLoader = true;
      //step - xsd based validation
      await this.uploadHelper_validateWithXsd().then(value => {
        //step - TODO rollbackPythonModels (even if failed, go for import. Ask Davide if any plans here?)
        //step - actual import
        if (value) this.uploadHelper_updateXml();
        else {
          this.nodeService.showErrorResposeMessage("ERROR", this.text_proc_invalid);
          this.decline();
          this.xml = null;
        }
      }).catch(reason => {
        console.log(reason);
        this.nodeService.showErrorResposeMessage("ERROR", reason);
        this.decline();
        this.xml = null;
      });
      this.nodeService.showAppLoader = false;
      console.log("uploadFile fn ends.");

    }
  }

  private uploadHelper_validateWithXsd(): Promise<boolean> {
    let isValid: boolean = true;
    let xsdVersion: number;
    //upto s1 v4
    if (this.xml.includes("http://www.crif.com/strategyone/v1"))
      xsdVersion = 4;
    //s1 v5
    if (this.xml.includes("http://www.crif.com/s1/xsd/v2"))
      xsdVersion = 5;
    //s1 v6
    if (this.xml.includes("http://www.crif.com/s1/xsd/v6"))
      xsdVersion = 6;
    //s1 v7
    if (this.xml.includes("http://www.crif.com/s1/xsd/v7"))
      xsdVersion = 7;
    if (xsdVersion === undefined) {
      console.log("Invalid xsd schema url. -"
        + this.xml.substring(this.xml.indexOf("xmlns="), this.xml.indexOf("xmlns=") + 40));
      isValid = false;
    }

    if (!isValid) {
      return Promise.resolve(false);
    } else {
      return new Promise((resolve, reject) => {
        this.nodeService.validateProcessWithXSD(xsdVersion, this.xml).subscribe({
          next: (res) => {
            if (res) resolve(true);
            else resolve(false);
          },
          error: (err) => {
            console.log(err);
            let s1err: SpringErrorPayload = err['error']
            reject(s1err.exception);
          }
        })
      });
    }
  }
  private uploadHelper_updateXml(): void {
    //replace tags in imported xml for current version compatibility and for current process compatibility
    //xsd url.
    this.xml = this.xml.replace("http://www.crif.com/s1/xsd/v6", "http://www.crif.com/s1/xsd/v7");
    this.xml = this.xml.replace("http://www.crif.com/s1/xsd/v5", "http://www.crif.com/s1/xsd/v7");
    this.xml = this.xml.replace("http://www.crif.com/s1/xsd/v4", "http://www.crif.com/s1/xsd/v7");
    //update ID.
    this.xml = this.xml
      .replace(this.xml.substring(this.xml.indexOf("<ID>"), this.xml.indexOf("</ID>")) + "</ID>"
        , "<ID>" + this.nodeService.currentProcessName.split(" ")[0] + "</ID>");

    //StrategyOneVersion
    let s1clientinfo: S1ClientInfo = JSON.parse(this.userDetails.getClientInfo());
    this.xml = this.xml.replace(
      this.xml.substring(this.xml.indexOf("<StrategyOneVersion>"), this.xml.indexOf("</StrategyOneVersion>"))
      + "</StrategyOneVersion>"
      , "<StrategyOneVersion>" + s1clientinfo.clientVersion
      + "</StrategyOneVersion>");
    //Version
    this.xml = this.xml.replace(
      this.xml.substring(this.xml.indexOf("<Version>"), this.xml.indexOf("</Version>"))
      + "</Version>"
      , "<Version>" + this.nodeService.currentProcessDetails.s1ProcessInfo.revision
      + "</Version>");
    //LayoutVersion
    this.xml = this.xml.replace(
      this.xml.substring(this.xml.indexOf("<LayoutVersion>"), this.xml.indexOf("</LayoutVersion>"))
      + "</LayoutVersion>"
      , "<LayoutVersion>" + this.nodeService.currentProcessDetails.s1ProcessInfo.layoutRevision
      + "</LayoutVersion>");
    //Template - not allowed if mismatch
    if (this.xml.substring(this.xml.indexOf("<Template>") + 10, this.xml.indexOf("<Template>") + 14).toLowerCase() === 'true') {
      this.nodeService.showErrorResposeMessage("ERROR", this.text_invalidTemplateFormat + "!");
      this.decline();
      this.xml = null;
      return;
    }
    //Reporting - stays as it is.
    //Shared - stays as it is.
    //TFSSettings - im fully removing. but designer throws error if url, folder value present and imports if url, folder are empty.
    if (this.xml.includes("<TFSSettings>")) {
      this.xml = this.xml.replace(
        this.xml.substring(this.xml.indexOf("<TFSSettings>"), this.xml.indexOf("</TFSSettings>"))
        + "</TFSSettings>"
        , "");
    }
    //Signature - fully remove
    if (this.xml.includes("<Signature")) {
      this.xml = this.xml.replace(
        this.xml.substring(this.xml.indexOf("<Signature"), this.xml.indexOf("</Signature>"))
        + "</Signature>"
        , "");
    }
    //References ? - TODO. Elena will explain.
    this.uploadHelper_api();
  }
  private uploadHelper_api() {
    if (
      (new ProcessTypeValuePipe()
        .transform(this.xml.substring(this.xml.indexOf("<Type>") + 6, this.xml.indexOf("</Type>")).toUpperCase())
        === this.nodeService.processType.toUpperCase())
      &&
      (
        (new IOTypeValuePipe().transform(this.nodeService.ioType.toUpperCase()) === "XML"
          && (this.xml.substring(this.xml.indexOf("<IOType>") + 8, this.xml.indexOf("</IOType>")).toUpperCase() === "XML"
            || this.xml.substring(this.xml.indexOf("<IOType>") + 8, this.xml.indexOf("</IOType>")).toUpperCase() === "FLAT"
            || this.xml.substring(this.xml.indexOf("<IOType>") + 8, this.xml.indexOf("</IOType>")).toUpperCase() === "COBOL"))
        ||
        ((new IOTypeValuePipe().transform(this.nodeService.ioType.toUpperCase()) === "FLAT"
          || new IOTypeValuePipe().transform(this.nodeService.ioType.toUpperCase()) === "COBOL")
          && (this.xml.substring(this.xml.indexOf("<IOType>") + 8, this.xml.indexOf("</IOType>")).toUpperCase() === "FLAT"
            || this.xml.substring(this.xml.indexOf("<IOType>") + 8, this.xml.indexOf("</IOType>")).toUpperCase() === "COBOL"))
        ||
        ((new IOTypeValuePipe().transform(this.nodeService.ioType.toUpperCase()) === "XML-EVO"
          || new IOTypeValuePipe().transform(this.nodeService.ioType.toUpperCase()) === "JSON-EVO")
          && (this.xml.substring(this.xml.indexOf("<IOType>") + 8, this.xml.indexOf("</IOType>")).toUpperCase() === "XML-EVO"
            || this.xml.substring(this.xml.indexOf("<IOType>") + 8, this.xml.indexOf("</IOType>")).toUpperCase() === "JSON-EVO"))

      )
    ) {
      this.importTheProcess();
    } else {
      this.nodeService.showErrorResposeMessage("ERROR", this.text_invalidLayoutFormat + "!");
      this.decline();
      this.xml = null;
    }
  }
  private importTheProcess() {
    let procId = this.nodeService.getProcessID();
    if (procId) {
      this.nodeService.showAppLoader = true;
      this.router.navigate(['/process-management/process']); //req as p-tree refresh bug
      this.nodeService.saveProcess(procId, this.xml).subscribe(response => {
        let resultObj: S1ProcessInfo = response;
        if (resultObj !== undefined) {
          this.alert.success(this.text_importSuccessful);
          this.nodeService.showAppLoader = false;
          this.removeProcessFromSideBarAfterCheckInOutUndo();
        } else {
          this.alert.error('empty import response',this.text_importFailed);
          this.nodeService.showAppLoader = false;
        }
      });
    } else {
      this.nodeService.showErrorResposeMessage("ERROR", this.text_nullError);
    }
    this.decline();
  }

  callSaveProcess() {
    this.nodeService.showAppLoader = true;
    this.router.navigate(['/process-management/process']); //req as p-tree refresh bug
    this.saveProcess().then(value => {
      this.nodeService.getSelectedProcessInfo(this.processIdObj).then(_innervalue => {
        this.nodeService.showAppLoader = false;
        localStorage.setItem('whatIfIsEnabled', 'true')
      }).catch(reason => {
        console.log(reason);
        this.nodeService.showAppLoader = false;
      });
    }).catch(reason => {
      console.log(reason);
      this.nodeService.showAppLoader = false;
    });
  }


  combineInputOutput(processTables) {

    processTables.forEach(element => {
      let crrInput = null != element.inputVariables ? element.inputVariables.variable : [];
      let crrOutput = null != element.outputValues ? element.outputValues.output.filter(outele => outele.name !== undefined) : [];
      let maxNumber = [];
      let valueKeyMap = [];

      crrOutput.forEach(outelement => {
        let trck: number = 0;
        let nameArr = [];
        outelement.name.split(".").forEach(name => {

          valueKeyMap[trck] = _combineInputOutput_set_valueKeyMap(valueKeyMap, trck);
          
          let inName: string = _combineInputOutput_set_inName(name);

          if (crrInput[trck] && crrInput[trck].values && crrInput[trck].values.valueState) {
            crrInput[trck].values.valueState.forEach(inputData => {
              maxNumber[trck] = _combineInputOutput_set_maxNumber(maxNumber, trck);
              if (inputData["code"] === name) {
                this._combineInputOutput_set_innerCrrInputValues(inputData, valueKeyMap, maxNumber, trck, inName, nameArr);
              }
            });
          }
          trck++;
        });
        outelement.key = _combineInputOutput_set_outElementKey(outelement, nameArr);
        outelement.name = nameArr.join(".");
      });

      this.removeDuplicateAndSetToInput(crrInput);
    });
  }

  private _combineInputOutput_set_innerCrrInputValues(inputData, valueKeyMap, maxNumber, trck: number, inName: string, nameArr) {
    if (inputData.status === 'M' || inputData.status === 'N' || inputData.status === 'A') {
      if (valueKeyMap[trck][inputData.status]) {
        inputData["code"] = valueKeyMap[trck][inputData.status];
      } else {
        inputData["code"] = inName + maxNumber[trck];
        valueKeyMap[trck][inputData.status] = inName + maxNumber[trck];
        maxNumber[trck] = maxNumber[trck] + 1;
      }
      nameArr.push(valueKeyMap[trck][inputData.status]);
    } else if (inputData["value"] === null || inputData["else"] == "") {
      if (valueKeyMap[trck]["Else"]) {
        inputData["code"] = valueKeyMap[trck]["Else"];
      } else {
        inputData["code"] = inName + maxNumber[trck];
        valueKeyMap[trck]["Else"] = inName + maxNumber[trck];
        maxNumber[trck] = maxNumber[trck] + 1;
      }
      nameArr.push(valueKeyMap[trck]["Else"]);
    } else {
      if (valueKeyMap[trck][inputData["value"]["value"]]) {
        inputData["code"] = valueKeyMap[trck][inputData["value"]["value"]];
      } else {
        inputData["code"] = inName + maxNumber[trck];
        valueKeyMap[trck][inputData["value"]["value"]] = inName + maxNumber[trck];
        maxNumber[trck] = maxNumber[trck] + 1;
      }
      nameArr.push(valueKeyMap[trck][inputData["value"]["value"]]);
    }
  }


  private removeDuplicateAndSetToInput(crrInput) {
    crrInput.forEach(element => {
      let input = [];
      let found = [];
      element.values.valueState.forEach(inelement => {
        if (found.indexOf(inelement["code"]) < 0) {
          input.push(inelement);
          found.push(inelement["code"]);
        }
      });
      element.values.valueState = input;
    });
    crrInput.forEach(variable => {
      let trkIndex = 0;
      variable.values.valueState.forEach(vState => {
        vState["index"] = trkIndex;
        trkIndex++;
      })
    });
  }
  /**
   * this process does not edit showAppLoader
   */
  private saveProcess() {
    this.url = false;
    var bacprocess: S1BacProcess = this.nodeService.bacProcessNode;
    //start TableWork
    console.log("--pre table");
    if (bacprocess && bacprocess.tables && bacprocess.tables.table) {
      this.combineInputOutput(bacprocess.tables.table);
    }
    console.log("--post table");
    //end TableWork

    if (this.processIdObj) {
      return this.saveProcessInfo(bacprocess);
    } else {
      this.processIdObj = this.nodeService.getProcessID();
      if (this.processIdObj) {
        return this.saveProcessInfo(bacprocess);
      } else {
        this.nodeService.showErrorResposeMessage("ERROR", this.text_nullError);
        return null
      }
    }
  }

  /**
   * this process does not edit showAppLoader
   */
  private saveProcessInfo(bacprocess: S1BacProcess) {
    bacprocess.version = this.nodeService.currentProcessDetails.s1ProcessInfo.revision;
    bacprocess.layoutVersion = this.nodeService.currentProcessDetails.s1ProcessInfo.layoutRevision;
    //saveProc does not preserve layout version cause undocheckout should rollback it. Hence sending false in below API call.
    return this.nodeService.saveProcessBac(this.processIdObj, bacprocess, false).toPromise()
      .then((response) => {
        let resultObj = response;
        if (resultObj['id'] && resultObj['name'] && resultObj['lock']) {
          this.nodeService.isOpen = false;
          OperationsComponent.scrollenable = false;
          OperationsComponent.url = false;
          OperationsComponent.isCheckProc = false;
          this.isUnsafeHistoryClick = false;
          this.alert.success(this.text_saveFailed);
        } else {
          if (resultObj.errorCode === 'PROCESS_NOT_LOCKED') {
            this.alert.error(this.text_processAccessError);
          } else {
            this.alert.error(this.text_unexpectedProcessError2)
            this.removeProcessFromSideBarAfterCheckInOutUndo();
          }
        }
      });
  }

  isOpenDisabled(): boolean {
    let isopendisable = true;
    if (!this.nodeService.showAppLoader) {
      //1st node selection check
      if (ProcessManagementService.openProcessDetailsArray.length === 0) {
        if (this.nodeService.currentProcessDetails) {
          isopendisable = false;
        }
      } else {
        //below condition req after freezeObject(); else button is clickable
        if (this.nodeService.currentProcessDetails) {
          isopendisable = this.checkIfExist();
        }
      }
    }
    return isopendisable;
  }

  isSaveCheckinUndoDisable() {
    if (this.checkIfExist()
      && !this.nodeService.currentProcessDetails.s1ProcessInfo.historic
      && this.nodeService.currentProcessDetails.s1ProcessInfo.lock === 'LOCKEDBYME'
      && (this.userDetails.getUserRole() !== "USER"
        || (this.userDetails.getUserRole() === "USER"
          && this.nodeService.currentProcessDetails
          && this.nodeService.currentProcessDetails.permission
          && this.nodeService.currentProcessDetails.permission
            .filter(val => val.permission === "CHCKIN_CHECKOUT" && val.level === "LEVEL_EXEC").length === 1)
      )) {
      return false;
    } else {
      return true;
    }
  }

  isCheckoutDisabled() {
    if (this.checkIfExist()
      && !this.nodeService.currentProcessDetails.s1ProcessInfo.historic
      && this.nodeService.currentProcessDetails.s1ProcessInfo.lock === 'UNLOCKED'
      && (this.userDetails.getUserRole() !== "USER"
        || (this.userDetails.getUserRole() === "USER"
          && this.nodeService.currentProcessDetails
          && this.nodeService.currentProcessDetails.permission
          && this.nodeService.currentProcessDetails.permission
            .filter(val => val.permission === "CHCKIN_CHECKOUT" && val.level === "LEVEL_EXEC").length === 1)
      )) {
      return false;
    } else {
      return true;
    }
  }

  isReassignDisable() {
    if (this.checkIfExist()
      && !this.nodeService.currentProcessDetails.s1ProcessInfo.historic
      && this.nodeService.currentProcessDetails.s1ProcessInfo.lock === 'LOCKEDBYOTHER'
      && this.userDetails.getUserRole() !== "USER") {
      return false;
    } else {
      return true;
    }
  }

  checkURLForDisable() {
    if (this.router.url !== ("/process-management/process")) {
      return true;
    } else {
      return false;
    }
  }
  isImportDisable(): boolean {
    if (this.userDetails.getUserRole() !== "USER"
      || (this.userDetails.getUserRole() === "USER"
        && this.nodeService.currentProcessDetails
        && this.nodeService.currentProcessDetails.permission
        && this.nodeService.currentProcessDetails.permission
          .filter(val => val.permission === "IMPORT_EXPORT" && val.level === "LEVEL_EXEC").length === 1)
    ) {
      return this.isSaveCheckinUndoDisable();
    } else {
      return true;
    }
  }
  isExportDisable(): boolean {
    if (this.checkIfExist()
      && (this.userDetails.getUserRole() !== "USER"
        || (this.userDetails.getUserRole() === "USER"
          && this.nodeService.currentProcessDetails
          && this.nodeService.currentProcessDetails.permission
          && this.nodeService.currentProcessDetails.permission
            .filter(val => val.permission === "IMPORT_EXPORT" && val.level === "LEVEL_EXEC").length === 1)
      )) {
      return false;
    } else {
      return true;
    }
  }
  isDeployDisable(): boolean {
    if (this.checkIfExist()
      && (this.nodeService.currentProcessDetails.s1ProcessInfo.historic
        || this.nodeService.currentProcessDetails.s1ProcessInfo.lock === 'UNLOCKED')
      && (this.userDetails.getUserRole() !== "USER"
        || (this.userDetails.getUserRole() === "USER"
          && this.nodeService.currentProcessDetails
          && this.nodeService.currentProcessDetails.permission
          && this.nodeService.currentProcessDetails.permission
            .filter(val => val.permission === "DEPLOY" && val.level === "LEVEL_EXEC").length === 1)
      )) {
      return false;
    } else {
      return true;
    }
  }

  isHistoryDisable(): boolean {
    if (this.checkIfExist()
      && !this.nodeService.currentProcessDetails.s1ProcessInfo.historic
      && (this.userDetails.getUserRole() !== "USER"
        || (this.userDetails.getUserRole() === "USER"
          && this.nodeService.currentProcessDetails
          && this.nodeService.currentProcessDetails.permission
          && this.nodeService.currentProcessDetails.permission
            .filter(val => val.permission === "HISTORY" && val.level === "LEVEL_READ").length === 1)
      )) {
      return false;
    } else {
      return true;
    }
  }

  docExportProcess() {
    let procId = this.nodeService.getProcessID();
    if (procId) {
      this.nodeService.showAppLoader = true;
      this.nodeService.docExportProcess(procId).subscribe(response => {
        let resultObj = response;
        if (resultObj['processName']) {
          const blob = new Blob([response['processXml']], { type: 'application/xml' });
          blob.arrayBuffer().then(value => {
            this.xml = new TextDecoder().decode(value);
            this.xml = this.xml.replace(
              this.xml.substring(this.xml.indexOf("<Version>"), this.xml.indexOf("</Version>"))
              , "<Version>" + this.nodeService.currentProcessDetails.s1ProcessInfo.revision);
            this.xml = this.xml.replace(
              this.xml.substring(this.xml.indexOf("<LayoutVersion>"), this.xml.indexOf("</LayoutVersion>"))
              , "<LayoutVersion>" + this.nodeService.currentProcessDetails.s1ProcessInfo.layoutRevision);
            if (this.nodeService.currentProcessDetails.s1ProcessInfo.layoutRevision === 0) {
              this.xml = this.xml.replace(
                this.xml.substring(this.xml.indexOf("<LayoutVersion>"), this.xml.indexOf("</LayoutVersion>"))
                , "<LayoutVersion>1");
            }
            const updatedblob = new Blob([this.xml], { type: 'application/xml' });
            let filename4saveAs: string = response['processName'] + '.xml';
            saveAs(updatedblob, filename4saveAs);
          });
        } else {
          this.nodeService.showErrorResposeMessage(this.text_exportFailed, resultObj.description);
        }
        this.nodeService.showAppLoader = false;
      });
    } else {
      this.nodeService.showErrorResposeMessage(this.text_exportFailed, this.text_nullError);
    }
  }

  openDialogReassignProcess(template: TemplateRef<any>) {
    let procId = this.nodeService.getProcessID();
    if (procId) {
      this.nodeService.getAssignableUsers(procId).subscribe(response => {
        this.users = response['s1UserInfo'];
        if (this.users.length > 0) {
          this.selectedUser = this.users[0];
          this.modalRef = this.modalService.open(template,MODAL_OPTIONS);
        }
      });
    } else {
      this.nodeService.showErrorResposeMessage("ERROR", this.text_nullError);
    }
  }

  openModal(template: TemplateRef<any>){
    //this.modalRef = this.modalService2.open(template, MODAL_OPTIONS);
  }
  
  reassign() {
    let procId = this.nodeService.getProcessID();
    if (procId) {
      this.nodeService.showAppLoader = true;
      this.router.navigate(['/process-management/process']); //req as p-tree refresh bug
      this.nodeService.docReassignProcessToUser(procId, this.users.find(user => user.loginCredentials.userId==this.selectedUser).id ).subscribe(response => {
        let resultObj = response;

        if (resultObj['name'] && resultObj['id'] && resultObj['lock']) {
          this.removeProcessFromSideBarAfterCheckInOutUndo();
          this.alert.success(this.text_reassignSuccessful);
        } else {
          this.alert.error(this.text_reassignFailed);
        }
        this.decline();
      });
    } else {
      this.nodeService.showErrorResposeMessage("ERROR", this.text_nullError);
    }
  }

  setHistoryData(index: Data) {
    if (!this.nodeService.showAppLoader) {
      if (this.backup4selectedHistoryData) {
        this.nodeService.setSelectedProcessData(this.backup4selectedHistoryData);
        this.backup4selectedHistoryData = null;
        this.selectedRowNameDisplay = null;
      }
      this.isEnableHistoryOpen = true;
      this.selectedRowNameDisplay = index.s1ProcessInfo.nameDisplay;
      this.backup4selectedHistoryData = this.nodeService.currentProcessDetails;
      this.nodeService.setSelectedProcessData(index);
    }
  }

  unsetHistoryData() {
    this.isEnableHistoryOpen = false;
    if (this.backup4selectedHistoryData) {
      this.nodeService.setSelectedProcessData(this.backup4selectedHistoryData);
      this.backup4selectedHistoryData = null;
      this.selectedRowNameDisplay = null;
    }
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  @HostListener('document:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.url) {
      $event.returnValue = true;
    }
  }

  beforeOpenHistoryProcess() {
    this.nodeService.isHistoryProcess = true;
    this.openProcess();
  }

  createProcess() {
    if (this.createProcForm.invalid) {
      return;
    }
    this.nodeService.showAppLoader = true;
    let s1procinfo: S1ProcessInfo = new S1ProcessInfo();
    s1procinfo.name = this.createProcForm.value.pname;
    s1procinfo.lock = "NONE"; //LockType[LockType.NONE]
    s1procinfo.comment = this.createProcForm.value.pcomment;
    s1procinfo.containerId = new S1ProcessContainerID();
    s1procinfo.containerId.id = this.nodeService.selectedNode.data.id;
    s1procinfo.revision = 0;
    s1procinfo.layoutRevision = 0;
    s1procinfo.deployedTimes = 0;
    s1procinfo.revisionDeployed = false;
    if (this.createProcForm.value.pprocessType === "APPLICATION") s1procinfo.processType = ProcessType.APPLICATION;
    else s1procinfo.processType = ProcessType.BEHAVIORAL;
    if (this.createProcForm.value.pioType === ProcessIOType.XML) s1procinfo.ioType = "1";
    else if (this.createProcForm.value.pioType === ProcessIOType.FLAT) s1procinfo.ioType = "0";
    else if (this.createProcForm.value.pioType === ProcessIOType.XMLEVO) s1procinfo.ioType = "2";
    else if (this.createProcForm.value.pioType === ProcessIOType.JSONEVO) s1procinfo.ioType = "3";
    s1procinfo.archetype = this.isCreateProcShared ? "SHARED" : "PROCESS";
    s1procinfo.archived = false;
    let clientinfo: S1ClientInfo = JSON.parse(this.userDetails.getClientInfo());
    s1procinfo.clientVersion = clientinfo.clientVersion;
    this._createProcess_api(s1procinfo);
  }
  private _createProcess_api(s1procinfo: S1ProcessInfo) {
    let req: CreateProcess = new CreateProcess();
    req.process = new S1Process2()
    req.process.processXML = new S1ProcessXML()
    req.processInfo = s1procinfo;
    req.comment = s1procinfo.comment;
   // req.shared = this.isCreateProcShared;
   // req.description = s1procinfo.comment;
    let missingAllType;
    if (this.createProcForm.value.pioType === ProcessIOType.XMLEVO || this.createProcForm.value.pioType === ProcessIOType.JSONEVO) {
      missingAllType = this.createProcForm.value.pmissingType === 'Standard' ? MissingAllType.V_7 : MissingAllType.V_6;
    } else {
    missingAllType = MissingAllType.V_7;
    }
   // req.encrypted = this.isCreateProcEncrypted;
   let createRequestXml:CreateProcessXML ={
     name: s1procinfo.name,
     archetype: s1procinfo.archetype,
     shared: this.isCreateProcShared,
     description: s1procinfo.comment,
     missingAllType: missingAllType,
     encrypted: this.createProcForm.controls['pencrypted'].value,
     processType: s1procinfo.processType,
     clientVersion: s1procinfo.clientVersion,
     iotype: s1procinfo.ioType
   };
   this.nodeService.createXml(createRequestXml).subscribe(res=>{
    if(res){
      req.process.processXML.contentType = res.process.processXML.contentType;
      req.process.processXML.data = res.process.processXML.data;
      console.log('request', req)
      this.nodeService.createProcess(req).subscribe({
        next: resObj => {
          if (resObj['id'] && resObj['name']) {
            console.log("proc done.");
            this.alert.success(this.text_createProc_success);
            this.decline();
            this.router.navigate(['/process-management/process']);
          } else {
            this.isCreateProcErr = true;
            this.text_createProc_fail = "500 Internal Server Error";
            this.alert.error(this.text_createProc_fail);
            this.decline();
          }
          this.nodeService.showAppLoader = false;
        }, error: err => {
          console.log(err);
          this.isCreateProcErr = true;
          let errText: string;
          if (err.error.code === "PROCESS_ALREADY_EXIST") {
            this.text_createProc_fail = this.text_createProc_failExists;
            errText = this.text_duplicateVal + ": '" + s1procinfo.name + "'.";
          } else {
            this.text_createProc_fail = "Error";
            errText = err.error.error;
          }
          this.alert.error(errText,this.text_createProc_fail);
          this.decline();
  
          this.nodeService.showAppLoader = false;
        }
      });
    }else{
      this.alert.error('Generic Error')
    }
   })
  }
  setCreateProcForm() {
    this.isCreateProcErr = false;
    this.text_createProc_fail = '';
    this.createProcForm = this.fb.group({
      'pname': ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_]+([a-zA-Z0-9_]+)?$')])],
      'pcomment': '',
      'pprocessType': ['', Validators.compose([Validators.required])],
      'pioType': ['', Validators.compose([Validators.required])],
      'pmissingType': ['', Validators.compose([Validators.required])],
      'pshared': '',
      'pencrypted': ''
    });
    this.createProcForm.get('pprocessType').setValue(this.pTypeArray[0].value);
    this.createProcForm.get('pioType').setValue(this.pIoTypeArray[3].value);
    this.createProcForm.get('pmissingType').setValue(this.pMissingTypeArray[0].value);
    const nameControl = this.createProcForm.get('pname');
    nameControl.setErrors({
      "pname": true
    });
  }
  setCheckbox4Shared(value) {
    this.isCreateProcShared = value.checked;

  }
  setCheckbox4Encrypted(value) {
    this.isCreateProcEncrypted = value.checked;
  }
  setCreateProcMissingType() {
    if (this.createProcForm.value.pioType === ProcessIOType.XMLEVO || this.createProcForm.value.pioType === ProcessIOType.JSONEVO) {
      this.isCreateProcDisableMissing = false;
    } else {
      this.createProcForm.get('pmissingType').setValue(this.pMissingTypeArray[0].value);
      this.isCreateProcDisableMissing = true;
    }
  }
  // setSampleProcXML() {
  //   this.sampleProcXML = `<?xml version="1.0" encoding="utf-8"?>
  //   <Process xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" GUID="492d76ce-121a-4911-bae0-54ff748577f0" crc="8d270584-2ac3-41ad-8e75-27c60d9a6105" xmlns="http://www.crif.com/s1/xsd/v7">
  //     <ID>appstandardevoshared</ID>
  //     <StrategyOneVersion>7.2.0.50</StrategyOneVersion>
  //     <Description>timepas trial</Description>
  //     <Type>Application</Type>
  //     <Shared>true</Shared>
  //     <MissingAllType>V7</MissingAllType>
  //     <Template>false</Template>
  //     <IOType>JSON-EVO</IOType>
  //     <Version>0</Version>
  //     <LayoutVersion>1</LayoutVersion>
  //     <References/>
  //     <Categories Reporting="false">
  //       <Category GUID="00000000-0000-0000-0000-000000000000">
  //         <ID>Application</ID>
  //       </Category>
  //     </Categories>
  //     <Objects>
  //       <CalculationGroups/>
  //       <Rules/>
  //       <Libraries/>
  //       <Tables/>
  //       <TemplateForScenarios/>
  //       <Scenarios/>
  //       <Trees/>
  //       <Variables/>
  //       <Operations/>
  //       <ScoreCards/>
  //       <Strategies/>
  //       <RiskLevels/>
  //       <Structures/>
  //       <PmmlModels/>
  //       <Campaigns/>
  //       <Connectors/>
  //     </Objects>
  //   </Process>`;
  // }
}

function _combineInputOutput_set_valueKeyMap(valueKeyMap, trck: number): any {
  if (!valueKeyMap[trck]) {
    return {};
  } else {
    return valueKeyMap[trck];
  }
}

function _combineInputOutput_set_inName(name): string {
  let inName: string = "";
  for (let s = 0; s < name.length; s++) {
    if (isNaN(name.charAt(s))) {
      inName = inName + name.charAt(s);
    }
  }
  return inName;
}

function _combineInputOutput_set_outElementKey(outelement, nameArr): string {
  if (outelement.key.indexOf(".") >= 0) return nameArr.join(".")
  else return nameArr.join("");
}

function _combineInputOutput_set_maxNumber(maxNumber, trck: number) {
  if (!maxNumber[trck]) return 1;
  else return maxNumber[trck];
}