import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { StorageDetails } from './user-details.service';
import { ResMap } from 'app/entities/resmap';
import { S1UserID } from 'app/entities/userid';
import { UpdateUserPermissionsRequest } from 'app/entities/updateUserPermissionsRequest';

@Injectable({
   providedIn: 'root'
})
export class S1EntityManagementService {
   constructor(private http: HttpClient, private userInfo: StorageDetails) {
   }

   authGetUserProfiles(): Observable<any> {
      return this.http.get<any>('private/users/profiles');
   }
  authGetUserProfilesId(id): Observable<any> {
    return this.http.get<any>('private/users/profiles/id?id='+id
    );
  }
   authGetInstallation(): Observable<any> {
      return this.http.get('private/bac/installation');
   }

   authGetAllGroups(): Observable<any> {
      return this.http.get('private/bac/groups');
   }

   authGetInstituteOfGroup(groupId: number): Observable<any> {
      return this.http.get('private/bac/institutes?groupId=' + groupId);
   }

   //doesnt work from proc-mngt service page
   getProcessList_copy1(containerId: number): Observable<any> {
      return this.http.get('private/processes?container_id=' + containerId);
   }

   getProcessTypeList(){
      return this.http.get<any>('private/installation');
   }

   getPermissionsStructure(userid: number): Observable<any> {
      //spring automap arg to request obj
      //call proc related call here just to knw if 2 arg working..
      //('private/bac/permissions/structure?s1UserID='+ encodeURIComponent(JSON.stringify(s1userid)));
      //('private/bac/permissions/structure'+ encodeURIComponent(JSON.stringify(s1userid)));
      // let s1userid: S1UserID = new S1UserID();
      // s1userid.userId = userid;
      // const queryParams = new HttpParams().set('s1UserID', JSON.stringify(s1userid));
      //('private/bac/permissions/structure', {params:  queryParams});
      //('private/bac/permissions/structure', {params: {filter: jsonvar}});
      //('private/bac/permissions/structure?userId=' + userid);
      return this.http.get<any>('private/bac/permissions/structure?userId=' + userid);
   }

   updateUserPermissions(userIDIp: number, selectedNodeData: any): Observable<any> {
      let seen = [];
      //form S1UserID
      let s1userid: S1UserID = new S1UserID();
      s1userid.userId = userIDIp;
      //form List<TreeObject>
      let obj = JSON.stringify(selectedNodeData, function (key, val) {
         if (val != null && typeof val == "object") {
            if (seen.indexOf(val) >= 0) {
               return;
            }
            seen.push(val);
         }
         return val;
      });

      //form UpdateUserPermissionsRequest
      let finalobj: UpdateUserPermissionsRequest = new UpdateUserPermissionsRequest();
      finalobj.userId = s1userid;
      finalobj.treeObjectList = JSON.parse(obj);
      return this.http.patch<any>('private/bac/users/permissions', finalobj);
   }
}
