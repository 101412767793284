import { Component, OnInit, TemplateRef, Output, EventEmitter, Injectable } from '@angular/core';
import { ProcessManagementService } from 'app/core/services/process-management.service'
import { StorageDetails } from 'app/core/services/user-details.service';
import * as _ from "lodash";
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Campaign } from 'app/entities/bac-campaign';
import { OperationsComponent } from '../operations/operations.component';
import { ProcessIOType } from 'app/entities/bac-processiotype.enum';
//import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe, formatDate } from '@angular/common';
import { Router } from '@angular/router';
//import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { NgbDateParserFormatter, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
const MODAL_OPTIONS: NgbModalOptions = { size: 'lg', backdrop: 'static', keyboard: true, windowClass: 'modal-full-height modal-full-width' };

export const PICK_FORMATS = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};

// @Injectable()
// export class PickDateAdapter extends NativeDateAdapter {
//   format(date: Date, displayFormat: Object): string {
//     if (displayFormat === 'input') {
//       return formatDate(date, 'yyyy-MM-dd', this.locale);
//     } else {
//       return date.toDateString();
//     }
//   }
// }
@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
  // providers: [
  //   { provide: DateAdapter, useClass: PickDateAdapter },
  //   { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }
  // ]
})
export class CampaignComponent implements OnInit {

  campaignData: Campaign[] = [];
  categoryVariablesData: any;
  inputValueData: Array<any> = [];
  campaignsName: string;
  desc: string;
  currentObj: any;
  campaignForm: UntypedFormGroup;
  modalRef: NgbModalRef;
  index: number;
  searchText: string;
  processVersion: number;
  isDirty: boolean;
  isDisabledFlag: boolean = false;
  currentFromDt: Date;
  currentToDt: Date;
  isSaveDisable = false;
  errorMessage: string = "";
  campaignBkpData: any;
  minDate: Date;
  isPermissionError: boolean = true;
  notSlave: boolean = true;

  @Output()
  dateChange: EventEmitter<any>;

  constructor(public nodeService: ProcessManagementService,
    private modalService: NgbModal,
    private parserFormatter: NgbDateParserFormatter,
    private userDetail: StorageDetails, private fb: UntypedFormBuilder,
    private datePipe: DatePipe, private router: Router) { 
      //do nothing
    }

  ngOnInit() {
    if (!this.nodeService.currentProcessDetails) {
      this.router.navigate(['/process-management']);
    }
    if (this.userDetail.getUserRole() !== "USER"
      || (this.userDetail.getUserRole() === "USER"
        && this.nodeService.currentProcessDetails
        && this.nodeService.currentProcessDetails.permission
        && (this.nodeService.currentProcessDetails.userProfileId === 7
          || this.nodeService.currentProcessDetails.userProfileId === 8)
      )
    ) {
      this.isPermissionError = false;
      this.minDate = new Date(1900, 0, 1); //(1985, 4, 12)
      this.reloadPageData();
      interval(1000)
        .pipe(takeWhile(() => this.nodeService.showAppLoader))
        .subscribe({
          //meaningless trial of interval
        });
    } else {
      this.isPermissionError = true;
      this.errorMessage = this.nodeService.errMsgPermissionDeniedDetails;
    }
  }
  
  ngOnDestroy(){
    localStorage.removeItem('OriginalTable');
  }

  reloadPageData() {
    this.campaignData = this.nodeService.bacProcessNode && this.nodeService.bacProcessNode.campaigns && this.nodeService.bacProcessNode.campaigns.campaign ? this.nodeService.bacProcessNode.campaigns.campaign : [];
    this.campaignData = this.campaignData.filter(campaign => null !== campaign.bac && campaign.bac === true);
    this.categoryVariablesData = this.nodeService.bacProcessNode && this.nodeService.bacProcessNode['categoryVariablesMap'] ? this.nodeService.bacProcessNode['categoryVariablesMap'] : '';
    this.processVersion = this.nodeService.currentProcessVersion;
  }

  initform() {
    if (this.currentObj && this.currentObj.always) {
      this.campaignForm = this.fb.group({
        'fromDate': null,
        'toDate': null,
        'description': ''
      });
    } else {
      var currentDt = new Date();
      this.campaignForm = this.fb.group({
        'fromDate': currentDt,
        'toDate': currentDt.getDate() + 1,
        'description': ''
      });
    }
  }

  findInputVariableName(id) {
    let inputName = "";
    if (null !== this.categoryVariablesData) {
      if (this.nodeService.bacProcessNode.ioType === ProcessIOType.COBOL ||
        this.nodeService.bacProcessNode.ioType === ProcessIOType.XML) {
        inputName = this.categoryVariablesData[id].objectsVariableId;
      } else if (this.nodeService.bacProcessNode.ioType === ProcessIOType.XML_EVO ||
        this.nodeService.bacProcessNode.ioType === ProcessIOType.JSON_EVO) {
        inputName = this.categoryVariablesData[id].categoryId + "." + this.categoryVariablesData[id].objectsVariableId;
      }
    } else {
      inputName = "UNDEFINED";
    }
    return inputName;
  }

  showVariablesDetails(value: Campaign) {
    this.notSlave = value.editable;
    this.campaignBkpData = value;
    this.campaignsName = value.id;
    this.inputValueData = _.each(value.range, function (val) {
      return val;
    });

    this.inputValueData.forEach(inputvalue => {
      if (inputvalue.from) {
        let tempdate: Date = new Date(inputvalue.from);
        inputvalue.fromDisplay = this.datePipe.transform(tempdate, 'yyyy-MM-dd');
      }
      if (inputvalue.to) {
        let tempdate: Date = new Date(inputvalue.to);
        inputvalue.toDisplay = this.datePipe.transform(tempdate, 'yyyy-MM-dd');
      }
    });
  }

  editValue(index: number, value: any, template: TemplateRef<any>, event: boolean) {
    if(this.notSlave){
      localStorage.setItem('OriginalTable', JSON.stringify(value));
      this.isDisabledFlag = value.always === true ? true : false;
      this.index = index;
      this.desc = value.description;
      this.currentObj = value;
  
      if (this.currentObj && this.currentObj.always) {
        this.campaignForm = this.fb.group({
          'fromDate': null,
          'toDate': null,
          'description': this.currentObj.description
        });
      } else {
        this.currentFromDt = new Date(this.currentObj.from);
        this.currentToDt = new Date(this.currentObj.to);
        this.campaignForm = this.fb.group({
          'fromDate': [{day: new Date(this.currentFromDt).getDate(), month: new Date(this.currentFromDt).getMonth()+1, year: new Date(this.currentFromDt).getFullYear()}],
          'toDate': [{day: new Date(this.currentToDt).getDate(), month: new Date(this.currentToDt).getMonth()+1, year: new Date(this.currentToDt).getFullYear()}],
          'description': this.currentObj.description
        });
      }
      this.modalRef = this.modalService.open(template,MODAL_OPTIONS)
    }
  }

  saveSelected() {
    OperationsComponent.scrollenable = true;
    OperationsComponent.url = true;
    let newRangeValue = null;
    if (this.currentObj.always) {
      this.isDisabledFlag = true;
      newRangeValue = {
        'from': null,
        'to': null,
        'description': this.campaignForm.controls['description'].value,
        'outputCode': this.inputValueData[this.index].outputCode,
        'always': this.currentObj.always
      };
    } else {
      this.isDisabledFlag = false;
      newRangeValue = {
        'from': new Date(this.parserFormatter.format(this.campaignForm.controls['fromDate'].value)).getTime(),
        'to': new Date(this.parserFormatter.format(this.campaignForm.controls['toDate'].value)).getTime(),
        'description': this.campaignForm.controls['description'].value,
        'outputCode': this.inputValueData[this.index].outputCode,
        'always': null
      };
    }

    this.inputValueData[this.index] = newRangeValue;
    //TODO add date
    this.inputValueData.sort(this.orderDataByKey());
    this.campaignBkpData['range'] = this.inputValueData;
    this.showVariablesDetails(this.campaignBkpData);
    this.modalRef.close();
    this.enablingWhatIf(this.inputValueData[this.index])
  }
  enablingWhatIf(selectedRow){
    var originalTable = JSON.parse(localStorage.getItem('OriginalTable'));
    if((selectedRow.fromDisplay != originalTable.fromDisplay) || (selectedRow.toDisplay != originalTable.toDisplay) || (selectedRow.description != originalTable.description)){
      localStorage.setItem('whatIfIsEnabled', 'false')
      return
    }
  }

  orderDataByKey() {
    return function (a, b) {
      if ((!a['from'] && b['from']) || a['from'] > b['from']) {
        return 1;
      } else if ((a['from'] && !b['from']) || a['from'] < b['from']) {
        return -1;
      }
      return 0;
    }
  }

  dateChanged() {
    if (new Date(this.parserFormatter.format(this.campaignForm.controls['fromDate'].value)).getTime() > new Date(this.parserFormatter.format(this.campaignForm.controls['toDate'].value)).getTime()) {
      this.isSaveDisable = true;
    } else if (new Date(this.parserFormatter.format(this.campaignForm.controls['fromDate'].value)).getTime() === new Date(this.parserFormatter.format(this.campaignForm.controls['toDate'].value)).getTime()) {
      this.isSaveDisable = false;
    } else {
      this.isSaveDisable = false;
    }
  }

  cancel() {
    this.modalRef.close();
    this.isSaveDisable = false;
  }

  isEnableEdit(): boolean {
    let returnval: boolean = !this.nodeService.isDisabled;
    if (returnval
      && this.userDetail.getUserRole() === "USER"
      && this.nodeService.currentProcessDetails
      && this.nodeService.currentProcessDetails.permission
    ) {
      if (this.nodeService.currentProcessDetails.userProfileId === 7)
        returnval = true;
      else if (this.nodeService.currentProcessDetails.userProfileId === 8)
        returnval = true;
      else
        returnval = false;
    }
    return returnval;
  }
}