<div class="cds-body">
 
  <div class="cds-sidebar">
    <div class="cds-sidebar-toggle">
        <!-- <button type="button" class="cds-sidebar-toggle-button">
            <i class="fa fa-arrow-left cds-sidebar-toggle-icon expanded" aria-hidden="true"></i>
            <i class="fa fa-arrow-right cds-sidebar-toggle-icon collapsed" aria-hidden="true"></i>
        </button> -->
    </div>
    <div class="cds-sidebar-header">
        <div class="cds-sidebar-header-logo">
            <div class="expanded">
                <img src="assets/img/logo-coloured-s1-whitelogo.png" style="width: 200px;">
            </div>
        </div>
    </div>
    <app-sidebar></app-sidebar>
</div>

  <div class="cds-content">

      <app-header></app-header>
      <div class="cds-container container-fluid">
          <router-outlet (activate)="onActivate($event)"></router-outlet>
      </div>
  </div>
</div>

<!--<app-header></app-header>

 <app-sidebar (collapsedEvent)="receiveCollapsed($event)"></app-sidebar>
<section class="main-container" [ngClass]="{collapsed: collapedSideBar}">
    <router-outlet></router-outlet>
</section> -->

