<div *ngIf="!whatIfIsEnabled" translate>
    <div class="alert cds-alert alert-danger" role="alert">
        <div class="cds-alert-icon">
          <i class="fa-solid fa-triangle-exclamation"></i>
        </div>
        <div class="cds-alert-content">{{"warning_whatif_blocked" | translate}}</div>
    </div>
</div>

<div class="col-12" *ngIf="isPermissionError && whatIfIsEnabled">
    <div class="alert cds-alert alert-danger" role="alert">
        <div class="cds-alert-icon">
          <i class="fa-solid fa-triangle-exclamation"></i>
        </div>
        <div class="cds-alert-content">{{"You do not have permission to access this page." | translate}}</div>
    </div>
</div>
<appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>

<div class="col-12" *ngIf="!nodeService.showAppLoader && !isPermissionError && whatIfIsEnabled && errorMessage">
    <div class="alert cds-alert alert-danger" role="alert">
        <div class="cds-alert-icon">
          <i class="fa-solid fa-triangle-exclamation"></i>
        </div>
        <div class="cds-alert-content">{{errorMessage}}</div>
    </div>
</div>

<ng-container *ngIf="whatIfIsEnabled">
<h6 class="mb-1">{{'Available What-if simulations for process :' | translate}}
    <h6 class="badge cds-badge active text-bg-info">{{nodeService.currentProcessName}}</h6>
</h6>
<div class="card cds-card-shadow">
    <div class="card-body">
        <div class="col-12 mb-3">
            <input class="form-control searchBox" placeholder="{{ 'Quick Filter' | translate}}" [(ngModel)]="searchText">
        </div>
        <!--Actions for first table-->
        <div class="col-12 text-center">
            <button class="btn btn-primary btn-sm m-1" [disabled]="whatIfName ? false : true" title="{{ 'Delete' | translate }}" (click)="deleteWhatIf(whatIfName)">
                <i class="fa fa-trash p-1" aria-hidden="true"></i>{{ 'Delete' | translate }}
            </button>
            <button class="btn btn-primary btn-sm m-1" [disabled]="whatIfName ? false : true" title="{{ 'Refresh' | translate }}" (click)="refreshWhatIf()">
                <i class="fa fa-refresh p-1" aria-hidden="true"></i>{{ 'Refresh' | translate }}
            </button>
            <button class="btn btn-primary btn-sm m-1" [disabled]="whatIfName ? false : true" title="{{ 'Stop' | translate }}" (click)="stopWhatIf(whatIfName)">
                <i class="fa fa-stop p-1" aria-hidden="true"></i>{{ 'Stop' | translate }}
            </button>
            <button class="btn btn-primary btn-sm m-1" [disabled]="whatIfName ? false : true" title="{{ 'Play' | translate }}" (click)="startWhatIf(whatIfName)">
                <i class="fa fa-play p-1" aria-hidden="true"></i>{{ 'Play' | translate }}
            </button>
            <button class="btn btn-primary btn-sm m-1" title="{{ 'Load From Local' | translate }}" (click)="loadTheWhatIf(templateLoad, false)">
                <i class="fa fa-spinner p-1" aria-hidden="true"></i>{{ 'Load From Local' | translate }}
            </button>
            <button class="btn btn-primary btn-sm m-1" title="{{ 'Load From Production' | translate }}" (click)="loadTheWhatIf(templateLoad, true)">
                <i class="fa fa-spinner p-1" aria-hidden="true"></i>{{ 'Load From Production' | translate }}
            </button>
            <hr class="mb-3">
        </div>
        <!--First table-->
        <div class="col-12">
            <table aria-describedby="table-proc-whatif" class="table table-striped"
                [mfData]="whatIfParentTblData | filter :'id,notes,status,progress,updatedDt,user': searchText"
                #mf="mfDataTable" [mfRowsOnPage]="15" mfSortBy="id">
                <thead>
                    <tr>
                        <th>
                        </th>
                        <th>
                            <mfDefaultSorter by="id">{{ 'Code' | translate }}</mfDefaultSorter>
                        </th>
                        <th>
                            <mfDefaultSorter by="notes">{{ 'Notes' | translate }}</mfDefaultSorter>
                        </th>
                        <th>
                            <mfDefaultSorter by="status">{{ 'Status' | translate }}</mfDefaultSorter>
                        </th>
                        <th>
                            <mfDefaultSorter by="progress">{{ 'Completed' | translate }}</mfDefaultSorter>
                        </th>
                        <th>
                            <mfDefaultSorter by="updatedDt">{{ 'Date' | translate }}</mfDefaultSorter>
                        </th>
                        <th>
                            <mfDefaultSorter by="user">{{ 'User' | translate }}
                            </mfDefaultSorter>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="whatIfParentTblData.length > 0">
                    <tr class="cursor" *ngFor="let value of mf.data let index" 
                        [ngStyle]="{'background-color': value.id === whatIfName ? 'antiquewhite' : ''}">
                        <td>
                            <input id="{{value.id}}" class="form-check-input" type="radio" name="flexRadioDefault" (click)="showVariablesDetails(value)">
                        </td>
                        <td>{{value.id}}</td>
                        <td>{{value.notes}}</td>
                        <td>{{value.status | statusPipe}}</td>
                        <td>{{value.progress}}</td>
                        <td>{{value.updatedDt}}</td>
                        <td>{{value.user}}</td>
                    </tr>
                </tbody>
                <tfoot
                    *ngIf="(whatIfParentTblData | filter :'id,notes,status,progress,updatedDt,user': searchText).length > 15">
                    <tr>
                        <td colspan="10">
                            <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <div class="alert cds-alert alert-danger" role="alert" *ngIf="whatIfParentTblData.length === 0">
                <div class="cds-alert-icon">
                  <i class="fa-solid fa-triangle-exclamation"></i>
                </div>
                <div class="cds-alert-content">{{ 'No records found.' | translate }}</div>
            </div>
        </div>
        <hr class="mt-5">
        <!--Actions linked to second table/whatif execution-->
        <div class="col-12 text-center">
            <button class="btn btn-primary btn-sm m-1" [disabled]="whatIfName ? false : true" title="{{ 'Save complete Input file' | translate }}" 
                (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveCompleteInputFile')">
                {{'Save complete Input file' | translate }}
            </button>
            <button class="btn btn-primary btn-sm m-1" [disabled]="isActiveWhatifSuccessFlag && isActiveWhatifBothFlag" title="{{ 'Save Output for Successful Req Only' | translate }}" 
                (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveOutputSuccessReq')">
                {{'Save Output for Successful Req Only' | translate }}
            </button>
            <button class="btn btn-primary btn-sm m-1" [disabled]="isActiveWhatifBothFlag" title="{{ 'Save Input for Successful Req Only' | translate }}"
                (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveInputSuccessReq')">
                {{'Save Input for Successful Req Only' | translate }}
            </button>
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary btn-sm m-1" [disabled]="isBenchmarkFlag" title="{{ 'Save Benchmark Output' | translate }}" 
            (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveBenchmarkReq')">
            {{'Save Benchmark Output' | translate }}
            </button>
            <button class="btn btn-primary btn-sm m-1" [disabled]="isActiveWhatifBothFlag" title="{{ 'Save Input for Unsuccessful Req Only' | translate }}" 
                (click)="saveInputFile(template, subInfoOfWhatIfData,'SaveInputUnsuccessReq')">
                {{'Save Input for Unsuccessful Req Only' | translate }}
            </button>
            <button class="btn btn-primary btn-sm m-1" [disabled]="isActiveWhatifUnsuccessFlag && isActiveWhatifBothFlag" title="{{ 'Save Output for Unsuccessful Req Only (error log)' | translate }}" 
                (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveOutputUnsuccessReqErrorLog')">
                {{'Save Output for Unsuccessful Req Only (error log)' | translate }}
            </button>
        </div>
        <hr class="mb-5">
        <!--Second Table-->
        <div class="col-12">
            <table aria-describedby="table-proc-whatifChild" class="table table-striped">
            <thead>
                <tr class="table-header-font-color">
                    <th>{{ 'Current Exec Results' | translate }}</th>
                    <th>{{ 'Results' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><strong>{{ 'Processed Requests :' | translate }}</strong></td>
                    <td> {{subInfoOfWhatIfData.numberOfProcessedRecords}} </td>
                </tr>
                <tr>
                    <td><strong>{{ 'Successful Requests :' | translate }}</strong></td>
                    <td>{{ subInfoOfWhatIfData.numberOfRegularRecords}}
                    </td>
                </tr>
                <tr>
                    <td><strong>{{ 'Unsuccessful Requests :' | translate }}</strong></td>
                    <td>{{subInfoOfWhatIfData.numberOfIrregularRecords}}
                    </td>
                </tr>
                <tr>
                    <td><strong>{{ 'Execution Time (sec.) :' | translate }}</strong></td>
                    <td>{{ subInfoOfWhatIfData.executionTimeInSeconds }}
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
    </div>
</div>


<!-- <div class="row" *ngIf="!nodeService.showAppLoader && !isPermissionError">
    <div class="col-12">
        <div class="col-12">
            <span class="heading-text">{{'Available What-if simulations for process :' | translate}}
                {{nodeService.currentProcessName}}</span>
        </div>
    </div>
    <div class="col-12"><br /></div>
    <div class="col-12">
        <div class="col-5 pull-left"><input class="form-control searchBox"
                placeholder="{{ 'Quick Filter' | translate}}" [(ngModel)]="searchText"></div>
        <div class="col-7 pull-right">
            <div class="col-12">
                <button class="btn btn-primary pull-right" [disabled]="whatIfName ? false : true"
                    title="{{ 'Delete' | translate }}" (click)="deleteWhatIf(whatIfName)"><span class="fa fa-trash"
                        aria-hidden="true"></span>&nbsp;{{ 'Delete' | translate }}</button>
                <button class="btn btn-primary pull-right" [disabled]="whatIfName ? false : true"
                    title="{{ 'Refresh' | translate }}" (click)="refreshWhatIf()"><span class="fa fa-refresh"
                        aria-hidden="true"></span>&nbsp;{{ 'Refresh' | translate }}</button>&nbsp;
                <button class="btn btn-primary pull-right" [disabled]="whatIfName ? false : true"
                    title="{{ 'Stop' | translate }}" (click)="stopWhatIf(whatIfName)"><span class="fa fa-stop"
                        aria-hidden="true"></span>&nbsp;{{ 'Stop' | translate }}</button>&nbsp;
                <button class="btn btn-primary pull-right" [disabled]="whatIfName ? false : true"
                    title="{{ 'Play' | translate }}" (click)="startWhatIf(whatIfName)"><span class="fa fa-play"
                        aria-hidden="true"></span>&nbsp;{{ 'Play' | translate }}</button>&nbsp;
            </div>
            <div class="col-12">&nbsp;</div>
            <div class="col-12">
                <button class="btn btn-primary pull-right" title="{{ 'Load From Local' | translate }}"
                    (click)="loadTheWhatIf(templateLoad, false)"><span class="fa fa-spinner"
                        aria-hidden="true"></span>&nbsp;{{ 'Load From Local' | translate }}</button>&nbsp;
                <button class="btn btn-primary pull-right" title="{{ 'Load From Production' | translate }}"
                    (click)="loadTheWhatIf(templateLoad, true)"><span class="fa fa-spinner"
                        aria-hidden="true"></span>&nbsp;{{ 'Load From Production' | translate }}</button>&nbsp;
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="col-12"><br /></div>
        <div class="col-12 table-height">
            <div class="scroll-only-x">
                <table aria-describedby="table-proc-whatif" class="table table table-bordered table-striped mb-0"
                    [mfData]="whatIfParentTblData | filter :'id,notes,status,progress,updatedDt,user': searchText"
                    #mf="mfDataTable" [mfRowsOnPage]="15" mfSortBy="id">
                    <thead>
                        <tr>
                            <th>
                                <mfDefaultSorter by="id">{{ 'Code' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="notes">{{ 'Notes' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="status">{{ 'Status' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="progress">{{ 'Completed' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="updatedDt">{{ 'Date' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="user">{{ 'User' | translate }}
                                </mfDefaultSorter>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="whatIfParentTblData.length > 0">
                        <tr class="cursor" *ngFor="let value of mf.data let index" (click)="showVariablesDetails(value)"
                            [ngStyle]="{'background-color': value.id === whatIfName ? 'antiquewhite' : ''}">
                            <td>{{value.id}}</td>
                            <td>{{value.notes}}</td>
                            <td>{{value.status | statusPipe}}</td>
                            <td>{{value.progress}}</td>
                            <td>{{value.updatedDt}}</td>
                            <td>{{value.user}}</td>
                        </tr>
                    </tbody>
                    <tfoot
                        *ngIf="(whatIfParentTblData | filter :'id,notes,status,progress,updatedDt,user': searchText).length > 15">
                        <tr>
                            <td colspan="10">
                                <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <div class="col-12 text-center" *ngIf="whatIfParentTblData.length === 0">
                    <h5>
                        <mat-error>{{ 'No records found.' | translate }}</mat-error>
                    </h5>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12"><br /></div>
    <div class="col-12">
        <hr>
    </div>
    <div class="col-12"><br /></div>
    <div class="col-12"><br /></div>
    <div class="col-12"> -->
        <!-- New UI -->

        <!-- <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <button class="btn btn-primary col-12" [disabled]="whatIfName ? false : true"
                                title="{{ 'Save complete Input file' | translate }}"
                                (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveCompleteInputFile')">&nbsp;{{
                                'Save complete Input file' | translate }}</button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-primary col-12"
                                [disabled]="isActiveWhatifSuccessFlag && isActiveWhatifBothFlag"
                                title="{{ 'Save Output for Successful Req Only' | translate }}"
                                (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveOutputSuccessReq')">&nbsp;{{
                                'Save Output for Successful Req Only' | translate }}</button>
                        </div>
                    </div>
                    <div class="col-12"><br /></div>
                    <div class="row">
                        <div class="col-6">
                            <button class="btn btn-primary col-12" [disabled]="isActiveWhatifBothFlag"
                                title="{{ 'Save Input for Successful Req Only' | translate }}"
                                (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveInputSuccessReq')">&nbsp;{{
                                'Save Input for Successful Req Only' | translate }}</button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-primary col-12" [disabled]="isBenchmarkFlag"
                                title="{{ 'Save Benchmark Output' | translate }}"
                                (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveBenchmarkReq')">&nbsp;{{
                                'Save Benchmark Output' | translate }}</button>
                        </div>
                    </div>
                    <div class="col-12"><br /></div>
                    <div class="row">
                        <div class="col-6">
                            <button class="btn btn-primary col-12" [disabled]="isActiveWhatifBothFlag"
                                title="{{ 'Save Input for Unsuccessful Req Only' | translate }}"
                                (click)="saveInputFile(template, subInfoOfWhatIfData,'SaveInputUnsuccessReq')">&nbsp;{{
                                'Save Input for Unsuccessful Req Only' | translate }}</button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-primary col-12"
                                [disabled]="isActiveWhatifUnsuccessFlag && isActiveWhatifBothFlag"
                                title="{{ 'Save Output for Unsuccessful Req Only (error log)' | translate }}"
                                (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveOutputUnsuccessReqErrorLog')">&nbsp;{{
                                'Save Output for Unsuccessful Req Only (error log)' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12"><br /></div>
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <table aria-describedby="table-proc-whatifChild"
                        class="table table table-bordered table-striped mb-0">
                        <thead>
                            <tr>
                                <th>
                                    <mfDefaultSorter by="id">{{ 'Code' | translate }}</mfDefaultSorter>
                                </th>
                                <th>
                                    <mfDefaultSorter by="notes">{{ 'Notes' | translate }}</mfDefaultSorter>
                                </th>
                                <th>
                                    <mfDefaultSorter by="status">{{ 'Status' | translate }}</mfDefaultSorter>
                                </th>
                                <th>
                                    <mfDefaultSorter by="progress">{{ 'Completed' | translate }}</mfDefaultSorter>
                                </th>
                                <th>
                                    <mfDefaultSorter by="updatedDt">{{ 'Date' | translate }}</mfDefaultSorter>
                                </th>
                                <th>
                                    <mfDefaultSorter by="user">{{ 'User' | translate }}
                                    </mfDefaultSorter>
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="whatIfParentTblData.length > 0">
                            <tr class="cursor" *ngFor="let value of mf.data let index" (click)="showVariablesDetails(value)"
                                [ngStyle]="{'background-color': value.id === whatIfName ? 'antiquewhite' : ''}">
                                <td>{{value.id}}</td>
                                <td>{{value.notes}}</td>
                                <td>{{value.status | statusPipe}}</td>
                                <td>{{value.progress}}</td>
                                <td>{{value.updatedDt}}</td>
                                <td>{{value.user}}</td>
                            </tr>
                        </tbody>
                        <tfoot
                            *ngIf="(whatIfParentTblData | filter :'id,notes,status,progress,updatedDt,user': searchText).length > 15">
                            <tr>
                                <td colspan="10">
                                    <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="col-sm-12 text-center" *ngIf="whatIfParentTblData.length === 0">
                        <h5>
                            <mat-error>{{ 'No records found.' | translate }}</mat-error>
                        </h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="row">
                <div class="col-3">
                    <div class="row pull-left">
                        <div class="col-12">
                            <div class="scroll-only-x">

            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-6">
                                <button class="btn btn-primary col-sm-12" [disabled]="whatIfName ? false : true"
                                    title="{{ 'Save complete Input file' | translate }}"
                                    (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveCompleteInputFile')">&nbsp;{{
                                    'Save complete Input file' | translate }}</button>
                            </div>
                            <div class="col-sm-6">
                                <button class="btn btn-primary col-sm-12"
                                    [disabled]="isActiveWhatifSuccessFlag && isActiveWhatifBothFlag"
                                    title="{{ 'Save Output for Successful Req Only' | translate }}"
                                    (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveOutputSuccessReq')">&nbsp;{{
                                    'Save Output for Successful Req Only' | translate }}</button>
                            </div>
                        </div>
                        <div class="col-sm-12"><br /></div>
                        <div class="row">
                            <div class="col-sm-6">
                                <button class="btn btn-primary col-sm-12" [disabled]="isActiveWhatifBothFlag"
                                    title="{{ 'Save Input for Successful Req Only' | translate }}"
                                    (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveInputSuccessReq')">&nbsp;{{
                                    'Save Input for Successful Req Only' | translate }}</button>
                            </div>
                            <div class="col-sm-6">
                                <button class="btn btn-primary col-sm-12" [disabled]="isBenchmarkFlag"
                                    title="{{ 'Save Benchmark Output' | translate }}"
                                    (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveBenchmarkReq')">&nbsp;{{
                                    'Save Benchmark Output' | translate }}</button>
                            </div>
                        </div>
                        <div class="col-sm-12"><br /></div>
                        <div class="row">
                            <div class="col-sm-6">
                                <button class="btn btn-primary col-sm-12" [disabled]="isActiveWhatifBothFlag"
                                    title="{{ 'Save Input for Unsuccessful Req Only' | translate }}"
                                    (click)="saveInputFile(template, subInfoOfWhatIfData,'SaveInputUnsuccessReq')">&nbsp;{{
                                    'Save Input for Unsuccessful Req Only' | translate }}</button>
                            </div>
                            <div class="col-sm-6">
                                <button class="btn btn-primary col-sm-12"
                                    [disabled]="isActiveWhatifUnsuccessFlag && isActiveWhatifBothFlag"
                                    title="{{ 'Save Output for Unsuccessful Req Only (error log)' | translate }}"
                                    (click)="saveInputFile(template, subInfoOfWhatIfData, 'SaveOutputUnsuccessReqErrorLog')">&nbsp;{{
                                    'Save Output for Unsuccessful Req Only (error log)' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <div class="row pull-right">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- Download File name -->
<!-- <ng-template #template>
    <form [formGroup]="whatIfForm" novalidate>
        <div class="modal-header">
            <h4 class="modal-title pull-left">{{ 'Download Whatif :' | translate }} {{whatIfName}}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-12">
                <appspinner [startSpinner]="showAppLoader"></appspinner>
            </div>
            <div class="col-12">
                <div class="row" *ngIf="isErrorFlag">
                    <div class="col-12">
                        <mat-error>
                            {{'File name is required' | translate }}
                        </mat-error>
                    </div>
                </div>
                <div class="col-12"><br /></div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="col-6" appearance="outline">
                            <mat-label>{{ 'File Name' | translate }} *</mat-label>
                            <input type="text" matInput placeholder="{{ 'File Name' | translate }}"
                                formControlName="fileName" style="max-width: 90%" [disabled]='showAppLoader'>
                        </mat-form-field>
                        <mat-form-field class="col-6" appearance="outline">
                            <mat-label>{{ 'Content Type' | translate }} *</mat-label>
                            <input type="text" matInput placeholder="{{ 'Content Type' | translate }}"
                                formControlName="contentType" style="max-width: 90%" [readonly]='true'>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" (click)="downloadTheWhatIf(subInfoOfWhatIfData)"
                [disabled]='showAppLoader'>{{ 'Save' | translate }}</button>
            <button type="button" class="btn btn-default" (click)="cancel()" [disabled]='showAppLoader'>{{ 'Close' |
                translate }}</button>
        </div>
    </form>
</ng-template> -->

<ng-template #template tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>
                    {{ "Download Whatif :" | translate }}
                    <h5 class="badge cds-badge active text-bg-info">{{whatIfName}}</h5>
                </h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <appspinner [startSpinner]="showAppLoader"></appspinner>
                <div class="col-12" *ngIf="isErrorFlag">
                    <div class="alert cds-alert alert-danger" role="alert">
                        <div class="cds-alert-icon">
                          <i class="fa-solid fa-triangle-exclamation"></i>
                        </div>
                        <div class="cds-alert-content">{{'File name is required' | translate }}</div>
                    </div>
                </div>
                <form [formGroup]="whatIfForm" novalidate>
                    <div class="row">
                        <div class="col-6">
                            <label class="form-label"> {{ 'File Name' | translate }} </label>
                            <input placeholder="{{ 'File Name' | translate }}" class="form-control" formControlName="fileName"  [disabled]='showAppLoader' type="text">
                        </div>
                        <div class="col-6">
                            <label class="form-label"> {{ 'Content Type' | translate }} </label>
                            <input placeholder="{{ 'Content Type' | translate }}" class="form-control" formControlName="contentType" disabled type="text">
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button [[disabled]='showAppLoader' type="submit" class="btn btn-primary" (click)="downloadTheWhatIf(subInfoOfWhatIfData)">
                    {{'Save' | translate }}
                </button>
                <button (click)="cancel()" [disabled]='showAppLoader' type="button" class="btn btn-default">
                    {{ 'Close' |translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<!-- <ng-template #templateLoad33333>
    <form [formGroup]="whatIfLoadForm" novalidate>
        <div class="modal-header">
            <h4 class="modal-title pull-left" *ngIf="loadWhatIf === true">{{ 'Load From Production' | translate }}</h4>
            <h4 class="modal-title pull-left" *ngIf="loadWhatIf === false">{{ 'Load From Local' | translate }}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeForPopup()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-12" *ngIf="progress > 0"><br /></div>
            <div class="col-12">
                <div class="progress form-group" *ngIf="progress > 0">
                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                        [style.width.%]="progress">
                        {{progress}}%</div>
                </div>
            </div>
            <div class="col-12">
                <div>
                    <div class="row" *ngIf="loadWhatIf === false || loadWhatIf === true">
                        <mat-form-field class="col-12" appearance="outline">
                            <input matInput placeholder="{{ 'Notes' | translate }}" formControlName="notes"
                                maxlength="100">
                        </mat-form-field>
                    </div>
                    <fieldset style="height: auto;width:auto" *ngIf="loadWhatIf === true">
                        <legend>
                            <label>{{ 'Date' | translate }}</label>
                        </legend>
                        <div class="row">
                            <mat-form-field class="col-6" appearance="outline">
                                <mat-label>{{ 'Date from' | translate }}</mat-label>
                                <input matInput (dateChange)="dateChanged()" [matDatepicker]="pickerFrom"
                                    [readonly]="true" placeholder="{{ 'Date From' | translate }}"
                                    formControlName="fromDate">
                                <mat-datepicker-toggle matSuffix [for]="pickerFrom">
                                </mat-datepicker-toggle>
                                <mat-datepicker type="time" #pickerFrom></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="col-6" appearance="outline">
                                <mat-label>{{ 'Date to' | translate }}</mat-label>
                                <input matInput (dateChange)="dateChanged()" [matDatepicker]="pickerTo"
                                    [readonly]="true" placeholder="{{ 'Date To' | translate }}"
                                    formControlName="toDate">
                                <mat-datepicker-toggle matSuffix [for]="pickerTo">
                                </mat-datepicker-toggle>
                                <mat-datepicker #pickerTo></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="isDateValidation" style="color: red;">
                            {{ "INVALID DATE RANGE : From date should not be greater than to date." | translate }}</div>
                    </fieldset>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" (click)="downloadTheWhatIf(subInfoOfWhatIfData)"
                    [disabled]='showAppLoader'>{{ 'Save' | translate }}</button>
                <button type="button" class="btn btn-default" (click)="cancel()" [disabled]='showAppLoader'>{{ 'Close' |
                    translate }}</button>
            </div>
        </form>
    </ng-template>

    Load the file from Local/Production 
    <ng-template #templateLoad>
        <form [formGroup]="whatIfLoadForm" novalidate>
            <div class="modal-header">
                <h4 class="modal-title pull-left" *ngIf="loadWhatIf === true">{{ 'Load From Production' | translate }}</h4>
                <h4 class="modal-title pull-left" *ngIf="loadWhatIf === false">{{ 'Load From Local' | translate }}</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeForPopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-sm-12" *ngIf="progress > 0"><br /></div>
                <div class="col-sm-12">
                    <div class="progress form-group" *ngIf="progress > 0">
                        <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                            [style.width.%]="progress">
                            {{progress}}%</div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div>
                        <div class="row" *ngIf="loadWhatIf === false || loadWhatIf === true">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <input matInput placeholder="{{ 'Notes' | translate }}" formControlName="notes"
                                    maxlength="100">
                            </mat-form-field>
                        </div>
                        <fieldset style="height: auto;width:auto" *ngIf="loadWhatIf === true">
                            <legend>
                                <label>{{ 'Date' | translate }}</label>
                            </legend>
                            <div class="row">
                                <mat-form-field class="col-sm-6" appearance="outline">
                                    <mat-label>{{ 'Date from' | translate }}</mat-label>
                                    <input matInput (dateChange)="dateChanged()" [matDatepicker]="pickerFrom"
                                        [readonly]="true" placeholder="{{ 'Date From' | translate }}"
                                        formControlName="fromDate">
                                    <mat-datepicker-toggle matSuffix [for]="pickerFrom">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker type="time" #pickerFrom></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field class="col-sm-6" appearance="outline">
                                    <mat-label>{{ 'Date to' | translate }}</mat-label>
                                    <input matInput (dateChange)="dateChanged()" [matDatepicker]="pickerTo"
                                        [readonly]="true" placeholder="{{ 'Date To' | translate }}"
                                        formControlName="toDate">
                                    <mat-datepicker-toggle matSuffix [for]="pickerTo">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerTo></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <mat-form-field class="col-12" appearance="outline">
                                        <mat-label>{{ 'Number' | translate }} </mat-label>
                                        <input matInput [readonly]="processVFlag" formControlName="processVersion"
                                            maxlength="100">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 top-label">
                                <mat-checkbox color="primary" formControleName="requestCodeFlag"
                                    (change)="checkRequestCode($event)">{{ 'Request Code' | translate }}
                                </mat-checkbox>
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <mat-form-field class="col-12" appearance="outline">
                                        <input matInput [readonly]="requestCFlag" formControlName="requestID"
                                            maxlength="100">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-checkbox color="primary" formControleName="lastCallPerRequest"
                                        (change)="lastRequest($event)">
                                        {{ 'Last Call Per Request' | translate }}</mat-checkbox>
                                </div>
                                <div class="col-md-8"></div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 top-label">
                                    <mat-checkbox color="primary" formControleName="requestStatusFlag"
                                        (change)="checkRequestStatus($event)">{{ 'Request Status' | translate }}
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-8">
                                    <div class="row">
                                        <mat-form-field class="col-sm-12" appearance="outline">
                                            <mat-select [disabled]="requestSFlag"
                                                placeholder="{{ 'Request Status' | translate }}"
                                                formControlName="extractMode">
                                                <mat-option *ngFor="let option of requestStatusOptions"
                                                    [value]="option.value">{{ option.viewValue | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <mat-form-field class="col-12" appearance="outline">
                                        <mat-select [disabled]="requestSFlag"
                                            placeholder="{{ 'Request Status' | translate }}"
                                            formControlName="extractMode">
                                            <mat-option *ngFor="let option of requestStatusOptions"
                                                [value]="option.value">{{ option.viewValue | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div>
                        <fieldset style="height: auto; width: auto;" *ngIf="loadWhatIf === true">
                            <legend>
                                <label>{{ 'Sampling' | translate }}</label>
                            </legend>
                            <div class="row">
                                <mat-form-field class="col-sm-6" appearance="outline">
                                    <mat-label>{{ 'Percentage (1-100 %)' | translate }} </mat-label>
                                    <input matInput formControlName="sampleInterval" min="1" max="100" maxlength="3">
                                </mat-form-field>
                                <mat-form-field class="col-sm-6" appearance="outline">
                                    <mat-label>{{ 'Max. number of requests' | translate }} </mat-label>
                                    <input matInput formControlName="maxRequest" maxlength="100">
                                </mat-form-field>
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <mat-form-field class="col-6" appearance="outline">
                                        <mat-label>{{ 'From (ms)' | translate }} </mat-label>
                                        <input matInput [readonly]="execTFlag" formControlName="fromExecTime"
                                            maxlength="100">
                                    </mat-form-field>
                                    <mat-form-field class="col-6" appearance="outline">
                                        <mat-label>{{ 'To (ms)' | translate }} </mat-label>
                                        <input matInput [readonly]="execTFlag" formControlName="toExecTime"
                                            maxlength="100">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div>
                    <fieldset style="height: auto; width: auto;" *ngIf="loadWhatIf === true">
                        <legend>
                            <label>{{ 'Sampling' | translate }}</label>
                        </legend>
                        <div class="row">
                            <mat-form-field class="col-6" appearance="outline">
                                <mat-label>{{ 'Percentage (1-100 %)' | translate }} </mat-label>
                                <input matInput formControlName="sampleInterval" min="1" max="100" maxlength="3">
                            </mat-form-field>
                            <mat-form-field class="col-6" appearance="outline">
                                <mat-label>{{ 'Max. number of requests' | translate }} </mat-label>
                                <input matInput formControlName="maxRequest" maxlength="100">
                            </mat-form-field>
                        </div>
                    </fieldset>
                </div>
                <div>
                    <fieldset style="height: auto; width: auto;" *ngIf="loadWhatIf === false">
                        <legend>
                            <label>{{ 'Local File Upload' | translate }}</label>
                        </legend>
                        <div class="row">
                            <div class="col-12">
                                <input id="file1" type="file" class="hidden" accept="{{acceptType}}" ng2FileSelect
                                    [uploader]="uploader" (change)="getFileForLoad()" />
                            </div>
                        </div>
                    </fieldset>

                    <div class="row">
                        <div class="col-12">
                            <mat-error>{{fileErrorMessage | translate }}</mat-error>
                        </div>
                    </div>
                </div>
                <div>
                    <fieldset style="height: auto; width: auto;" *ngIf="loadWhatIf === false || loadWhatIf === true">
                        <legend>
                            <label>{{ 'I/O and Reporting Data' | translate }}</label>
                        </legend>
                        <div class="row">
                            <div class="col-12">
                                <mat-checkbox color="primary" [disabled]="true" [checked]="true"
                                    formControleName="fastWhatIf">
                                    {{ 'Fast What-if (No - What-if/Reporting analysis allowed)' | translate }}
                                </mat-checkbox>
                            </div>
                            <div class="col-12">
                                <mat-checkbox color="primary" formControleName="enableDyanamicSimulation">
                                    {{ 'Enable Dynamic Simulation' | translate }}</mat-checkbox>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div>
                    <fieldset style="height: auto; width: auto;" *ngIf="loadWhatIf === true">
                        <legend>
                            <label>{{ 'Output Comparison' | translate }}</label>
                        </legend>
                        <div class="row">
                            <div class="col-4">
                                <mat-checkbox color="primary" formControleName="extractOutput"
                                    (change)="changeExtractOutput($event)">
                                    {{ 'Extraction Output from the source enviorment (Benchmark)' | translate }}
                                </mat-checkbox>
                            </div>
                            <div class="col-8"></div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" (click)="loadWhatIf === true ? saveWhatIf() : fileChanged()"
                [disabled]="progress > 0 || isSaveFlag">{{ 'Save' | translate }}</button>
            <button type="button" class="btn btn-default" (click)="closeForPopup()">{{ 'Close' | translate }}</button>
        </div>
    </form>
</ng-template> -->



<!-- Load the file from Local/Production -->
<ng-template #templateLoad tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left" *ngIf="loadWhatIf === true">{{ 'Load From Production' | translate }}</h5>
                <h5 class="modal-title pull-left" *ngIf="loadWhatIf === false">{{ 'Load From Local' | translate }}</h5>
                <button type="button" class="btn-close" (click)="closeForPopup()"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="whatIfLoadForm" role="form">
                    <!--progressbar-->
                    <div class="progress" role="progressbar" *ngIf="progress > 0">
                        <div class="progress-bar progress-bar-striped bg-info" [style.width.%]="progress">{{progress}}%</div>
                    </div>
                    <!--form-->
                    <div class="row">
                        <div class="col-12">
                            <h6>{{ 'Notes' | translate }} :</h6>
                            <input id="notes" class="form-control" type="text" placeholder="{{ 'Notes' | translate }}" formControlName="notes" maxlength="100">
                        </div>
                        <!--ONLY load from LOCAL-->
                        <div class="col-12" *ngIf="loadWhatIf === false">
                            <div class="card mt-2 p-3">
                                <h6 class="card-title">{{ 'Local File Upload' | translate }} :</h6>
                                <input id="file1" type="file" class="hidden" accept="{{acceptType}}" ng2FileSelect [uploader]="uploader" (change)="getFileForLoad()" />
                                <div *ngIf="fileErrorMessage" class="cds-form-control-error">{{fileErrorMessage | translate }}</div>
                            </div>
                        </div>
                        <!--ONLY load from PROD-->
                        <div class="col-12" *ngIf="loadWhatIf === true">
                            <div class="card mt-2 p-3">
                                <!--DATE-->
                                <div class="col-12 mb-3">
                                    <div class="card mt-2 p-3">
                                        <h6 class="card-title">{{ 'Date' | translate }} :</h6>
                                        <div class="row">
                                            <div class="col-6">
                                                <label class="form-label">{{ 'Date from' | translate }}</label>
                                                <div class="input-group">
                                                    <input
                                                        [readonly]="true"
                                                        (ngModelChange)="dateChanged($event)"
                                                        class="form-control"
                                                        placeholder="yyyy-mm-dd"
                                                        name="dp"
                                                        formControlName="fromDate"
                                                        standalone="true"
                                                        ngbDatepicker
                                                        #d="ngbDatepicker"
                                                    />
                                                    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button">
                                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <label class="form-label">{{ 'Date to' | translate }}</label>
                                                <div class="input-group">
                                                    <input
                                                        [readonly]="true"
                                                        (ngModelChange)="dateChanged($event)"
                                                        class="form-control"
                                                        placeholder="yyyy-mm-dd"
                                                        name="dp"
                                                        formControlName="toDate"
                                                        standalone="true"
                                                        ngbDatepicker
                                                        #d2="ngbDatepicker"
                                                    />
                                                    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d2.toggle()" type="button">
                                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="cds-form-control-error"  *ngIf="isDateValidation">
                                                {{ "INVALID DATE RANGE : From date should not be greater than to date." | translate }}
                                            </div>
                                        </div>
                                        <!-- <div class="row">
                                            <mat-form-field class="col-6" appearance="outline">
                                                <mat-label>{{ 'Date from' | translate }}</mat-label>
                                                <input matInput (dateChange)="dateChanged()" [matDatepicker]="pickerFrom"
                                                    [readonly]="true" placeholder="{{ 'Date From' | translate }}"
                                                    formControlName="fromDate">
                                                <mat-datepicker-toggle matSuffix [for]="pickerFrom">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker type="time" #pickerFrom></mat-datepicker>
                                            </mat-form-field>
                                            <mat-form-field class="col-6" appearance="outline">
                                                <mat-label>{{ 'Date to' | translate }}</mat-label>
                                                <input matInput (dateChange)="dateChanged()" [matDatepicker]="pickerTo"
                                                    [readonly]="true" placeholder="{{ 'Date To' | translate }}"
                                                    formControlName="toDate">
                                                <mat-datepicker-toggle matSuffix [for]="pickerTo">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #pickerTo></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12" *ngIf="isDateValidation" style="color: red;">
                                            {{ "INVALID DATE RANGE : From date should not be greater than to date." | translate }}
                                        </div> -->
                                    </div>
                                </div>
                                <!--EXTRACTION-->
                                <h6 class="card-title mb-2">{{ 'Extraction Criteria' | translate }} :</h6>
                                <div class="card p-2">
                                    <div class="row mb-2">
                                        <div class="col-4 mt-2">
                                            <div class="form-check">
                                                <label class="form-label"> {{ 'Process Version' | translate }} </label>
                                                <input class="form-check-input" type="checkbox" formControleName="processVersionFlag" (change)="checkProcessVersion($event)">
                                            </div>
                                        </div>
                                        <div class="col-8">
                                            <input placeholder="{{ 'Number' | translate }}" class="form-control" [disabled]="processVFlag" formControlName="processVersion" maxlength="100">
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-4 mt-2">
                                            <div class="form-check">
                                                <label class="form-label"> {{ 'Request Code' | translate }} </label>
                                                <input class="form-check-input" type="checkbox" formControleName="requestCodeFlag" (change)="checkRequestCode()">
                                            </div>
                                        </div>
                                        <div class="col-8">
                                            <input placeholder="{{ 'Request Code' | translate }}" class="form-control" [disabled]="requestCFlag" formControlName="requestID" maxlength="100">
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-4 mt-2">
                                            <div class="form-check">
                                                <label class="form-label"> {{ 'Last Call Per Request' | translate }} </label>
                                                <input class="form-check-input" type="checkbox" formControleName="lastCallPerRequest" (click)="lastRequest()">
                                            </div>
                                        </div>
                                        <div class="col-8"></div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-4 mt-2">
                                            <div class="form-check">
                                                <label class="form-label"> {{ 'Request Status' | translate }} </label>
                                                <input class="form-check-input" type="checkbox"  formControleName="requestStatusFlag" (click)="checkRequestStatus()">
                                            </div>
                                        </div>
                                        <div class="col-8">
                                            <ng-select 
                                                [items]="requestStatusOptions"
                                                bindLabel="viewValue"
                                                bindValue="value"
                                                placeholder="{{ 'Request Status' | translate }}"
                                                formControlName="extractMode">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-4 mt-2">
                                            <div class="form-check">
                                                <label class="form-label"> {{ 'Execution Time' | translate }} </label>
                                                <input class="form-check-input" type="checkbox" formControleName="executionTimeFlag" (click)="checkExecutionTime()">
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <input placeholder="{{ 'From (ms)' | translate }}" class="form-control" [disabled]="execTFlag" formControlName="fromExecTime" maxlength="100">
                                        </div>
                                        <div class="col-4">
                                            <input placeholder="{{ 'To (ms)' | translate }}" class="form-control" [disabled]="execTFlag" formControlName="toExecTime" maxlength="100">
                                        </div>
                                    </div>

                                </div>
                                <!--SAMPLING-->
                                <h6 class="card-title mb-2 mt-3">{{ 'Sampling' | translate }} :</h6>
                                <div class="row mb-2">
                                    <div class="col-6 mt-2">
                                        <input placeholder="{{ 'Percentage (1-100 %)' | translate }}" class="form-control" formControlName="sampleInterval" min="1" max="100" maxlength="3">
                                    </div>
                                    <div class="col-6">
                                        <input placeholder="{{ 'Max. number of requests' | translate }}" class="form-control" formControlName="maxRequest" maxlength="100">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--REPORTING-->
                        <div class="col-12">
                            <div class="card mt-2 p-3">
                                <h6 class="card-title">{{ 'I/O and Reporting Data' | translate }} :</h6>
                                <div class="form-check">
                                    <label class="form-label"> {{ 'Fast What-if (No - What-if/Reporting analysis allowed)' | translate }} </label>
                                    <input class="form-check-input" type="checkbox" [disabled]="true" [checked]="true" formControleName="fastWhatIf">
                                </div>
                                <div class="form-check">
                                    <label class="form-label">{{ 'Enable Dynamic Simulation' | translate }}</label>
                                    <input class="form-check-input" type="checkbox" formControleName="enableDyanamicSimulation">
                                </div>
                            </div>
                        </div>
                        <!--OUTPUT-->
                        <div class="col-12" *ngIf="loadWhatIf === true">
                            <div class="card mt-2 p-3">
                                <h6 class="card-title">{{ 'Output Comparison' | translate }} :</h6>
                                <div class="form-check">
                                    <label class="form-label"> {{ 'Extraction Output from the source enviorment (Benchmark)' | translate }} </label>
                                    <input class="form-check-input" type="checkbox"  formControleName="extractOutput" (click)="changeExtractOutput()">
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" (click)="loadWhatIf === true ? saveWhatIf() : fileChanged()"
                    [disabled]="progress > 0 || isSaveFlag">
                    {{ 'Save' | translate }}
                </button>
                <button type="button" class="btn btn-default" (click)="closeForPopup()">
                    {{ 'Close' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
</ng-container>