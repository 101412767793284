import { PmmlDataDictionary } from "./bac-pmmldatadictionary";
import { ModelType } from "./bac-modeltype.enum";

export class PmmlModel{
    id:string;
    passwordHash?:string;
    description?:string;
    reporting?:boolean;
    bac?:boolean;
    editable: boolean;
    pmmlDataDictionary?:PmmlDataDictionary;
    fileName:string;
    pmmlFileContent:any; //java type=byte[]
    guid:string;
    ownerID?:string;
    sharedGUID?:string;
    version:string;
    modelType:ModelType;
    crc?:string;

    //initialize required variables to default values
    constructor() {
        this.id="";
        this.fileName="";
        this.pmmlFileContent="";  
        this.guid=""; 
        this.version ="";
        this.modelType=ModelType.NULL_VALUE;
    }
}