<div class="alert cds-alert alert-danger col-12" role="alert" *ngIf="isPermissionError">
    <div class="cds-alert-icon">
      <i class="fa-solid fa-triangle-exclamation"></i>
    </div>
    <div class="cds-alert-content">{{"You do not have permission to access this page." | translate}}</div>
</div>

<appspinner [startSpinner]="processManagementService.showAppLoader"></appspinner>
<h6 class=" mb-1">{{ 'Available operations for process :' | translate }} 
    <h6 class="badge cds-badge active text-bg-info">{{processManagementService.currentProcessName}}</h6>
</h6>
<div class="row card cds-card-shadow" *ngIf="!processManagementService.showAppLoader && !isPermissionError">
    <div class="card-body">
        <div class="col-sm-12">
            <div class="col-12 mb-4">
                <input class="form-control searchBox" placeholder="{{ 'Quick Filter' | translate}}" [(ngModel)]="searchText">
            </div>
            <div class="col-sm-12">
                <div class="scroll-only-x">
                    <table aria-describedby="table-proc-opr" class="table table-striped"
                        [mfData]="tablesData | filter :'id,description,expression,condition,filter': searchText"
                        #mf="mfDataTable" [mfRowsOnPage]="15" mfSortBy="id">
                        <thead>
                            <tr>
                                <th class="actions-col">
                                    {{'Action' | translate}}
                                </th>
                                <th class="actions-col" *ngIf="shared == 'true'"> Override </th>
                                <th>
                                    <mfDefaultSorter by="id">{{ 'Code' | translate }}</mfDefaultSorter>
                                </th>
                                <th>
                                    <mfDefaultSorter by="description">{{ 'Notes' | translate }}</mfDefaultSorter>
                                </th>
                                <th>
                                    <mfDefaultSorter by="expression">{{ 'Expression' | translate }}</mfDefaultSorter>
                                </th>
                                <th>
                                    <mfDefaultSorter by="condition">{{ 'Condition' | translate }}</mfDefaultSorter>
                                </th>
                                <th>
                                    <mfDefaultSorter by="filter">{{ 'Filter' | translate }}</mfDefaultSorter>
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="categoryVariableMap">
                            <tr *ngFor="let value of mf.data let index"
                                [ngStyle]="{'background-color': value.id === clickedValueId ? 'antiquewhite' : '' }">
                                <td class="center-align text-nowrap">
                                    <button [disabled]="isDisableEdit" class="btn btn-sm btn-primary"
                                        (click)="openTemplate(templateEdit,'Edit',value)" title="{{ 'Edit' | translate }}">
                                        <span class="fa fa-pencil"></span>
                                    </button>
                                </td>
                                <td class="text-center text-secondary" *ngIf="shared == 'true'"> 
                                    <i *ngIf="value.override.enabled"  class="fa fa-info-circle" aria-hidden="true"  data-toggle="tooltip" data-placement="top" title="Original value: {{value.expression}}"></i> 
                                </td>
                                <td>{{value.id}}</td>
                                <td>{{value.description}}</td>
                                <td>{{value.override.enabled? value.override.expression : value.expression | VariableNamefilter: categoryVariableMap }}</td>
                                <td>{{value.condition | VariableNamefilter: categoryVariableMap }}</td>
                                <td>{{value.filter | VariableNamefilter: categoryVariableMap }}</td>
                            </tr>
                        </tbody>
                        <tfoot
                            *ngIf="(tablesData | filter :'id,description,expression,condition,filter': searchText).length > 15">
                            <tr>
                                <td colspan="10">
                                    <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="alert cds-alert alert-danger col-12" role="alert" *ngIf="tablesData.length===0">
                        <div class="cds-alert-icon">
                          <i class="fa-solid fa-triangle-exclamation"></i>
                        </div>
                        <div class="cds-alert-content">{{ 'No records found.' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <ng-template #templateEdit>
    <form [formGroup]="operationForm">
        <div class="modal-header">
            <h4 class="modal-title pull-left">{{ "Edit Operation" | translate }} : {{selectedRow.id}}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="cancelWindow()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-sm-12">
                <fieldset style="height: auto;width:auto">
                    <legend><span class="accessibility">Operation Form</span></legend>
                    <mat-form-field class="col-sm-12" appearance="outline">
                        <mat-label>{{ 'Notes' | translate }}</mat-label>
                        <input matInput placeholder="{{ 'Notes' | translate }}" formControlName="descriptionField">
                    </mat-form-field>
                    <mat-form-field class="col-sm-12" appearance="outline">
                        <mat-label>{{ 'Expression' | translate }}</mat-label>
                        <input matInput formControlName="expressionField" placeholder="{{ 'Expression' | translate }}">
                        <mat-error>
                            {{!operationForm.controls['expressionField'].hasError('required') ? expressionValidationMsg
                            : expressionRequiredMsg }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-sm-12" appearance="fill">
                        <mat-label>{{ 'Condition' | translate }}</mat-label>
                        <input matInput placeholder="{{ 'Condition' | translate }}" [readonly]="true"
                            value="{{selectedRow.condition | VariableNamefilter: categoryVariableMap}}">
                    </mat-form-field>
                    <mat-form-field class="col-sm-12" appearance="fill">
                        <mat-label>{{ 'Filter' | translate }}</mat-label>
                        <input matInput placeholder="{{ 'Filter' | translate }}" [readonly]="true"
                            value="{{selectedRow.filter | VariableNamefilter: categoryVariableMap}}">
                    </mat-form-field>
                </fieldset>
            </div>
        </div>
        <div class="modal-footer">
            <button class="col-sm-12" type="submit" (click)="save()" class="btn btn-primary m-b-0">
                {{ 'Save' | translate }}
            </button>
            <button type="button" class="btn btn-default" (click)="cancelWindow()">{{ 'Close' | translate }}</button>
        </div>
    </form>
</ng-template> -->
<ng-template #templateEdit tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>Operation : {{selectedRow.id}}</h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="operationForm">
                    <div class="col-12">
                        <label for="notes" class="form-label">{{ 'Notes' | translate }}</label>
                        <input type="text" id="notes" placeholder="{{ 'Notes' | translate }}" 
                        class="form-control" formControlName="descriptionField">
                        <label for="expression" class="form-label mt-2">{{ 'Expression' | translate }}</label>
                        <input type="text" id="expression" placeholder="{{ 'Expression' | translate }}" 
                        class="form-control" formControlName="expressionField">
                        <div class="cds-form-control-error" *ngIf="operationForm.controls['expressionField'].errors && operationForm.controls['expressionField'].touched">                                
                            {{!operationForm.controls['expressionField'].hasError('required') ? expressionValidationMsg
                            : expressionRequiredMsg }}
                        </div>
                        
                        <label for="condition" class="form-label mt-3">{{ 'Condition' | translate }}</label>
                        <input type="text" id="condition" placeholder="{{ 'Condition' | translate }}" 
                        class="form-control" value="{{selectedRow.condition | VariableNamefilter: categoryVariableMap}}" disabled>

                        <label for="filter" class="form-label mt-2">{{ 'Filter' | translate }}</label>
                        <input type="text" id="filter" placeholder="{{ 'Filter' | translate }}" 
                        class="form-control" value="{{selectedRow.filter | VariableNamefilter: categoryVariableMap}}" disabled>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm  btn-primary m-b-0" (click)="save()">
                    {{ 'Save' | translate }}
                </button>
                <button type="button" class="btn btn-sm  btn-light m-b-0" (click)="modal.dismiss()">
                    {{ 'Close' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>