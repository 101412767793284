
import { S1ProcessID } from './s1-processid';
import { S1UserID } from './userid';

export class ProcessUserRequest {
  processID: S1ProcessID;
  userID: S1UserID;
  constructor(processID: S1ProcessID, userID: S1UserID) {
    this.processID = processID;
    this.userID = userID;
  }
}