import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessManagementRoutingModule } from './process-management-routing.module';
import { TreeTableModule } from 'primeng/treetable';
import { SharedModule } from 'app/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OperationsComponent } from './operations/operations.component';
import { LiabrariesComponent } from './liabraries/liabraries.component';
import { TablesComponent } from './tables/tables.component';
import { CampaignComponent } from './campaign/campaign.component';
import { PmmlComponent } from './pmml/pmml.component';
import { PythonComponent } from './python/python.component';
import { WhatifComponent } from './whatif/whatif.component';
import { ProcessComponent } from './process-list/Process/process.component';
import { ProcessListComponent } from './process-list/process-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService } from 'app/shared/alert/alert-service';
import { ConfirmService } from 'app/shared/confirm/confirm.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ProcessManagementService } from 'app/core/services/process-management.service';
import { HttpLoaderFactory } from 'app/app.module';
import { DataTableModule } from 'app/shared/angular2-datatable/DataTableModule';
import { AppSpinnerModule } from 'app/shared/app-loader/appspinner.module';


@NgModule({
  imports: [
    CommonModule,
    ProcessManagementRoutingModule,
    TreeTableModule,
    TranslateModule,
    SharedModule,
  //   TranslateModule.forRoot({
  //     loader: {
  //         provide: TranslateLoader,
  //         useFactory: HttpLoaderFactory,
  //         deps: [HttpClient]
  //     }
  // }),
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    HttpClientModule,
    DataTableModule,
    AppSpinnerModule
  ],
  declarations: [
    ProcessComponent,
    OperationsComponent,
    LiabrariesComponent,
    TablesComponent,
    CampaignComponent,
    PmmlComponent,
    PythonComponent,
    WhatifComponent,
    ProcessListComponent,
    
  ],
  exports: [],
  providers: [AlertsService, ConfirmService,ProcessManagementService]
})
export class ProcessManagementModule { }
