export enum ProcessIOType {
    //default value
    NULL_VALUE="",
    //from jaxb generated ProcessIOType
    COBOL="COBOL",
    XML_EVO="XML_EVO",
    JSON_EVO="JSON_EVO",
    //from wsd wsinterfaces ProcessIOType
    XML="XML",
    FLAT="FLAT",
    XMLEVO="XMLEVO",
    JSONEVO="JSONEVO"
}