import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'operatorPipe'
})
export class OperatorMappingFilter implements PipeTransform {
    public transform(dbvalue) {
        let operator: string;
        switch (dbvalue) {
            //Operator
            case "EQ":
                operator = "(=)";
                break;
            case "GE":
                operator = "(>=)";
                break;
            case "GT":
                operator = "(>)";
                break;
            case "LE":
                operator = "(<=)";
                break;
            case "LT":
                operator = "(<)";
                break;
            case "NE":
                operator = "(<>)";
                break;
            default:
                operator = "(NONE)";
        }
        return operator;
    }
}