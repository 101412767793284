import { Component, EventEmitter } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirm-component',
    template: `
    <div class="modal-header">
        <h6 class="modal-title m-auto">{{titleKey}}</h6>
    </div>
    <div class="modal-body text-center">{{messageKey}}</div>
    <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-primary btn-sm" (click)="accept($event)">{{acceptKey}}</button>
        <button type="button" class="btn btn-secondary btn-sm" (click)="cancel($event)">{{cancelKey}}</button>
    </div>
`
})
export class ConfirmComponent {

    public bsModalRef!: NgbModalRef;
    public messageKey!: string;
    public titleKey!: string;
    public acceptKey!: string;
    public cancelKey!: string;
    public closed: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() { }

    public accept(ev: Event) {
        if(ev)
            ev.preventDefault();
        this.bsModalRef.close();
        this.closed.emit(true);
    }
    public cancel(ev: Event) {
        if(ev)
            ev.preventDefault();
        this.bsModalRef.close();
        this.closed.emit(false);
    }
}