import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { AuthGuard } from 'app/core/guards/authguard';
import { OperationsComponent } from './operations/operations.component';
import { LiabrariesComponent } from './liabraries/liabraries.component';
import { TablesComponent } from './tables/tables.component';
import { CampaignComponent } from './campaign/campaign.component';
import { PmmlComponent } from './pmml/pmml.component';
import { PythonComponent } from './python/python.component';
import { WhatifComponent } from './whatif/whatif.component';
import { ProcessComponent } from './process-list/Process/process.component';
import { ProcessListComponent } from './process-list/process-list.component';
import { CanDeactivateGuard } from 'app/core/guards/candeactivateguard';
import { RouteCheck } from 'app/core/guards/route-check.service';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'process-list',
        component: ProcessListComponent,
        data: {
          expectedRole: ['SYSTEM_ADMIN', 'MANAGER', 'SECURITY_ADMIN','USER'],
        },
        canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuard, RouteCheck]
      },
      {
        path: 'process',
        component: ProcessComponent,
        data: {
          expectedRole: ['SYSTEM_ADMIN', 'MANAGER', 'USER'],
        },
        canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuard, RouteCheck]
      },{
        path: 'operation',
        component: OperationsComponent,
        data: {
          expectedRole: ['SYSTEM_ADMIN', 'MANAGER', 'USER'],
        },
        canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuard, RouteCheck]
      },{
        path: 'liabraries',
        component: LiabrariesComponent,
        data: {
          expectedRole: ['SYSTEM_ADMIN', 'MANAGER', 'USER'],
        },
        canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuard, RouteCheck]
      },{
        path: 'tables',
        component: TablesComponent,
        data: {
          expectedRole: ['SYSTEM_ADMIN', 'MANAGER', 'USER'],
        },
        canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuard, RouteCheck]
      },{
        path: 'campaign',
        component: CampaignComponent,
        data: {
          expectedRole: ['SYSTEM_ADMIN', 'MANAGER', 'USER'],
        },
        canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuard, RouteCheck]
      },{
        path: 'pmml',
        component: PmmlComponent,
        data: {
          expectedRole: ['SYSTEM_ADMIN', 'MANAGER', 'USER'],
        },
        canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuard, RouteCheck]
      },{
        path: 'python',
        component: PythonComponent,
        data: {
          expectedRole: ['SYSTEM_ADMIN', 'MANAGER', 'USER'],
        },
        canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuard, RouteCheck]
      },{
        path: 'whatif',
        component: WhatifComponent,
        data: {
          expectedRole: ['SYSTEM_ADMIN', 'MANAGER', 'USER'],
        },
        canActivate: [AuthGuard],canDeactivate: [CanDeactivateGuard, RouteCheck]
      }
    ]
  }
];
// , RouteCheck
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProcessManagementRoutingModule { }
