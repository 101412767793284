import { S1ProcessInfo } from "./s1-processInfo";
import { WhatIfExtractMode } from "./s1-whatifExtractStatus.enum";
import { Environment } from "./s1-environment.enum";
import { UntypedFormGroup } from "@angular/forms";

export class S1DownloadRequestInfo {
    processInfo: S1ProcessInfo;
    fromDate: Date;
    toDate: Date;
    whatifFileName: string;
    outRefFileName: string;
    sampleInterval: number;
    numberOfRecords: number;
    extractOutput: boolean;
    extractMode: WhatIfExtractMode = WhatIfExtractMode.DONE;
    requestID: string;
    lastCallPerRequest: boolean;
    fromExecTime: number;
    toExecTime: number;
    processVersion: string;
    layoutVersion: string;
    maxRequest: number;
    source: Environment = Environment.PRODUCTION;
    numberOfRecordsFailed: number;
    elabTime: number;


    constructor(formVal: UntypedFormGroup) {
        this.processInfo = formVal.value.processInfo;
        this.fromDate = formVal.controls['fromDate'] ? formVal.value.fromDate
            : new Date(formVal.value.nYear, formVal.value.nMonth, formVal.value.nDay,
                formVal.value.nHour, formVal.value.nMinute, formVal.value.nSecond, formVal.value.nMillisecond);
        this.toDate = formVal.controls['toDate'] ? formVal.value.toDate
            : new Date(formVal.value.nYear, formVal.value.nMonth, formVal.value.nDay,
                formVal.value.nHour, formVal.value.nMinute, formVal.value.nSecond, formVal.value.nMillisecond);
        this.whatifFileName = formVal.value.whatifFileName;
        this.outRefFileName = formVal.value.outRefFileName;
        this.sampleInterval = "" === formVal.value.sampleInterval ? 0 : formVal.value.sampleInterval;
        this.numberOfRecords = "" === formVal.value.numberOfRecords || null === formVal.value.numberOfRecords ? 0 : formVal.value.numberOfRecords;
        this.extractOutput = "" === formVal.value.extractOutput ? false : formVal.value.extractOutput;
        this.requestID = formVal.value.requestID;
        this.lastCallPerRequest = null === formVal.value.lastCallPerRequest || "" === formVal.value.lastCallPerRequest ? false : formVal.value.lastCallPerRequest;
        this.fromExecTime = formVal.value.fromExecTime;
        this.toExecTime = formVal.value.toExecTime;
        this.processVersion = formVal.value.processVersion;
        this.layoutVersion = formVal.value.layoutVersion;
        this.maxRequest = null === formVal.value.maxRequest || "" === formVal.value.maxRequest ? 0 : formVal.value.maxRequest;
        this.numberOfRecordsFailed = "" === formVal.value.numberOfRecordsFailed || null === formVal.value.numberOfRecordsFailed ? 0 : formVal.value.numberOfRecordsFailed;
        this.elabTime = formVal.value.elabTime;
    }
}