import { S1ProcessID } from "./s1-processid";
import { S1PythonModelState } from "./s1-pythonmodelstate.enum";

export class S1PythonModel {
    processID: S1ProcessID = new S1ProcessID();
    port: number;
    uploadedPackageGUID: string;
    processDisplayName: string;
    processDisplayVersion: number;
    autoStart: boolean;
    state: S1PythonModelState;
    executionState: S1PythonModelState;
    pythonObjectGuid: string;
    //additional vars for UI
    viewState?: string;
}