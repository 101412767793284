import { Component, OnInit } from '@angular/core';
import { ProcessManagementService } from 'app/core/services/process-management.service';
import { TreeObject } from 'app/entities/treeNode.model';
import { S1EntityManagementService } from 'app/core/services/s1entity-management.service';
import { StorageDetails } from 'app/core/services/user-details.service';
import { MenuItems } from 'app/shared/menu-items/menu-items';
import { S1ProcessID } from 'app/entities/s1-processid';

@Component({
  selector: 'app-process-list',
  templateUrl: './process-list.component.html',
  styleUrls: ['./process-list.component.scss']
})
export class ProcessListComponent implements OnInit {

  files1: TreeObject[];
  selectedNode: TreeObject;
  showLoginUserName: string;
  type: string;
  cols: any[];
  isROLE_SECURITY_ADMIN: boolean = false;

  constructor(public nodeService: ProcessManagementService, private entityManagementService: S1EntityManagementService,
    private userDetails: StorageDetails, public menuItems: MenuItems) { }

  ngOnInit() {
    if (this.userDetails.getUserRole() === "SECURITY_ADMIN") {
      this.isROLE_SECURITY_ADMIN = true;
      return;
    }
    this.showLoginUserName = this.userDetails.getLoginUserId();
    this.reloadPageData();
  }
  public reloadPageData() {
    this.nodeService.showAppLoader = true;
    this.entityManagementService.getPermissionsStructure(this.userDetails.getUserId()).subscribe({
      next: (response) => {
        this.files1 = response;
        this.files1 = [...this.files1];
        this.files1[0].expanded = true;
        this.files1[0].children.forEach(child => child.expanded = false);
        this.nodeService.showAppLoader = false;
      },
      error: (err) => {
        this.nodeService.showAppLoader = false;
      }
    });

  }

  nodeSelect(event) {    
    if (!event.node.data.type) {
      this.nodeService.selectedNode = null;
      this.nodeService.currentProcessDetails = null;
      this.nodeService.currentProcessName = null;
      return;
    }
    if (event.node.data.type !== 'process') {
      this.nodeService.selectedNode = event.node;
      this.nodeService.currentProcessDetails = null;
      this.nodeService.currentProcessName = null;
      return;
    }
    this.nodeService.showAppLoader = true;
    this.nodeService.selectedNode = event.node;
    this.type = this.nodeService.selectedNode.data.type;
    this.nodeService.setSelectedProcessData(this.nodeService.selectedNode.data);
    //if node is already opened in sidebar then update bacProcessNode
    let procId: S1ProcessID = this.nodeService.getProcessID();
    if (procId) {
      this.nodeService.getSelectedProcessInfo(procId).then((value) => {
        console.log("sidebar update req.");

        //highlight sidebar proc and update submenu
        this.nodeService.setSelectedProcessData(this.nodeService.currentProcessDetails);
        this.nodeService.sidebarProcName = this.nodeService.currentProcessName;
        this.nodeService.addSubChildMenu(this.nodeService.sidebarProcName);
        this.nodeService.showAppLoader = false;
      });
    } else {
      this.nodeService.showAppLoader = false;
    }
  }

  getColor(rowNode: any) {
    if (this.nodeService.selectedNode
      && rowNode
      && rowNode.s1ProcessInfo
      && this.nodeService.selectedNode.data
      && (this.nodeService.selectedNode.data.s1ProcessInfo
        && this.nodeService.selectedNode.data.s1ProcessInfo.nameDisplay
        === rowNode.name + " (" + rowNode.s1ProcessInfo.revision + ")")
    ) {
      //TODO add condition for parent nodes too
      return 'blue';
    } else {
      return 'black';
    }

  }

  getColorForGroup(rowData: string){
    if (this.nodeService.selectedNode
      && this.nodeService.selectedNode.data.name ==rowData) {
      //TODO add condition for parent nodes too
      return 'blue';
    } else {
      return 'black';
    }
  }

  getCursor(rowNode: any) {
    if (rowNode) {
      return 'pointer';
    } else {
      return 'auto';
    }
  }
}