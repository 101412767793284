import { Pipe, PipeTransform } from '@angular/core';
import { ProcessType } from "app/entities/bac-processtype.enum";
@Pipe({
    name: 'processTypeValue'
})
export class ProcessTypeValuePipe implements PipeTransform {
    public transform(dbvalue) {
        let viewVal:string;
        if(dbvalue === ProcessType.Behavioural || dbvalue === ProcessType.BEHAVIOURAL || dbvalue === ProcessType.BEHAVIORAL){
            viewVal="BEHAVIORAL";
        } else if(dbvalue === ProcessType.Application || dbvalue === ProcessType.APPLICATION){
            viewVal="APPLICATION";
        }
        return viewVal;
    }
}