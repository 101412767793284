<div class="row">
    <div class="col-12">
        <div class="card cds-card-shadow">
            <div class="card-body">
                <div class="row">
                    <!--ACTIONS-->
                    <div class="col-12">
                        <button [disabled]="showAppLoader || currentUserAuthMode=='OAUTH2'" type="button" class="btn btn-primary btn-sm"
                            (click)="open(usertemplate,'New', null)">
                            <i class="fa fa-plus-circle" title="{{ 'Create New User' | translate }}"></i>
                        </button>
                        &nbsp;
                        <button [disabled]="showAppLoader" *ngIf="!fetchExpired" type="button"
                            class="btn btn-primary btn-sm" (click)="fetchAllExpired()" title="{{ 'Expired Users' | translate }}">
                            <i class="fa fa-users"></i>
                        </button>

                        <button [disabled]="showAppLoader" *ngIf="fetchExpired" type="button" class="btn btn-primary btn-sm"
                            (click)="fetchAllExpired()" title="{{ 'Active Users' | translate }}">
                            <i class="fa fa-users blackcolor"></i>
                        </button>
                        &nbsp;
                        <button [disabled]="showAppLoader" type="button" class="btn btn-primary btn-sm"
                            (click)="fetchAll(true)" title="{{ 'Refresh' | translate }}">
                            <i class="fa fa-refresh"></i>
                        </button>
                        &nbsp;
                        <button  type="button" class="btn btn-primary btn-sm" [disabled]="listaUtentiDaStampareProva.length === 0"
                                (click)="exportAsXLSX();">
                            <i class="fa fa-print" title="Print User"></i>
                        </button>
                    </div>
                    <!--FILTER-->
                    <div class="col-12 mt-3 mb-2">
                        <input class="form-control searchBox" placeholder="{{ 'Quick Filter' | translate}}" [disabled]="showAppLoader" [(ngModel)]="searchText">
                    </div>
                    <appspinner [startSpinner]="showAppLoader"></appspinner>
                    <div class="alert cds-alert alert-danger" role="alert" *ngIf="isError && errorMessage">
                        <div class="cds-alert-icon">
                          <i class="fa-solid fa-triangle-exclamation"></i>
                        </div>
                        <div class="cds-alert-content">{{errorMessage | translate}}</div>
                    </div>
                    <!--TABLE-->
                    <div class="col-12 table-responsive">
                        <table aria-describedby="table-userManagement" class="table table-striped table-sm"
                            [mfData]="userDetails | filter :'loginCredentials.userId,loginCredentials.domain,name,surname,language,role,loggedAtDisplay,expiredOnDisplay' :searchText :false"
                             #mf="mfDataTable" [mfRowsOnPage]="15">
                            <thead>
                                <tr>
                                  <th class="text-center">
                                    <input id="checkbox" class="form-check-input"
                                           type="checkbox"
                                           (change)="toggleAllCheckboxes(mf.data, userDetails,true)"

                                    >
                                  </th>
                                    <th>
                                        {{ 'Action' | translate}}
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="loginCredentials.userId">{{ 'UserID' | translate }}
                                        </mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="loginCredentials.domain">{{ 'Domain' | translate }}
                                        </mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="name">{{ 'Name' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="surname">{{ 'Last Name' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="language">{{ 'Language' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="role">{{ 'Role' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="loggedAtDisplay">{{ 'Logged At' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="expiredOnDisplay">{{ 'Expiry Day' | translate }}</mfDefaultSorter>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let user of mf.data  let i = index">
                                    <ng-container *ngIf="fetchExpired; then allUsersList else activeUsersList">
                                        Flow will never come here.
                                    </ng-container>
                                    <ng-template #allUsersList>
                                        <tr>
                                          <td class="center-align text-nowrap"  style="width:10%;text-align:center;" class="p-2">
                                          <input  id="{{user.loginCredentials.userId+user.loginCredentials.domain}}" class="form-check-input" [(ngModel)]='selectedCheckboxes[user.loginCredentials.userId+user.loginCredentials.domain]'
                                                  type="checkbox"
                                                  [disabled]="isAllChecked"
                                                  (change)="utenteSelezionato($event, user);"
                                          >

                                        </td>
                                            <td class="center-align text-nowrap">

                                                <button [disabled]="showAppLoader || user.role === 'APPLICATION'"
                                                    class="btn btn-sm btn-primary" (click)="open(usertemplate,'Edit',user)"
                                                    title="{{ 'Edit' | translate }}">
                                                    <span class="fa fa-pencil"></span>
                                                </button>
                                                &nbsp;
                                                <button class="btn btn-sm btn-primary"
                                                    (click)="open(usertemplate,'Delete',user)"
                                                    [disabled]="(user.expiredOn !== null && currentDateTime > (+user.expiredOn))|| showAppLoader">
                                                    <span class="fa fa-minus-circle" title="{{ 'Delete' | translate }}"></span>
                                                </button>
                                            </td>
                                            <td class="text-nowrap">
                                                <img *ngIf="user.logged === true" alt="i-userRoleColor"
                                                    src="{{user.role === 'USER' ? 'assets/img/icon-coloured-role-user.png' : user.role === 'SYSTEM_ADMIN' ? 'assets/img/icon-coloured-role-system-admin.png' : user.role === 'SECURITY_ADMIN' ? 'assets/img/icon-coloured-role-security-admin.png' : user.role === 'MANAGER' ? 'assets/img/icon-coloured-role-manager.png' : 'assets/img/icon-coloured-role-application.svg'}}"
                                                    style="width: 24px; height: 24px;">
                                                <img *ngIf="user.logged === false" alt="i-userRoleBlackNWhite"
                                                    src="{{'assets/img/icon-outline-user.svg'}}">
                                                {{user.loginCredentials.userId | uppercase}}
                                            </td>
                                            <td class="text-nowrap">{{user.loginCredentials.domain}}</td>
                                            <td class="text-nowrap">{{user.name}}</td>
                                            <td class="text-nowrap">{{user.surname}}</td>
                                            <td class="text-nowrap">{{user.language}}</td>
                                            <td class="text-nowrap">{{(user.role === 'USER' ? 'Simple User'
                                                : user.role === 'SYSTEM_ADMIN' ? 'System Administrator'
                                                : user.role === 'MANAGER' ? 'Manager'
                                                : user.role === 'SECURITY_ADMIN' ? 'Security Administrator'
                                                : user.role === 'APPLICATION' ? 'Application User'
                                                : user.role) | translate }}
                                            </td>
                                            <td class="text-nowrap">{{user.loggedAtDisplay}}</td>
                                            <td class="text-nowrap">{{user.expiredOnDisplay}}</td>
                                        </tr>
                                    </ng-template>
                                    <ng-template #activeUsersList>
                                        <tr *ngIf="user.expiredOn === null || currentDateTime < user.expiredOn">
                                            <td class="center-align text-nowrap"  style="width:10%;text-align:center;" class="p-2">
                                                <input  id="{{user.loginCredentials.userId+user.loginCredentials.domain}}" class="form-check-input" [(ngModel)]='selectedCheckboxes[user.loginCredentials.userId+user.loginCredentials.domain]'
                                                        type="checkbox"
                                                        [disabled]="isAllChecked"
                                                        (change)="utenteSelezionato($event, user);"
                                                >
                                            </td>
                                            <td class="center-align text-nowrap">
                                                <button [disabled]="showAppLoader || user.role === 'APPLICATION'"
                                                    class="btn btn-sm btn-primary" (click)="open(usertemplate,'Edit',user)"
                                                    title="{{ 'Edit' | translate }}">
                                                    <span class="fa fa-pencil"></span>
                                                </button>
                                                &nbsp;
                                                <button class="btn btn-sm btn-primary"
                                                    (click)="open(usertemplate,'Delete',user)"
                                                    [disabled]="(user.expiredOn !== null && todaydate > user.expiredOn) || showAppLoader">
                                                    <span class="fa fa-minus-circle" title="{{ 'Delete' | translate }}"></span>
                                                </button>
                                            </td>
                                            <td class="text-nowrap">
                                                <img *ngIf="user.logged === true" alt="i-userRoleColor"
                                                    src="{{user.role === 'USER' ? 'assets/img/icon-coloured-role-user.png' : user.role === 'SYSTEM_ADMIN' ? 'assets/img/icon-coloured-role-system-admin.png' : user.role === 'SECURITY_ADMIN' ? 'assets/img/icon-coloured-role-security-admin.png' : user.role === 'MANAGER' ? 'assets/img/icon-coloured-role-manager.png' : 'assets/img/icon-coloured-role-application.svg'}}"
                                                    style="width: 24px; height: 24px;">
                                                <img *ngIf="user.logged === false" alt="i-userRoleBlackNWhite"
                                                    src="{{'assets/img/icon-outline-user.svg'}}">
                                                {{user.loginCredentials.userId}}
                                            </td>
                                            <td class="text-nowrap">{{user.loginCredentials.domain}}</td>
                                            <td class="text-nowrap">{{user.name}}</td>
                                            <td class="text-nowrap">{{user.surname}}</td>
                                            <td class="text-nowrap">{{user.language}}</td>
                                            <td class="text-nowrap">{{(user.role === 'USER' ? 'Simple User'
                                                : user.role === 'SYSTEM_ADMIN' ? 'System Administrator'
                                                : user.role === 'MANAGER' ? 'Manager'
                                                : user.role === 'SECURITY_ADMIN' ? 'Security Administrator'
                                                : user.role === 'APPLICATION' ? 'Application User'
                                                : user.role) | translate }}
                                            </td>
                                            <td class="text-nowrap">{{user.loggedAtDisplay}}</td>
                                            <td class="text-nowrap">{{user.expiredOnDisplay}}</td>
                                        </tr>
                                    </ng-template>
                                </ng-container>
                            </tbody>
                            <tfoot *ngIf="(userDetails | filter :'loginCredentials.userId,loginCredentials.domain,name,surname,language,role,loggedAtDisplay,expiredOnDisplay': searchText).length> 15">
                                <tr>
                                    <td colspan="10">
                                        <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="alert cds-alert alert-danger" role="alert" *ngIf="!showAppLoader && userDetails.length===0">
                            <div class="cds-alert-icon">
                              <i class="fa-solid fa-triangle-exclamation"></i>
                            </div>
                            <div class="cds-alert-content">{{ 'No records found.' | translate }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



<ng-template #usertemplate tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>{{ modalTitle | translate }}</h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <div class="col-12" *ngIf="isError && errorMessage">
                    <div class="alert cds-alert alert-danger" role="alert">
                        <div class="cds-alert-icon">
                          <i class="fa-solid fa-triangle-exclamation"></i>
                        </div>
                        <div class="cds-alert-content">{{errorMessage | translate}}</div>
                    </div>
                </div>
                <form [formGroup]="userForm" (ngSubmit)="type === 'Edit' ? updateUser() : saveUser()" novalidate>
                    <!--credential-->
                    <div class="card p-3">
                        <div class="col-12"><h6>{{ 'Credentials' | translate }}:</h6></div>
                        <div class="col-12 p-2">
                            <div class="row mb-3">
                                <div class="col-6">
                                    <label class="form-label">{{ 'User Id' | translate }} <span class="text-danger">*</span></label>
                                    <input type="text" placeholder="{{'User Id' | translate }}" class="form-control"  formControlName="username" maxlength="30">
                                    <div class="cds-form-control-error" *ngIf="userForm.controls['username'].touched && userForm.controls['username'].errors">
                                        {{!userForm.controls['username'].hasError('required') ? userIdMsg :
                                        text_userid_required }}
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">{{ 'Password' | translate }} <span class="text-danger">*</span></label>
                                    <div class="input-group">
                                        <span class="input-group-text {{iconClass}}" *ngIf="type !== 'Edit'" (click)="showPassword()"></span>
                                        <input type="password" placeholder="{{ 'Password' | translate }}" class="form-control"  type="{{textType}}" (keyup)="resetControlls($event.value)" formControlName="password">
                                    </div>
                                    <div class="cds-form-control-error" *ngIf=" type === 'New' && userForm.controls['password'].touched && userForm.controls['password'].errors">
                                        {{ !userForm.controls['password'].hasError('required') ? pwdMsg : 'Password is
                                        required' | translate }}
                                    </div>
                                    <div class="cds-form-control-error" *ngIf="type === 'Edit' && userForm.controls['password'].touched && userForm.controls['password'].errors">
                                        {{!userForm.controls['password'].hasError('required') ? pwdMsg : 'Password is
                                        required' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-6">
                                    <label class="form-label">{{ 'Confirm Password' | translate }} <span class="text-danger">*</span></label>
                                    <div class="input-group">
                                        <input type="password" placeholder="{{ 'Confirm Password' | translate }}" class="form-control" formControlName="confirmPassword" autocomplete="off">
                                    </div>
                                    <div class="cds-form-control-error" *ngIf=" type === 'New' && userForm.controls['confirmPassword'].touched && userForm.controls['confirmPassword'].errors">
                                        {{ !userForm.controls['confirmPassword'].hasError('required') ? pwdMsg : 'Confirm password is required' | translate }}
                                    </div>
                                    <div class="cds-form-control-error" *ngIf="type === 'Edit' && userForm.controls['confirmPassword'].touched && userForm.controls['confirmPassword'].errors">
                                        {{!userForm.controls['confirmPassword'].hasError('required') ? pwdMsg : 'Confirm password is required' | translate }}
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">{{ 'Password Settings' | translate }}</label>
                                        <ng-select
                                        (change)="resetControlls($event.value)" [multiple]="true"
                                        placeholder="{{ 'Password Settings' | translate }}"
                                        formControlName="passwordPolicies" [disabled]="isWindowsUserPolicy">
                                        <ng-option *ngFor="let policy of passwordPoliciesArray" [value]="policy.value">
                                            {{policy.viewValue | translate}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--user info-->
                    <div class="card p-3 mt-3">
                        <div class="col-12"><h6>{{ 'User Info' | translate }}:</h6></div>
                        <div class="col-12 p-2">
                            <div class="row mb-3">
                                <div class="col-4">
                                    <label class="form-label">{{ 'Name' | translate }} <span class="text-danger">*</span></label>
                                    <input  formControlName="name" maxlength="100" type="text" placeholder="{{'Name' | translate }}" class="form-control">
                                    <div class="cds-form-control-error" *ngIf="type === 'New' && userForm.controls['name'].touched && userForm.controls['name'].errors">
                                        {{!userForm.controls['name'].hasError('required') ? nameMsg : 'Name is required' | translate }}
                                    </div>
                                    <div class="cds-form-control-error" *ngIf="type === 'Edit' && userForm.controls['name'].touched && userForm.controls['name'].errors">
                                        {{!userForm.controls['name'].hasError('required') ? nameMsg : 'Name is required' | translate }}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <label class="form-label">{{ 'Surname' | translate }} <span class="text-danger">*</span></label>
                                    <input  formControlName="surName" maxlength="100" type="text" placeholder="{{'Surname' | translate }}" class="form-control">
                                    <div class="cds-form-control-error" *ngIf="type === 'New' && userForm.controls['surName'].touched && userForm.controls['surName'].errors">
                                        {{!userForm.controls['surName'].hasError('required') ? nameMsg : 'Surname is required' | translate }}
                                    </div>
                                    <div class="cds-form-control-error" *ngIf="type === 'Edit' && userForm.controls['surName'].touched && userForm.controls['surName'].errors">
                                        {{!userForm.controls['surName'].hasError('required') ? nameMsg : 'Surname isrequired' | translate }}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <label class="form-label">{{ 'Language' | translate }}</label>
                                    <ng-select
                                        (change)="resetControlls($event.value)"
                                        placeholder="{{ 'Language' | translate }}"
                                        formControlName="language">
                                        <ng-option *ngFor="let lang of language" [value]="lang.value">
                                            <img src="{{lang.imgPath}}" alt="i-userLanguage" style="width: 20px; height: 20px;" />
                                            {{lang.viewValue}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-4">
                                    <label class="form-label">{{ 'Company' | translate }}</label>
                                    <input  formControlName="company" maxlength="100" type="text" placeholder="{{'Company' | translate }}" class="form-control">
                                </div>
                                <div class="col-4">
                                    <label class="form-label">{{ 'Organization' | translate }}</label>
                                    <input  formControlName="organization" maxlength="100" type="text" placeholder="{{'Organization' | translate }}" class="form-control">
                                </div>
                                <div class="col-4">
                                    <label class="form-label">{{ 'E-mail' | translate }} <span class="text-danger">*</span></label>
                                    <input  formControlName="email" type="text" placeholder="{{'E-mail' | translate }}" class="form-control">
                                    <div class="cds-form-control-error" *ngIf="userForm.controls['email'].touched && userForm.controls['email'].errors">
                                        {{(!userForm.controls['email'].hasError('required') ? 'email_format_error' : 'email_required')  | translate }}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <label class="form-label">{{ 'Description' | translate }}</label>
                                    <input  formControlName="description" maxlength="100" type="text" placeholder="{{'Description' | translate }}" class="form-control">
                                </div>
                              <div class="col-4">
                                <label class="form-label">{{ 'StaffID' | translate }}</label>
                                <input  formControlName="staffID" maxlength="100" type="text" placeholder="{{'staffID' | translate }}" class="form-control">
                              </div>
                                <div class="col-4">
                                    <label class="form-label">{{ 'CreationDate' | translate }}</label>
                                    <input  formControlName="creationDate" maxlength="100" type="text" placeholder="{{'CreationDate' | translate }}" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--role mangement-->
                    <div class="card p-3 mt-3">
                        <div class="col-12"><h6>{{ 'Role Management' | translate }}:</h6></div>
                        <div class="col-12 p-2">
                            <div class="row mb-3">
                                <div class="col-4">
                                    <label class="form-label">{{ 'Role' | translate }} {{roleChanged}}</label>
                                    <ng-select
                                        placeholder="{{ 'Role' | translate }}"
                                        formControlName="role"
                                        (change)="roleIsChanged()">
                                        <ng-option *ngFor="let r of role" [value]="r.value">
                                            <img src="{{r.value | imagePipe}}" alt="i-userRole" style="width: 20px; height: 20px;" />
                                            {{r.viewValue | translate}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-4">
                                    <label class="form-label">{{ 'Expire On' | translate }}</label>
                                    <ng-select
                                        placeholder="{{ 'Expire On' | translate }}"
                                        formControlName="expiredOn" (change)="changeValueOfExpiryDate($event)">
                                        <ng-option *ngFor="let expiryOption of expiryOptions" [value]="expiryOption.value">
                                            {{expiryOption.viewValue | translate }}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-4" *ngIf="!(currentUserAuthMode=='OAUTH2' && userForm.controls['authmode'].value == 'STRATEGYONE')">
                                    <label class="form-label">{{ 'Expire On' | translate }}</label>
                                    <div class="input-group">
                                        <input
                                            [readonly]="true"
                                            [disabled]="isDisableExpiryDate"
                                            class="form-control"
                                            placeholder="yyyy-mm-dd"
                                            name="dp"
                                            formControlName="expiryDate"
                                            standalone="true"
                                            ngbDatepicker
                                            #d="ngbDatepicker"
                                        />
                                        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button" [disabled]="isDisableExpiryDate">
                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button [disabled]="showAppLoader || userForm.invalid" type="submit" class="btn btn-primary" (click)="type === 'Edit' ? updateUser() : saveUser()">
                    {{'SAve' | translate }}
                </button>
                <button [disabled]='showAppLoader' type="button" class="btn btn-default" (click)="decline()">
                    {{ 'Close' |translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #confirmTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ modalTitle | translate }}</h4>
        <button [disabled]="showAppLoader" type="button" class="close pull-right" aria-label="Close"
            (click)="decline()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
    </div>
</ng-template>
