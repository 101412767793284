<div class="cds-navbar p-2">
    <!-- Navbar (different for each product) -->
    <div class="d-flex justify-content-end"> 
        <div class="mt-2 pt-1">
            <i class="fa fa-user"></i>&nbsp;{{loginUserId}}
        </div>&nbsp;&nbsp;
        <button type="button" class="btn btn-sm cds-btn btn-stroke-info mt-1" title="{{'Logout' | translate}}" (click)="authenticationMode == 'OAUTH2' ? logoutOIDC() : logout()">
            <i class="fa fa-sign-out"></i>
        </button>
    </div>
</div>

<!-- <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <img src="assets/img/logo-coloured-s1-whitelogo.png" alt="StrategyOne Business Administration Console"
        title="StrategyOne Business Administration Console" height="41" width="210">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto"></ul>
        <span class="navbar-text mr-2">
            <span class="fa fa-user">&nbsp;{{loginUserId}}</span>
        </span>
        <button type="button" class="btn btn-default" title="{{'Logout' | translate}}" (click)="logout()">
            <span class="fa fa-sign-out"></span>
        </button>
    </div>
</nav> -->

<!-- <ng-template #templateInfoPage>
    <div class="col-sm-12">
        <br />
        <div class="col-sm-12">
            <button type="button" class="btn btn-sm close pull-right" aria-label="Close" (click)="cancelWindow()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <appstandardinfo></appstandardinfo>
    <div class="col-sm-12">
        <div class="col-sm-12">
            <br />
            <button type="button" class="btn btn-sm btn-default pull-right" (click)="cancelWindow()">{{ 'Close' |
                translate }}</button>
            <br />
            <br />
        </div>
    </div>
</ng-template> -->