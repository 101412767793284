<div class="col-sm-12" *ngIf="!isPlatformBrowserOk">
    <br />
    <h5 class="text-danger" (ngModel)="errorMessage">{{errorMessage}}</h5>
</div>
<appspinner *ngIf="showAppLoader" [startSpinner]="showAppLoader"></appspinner>
<div class="card cds-card-shadow login-panel" *ngIf="isPlatformBrowserOk && !showAppLoader && authenticationMode!=''">
    <div class="card-body">
        <img class="float-end" src="assets/img/logo-coloured-s1logo-29x33.png"
            alt="StrategyOne Business Administration Console" title="StrategyOne Business Administration Console">
        <img src="assets/img/logo-coloured-s1.png" alt="StrategyOne Business Administration Console"
            title="StrategyOne Business Administration Console" width="50%">
        <hr>
        <h6 class="card-title" translate="">StrategyOne Business Administration Console</h6>
        <h6 class="text-danger text-with-newlines" (ngModel)="errorMessage">{{errorMessage}}</h6>
        <form *ngIf="authenticationMode != 'OAUTH2'" [formGroup]="valForm" class="form-validate"  name="loginForm" novalidate="" (submit)="login()">
            <div class="form-group has-feedback">
                <label class="form-label">Authentication Mode</label>
                <select class="form-control" id="authmode" name="authmode" formControlName="authmode"
                    (ngModelChange)="clearFormErr()" required>
                    <option *ngFor="let choice of keysAuthMode" [ngValue]="choice">{{localS1AuthMode[choice]}}</option>
                </select>
                <br />
                <fieldset *ngIf="valForm.controls['authmode'].value===0">
                    <legend><span class="accessibility">Domain</span></legend>
                    <label class="form-label">Domain</label>
                    <input class="form-control" type="text" id="domain" name="domain" formControlName="domain"
                        placeholder="Domain" />
                    <span class="text-danger" *ngIf="isShowFieldError">
                        This field is required
                    </span>
                    <br />
                </fieldset>
                <label class="form-label">Username <span class="text-danger">*</span></label>
                <input class="form-control" type="text" id="username" name="username" formControlName="username"
                    placeholder="Username" required />
                <span class="text-danger" *ngIf="valForm.controls['username'].hasError('required') && isShowFieldError">
                    This field is required
                </span>
                <br />
                <label class="form-label">Password<span class="text-danger">*</span></label>
                <input class="form-control" type="password" id="password" name="password" formControlName="password"
                    placeholder="Password" autocomplete="off" required />
                <span class="text-danger" *ngIf="valForm.controls['password'].hasError('required') && isShowFieldError">
                    This field is required
                </span>
                <fieldset *ngIf="isResetPassword">
                    <br />
                    <legend><span class="accessibility">Reset password</span></legend>
                    <label class="form-label">New Password<span class="text-danger">*</span></label>
                    <input class="form-control" type="password" name="newPassword" formControlName="newPassword"
                        placeholder="New Password" autocomplete="off" required />
                    <span class="text-danger"
                        *ngIf="valForm.controls['newPassword'].hasError('required') && isShowFieldError">
                        This field is required
                    </span>
                    <br />
                    <label class="form-label">Confirm Password<span class="text-danger">*</span></label>
                    <input class="form-control" type="password" name="confirmPassword" formControlName="confirmPassword"
                        placeholder="Confirm Password" autocomplete="off" required />
                    <span class="text-danger"
                        *ngIf="valForm.controls['confirmPassword'].hasError('required') && isShowFieldError">
                        <!-- can use .dirty or .touched like .hasError)-->
                        This field is required
                    </span>
                </fieldset>
            </div>

            <button [disabled]="!isEnableSubmit || showAppLoader" class="btn btn-info btn-block btn-sm mt-4 col-12" type="submit" formmethod="post">
                <i class="fa fa-sign-in"></i> 
                Login
            </button>
        </form>
        <button *ngIf="authenticationMode == 'OAUTH2'" [disabled]="showAppLoader" class="btn btn-info btn-block btn-sm mt-4 col-12" (click)="loginOIDC()">
            <i class="fa fa-sign-in"></i> 
            Login
        </button>
    </div>
    <hr>
    <div class="ibox-footer text-center default form-label">
        StrategyOne v{{version}}
    </div>
</div>

<ng-template #templateInfoPage>
    <div class="col-sm-12">
        <br />
        <div class="col-sm-12">
            <button type="button" class="btn btn-sm close pull-right" aria-label="Close" (click)="cancelWindow()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <appstandardinfo></appstandardinfo>
    <div class="col-sm-12">
        <div class="col-sm-12">
            <br />
            <button type="button" class="btn btn-sm btn-default pull-right" (click)="cancelWindow()">{{ 'Close' |
                translate }}</button>
            <br />
            <br />
        </div>
    </div>
</ng-template>