import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { InstallationManagementRoutingModule } from './installation-management-routing.module';
import { UserCreationComponent } from './user-creation/user-creation.component';
import { UserPermissionComponent } from './user-permission/user-permission.component';
import { PythonManagementComponent } from './python-management/python-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogTracerComponent } from './log-tracer/log-tracer.component';
import { TreeTableModule } from 'primeng/treetable';
import { SharedModule } from 'app/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import {NgbDatepickerModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import { AlertsService } from 'app/shared/alert/alert-service';
import { ConfirmService } from 'app/shared/confirm/confirm.service';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ProcessManagementService } from 'app/core/services/process-management.service';
import { HttpLoaderFactory } from 'app/app.module';
import { DataTableModule } from 'app/shared/angular2-datatable/DataTableModule';
import { DataFilterPipe } from 'app/shared/custom-pipe/customfilter.pipe';
import { AppSpinnerModule } from 'app/shared/app-loader/appspinner.module';

@NgModule({
  imports: [
    CommonModule,
    InstallationManagementRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TreeTableModule,
    TranslateModule,
    SharedModule,
  //   TranslateModule.forRoot({
  //     loader: {
  //         provide: TranslateLoader,
  //         useFactory: HttpLoaderFactory,
  //         deps: [HttpClient]
  //     }
  // }),
    NgSelectModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbNavModule,
    HttpClientModule,
    DataTableModule,
    AppSpinnerModule
  ],
  declarations: [
    UserCreationComponent,
    UserPermissionComponent,
    LogTracerComponent,
    PythonManagementComponent
  ],
  exports: [],
  providers: [DatePipe, AlertsService, ConfirmService, ProcessManagementService]
})
export class InstallationManagementModule { }
