export enum ModelType {
    //default null
    NULL_VALUE="",
    //original values 
    ASSOCIATION_MODEL="ASSOCIATION_MODEL",// tag : "AssociationModel",
    BAYESIAN_NETWORK_MODEL="BAYESIAN_NETWORK_MODEL",//"BayesianNetworkModel",
    BASELINE_MODEL="BASELINE_MODEL",//"BaselineModel",
    CLUSTERING_MODEL="CLUSTERING_MODEL",//"ClusteringModel",
    GAUSSIAN_PROCESS_MODEL="GAUSSIAN_PROCESS_MODEL",//"GaussianProcessModel",
    GENERAL_REGRESSION_MODEL="GENERAL_REGRESSION_MODEL",//"GeneralRegressionModel",
    MINING_MODEL="MINING_MODEL",//"MiningModel",
    NAIVE_BAYES_MODEL="NAIVE_BAYES_MODEL",//"NaiveBayesModel",
    NEAREST_NEIGHBOR_MODEL="NEAREST_NEIGHBOR_MODEL",//"NearestNeighborModel",
    NEURAL_NETWORK="NEURAL_NETWORK",//"NeuralNetwork",
    REGRESSION_MODEL="REGRESSION_MODEL",//"RegressionModel",
    RULE_SET_MODEL="RULE_SET_MODEL",//"RuleSetModel",
    SEQUENCE_MODEL="SEQUENCE_MODEL",//"SequenceModel",
    SCORECARD="SCORECARD",//"Scorecard",
    SUPPORT_VECTOR_MACHINE_MODEL="SUPPORT_VECTOR_MACHINE_MODEL",//"SupportVectorMachineModel",
    TEXT_MODEL="TEXT_MODEL",//"TextModel",
    TIME_SERIES_MODEL="TIME_SERIES_MODEL",//"TimeSeriesModel",
    TREE_MODEL="TREE_MODEL"//"TreeModel"
}