import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthGuardService } from './authorization-service.service';
import { Observable } from '../../../../node_modules/rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {

  constructor(protected router: Router,
    protected authorizationService: AuthGuardService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.authorizationService.hasPermission(route.data['expectedRole']);
  }

}
