import { Component } from '@angular/core';
import { OidcSecurityService, PublicEventsService, EventTypes } from 'angular-auth-oidc-client';
import { filter } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  
})
export class AppComponent { 

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private eventService: PublicEventsService, private router : Router
  ) {}

  ngOnInit() {
  }
}