import { S1LogQueryType } from './s1-logquerytype.enum';
import { S1UserID } from './userid';
import { UntypedFormGroup } from '@angular/forms';

export class S1LogQueryParameters{
    private type : S1LogQueryType;
    private processes : string;
    private users: S1UserID;
    private fromDate: Date;
    private toDate: Date;

    constructor(queryParamForm : UntypedFormGroup){
        this.type = queryParamForm.value.type;
        this.processes = queryParamForm.value.processes;
        this.users = queryParamForm.value.name;
        this.fromDate = queryParamForm.value.fromDate;
        this.toDate = queryParamForm.value.toDate;
    }
}