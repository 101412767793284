import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'VariableNamefilter'
})
export class VariableExpressionFilterPipe implements PipeTransform {
    transform(strVar: any, categoryVariableMap: any) {
        let temp = "";
        let key = "";
        let val = "";
        if (strVar && categoryVariableMap) {
            while (strVar.indexOf("$GUID") >= 0) {
                temp = strVar.substring(strVar.indexOf("$GUID"), strVar.indexOf("}") + 1);
                key = temp.substring(temp.indexOf("{") + 1, temp.indexOf("}"));
                if(categoryVariableMap[key].categoryId){
                    val = categoryVariableMap[key].categoryId + "." + categoryVariableMap[key].objectsVariableId;
                } else {
                    val = categoryVariableMap[key].objectsVariableId;
                }
                strVar = strVar.replace(temp, val);
            }
        }
        return strVar;
    }
}