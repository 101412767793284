<div class="row">
    <div class="col-12">
        <div class="card cds-card-shadow">
            <div class="card-body">
                <!-- SEARCH FORM-->
                <div class="row">
                    <div class="col-12">
                        <div class="card p-3 mb-3">
                            <h6>{{ 'Search' | translate }} :</h6>
                            <hr class="mt-1">
                            <form [formGroup]="userForm" novalidate>
                                <div class="row">
                                  <div class="col-3">
                                        <label class="form-label">{{ 'Type' | translate }}</label>
                                        <ng-select [items]="operationType"
                                            bindLabel="viewValue"
                                            bindValue="value"
                                            placeholder="{{ 'Type' | translate }}"
                                            formControlName="type">
                                        </ng-select>
                                    </div>
                                  <div class="col-4">
                                    <label class="form-label">{{ 'Users' | translate }}</label>
                                    <ng-select formControlName="name" [(ngModel)]="selected" (ngModelChange)="setUserId(selected!=0? selected:'All',users)">
                                      <ng-option *ngFor="let user of users" [value]="user.viewValue">
                                        <img src="{{user.userRole === 'SYSTEM_ADMIN' ? 'assets/img/icon-coloured-role-system-admin.png' : user.userRole === 'MANAGER' ? 'assets/img/icon-coloured-role-manager.png' : user.userRole === 'SECURITY_ADMIN' ? 'assets/img/icon-coloured-role-security-admin.png' : user.viewValue === 'All' ? 'assets/img/icon-coloured-users.svg' : 'assets/img/icon-coloured-role-user.png'}}"
                                             alt="userRole" style="width: 20px; height: 20px;" />
                                        {{user.viewValue | translate}}
                                      </ng-option>
                                    </ng-select>
                                  </div>
                                  <div class="col-3">
                                    <label class="form-label">{{ 'Processes' | translate }}</label>
                                    <ng-select placeholder="{{ 'Processes' | translate }}" formControlName="processes">
                                      <ng-option *ngFor="let process of processDetails" [value]="process.name" (click)="setProcesses(process.name === 'All' ? processDetails : '')">
                                        {{process.name | translate}}
                                      </ng-option>
                                    </ng-select>
                                  </div>
                                  <div class="col-2 mt-4">
                                    <button [disabled]="showAppLoader || error" class="btn btn-sm btn-primary m-1" (click)="search()" title="{{ 'Search' | translate }}">
                                      <i class="fa fa-play-circle"></i>
                                    </button>
                                    <button [disabled]="showAppLoader || !isEnabledClearBtn" class="btn btn-sm btn-primary m-1" (click)="clear()" title="{{ 'Clear' | translate }}">
                                      <i class="fa fa-minus-circle"></i>
                                    </button>

                                  </div>
                                  <div class="col-5">
                                    <label class="form-label">{{ 'Date from' | translate }}</label>
                                    <div class="input-group">
                                      <input
                                        [readonly]="true"
                                        class="form-control"
                                        placeholder="yyyy-mm-dd"
                                        name="dp"
                                        formControlName="fromDate"
                                        standalone="true"
                                        ngbDatepicker
                                        #d="ngbDatepicker"
                                      />
                                      <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button">
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <div class="col-5">
                                    <label class="form-label">{{ 'Date to' | translate }}</label>
                                    <div class="input-group">
                                      <input
                                        [readonly]="true"
                                        class="form-control"
                                        placeholder="yyyy-mm-dd"
                                        name="dp2"
                                        formControlName="toDate"
                                        ngbDatepicker
                                        #d2="ngbDatepicker"
                                      />
                                      <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d2.toggle()" type="button">
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                      </button>
                                    </div>
                                  </div>

                                  <div class="col-2 mt-4">
                                    <div class="btn-group me-3 m-1 d-inline overflow-hidden rounded-pill">
                                      <button type="button" (click)="exportAsXLSXLogTracer()"  [disabled]="logDesc.length ===0" class="btn btn-primary btn-sm mt-2 rounded-end" > <i class="fa fa-print" title="Print User" ></i></button>
                                      <div class="btn-group dropdown rounded-0" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                                        <button type="button"   class="btn btn-primary btn-sm  mt-2  border-start dropdown-toggle dropdown-toggle-split mt-2 rounded-start" ngbDropdownToggle></button>
                                        <div class="dropdown-menu"  ngbDropdownMenu>
                                          <button (click)="exportAsXLSXViolation()" ngbDropdownItem>Violation Report</button>
                                          <button (click)="exportAsXLSXAdministration()" ngbDropdownItem>Administration Audit Trail Report</button>
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                 </div>
                             </form>
                         </div>
                     </div>
                 </div>
                 <h6>{{ 'Results' | translate }}</h6>
                 <div class="row">
                     <!-- FILTER -->
                    <div class="col-12">
                        <input class="form-control mb-1" placeholder="{{ 'Quick Filter' | translate }}" [disabled]="showAppLoader" [(ngModel)]="searchText">
                        <appspinner *ngIf="!isPageLoad" [startSpinner]="showAppLoader"></appspinner>
                    </div>
                    <!--ERROR-->
                    <div class="col-12" *ngIf="error && errorMessage">
                        <div class="alert cds-alert alert-danger" role="alert">
                            <div class="cds-alert-icon">
                              <i class="fa-solid fa-triangle-exclamation"></i>
                            </div>
                            <div class="cds-alert-content">{{errorMessage}}</div>
                        </div>
                    </div>
                    <!--TABLE-->
                    <div class="col-12">
                        <table aria-describedby="table-logTracer" class="table table-striped"
                            [mfData]="logDesc | filter :'logDateDisplay,logUser,logProcess,logType,logOperation,logMessage': searchText"
                            #mf="mfDataTable" [mfRowsOnPage]="15">
                            <thead>
                                <tr>
                                    <th>
                                        <mfDefaultSorter by="logDateDisplay">{{ 'Date' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="logUser">{{ 'User' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="logProcess">{{ 'Process' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="logType">{{ 'Type' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="logOperation">{{ 'Operation' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="logMessage">{{ 'Message' | translate }}</mfDefaultSorter>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let log of mf.data">
                                    <td>{{log.logDateDisplay}}</td>
                                    <td>{{log.logUser}}</td>
                                    <td>{{log.logProcess}}</td>
                                    <td>{{log.logType | translate}}</td>
                                    <td>{{log.logOperation}}</td>
                                    <td>{{log.logMessage | translate}}</td>
                                </tr>
                            </tbody>
                            <tfoot
                                *ngIf="(logDesc | filter :'logDateDisplay,logUser,logProcess,logType,logOperation,logMessage': searchText).length > 15">
                                <tr>
                                    <td colspan="10">
                                        <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="alert cds-alert alert-danger" role="alert" *ngIf="logDesc.length===0 && !isPageLoad && !showAppLoader && !error">
                            <div class="cds-alert-icon">
                              <i class="fa-solid fa-triangle-exclamation"></i>
                            </div>
                            <div class="cds-alert-content">{{ 'No records found.' | translate }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--
<div class="row">
    <div class="col-sm-12">
        <div class="col-sm-12" *ngIf="isPageLoad">
            <appspinner [startSpinner]="showAppLoader"></appspinner>
        </div>
        <div class="col-sm-12">
            <form [formGroup]="userForm" novalidate>
                <fieldset style="height: auto;width:auto">
                    <legend><label>{{ 'Search' | translate }}</label></legend>
                    <div class="row">
                        <div class="col-sm-2">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'Type' | translate }}</mat-label>
                                <mat-select placeholder="{{ 'Type' | translate }}" formControlName="type">
                                    <mat-option *ngFor="let type of operationType" [value]="type.value">
                                        {{type.viewValue | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-2">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'Date from' | translate }}</mat-label>
                                <input matInput [matDatepicker]="picker" placeholder="{{ 'Date from'  | translate }}"
                                    [readonly]="true" formControlName="fromDate">
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-2">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'Date to' | translate }}</mat-label>
                                <input matInput [matDatepicker]="pickerto" placeholder="{{ 'Date to' | translate }}"
                                    [readonly]="true" formControlName="toDate">
                                <mat-datepicker-toggle matSuffix [for]="pickerto">
                                </mat-datepicker-toggle>
                                <mat-datepicker #pickerto></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-2">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'Users' | translate }} </mat-label>
                                <mat-select placeholder="{{ 'Users' | translate }}" formControlName="name"
                                    [(value)]="selected">
                                    <mat-select-trigger>
                                        <img src="{{selected===undefined || selected.viewValue === 'All' ?'assets/img/icon-coloured-users.svg':selected.userRole | imagePipe}}"
                                            alt="userRole" style="width: 20px; height: 20px;" />
                                        {{selected===undefined ? 'All':selected.viewValue | translate}}
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let user of users" [value]="user"
                                        (click)="setUserId(user.userIdDb, user.viewValue === 'All' ? users :'')">
                                        <img src="{{user.userRole === 'SYSTEM_ADMIN' ? 'assets/img/icon-coloured-role-system-admin.png' : user.userRole === 'MANAGER' ? 'assets/img/icon-coloured-role-manager.png' : user.userRole === 'SECURITY_ADMIN' ? 'assets/img/icon-coloured-role-security-admin.png' : user.viewValue === 'All' ? 'assets/img/icon-coloured-users.svg' : 'assets/img/icon-coloured-role-user.png'}}"
                                            alt="userRole" style="width: 20px; height: 20px;" /> &nbsp;&nbsp;
                                        {{user.viewValue | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-2">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'Processes' | translate }} </mat-label>
                                <mat-select placeholder="{{ 'Processes' | translate }}" formControlName="processes">
                                    <mat-option *ngFor="let process of processDetails" [value]="process.name"
                                        (click)="setProcesses(process.name === 'All' ? processDetails : '')">
                                        {{process.name | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-2 btn-font">
                            <button [disabled]="showAppLoader || error" class="btn btn-primary" (click)="search()"
                                title="{{ 'Search' | translate }}">
                                <span class="fa fa-play-circle"></span></button>&nbsp;&nbsp;
                            <button [disabled]="showAppLoader || !isEnabledClearBtn" class="btn btn-primary"
                                (click)="clear()" title="{{ 'Clear' | translate }}">
                                <span class="fa fa-minus-circle"></span></button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
        <hr />
        <div class="col-sm-12">
            <span class="heading-text">{{ 'Results' | translate }}</span>
            <br />
            <br />
            <input class="form-control searchBox" placeholder="{{ 'Quick Filter' | translate }}"
                [disabled]="showAppLoader" [(ngModel)]="searchText">
            <div class="col-sm-12"><br /></div>
            <div *ngIf="!isPageLoad">
                <appspinner [startSpinner]="showAppLoader"></appspinner>
            </div>
            <h5>
                <mat-error *ngIf="error">{{errorMessage}}</mat-error>
            </h5>
            <div class="scroll-only-x">
                <table aria-describedby="table-logTracer" class="table table-sm table-bordered table-striped mb-0"
                    [mfData]="logDesc | filter :'logDateDisplay,logUser,logProcess,logType,logOperation,logMessage': searchText"
                    #mf="mfDataTable" [mfRowsOnPage]="15">
                    <thead>
                        <tr>
                            <th>
                                <mfDefaultSorter by="logDateDisplay">{{ 'Date' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="logUser">{{ 'User' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="logProcess">{{ 'Process' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="logType">{{ 'Type' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="logOperation">{{ 'Operation' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="logMessage">{{ 'Message' | translate }}</mfDefaultSorter>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let log of mf.data">
                            <td>{{log.logDateDisplay}}</td>
                            <td>{{log.logUser}}</td>
                            <td>{{log.logProcess}}</td>
                            <td>{{log.logType | translate}}</td>
                            <td>{{log.logOperation}}</td>
                            <td>{{log.logMessage | translate}}</td>
                        </tr>
                    </tbody>
                    <tfoot
                        *ngIf="(logDesc | filter :'logDateDisplay,logUser,logProcess,logType,logOperation,logMessage': searchText).length > 15">
                        <tr>
                            <td colspan="10">
                                <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <div class="col-sm-12 text-center"
                    *ngIf="logDesc.length===0 && !isPageLoad && !showAppLoader && !error">
                    <h5>
                        <mat-error>{{ 'No records found.' | translate }}</mat-error>
                    </h5>
                </div>

            </div>
        </div>
    </div>
</div> -->
