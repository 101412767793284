// role-filter.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roleFilter'
})
export class RoleFilterPipe implements PipeTransform {

  transform(userNames: any[], roles: number[]): any[] {
    if (!userNames || !roles) {
      return userNames;
    }
    return userNames.filter(user => roles.includes(user.userRole));
  }

}
