import {
  Component,
  OnInit,
  TemplateRef,
  HostListener, AfterContentChecked
} from '@angular/core';
import {S1UserRole} from 'app/entities/s1-userrole.enum';
import {UserName} from 'app/entities/username';
import {S1UserInfoArray} from 'app/entities/s1-userinfoarray';
import {S1UserProfile} from 'app/entities/s1-userprofile';
import {UserManagementService} from 'app/core/services/user-management.service';
import {StorageDetails} from 'app/core/services/user-details.service';
import {S1EntityManagementService} from 'app/core/services/s1entity-management.service';
import {TreeObject} from 'app/entities/treeNode.model';
import {TranslateService} from '@ngx-translate/core';

import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AlertsService} from 'app/shared/alert/alert-service';
import * as XLSX from "xlsx";
import {formatDate} from "@angular/common";

const MODAL_OPTIONS: NgbModalOptions = {
  size: 'lg',
  backdrop: 'static',
  keyboard: true,
  windowClass: 'modal-full-height modal-full-width'
};

interface UserGroup {
  disabled?: boolean;
  name: S1UserRole;
  grpUserNames: UserName[];
  viewValue: string;
  imgPath: string;
}

@Component({
  selector: 'app-user-authorisation',
  templateUrl: './user-permission.component.html',
  styleUrls: ['./user-permission.component.scss'],
})
export class UserPermissionComponent implements OnInit,AfterContentChecked {
  active: any;
  files1: TreeObject[];
  files1New: TreeObject[];
  selectedProfileId: any;
  selectedNode: TreeObject;
  cols: any[];
  selectedNode2: TreeObject[];
  type: string;
  enabled: boolean;
  indeterminate: boolean;
  noneProfile: any;
  refreshButton: any;
  saveButton: any;
  checked: true;
  modalRef: NgbModalRef;
  selectedUser: UserName;
  public userNames: Array<UserName> = [];
  private s1userInfoArray: S1UserInfoArray;
  public userProfiles: S1UserProfile[] = [];
  selected: any; //for drop-down mat-select-trigger
  errorType: string;
  okbtn: string;
  modalTitle = 'Assign Permissions';
  isEnableSave2DB: boolean;
  isDirty: boolean;
  url: boolean;
  scrollenable: boolean;
  isSimpleUser: boolean = false;
  //@ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent;
  showAppLoader: boolean = false;
  errorMessage: string = '';
  isError: boolean = false;
  searchText: string;
  userRole: any;

  userGroups: UserGroup[] = [];

  textLoadValue: string;
  titleLoadValue: string;
  titleSaveSuccessValue: string;
  titleSaveErrorValue: string;
  userProfileIds: number[];
  permissions: any[];
  isDisableSave: boolean = false;
  permission: any;
  listaUtentiDaStampare = [];
  checkboxIds: string[] = [];
  isAllChecked: boolean = false;

  constructor(private userService: UserManagementService, private userDetails: StorageDetails, private loggedUserInfo: StorageDetails,
              private entityManagementService: S1EntityManagementService, private translate: TranslateService,
              private modalService: NgbModal, private alert: AlertsService) {
    this.refreshButton = {value: 'refreshButton', viewValue: 'Refresh', imgPath: 'assets/img/refresh.png'};
    this.saveButton = {value: 'saveButton', viewValue: 'Save', imgPath: 'assets/img/save.png'};
    translate.get(['Error', 'Ok', 'Unable to get user permissions!', 'Server communication error occurred. Please reload the page or Try again later.', 'Permissions saved successfully!', 'Permissions save failed!']).subscribe((text: any) => {
      this.errorType = text['Error'];
      this.okbtn = text['Ok'];
      this.titleLoadValue = text['Unable to get user permissions!'];
      this.textLoadValue = text['Server communication error occurred. Please reload the page or Try again later.'];
      this.titleSaveSuccessValue = text['Permissions saved successfully!'];
      this.titleSaveErrorValue = text['Permissions save failed!'];
    });
    this.userGroups = [
      {
        name: S1UserRole.MANAGER,
        grpUserNames: [],
        viewValue: 'Managers',
        imgPath: 'assets/img/icon-coloured-role-manager.png'
      },
      {
        name: S1UserRole.USER,
        grpUserNames: [],
        viewValue: 'Users',
        imgPath: 'assets/img/icon-coloured-role-user.png'
      }
    ];
  }
  ngAfterContentChecked(): void {
// Esegui eventuali operazioni aggiuntive dopo che la view è stata inizializzata
    this.updateCheckboxes();
  }
  filteredResult: FilteredResult;

  ngOnInit() {
    this.loadUserProfiles();
    this.getGroupwiseUsers();
    this.isEnableSave2DB = true;
    this.isDirty = false;
    //   this.filteredResult = { groupNames: [], processNames: [], instituteNames: [], userProfileIds: [] };

  }

  selectTab(tabId: number) {
    //this.staticTabs.tabs[tabId].active = true;
  }

  getGroupwiseUsers() {
    this.showAppLoader = true;
    this.errorMessage = '';
    this.userService.authGetAllUsers().subscribe({
      next: res => {
        if (res && res['s1UserInfo']) {

          this.s1userInfoArray = res;
          this.userNames.length = 0; //update users every time
          this.s1userInfoArray.s1UserInfo.forEach(element => {
            if (element['expiredOn'] !== null) {
              let tempdate: Date = new Date(element.expiredOn);
              element.expiredOn = tempdate;
            }
            if ((element['expiredOn'] === null || Date.now() < element.expiredOn.getTime())
              && element['loginCredentials']['userId'] !== this.userDetails.getLoginUserId()) {
              this.userNames.push({
                firstName: element['name'],
                lastName: element['surname'],
                userIdDb: Number(element['id']['userId']),
                userIdString: element['loginCredentials']['userId'],
                viewValue: element['name']
                  + ' ' + element['surname']
                  + '(' + element['loginCredentials']['userId'] + ')',
                userRole: S1UserRole[element['role']]
              });
            }
          });

          //save each user in group; clean existing group.
          this.userGroups[0].grpUserNames.length = 0;
          this.userGroups[1].grpUserNames.length = 0;
          this.userGroups[0].grpUserNames = this.filterUsers(this.userNames, S1UserRole.MANAGER);
          this.userGroups[1].grpUserNames = this.filterUsers(this.userNames, S1UserRole.USER);
          this.showAppLoader = false;
        } else {
          let errdata: any;
          errdata.name = "OBJ_PARSE_ERR";
          errdata.status = "Invalid response format from server";
          this.handleHttpError(errdata);
          this.showAppLoader = false;
        }
      }, error: err => {
        this.handleHttpError(err);
      }
    });

  }

  loadPermisions(userName: UserName) {
    this.showAppLoader = true;
    this.errorMessage = '';
    this.isSimpleUser = userName.userRole === 1 ? true : false;
    this.selectedUser = userName;
    this.selectedNode = null;
    this.selectedNode2 = []
    this.listaUtentiDaStampare = [];
    this.entityManagementService.getPermissionsStructure(userName.userIdDb).subscribe({
      next: response => {
        this.files1 = response;
        this.files1[0].expanded = true;
        this.files1[0].children.forEach(child => child.expanded = false);
        this.isEnableSave2DB = false;
        this.showAppLoader = false;
        this.userProfileIds = this.getUserProfileIds(this.files1[0]);
        this.filterTree(response, userName)
      },
      error: err => {
        this.alert.error(this.textLoadValue, this.titleLoadValue);
        this.showAppLoader = false;
        // // console.log(err);
      }
    });


  }

  filterUsers(array: any[], s1role: S1UserRole): any {
    let roleBasedUser = [];
    if (array) {
      array.forEach(element => {
        if (element['userRole'] === s1role) {
          roleBasedUser.push(element)
        }
      });
    }
    return roleBasedUser;
  }

  //profiles tab methods

  loadUserProfilesId(): void {
    this.entityManagementService.authGetUserProfilesId(1556701642).subscribe({
      next: res => {
        this.userProfiles = res['s1UserProfile'];
      }, error: err => {
        this.handleHttpError(err);
      }
    });
  }

  loadUserProfiles(): void {
    this.showAppLoader = true;
    this.entityManagementService.authGetUserProfiles().subscribe({
      next: res => {
        this.userProfiles = res['s1UserProfile'];
        //this.showAppLoader will conitnue as followed by getGroupwiseUsers()
      }, error: err => {
        this.handleHttpError(err);
      }
    });
    this.noneProfile = {id: {id: -1}, name: "", description: "None"};
  }

  nodeSelect(event, template: TemplateRef<any>) {
    //this.isDisableSave = true;
    this.selectedNode = event.node;
    if (this.selectedNode.data.parentId === 0 && this.selectedUser.userRole === 1) {
      return;
    }
    this.type = this.selectedNode.data.type;
    if (this.type === 'process' && !this.selectedNode.data.enabled) {
      this.selectedProfileId = 0;
    } else {
      this.selectedProfileId = this.selectedNode.data.userProfileId;
      this.userRole = this.userProfiles.find(profile => profile.id.id == this.selectedProfileId)
    }

    this.enabled = this.selectedNode.data.enabled;
    this.indeterminate = this.selectedNode.data.indeterminate;
    this.modalRef = this.modalService.open(template, MODAL_OPTIONS)
    this.enable()
  }

  decline(): void {
    this.selectedNode.data.enabled = !this.selectedNode.data.enabled;
    this.modalRef.close();
    // this.isDisableSave = false;
  }

  nodeUnselect(event) {
    this.selectedNode = null;
  }

  /**
   * represents check-box in pop-up
   */
  enable() {
    switch (this.selectedUser.userRole) {
      case 5: //manager
        this.isDisableSave = false
        break;
      case 1: //user
        this.isDisableSave = this.enabled == true && this.userRole != undefined ? false : (this.enabled == false ? false : true);
        break;

      default:
        break;
    }

  }

  save() {
    this.isDirty = true;
    this.propagateSelectionDown(this.selectedNode, this.enabled);
    if (this.selectedNode.parent) {
      this.propagateSelectionUp(this.selectedNode.parent, this.enabled);
    }
    this.modalRef.close();
  }

  propagateSelectionDown(node: TreeObject, select: boolean) {
    node.data.enabled = select;
    node.data.indeterminate = false;
    if (select) {
      this._propagateSelectionDown_select(node);
    } else {
      if (node.data.type == 'process') {
        node.data.userProfileId = null;
      }
    }

    if (node.children && node.children.length) {
      for (let child of node.children) {
        this.propagateSelectionDown(child, select);
      }
    }
  }

  _propagateSelectionDown_select(node: TreeObject) {
    if (this.type !== 'process') {
      if (node.data.type === 'process') {
        if (this.selectedUser.userRole == 5) {
          node.data.userProfileId = 8;
        }
        if (this.selectedUser.userRole == 1) {
          node.data.userProfileId = 6;
        }
      }
    } else {
      node.data.userProfileId = this.selectedProfileId;
    }
  }

  propagateSelectionUp(node: TreeObject, select: boolean) {
    if (node.children && node.children.length) {
      let selectedChildCount: number = 0;
      let childPartialSelected: boolean = false;
      for (let child of node.children) {
        if (child.data.enabled)
          selectedChildCount++;
        else if (child.data.indeterminate)
          childPartialSelected = true;
        if (this.selectedUser.userRole === S1UserRole.MANAGER && child.data.type === 'process') {
          selectedChildCount++;
        }
      }
      this._propagateSelectionUp(node, selectedChildCount, childPartialSelected, select);
    }

    let parent = node.parent;
    if (parent) {
      this.propagateSelectionUp(parent, select);
    }
  }

  _propagateSelectionUp(node: TreeObject, selectedChildCount: number, childPartialSelected: boolean, select: boolean) {
    if (select && selectedChildCount == node.children.length) {
      node.data.enabled = true;
      node.data.indeterminate = false;
    } else {
      if (!select) {
        node.data.enabled = false;
      }

      if (childPartialSelected || selectedChildCount > 0 && selectedChildCount != node.children.length)
        node.data.indeterminate = true;
      else
        node.data.indeterminate = false;
    }
  }

  saveToDb() {
    this.isDirty = false; //global save
    this.showAppLoader = true;
    this.entityManagementService.updateUserPermissions(this.selectedUser.userIdDb, this.files1).subscribe({
      next: response => {
        this.files1 = response;
        this.files1[0].expanded = true;
        this.files1[0].children.forEach(child => child.expanded = false);
        this.alert.success(this.titleSaveSuccessValue);
        this.loadPermisions(this.selectedUser);
        this.showAppLoader = false;
      }, error: _error => {
        this.alert.error(this.titleSaveErrorValue);
        this.showAppLoader = false;
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.isDirty) {
      $event.returnValue = true;
    }
  }

  changed(profileId) {
    return this.userProfiles.find(profile => profile.id.id == profileId)?.name
  }

  /**
   * represents profile value in main page and in pop-up
   * partial logic for isDisableSave for simple users only
   */
  displayAssignedProfileValue(currentuserprofileid?: number, profileDesc?: string, profile?: any): string {
    let tempUserProfile: S1UserProfile = this.userProfiles.find(userprofile => userprofile.id.id === currentuserprofileid);
    this.selectedProfileId = this.userProfiles.find(uprofile => uprofile.name === this.userRole?.name)?.id.id;


    if (tempUserProfile) {

      return tempUserProfile.name;
    } else {
      this.enable()
      return undefined;
    }
  }

  handleHttpError(errdata: any, isHideWindow?: boolean) {
    this.isError = true;
    this.errorMessage = errdata.name + ": " + errdata.status + ". "
      + this.textLoadValue;
    this.showAppLoader = false;
    if (isHideWindow) {
      this.decline();
    }
  }
  getPerm(userProfileId: number): Promise<any> {
    return this.entityManagementService.authGetUserProfilesId(userProfileId).toPromise().then(response => {
  // console.logog('RESPONSE PERMISSION', response);
      return response;
      // Aggiungi la logica per manipolare la risposta come necessario
    }).catch(error => {
      // console.error('Errore nel recupero dei permessi utente', error);
    });
  }
  openDialog(template: TemplateRef<any>) {
    this.ngOnInit()
    this.selected = null;
    this.files1 = null;
    this.isAllChecked = false;
    this.listaUtentiDaStampare = [];
    for (let i = 0; i < 10; i++) {
      this.selectedCheckboxes.push(false);
    }
    this.modalRef = this.modalService.open(template, MODAL_OPTIONS);
  }
  selectedCheckboxes: boolean[] = [];
  isLoading: boolean = false;

  resetAllCheckboxes(): void {
    this.selectedCheckboxes = this.selectedCheckboxes.map(() => false);
// console.logog('Tutte le checkbox sono state resettate:', this.selectedCheckboxes);
  }
  filterTree(tree: TreeObject[], userSelected): any {
    // console.log('FILTERTREE');
    let groupNames;
    let processNames;
    let instituteNames;
    let userProfileIdTest;
    let isEnabledGroup;
    let isEnabledInstitute;
    let isEnabledProcess;
    const traverse = async (nodes: TreeObject[]) => {
      for (const node of nodes) {
    // console.logog('NODE ', node)
        if (node.data) {

          switch (node.data.type) {
            case 'group':
              if (node.data.name) {
                groupNames = node.data.name
                isEnabledGroup = node.data.enabled
              }
              break;
            case 'institute': // TODO ORGANIZATION
              if (node.data.name) {
                instituteNames = node.data.name
                isEnabledInstitute = node.data.enabled

              }
              break;
            case 'process':
              if (node.data.name) {
                processNames = node.data.name
                isEnabledProcess = node.data.enabled

                if (node.data.userProfileId) {
                  userProfileIdTest = node.data.userProfileId

                }
              }

              break;
          }
        }
        if (node.children) {
          await traverse(node.children);
        }
        if (node.children.length === 0) {
          if (userProfileIdTest && isEnabledProcess) {
            let testPerm = await this.getPerm(userProfileIdTest)
            let variabile = testPerm.permissions.filter(permissions => permissions.permission === 'VARIABLE').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'VARIABLE')[0].level : null;
            let structure = testPerm.permissions.filter(permissions => permissions.permission === 'STRUCTURE').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'STRUCTURE')[0].level : null;
            let layoutInput = testPerm.permissions.filter(permissions => permissions.permission === 'LAYOUT_INPUT').length !== 0 ? testPerm.permissions.filter(permissions => permissions.permission === 'LAYOUT_INPUT')[0].level : null;

            let layoutOutput = testPerm.permissions.filter(permissions => permissions.permission === 'LAYOUT_OUTPUT') .length !== 0 ? testPerm.permissions.filter(permissions => permissions.permission === 'LAYOUT_OUTPUT')[0].level : null;
            let product = testPerm.permissions.filter(permissions => permissions.permission === 'PRODUCT').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'PRODUCT')[0].level : null;
            let operation = testPerm.permissions.filter(permissions => permissions.permission === 'OPERATION').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'OPERATION')[0].level : null;
            let calculation = testPerm.permissions.filter(permissions => permissions.permission === 'CALCULATION').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'CALCULATION')[0].level : null;
            let rules = testPerm.permissions.filter(permissions => permissions.permission === 'RULE').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'RULE')[0].level : null;
            let genericLibrary = testPerm.permissions.filter(permissions => permissions.permission === 'GENERIC_LIBRARY').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'GENERIC_LIBRARY')[0].level : null;
            let exclusionLibrary = testPerm.permissions.filter(permissions => permissions.permission === 'EXCLUSION_LIBRARY').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'EXCLUSION_LIBRARY')[0].level : null;
            let testLibrary = testPerm.permissions.filter(permissions => permissions.permission === 'TEST_LIBRARY').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'TEST_LIBRARY')[0].level : null;
            let riskLevels = testPerm.permissions.filter(permissions => permissions.permission === 'RISK_LEVELS').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'RISK_LEVELS')[0].level : null;
            let trees = testPerm.permissions.filter(permissions => permissions.permission === 'TREE').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'TREE')[0].level : null;
            let decisionTables = testPerm.permissions.filter(permissions => permissions.permission === 'SIMPLETABLE').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'SIMPLETABLE')[0].level : null;
            let cutOffTables = testPerm.permissions.filter(permissions => permissions.permission === 'CUTOFFTABLE').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'CUTOFFTABLE')[0].level : null;
            let externalTables = testPerm.permissions.filter(permissions => permissions.permission === 'EXTERNAL_TABLE').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'EXTERNAL_TABLE')[0].level : null;
            let Scorecard = testPerm.permissions.filter(permissions => permissions.permission === 'SCORECARD').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'SCORECARD')[0].level : null;
            let templateforScenario = testPerm.permissions.filter(permissions => permissions.permission === 'SCENARIO_TEMPLATE').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'SCENARIO_TEMPLATE')[0].level : null;
            let scenario = testPerm.permissions.filter(permissions => permissions.permission === 'SCENARIO').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'SCENARIO')[0].level : null;
            let segmentation = testPerm.permissions.filter(permissions => permissions.permission === 'SEGMENTATION').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'SEGMENTATION')[0].level : null;
            let strategy = testPerm.permissions.filter(permissions => permissions.permission === 'STRATEGY').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'STRATEGY')[0].level : null;
            let subStrategy = testPerm.permissions.filter(permissions => permissions.permission === 'SUBSTRATEGY').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'SUBSTRATEGY')[0].level : null;
            let plugin = testPerm.permissions.filter(permissions => permissions.permission === 'PLUGIN').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'PLUGIN')[0].level : null;
            let scripting = testPerm.permissions.filter(permissions => permissions.permission === 'SCRIPT').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'SCRIPT')[0].level : null;
            let pmmlModels = testPerm.permissions.filter(permissions => permissions.permission === 'PMML_MODEL').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'PMML_MODEL')[0].level : null;
            let connectors = testPerm.permissions.filter(permissions => permissions.permission === 'CONNECTORS').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'CONNECTORS')[0].level : null;
            let pythonModels = testPerm.permissions.filter(permissions => permissions.permission === 'PYTHON').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'PYTHON')[0].level : null;

            let debuggerVariable = testPerm.permissions.filter(permissions => permissions.permission === 'DEBUGGER').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'DEBUGGER')[0].level : null;
            let whatIf = testPerm.permissions.filter(permissions => permissions.permission === 'WHATIF').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'WHATIF')[0].level : null;
            let comparison = testPerm.permissions.filter(permissions => permissions.permission === 'IO_COMPARATOR').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'IO_COMPARATOR')[0].level : null;
            let deploy = testPerm.permissions.filter(permissions => permissions.permission === 'DEPLOY').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'DEPLOY')[0].level : null;
            let importExport = testPerm.permissions.filter(permissions => permissions.permission === 'IMPORT_EXPORT').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'IMPORT_EXPORT')[0].level : null;
            let checkinCheckout = testPerm.permissions.filter(permissions => permissions.permission === 'CHCKIN_CHECKOUT').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'CHCKIN_CHECKOUT')[0].level : null;
            let printProcess = testPerm.permissions.filter(permissions => permissions.permission === 'PRINT_PROCESS').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'PRINT_PROCESS')[0].level : null;
            let enabledInBAC = testPerm.permissions.filter(permissions => permissions.permission === 'BAC_ENABLED').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'BAC_ENABLED')[0].level : null;
            let history = testPerm.permissions.filter(permissions => permissions.permission === 'HISTORY').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'HISTORY')[0].level : null;
            let categories = testPerm.permissions.filter(permissions => permissions.permission === 'EVO_CATEGORIES').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'EVO_CATEGORIES')[0].level : null;
            let inputOutputCategories = testPerm.permissions.filter(permissions => permissions.permission === 'EVO_INPUT_OUTPUT').length !== 0 ?testPerm.permissions.filter(permissions => permissions.permission === 'EVO_INPUT_OUTPUT')[0].level : null;
            const newUser = this.createRow(
              userSelected.firstName,
              userSelected.lastName,
              userSelected.userIdString,
              'USER',
              groupNames,
              instituteNames,
              processNames,
              testPerm.name + ' - ' + testPerm.description,
              variabile === 'LEVEL_READ' ? 'Read' : variabile === 'LEVEL_WRITE' ? 'Write' : variabile === 'LEVEL_EXEC' ? 'Execute' : 'None',
              structure === 'LEVEL_READ' ? 'Read' : structure === 'LEVEL_WRITE' ? 'Write' : structure === 'LEVEL_EXEC' ? 'Execute' : 'None',
              layoutInput === 'LEVEL_READ' ? 'Read' : layoutInput === 'LEVEL_WRITE' ? 'Write' : layoutInput === 'LEVEL_EXEC' ? 'Execute' : 'None',
              layoutOutput === 'LEVEL_READ' ? 'Read' : layoutOutput === 'LEVEL_WRITE' ? 'Write' : layoutOutput === 'LEVEL_EXEC' ? 'Execute' : 'None',
              product === 'LEVEL_READ' ? 'Read' : product === 'LEVEL_WRITE' ? 'Write' : product === 'LEVEL_EXEC' ? 'Execute' : 'None',
              operation === 'LEVEL_READ' ? 'Read' : operation === 'LEVEL_WRITE' ? 'Write' : operation === 'LEVEL_EXEC' ? 'Execute' : 'None',
              calculation === 'LEVEL_READ' ? 'Read' : calculation === 'LEVEL_WRITE' ? 'Write' : calculation === 'LEVEL_EXEC' ? 'Execute' : 'None',
              rules === 'LEVEL_READ' ? 'Read' : rules === 'LEVEL_WRITE' ? 'Write' : rules === 'LEVEL_EXEC' ? 'Execute' : 'None',
              genericLibrary === 'LEVEL_READ' ? 'Read' : genericLibrary === 'LEVEL_WRITE' ? 'Write' : genericLibrary === 'LEVEL_EXEC' ? 'Execute' : 'None',
              exclusionLibrary === 'LEVEL_READ' ? 'Read' : exclusionLibrary === 'LEVEL_WRITE' ? 'Write' : exclusionLibrary === 'LEVEL_EXEC' ? 'Execute' : 'None',
              testLibrary === 'LEVEL_READ' ? 'Read' : testLibrary === 'LEVEL_WRITE' ? 'Write' : testLibrary === 'LEVEL_EXEC' ? 'Execute' : 'None',
              riskLevels === 'LEVEL_READ' ? 'Read' : riskLevels === 'LEVEL_WRITE' ? 'Write' : riskLevels === 'LEVEL_EXEC' ? 'Execute' : 'None',
              trees === 'LEVEL_READ' ? 'Read' : trees === 'LEVEL_WRITE' ? 'Write' : trees === 'LEVEL_EXEC' ? 'Execute' : 'None',
              decisionTables === 'LEVEL_READ' ? 'Read' : decisionTables === 'LEVEL_WRITE' ? 'Write' : decisionTables === 'LEVEL_EXEC' ? 'Execute' : 'None',
              cutOffTables === 'LEVEL_READ' ? 'Read' : cutOffTables === 'LEVEL_WRITE' ? 'Write' : cutOffTables === 'LEVEL_EXEC' ? 'Execute' : 'None',
              externalTables === 'LEVEL_READ' ? 'Read' : externalTables === 'LEVEL_WRITE' ? 'Write' : externalTables === 'LEVEL_EXEC' ? 'Execute' : 'None',
              Scorecard === 'LEVEL_READ' ? 'Read' : Scorecard === 'LEVEL_WRITE' ? 'Write' : Scorecard === 'LEVEL_EXEC' ? 'Execute' : 'None',
              templateforScenario === 'LEVEL_READ' ? 'Read' : templateforScenario === 'LEVEL_WRITE' ? 'Write' : templateforScenario === 'LEVEL_EXEC' ? 'Execute' : 'None',
              scenario === 'LEVEL_READ' ? 'Read' : scenario === 'LEVEL_WRITE' ? 'Write' : scenario === 'LEVEL_EXEC' ? 'Execute' : 'None',
              segmentation === 'LEVEL_READ' ? 'Read' : segmentation === 'LEVEL_WRITE' ? 'Write' : segmentation === 'LEVEL_EXEC' ? 'Execute' : 'None',
              strategy === 'LEVEL_READ' ? 'Read' : strategy === 'LEVEL_WRITE' ? 'Write' : strategy === 'LEVEL_EXEC' ? 'Execute' : 'None',
              subStrategy === 'LEVEL_READ' ? 'Read' : subStrategy === 'LEVEL_WRITE' ? 'Write' : subStrategy === 'LEVEL_EXEC' ? 'Execute' : 'None',
              plugin === 'LEVEL_READ' ? 'Read' : plugin === 'LEVEL_WRITE' ? 'Write' : plugin === 'LEVEL_EXEC' ? 'Execute' : 'None',
              scripting === 'LEVEL_READ' ? 'Read' : scripting === 'LEVEL_WRITE' ? 'Write' : scripting === 'LEVEL_EXEC' ? 'Execute' : 'None',
              pmmlModels === 'LEVEL_READ' ? 'Read' : pmmlModels === 'LEVEL_WRITE' ? 'Write' : pmmlModels === 'LEVEL_EXEC' ? 'Execute' : 'None',
              connectors === 'LEVEL_READ' ? 'Read' : connectors === 'LEVEL_WRITE' ? 'Write' : connectors === 'LEVEL_EXEC' ? 'Execute' : 'None',
              pythonModels === 'LEVEL_READ' ? 'Read' : pythonModels === 'LEVEL_WRITE' ? 'Write' : 'None',
              debuggerVariable === 'LEVEL_READ' ? 'Read' : debuggerVariable === 'LEVEL_WRITE' ? 'Write' : debuggerVariable === 'LEVEL_EXEC' ? 'Execute' : 'None',
              whatIf === 'LEVEL_READ' ? 'Read' : whatIf === 'LEVEL_WRITE' ? 'Write' : whatIf === 'LEVEL_EXEC' ? 'Execute' : 'None',
              comparison === 'LEVEL_READ' ? 'Read' : comparison === 'LEVEL_WRITE' ? 'Write' : comparison === 'LEVEL_EXEC' ? 'Execute' : 'None',
              deploy === 'LEVEL_READ' ? 'Read' : deploy === 'LEVEL_WRITE' ? 'Write' : deploy === 'LEVEL_EXEC' ? 'Execute' : 'None',
              importExport === 'LEVEL_READ' ? 'Read' : importExport === 'LEVEL_WRITE' ? 'Write' : importExport === 'LEVEL_EXEC' ? 'Execute' : 'None',
              checkinCheckout === 'LEVEL_READ' ? 'Read' : checkinCheckout === 'LEVEL_WRITE' ? 'Write' : checkinCheckout === 'LEVEL_EXEC' ? 'Execute' : 'None',
              printProcess === 'LEVEL_READ' ? 'Read' : printProcess === 'LEVEL_WRITE' ? 'Write' : printProcess === 'LEVEL_EXEC' ? 'Execute' : 'None',
              enabledInBAC === 'LEVEL_READ' ? 'Read' : enabledInBAC === 'LEVEL_WRITE' ? 'Write' : enabledInBAC === 'LEVEL_EXEC' ? 'Execute' : 'None',
              history === 'LEVEL_READ' ? 'Read' : history === 'LEVEL_WRITE' ? 'Write' : history === 'LEVEL_EXEC' ? 'Execute' : 'None',
              categories === 'LEVEL_READ' ? 'Read' : categories === 'LEVEL_WRITE' ? 'Write' : categories === 'LEVEL_EXEC' ? 'Execute' : 'None',
              inputOutputCategories === 'LEVEL_READ' ? 'Read' : inputOutputCategories === 'LEVEL_WRITE' ? 'Write' : inputOutputCategories === 'LEVEL_EXEC' ? 'Execute' : 'None',
            )
            this.listaUtentiDaStampare.push(newUser)
            instituteNames = '';
          } else if (userSelected.userRole === 5 ){

            if(isEnabledGroup){

                const newUser = this.createRow(
                  userSelected.firstName,
                  userSelected.lastName,
                  userSelected.userIdString,
                  'MANAGER',
                  groupNames,
                  isEnabledInstitute ? instituteNames:'',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                );
                const isDuplicate = this.listaUtentiDaStampare.some(user => this.areUsersEqual(user, newUser));
             // Aggiungi newUser solo se non è duplicato
              if (!isDuplicate) {
                  this.listaUtentiDaStampare.push(newUser);
                instituteNames = '';

                }
                this.listaUtentiDaStampare = this.listaUtentiDaStampare.filter((user, index, self) =>
                  index === self.findIndex(u => this.areUsersEqual(u, user))
                );
              }




          }



        }

      }
    };

    traverse(tree);

    return 'result';
  }
  areUsersEqual(user1: ExcellRowTable, user2: ExcellRowTable): boolean {
    return (
    user1.name === user2.name &&
    user1.user_id === user2.user_id &&
    user1.role === user2.role &&
    user1.groupID === user2.groupID &&
    user1.organization === user2.organization &&
    user1.process === user2.process

    );
  }
  maxUsers = 1;

  async utenteSelezionatoCheckbox(ev: Event, userSelected,users, isCheckedAllButton) {
    // // console.log(' this.user.', userSelected);
    const target = ev.target as HTMLInputElement;
    const isChecked = target.checked;
    // console.log('UTENTI',);
    this.isLoading = true;
    if (isChecked) {
      this.entityManagementService.getPermissionsStructure(userSelected.userIdDb).subscribe({
        next: async response => {
          this.files1New = response;
          this.userProfileIds = this.getUserProfileIds(this.files1New[0]);
          await this.filterTree(response, userSelected);

        },
         error: err => {
        // console.error('Error fetching permissions structure', err);
         },
         complete: () => {
     if(isCheckedAllButton){
       if(this.checkboxIds.length-1 === this.maxUsers){
       setTimeout(()=>  this.isLoading = false, 10000)

         this.maxUsers = 1
       //  this.checkboxIds = []
       } else {
         this.maxUsers += 1
         // console.log('+= 1 .MAX else = ',this.maxUsers);
       }
     } else {
       this.isLoading = false

     }


           // console.log('Observable completed',users.length);
         }
      })
   // console.log('FINITO');

    } else {
      // console.log('userSelected',userSelected);
      this.listaUtentiDaStampare = this.removeUser(userSelected.userIdString);
    }
  }

  removeUser(userId: string): ExcellRowTable[] {

    return this.listaUtentiDaStampare.filter(user => user.user_id !== userId);
  }

  exporUserToXlsx() {
    this.exporUserToXlsxOld();
  }
toggleFalseCheckbox(){
  this.checkboxIds.forEach(id => {
    const checkbox = document.getElementById(id) as HTMLInputElement;
    if (checkbox) {
      checkbox.checked = this.isAllChecked;
    }
  });

}
  exportUserToXlsxFromModal() {
    this.exporUserToXlsxOld();
    this.toggleFalseCheckbox();
    this.checkboxIds = [];
    this.modalRef.close()
  }
  exporUserToXlsxOld() {
    // Ordinare la lista degli utenti per la colonna "Name"
    this.listaUtentiDaStampare.sort((a, b) => {
      const nameA = a.name.toLowerCase(); // Presuppone che l'oggetto abbia una proprietà 'Name'
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    // Converti i dati ordinati in un array di array
    const arraydiArray = this.listaUtentiDaStampare.map(obj => Object.values(obj));

    // Creare il workbook e aggiungere informazioni generali
    const workbook = XLSX.utils.book_new();
    const name = this.loggedUserInfo.getLoginName();
    const lastName = this.loggedUserInfo.getLoginSurname();
    const userName = this.loggedUserInfo.getLoginUserId();

    // Definizione dei dati del report
    const data = [
      ['Access Matrix Details Report'],
      ['Generate By:', `${userName} (${name}, ${lastName})`],
      ['Generate Date:', formatDate(new Date(), 'dd/MM/yyyy, hh:mm:ss a', 'en-US')],
      [''],
      [
        '', '', '', '', '', '', '', '', 'Data Model', '', 'Object', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'Testing', 'Testing', '', 'Actions', 'Actions', '', '', 'Miscellaneous', 'Miscellaneous', 'Evolutions', 'Evolutions'
      ],
      [
        'Name', 'Surname', 'User_ID', 'Role', 'Group', 'Organization', 'Process', 'assigned profile', 'Variable', 'Structure', 'Layout Input', 'Layout Output', 'Product', 'Operation', 'Calculation', 'Rules', 'Generic Library', 'Exclusion Library', 'Test Library', 'Risk Levels', 'Trees', 'Decision Tables', 'Cut-off Tables', 'External Tables', 'Scorecard', 'Template for Scenario', 'Scenario', 'Segmentation', 'Strategy', 'Sub-Strategy', 'Plugin', 'Scripting', 'PMML Models', 'Connectors', 'Python Models', 'Debugger', 'What-if', 'Comparison', 'Deploy', 'Import/Export', 'Check-in/Check-out', 'Print Process', 'Enabled In BAC', 'History', 'Categories', 'Input/output Categories'
      ],
      ...arraydiArray
    ];

    // Creazione del foglio di lavoro
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    // Unione delle celle per le intestazioni
    worksheet['!merges'] = [
      { s: { r: 4, c: 0 }, e: { r: 4, c: 7 } },
      { s: { r: 4, c: 8 }, e: { r: 4, c: 9 } },
      { s: { r: 4, c: 10 }, e: { r: 4, c: 34 } },
      { s: { r: 4, c: 35 }, e: { r: 4, c: 37 } },
      { s: { r: 4, c: 38 }, e: { r: 4, c: 42 } },
      { s: { r: 4, c: 44 }, e: { r: 4, c: 45 } }
    ];

    // Larghezza delle colonne
    const wscols = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 45 }
    ];

    // Altezza delle righe
    const wrows = [
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 }
    ];

    worksheet['!rows'] = wrows;
    worksheet['!cols'] = wscols;

    // Aggiunta del foglio di lavoro al workbook e salvataggio del file
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Access Matrix Details Report');
    XLSX.writeFile(workbook, 'Access Matrix Details Report.xlsx');
  }
  exporUserToXlsxOld1() {
// console.logog('this.files1  New 2 ', this.files1)

    // console.log('listaUtentiDaStampare dentor OLD', this.listaUtentiDaStampare);
    const workbook = XLSX.utils.book_new();
    const name = this.loggedUserInfo.getLoginName()
    const lastName = this.loggedUserInfo.getLoginSurname()
    const userName = this.loggedUserInfo.getLoginUserId()
    const arraydiArray = this.listaUtentiDaStampare.map(obj => Object.values(obj));
// console.logog('this.files1  New 3 ', this.files1)

    const data = [
      ['Access Matrix Details Report'],
      ['Generate By:', userName + ' ' + '(' + name + ',' + lastName + ')'],
      ['Generate Date:', formatDate(new Date(), 'dd/MM/yyyy, hh:mm:ss a', 'en-US')],
      [''],
      ['', '', '', '', '', '', '', '', 'Data Model', '', 'Object', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'Testing', 'Testing', '', 'Actions', 'Actions', '', '', 'Miscellaneous', 'Miscellaneous', 'Evolutions', 'Evolutions'],
      [
        'Name',
        'Surname',
        'User_ID',
        'Role',
        'Group',
        'Organization',
        'Process',
        'assigned profile',
        'Variable',
        'Structure',
        'Layout Input',
        'Layout Output',
        'Product',
        'Operation',
        'Calculation',
        'Rules',
        'Generic Library',
        'Exclusion Library',
        'Test Library',
        'Risk Levels',
        'Trees',
        'Decision Tables',
        'Cut-off Tables',
        'External Tables',
        'Scorecard',
        'Template for Scenario',
        'Scenario',
        'Segmentation',
        'Strategy',
        'Sub-Strategy',
        'Plugin',
        'Scripting', 'PMML Models', 'Connectors', 'Python Models', 'Debugger', 'What-if', 'Comparison', 'Deploy', 'Import/Export', 'Check-in/Check-out', 'Print Process', 'Enabled In BAC', 'History', 'Categories', 'Input/output Categories'],
      ...arraydiArray
    ]
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    worksheet['!merges'] = [
      {s: {r: 4, c: 0}, e: {r: 4, c: 7}},
      {s: {r: 4, c: 8}, e: {r: 4, c: 9}},
      {s: {r: 4, c: 10}, e: {r: 4, c: 34}},
      {s: {r: 4, c: 35}, e: {r: 4, c: 37}},
      {s: {r: 4, c: 38}, e: {r: 4, c: 42}},
      {s: {r: 4, c: 44}, e: {r: 4, c: 45}},
    ];

    var wscols = [
      {wch: 20},
      {wch: 20},
      {wch: 20},
      {wch: 20},
      {wch: 20},
      {wch: 20},
      {wch: 20},
      {wch: 45},

    ];
// console.logog('this.files1  New 4 ', this.files1)

    const wrows = [
      {hpt: 20},
      {hpt: 20},
      {hpt: 20},
    ];

    worksheet['!rows'] = wrows;
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Access Matrix Details Report');
    XLSX.writeFile(workbook, 'Access Matrix Details Report.xlsx');

  }

  createRow(name: any,
            surname: string,
            user_id: string,
            role: string,
            groupID: string,
            organization: string,
            process: string,
            assigned_profile: string,
            variable: string,
            structure: string,
            layoutInput: string,
            layoutOutput: string,
            product: string,
            operation: string,
            calculation: string,
            rules: string,
            genericLibrary: string,
            exclusionLibrary: string,
            testLibrary: string,
            riskLevels: string,
            trees: string,
            decisionTables: string,
            cutOffTables: string,
            externalTables: string,
            Scorecard: string,
            templateforScenario: string,
            scenario: string,
            segmentation: string,
            strategy: string,
            subStrategy: string,
            plugin: string,
            scripting: string,
            pmmlModels: string,
            connectors: string,
            pythonModels: string,
            debuggerVariable: string,
            whatIf: string,
            comparison: string,
            deploy: string,
            importExport: string,
            checkinCheckout: string,
            printProcess: string,
            enabledInBAC: string,
            history: string,
            categories: string,
            inputOutputCategories: string,
  ): ExcellRowTable {

    return {
      name,
      surname,
      user_id,
      role,
      groupID,
      organization,
      process,
      assigned_profile,
      variable,
      structure,
      layoutInput,
      layoutOutput,
      product,
      operation,
      calculation,
      rules,
      genericLibrary,
      exclusionLibrary,
      testLibrary,
      riskLevels,
      trees,
      decisionTables,
      cutOffTables,
      externalTables,
      Scorecard,
      templateforScenario,
      scenario,
      segmentation,
      strategy,
      subStrategy,
      plugin,
      scripting,
      pmmlModels,
      connectors,
      pythonModels,
      debuggerVariable,
      whatIf,
      comparison,
      deploy,
      importExport,
      checkinCheckout,
      printProcess,
      enabledInBAC,
      history,
      categories,
      inputOutputCategories,

    };
  }

  getUserProfileIds(tree: TreeObject): number[] {
    let userProfileIds: number[] = [];

    const traverse = (node: TreeObject) => {
      if (node.data?.userProfileId) {
        userProfileIds.push(node.data.userProfileId);
      }
      if (node.children) {
        node.children.forEach(child => traverse(child));
      }
    };

    traverse(tree);
    return userProfileIds;
  }

  getPermissions(tree: TreeObject): any[] {
    let permissions: any[] = [];

    const traverse = (node: TreeObject) => {
      if (node.data?.permission) {
        permissions = permissions.concat(node.data.permission);
      }
      if (node.children) {
        node.children.forEach(child => traverse(child));
      }
    };

    traverse(tree);
    return permissions;
  }

  isCheckedAllButton:boolean;
  toggleAllCheckboxes(ev: Event,users,isCheckedAllButton) {
    this.checkboxIds = [];
    this.maxUsers = 1
    this.generateCheckboxIds(users);

    this.isAllChecked = !this.isAllChecked;
    this.isCheckedAllButton = isCheckedAllButton;
    if (this.isAllChecked) {
      users.map(async user => {
          await this.utenteSelezionatoCheckbox(ev, user,users,isCheckedAllButton)
          //this.loadPermisions(user)


        }
      )
    } else {
      this.listaUtentiDaStampare = []
    }

   // this.checkboxes.forEach((checkbox: ElementRef) => {
  //    const inputElement = checkbox.nativeElement as HTMLInputElement;
  //    console.log('IMPUT',inputElement);
  //    inputElement.checked = this.isAllChecked;
  //  });
this.updateCheckboxes()
  }
  generateCheckboxIds(users) {
    for (let i = 0; i <= users.length-1; i++) {
    //  console.log('TEST USERS 1 - ',users[i].value.userIdString)

      this.checkboxIds.push(users[i].viewValue);
    }
  }

  updateCheckboxes() {

    if(this.checkboxIds.length !==0){
      this.checkboxIds.forEach(id => {
        const checkbox = document.getElementById(id) as HTMLInputElement;

        if (checkbox) {
          checkbox.checked = this.isAllChecked;

        }
      });
    }

  }



}

interface ExcellRowTable {
  name: any;
  surname: string;
  user_id: string;
  role: string;
  groupID: string;
  organization: string;
  process: string;
  assigned_profile: string;
  variable: string;
  structure: string;
  layoutInput: string;
  layoutOutput: string;
  product: string;
  operation: string;
  calculation: string;
  rules: string;
  genericLibrary: string;
  exclusionLibrary: string;
  testLibrary: string;
  riskLevels: string;
  trees: string;
  decisionTables: string;
  cutOffTables: string;
  externalTables: string;
  Scorecard: string;
  templateforScenario: string;
  scenario: string;
  segmentation: string;
  strategy: string;
  subStrategy: string;
  plugin: string;
  scripting: string;
  pmmlModels: string;
  connectors: string;
  pythonModels: string;
  debuggerVariable: string;
  whatIf: string;
  comparison: string;
  deploy: string;
  importExport: string;
  checkinCheckout: string;
  printProcess: string;
  enabledInBAC: string;
  history: string;
  categories: string;
  inputOutputCategories: string;
}

export interface FilteredResult {
  groupNames: string;
  instituteNames: string;
  processNames: string;
  userProfileIds: number;
}

