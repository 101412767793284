import { InputVariables } from "./bac-inputvariables";
import { OutputVariables } from "./bac-outputvariables";

export class Connector {
    id:string;
    passwordHash? : string;
    description?:string;
    reporting?:boolean;
    bac?:boolean;
    editable:boolean;
    protocolType:string;
    needManualFix?:boolean;
    version?:number;
    context:string;
    resourceID?:string;
    defaultEnvironment?:boolean;
    url?:string;
    enableAuthentication?:boolean;
    authUser?:string;
    authPassword?:any; //byte[]
    
    inputVariables?:InputVariables;
    outputVariables?:OutputVariables;

    guid:string;
    ownerID?:string;
    sharedGUID?:string;
    crc?:string;

    //initialize required variables to default values
    constructor() {
        this.id="";
        this.protocolType="";
        this.context="";  
        this.guid=""; 
    }
}