import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'installation-management',          
        loadChildren: () => import('./screens/installation-management/installation-management.module').then(m => m.InstallationManagementModule)
      },
      {
        path: 'process-management',          
        loadChildren: () => import('./screens/process-management/process-management.module').then(m => m.ProcessManagementModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule],
 
})
export class AppRoutingModule { }
