import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'appspinner',
  templateUrl: './appspinner.component.html',
  styleUrls: ['./appspinner.component.scss']
})
export class AppSpinnerComponent implements OnInit {
  @Input() startSpinner : boolean = true;
  constructor(private translate: TranslateService) { 
    //do nothing. translate used in html
  }
  ngOnInit() {
    //do nothing
  }
}