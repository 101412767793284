import { MissingAllType } from "./MissingAllType.enum";
import { S1ProcessInfo } from "./s1-processInfo";
import { S1Process } from "./s1process.model";

// export class CreateProcessEvoRequest {
//     processInfo: S1ProcessInfo;
//     comment: string;
//     shared: boolean;
//     description: string;
//     missingAllType: MissingAllType;
//     encrypted: boolean;

// }

export class S1ApprovalTemplateID{
    approvalTemplateId: number;
}
export class S1Process2{
    processXML: S1ProcessXML
}
export class S1ProcessXML{
    contentType: string;
    data:string
}
export class CreateProcess {
    processInfo: S1ProcessInfo;
    process: S1Process2;
    comment: string;
    s1ApprovalTemplateID: S1ApprovalTemplateID
}

export class CreateProcessXML{
    name: string;
    archetype: string;
    shared: boolean;
    description: string;
    missingAllType: string;
    encrypted: true;
    processType: string;
    clientVersion: string;
    iotype: string
  }