import { ResultVO } from './resultvo';
import { SessionToken } from './sessiontoken';
import { S1UserInfo } from './s1-userInfo';
import { S1PythonModel } from './s1-pythonmodel';
import { S1PythonActivityReport } from './s1-pythonactivityreport';
import { S1ProcessInfo } from './s1-processInfo';
import { S1WhatifScheduleInfo } from './s1-whatifScheduleInfoArray';
import { S1ChunkInfo } from './s1-chunkinfo';
import { S1SystemInfo } from 'app/entities/s1-systeminfo';

export class ResMap{
    ResultVO : ResultVO = new ResultVO();
    sessionToken? : SessionToken = new SessionToken();
    s1UserInfo? : S1UserInfo;
    S1PythonModelArray? : S1PythonModel[] = [];
    S1PythonActivityReport? : S1PythonActivityReport = new S1PythonActivityReport();
    S1ProcessInfoArray? : S1ProcessInfo[] = [];
    S1WhatifScheduleInfoArray? : S1WhatifScheduleInfo[] = [];
    S1ChunkInfo? : S1ChunkInfo[] = [];
    S1SystemInfo? : S1SystemInfo;
}