import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageDetails } from './user-details.service';
import { S1PythonCommand } from 'app/entities/s1-pythoncommand.enum';
import { S1PythonModel } from 'app/entities/s1-pythonmodel';
import { S1CreatePythonModelRequest } from 'app/entities/s1-createpythonmodelrequest';
import { ExecPythonModelRequest } from 'app/entities/execPythonModelRequest';

@Injectable({
  providedIn: 'root'
})
export class PythonManagementService {
  constructor(private http: HttpClient, private userInfo: StorageDetails) {
  }
  
  getAllPythonModels(checkRunningState: boolean): Observable<any> {
    return this.http.get("private/models?checkRunningState=" + checkRunningState);
  }

  docExecPythonModelCtrl(pythonCommand: S1PythonCommand, s1PythonModel: S1PythonModel): Observable<any> {
    let execReq: ExecPythonModelRequest = new ExecPythonModelRequest();
    execReq.command = pythonCommand;
    execReq.s1PythonModel = s1PythonModel;
    return this.http.put<any>( "private/models/execute", execReq);
  }

  getPythonModelByPort(portid: number): Observable<any> {
    return this.http.get<any>( "private/models/port" + "?port_id=" + portid);
  }

  docCreatePythonModel(createPythonModelRequest: S1CreatePythonModelRequest): Observable<any> {
    return this.http.post<any>( "private/models", createPythonModelRequest);
  }
}
