import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class TranslateConfigService {
    constructor(
        private translate: TranslateService
    ) {
        // Gets Default language from browser if available, otherwise set English ad default
        //translate.addLangs(['en', 'it']);
        const browserLang = translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|it/) ? browserLang : 'en');//|es|fr|de-DE|ur|fa|zh-CHS|de|tr-TR|ru|zh-CN
    }

    getDefaultLanguage() {
        let language = this.translate.getBrowserLang();
        this.translate.setDefaultLang(language);
        return language;
    }

    setLanguage(setLang) {
        this.translate.use(setLang);
    }

}