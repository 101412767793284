import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
@Pipe({
    name: 'statusPipe'
})
export class StatusMappingFilter implements PipeTransform {
    interruptStatus: string;
    pendingStatus: string;
    runningStatus: string;
    doneStatus: string;
    failedStatus: string;
    deleteStatus: string;
    waitStatus: string;
    downloadStatus: string;

    constructor(private translate: TranslateService) {
        this.translate.get(["Interrupted", "Pending", "Running", "Done", "Failed", "Deleting", "Waiting Download", "Downloading"]).subscribe((text: any) => {
            this.interruptStatus = text["Interrupted"];
            this.pendingStatus = text["Pending"];
            this.runningStatus = text["Running"];
            this.doneStatus = text["Done"];
            this.failedStatus = text["Failed"];
            this.deleteStatus = text["Deleting"];
            this.waitStatus = text["Waiting Download"];
            this.downloadStatus = text["Downloading"];
        });
    }
    public transform(dbvalue) {
        let status: string;
        switch (dbvalue) {
            case "STATUS_INTERRUPTED":
                status = this.interruptStatus;
                break;
            case "STATUS_PENDING":
                status = this.pendingStatus;
                break;
            case "STATUS_RUNNING":
                status = this.runningStatus;
                break;
            case "STATUS_DONE":
                status = this.doneStatus;
                break;
            case "STATUS_FAILED":
                status = this.failedStatus;
                break;
            case "STATUS_DELETING":
                status = this.deleteStatus;
                break;
            case "STATUS_WATING_DOWNLOAD":
                status = this.waitStatus;
                break;
            default:
                status = this.downloadStatus;
        }
        return status;
    }
}