//import 'sweetalert2/src/sweetalert2.scss';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Router } from '@angular/router';
import { StorageDetails } from '../services/user-details.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionTimeoutComponent } from 'app/shared/SessionComponent/session-timeout.component';
import { environment } from 'environments/environment';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

    constructor(private modalService: NgbModal, private router: Router,
        private storageDetails: StorageDetails) { }
    modalRef: any;

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const modifiedReq = this.buildHeaders(req);
        return next.handle(modifiedReq).pipe(
            tap((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if ((error.status === 401 && !error.url.endsWith("login") && !error.url.endsWith("logoff"))
                    || (error.status === 404 && error.url.endsWith("null.json"))
                    || (error.status === 504 && error.statusText === "Gateway Timeout" && !this.router.url.endsWith("login"))
                    ) {
                    this.showError();
                } else {                    
                    return throwError(() => error);
                }
            })
        );
    }

    buildHeaders(req){
        let token = this.storageDetails.getUserToken();
        let modifiedReq;
        if(token!=null){
            let modifiedHeader = req.headers.set('Authorization', `Bearer ${this.storageDetails.getUserToken()}`);
            modifiedReq = req.clone({
                url: environment.baseUrl + req.url,
                headers: modifiedHeader,
            });
        }else{
            modifiedReq = req.clone({
                url: environment.baseUrl + req.url
            });
        }
        return modifiedReq
    }

    showError() {
        this.storageDetails.clearStorage();
        this.modalRef = this.modalService.open(SessionTimeoutComponent, { centered: true, size: 'sm', backdrop: 'static', keyboard: false });
        this.modalRef.componentInstance.name = 'Please re-login to continue...';
    }

}