import { Pipe, PipeTransform } from '@angular/core';
import { ProcessIOType } from "app/entities/bac-processiotype.enum";
@Pipe({
    name: 'ioTypeValue'
})
export class IOTypeValuePipe implements PipeTransform {
    //TODO test this pipe, only used in proc.component cause ProcessIOType is updated in aug 22
    public transform(dbvalue) {
        let viewVal:string;
        if(dbvalue === ProcessIOType.COBOL.toUpperCase() || dbvalue === ProcessIOType.FLAT.toUpperCase()){
            viewVal="COBOL";
        } else if(dbvalue === ProcessIOType.XML.toUpperCase()){
            viewVal="XML";
        } else if(dbvalue === ProcessIOType.XMLEVO.toUpperCase()){
            viewVal="XML-EVO";
        } else if(dbvalue === ProcessIOType.JSONEVO.toUpperCase()){
            viewVal="JSON-EVO";
        }
        return viewVal;
    }
}