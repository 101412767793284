import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppComponent } from 'app/app.component';
import { LoginComponent } from 'app/login/login.component';
import { appRoutes } from 'app/app.routes';
import { SharedModule } from './shared/shared.module';
import { MenuItems } from 'app/shared/menu-items/menu-items';
import { LayoutComponent } from './layout/layout.component';
import { SidebarComponent } from './layout/components/sidebar/sidebar.component';
import { HeaderComponent } from './layout/components/header/header.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { RequestInterceptorService } from './core/interceptor/request.interceptor';
import { SessionTimeoutComponent } from './shared/SessionComponent/session-timeout.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CanDeactivateGuard } from './core/guards/candeactivateguard';
import { RouteCheck } from './core/guards/route-check.service';
//import { LayoutModule } from './layout/layout.module';
//import { MatCardModule } from '@angular/material/card';
import { AlertsService } from 'app/shared/alert/alert-service';
import { ConfirmService } from 'app/shared/confirm/confirm.service';
import { ProcessManagementService } from './core/services/process-management.service';
import { ProcessManagementModule } from './screens/process-management/process-management.module';
import { InstallationManagementModule } from './screens/installation-management/installation-management.module';
import { AuthModule, LogLevel, StsConfigHttpLoader, StsConfigLoader, StsConfigStaticLoader } from 'angular-auth-oidc-client';
import { S1PublicService } from './core/services/s1public.service';
import { map } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import {BrowserModule} from "@angular/platform-browser";


/*HttpLoaderFactory used for navigation to correct language json template for internationalization feaure.*/
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export const httpLoaderFactoryOidc = (httpClient: HttpClient) => {

    const config$ = httpClient.get<any>(`private/server/params`).pipe(
      map((customConfig: any) => {
        return {
            secureRoutes: [atob(customConfig['s1.oauth2.iss'])],
            authority: atob(customConfig['s1.oauth2.iss']),
            redirectUrl:  window.location.origin,
            postLogoutRedirectUri: window.location.origin,
            clientId:  atob(customConfig['s1.oauth2.clientid']),
            scope:  atob(customConfig['s1.oauth2.scope']),
            responseType: 'code',
            silentRenew: true,
            maxIdTokenIatOffsetAllowedInSeconds: 600,
            issValidationOff: true,
            autoUserInfo: false,
            useRefreshToken: true,
            logLevel: LogLevel.Debug,
        };
      })
    );
    return new StsConfigHttpLoader(config$);
};
  

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        //SharedModule,
        NgbDropdownModule,
        //LayoutModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        //MatCardModule
        ProcessManagementModule,
        InstallationManagementModule,
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader,
                useFactory: httpLoaderFactoryOidc,
                deps: [HttpClient],
            },
        }),
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        LayoutComponent,
        SidebarComponent,
        HeaderComponent,
        SessionTimeoutComponent,
        
    ],
    providers: [
        MenuItems,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptorService,
            multi: true
        },
        CanDeactivateGuard,
        RouteCheck,
        DatePipe,
        AlertsService, 
        ConfirmService,
        ProcessManagementService,
    ],
    bootstrap: [AppComponent],
    exports: [TranslateModule]
})
export class AppModule {

 }
