import { Component, Output, EventEmitter, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItems } from 'app/shared/menu-items/menu-items';
import { AuthGuardService } from 'app/core/guards/authorization-service.service';
import { TranslateService } from '@ngx-translate/core';
import { ProcessManagementService } from 'app/core/services/process-management.service';
import { OperationsComponent } from 'app/screens/process-management/operations/operations.component';
import { RouteCheck } from 'app/core/guards/route-check.service';
import { TreeObject } from 'app/entities/treeNode.model';
import { S1ProcessInfo } from 'app/entities/s1-processInfo';
import { Data } from 'app/entities/data.model';
import { S1ProcessID } from 'app/entities/s1-processid';
import { StorageDetails } from 'app/core/services/user-details.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
    isActive: boolean;
    collapsed: boolean;
    public static showMenu: string;
    showSubMenu: string;
    showSubOfMenu: string;
    selectedName: string;
    public static openProcessDetailsArray: any

    @Output() collapsedEvent = new EventEmitter<boolean>();

    files1: TreeObject[];

    installationMContainerIsCollapsed: boolean = false;
    processMContainerIsCollapsed: boolean = false;

    constructor(public router: Router, public menuItems: MenuItems, public authorizationService: AuthGuardService
        , public translate: TranslateService, public nodeService: ProcessManagementService,public userDetails:StorageDetails) { }

    ngOnInit() {
        this.isActive = false;
        this.collapsed = false;
        SidebarComponent.showMenu = '';
        this.showSubMenu = '';
        this.showSubOfMenu = ''
    }
    getCurrentProcessArray(){
        return ProcessManagementService.openProcessDetailsArray
    }
    getCurrentProcessName(){
       return ProcessManagementService.currentProcessName
    }
    public addExpandClass(element: string) {
        if (element === SidebarComponent.showMenu) {
            SidebarComponent.showMenu = '0';
        } else {
            SidebarComponent.showMenu = element;
        }
    }

    getVariable() {
        return SidebarComponent.showMenu;
    }

    addScrollEnable(url: string) {
        OperationsComponent.isCheckProc = false;
        if (url.indexOf("/process-management/") === 0) {
            OperationsComponent.scrollenable = true;
        } else {
            OperationsComponent.scrollenable = false;
            OperationsComponent.isCheckProc = false;
        }
    }

    addExpandSubClass(element: string, url: string) {
        if (element === this.showSubMenu) {
            this.showSubMenu = '0';
        } else {
            this.showSubMenu = element;
        }
    }

    getLogoAsPerType(object) {
        let s1procdata: Data = null;
        if (ProcessManagementService.openProcessDetailsArray && object) {
            s1procdata = ProcessManagementService.openProcessDetailsArray
                .find(procDetails => object.name === procDetails.s1ProcessInfo.nameDisplay);
        }
        if (s1procdata && s1procdata.s1ProcessInfo) {
            return this.getImageAgainstProcessStatus(s1procdata.s1ProcessInfo);
        } else {
            return "assets/img/icon-coloured-checkinNotDeployed.svg";//default proc icon
        }
    }
    //below method copied from nodeService to avoid slowness
    private getImageAgainstProcessStatus(s1processinfo: S1ProcessInfo): string {
        //default proc icon => s1processinfo.lock === "UNLOCKED" && s1processinfo.revisionDeployed === false
        let imgSrc: string = "assets/img/icon-coloured-checkinNotDeployed.svg";
        if (s1processinfo)
            if (s1processinfo.lock === "LOCKEDBYME") {
                imgSrc = "assets/img/icon-coloured-checkoutByUser.svg";
            } else if (s1processinfo.lock === "LOCKEDBYOTHER") {
                imgSrc = "assets/img/icon-coloured-checkoutByOtherUser.svg";
            } else if (s1processinfo.lock === "UNLOCKED" && s1processinfo.revisionDeployed === true) {
                imgSrc = "assets/img/icon-coloured-checkinAndDeployed.svg";
            }
        //imgSrc = "assets/img/icon-coloured-process.svg" - this is red proc icon same as checkinNotDeployed.
        return imgSrc;
    }

    @HostListener('click', ['$event'])
    unloadNotification($event: any) {
        if (null != OperationsComponent.finalElement && OperationsComponent.finalElement !== "##############") {
            this.elementSelection(OperationsComponent.finalElement);
        }
        OperationsComponent.finalElement = null;
    }

    addExpandOfSubClass(element: string, url: string) {
        OperationsComponent.isCheckProc = element !== this.nodeService.sidebarProcName ? true : false;
        if (url.indexOf("/process-management/") === 0 && OperationsComponent.scrollenable && OperationsComponent.isCheckProc && OperationsComponent.url) {
            OperationsComponent.selectedElement = element;
            if (url === "/process-management/process") {
                new RouteCheck().canDeactivate(null);
                OperationsComponent.isCheckProc = false;
            }
            return;
        }
        this.elementSelection(element);
    }

    elementSelection(element: string) {
        console.log("sidebar element selection -" + element);
        console.log("procName -" + this.nodeService.sidebarProcName);

        if (this.nodeService.sidebarProcName !== element
            || this.nodeService.sidebarProcName !== this.nodeService.currentProcessName) {
            this.nodeService.addSubChildMenu(element);
            this.nodeService.showAppLoader = true;
            //fetch correct data from openProcess arr
            ProcessManagementService.openProcessDetailsArray.forEach(procData => {
                if (procData.s1ProcessInfo.nameDisplay === element) {
                    this.nodeService.setSelectedProcessData(procData);
                }
            });
            var processID: S1ProcessID = this.nodeService.getProcessID();
            if (this.nodeService.currentProcessDetails.s1ProcessInfo.historic) {
                this.nodeService.isHistoryProcess = true;
            } else {
                this.nodeService.isHistoryProcess = false;
            }
            this.nodeService.getSelectedProcessInfo(processID)
                .then((value) => {
                    this.nodeService.setSelectedProcessData(this.nodeService.currentProcessDetails);
                    this.router.navigate(['/process-management/process']);
                    this.nodeService.showAppLoader = false;
                }).catch((reason) => {
                    console.log("sidebar proc navigation failed.");
                    console.log(reason);
                    this.nodeService.showAppLoader = false;
                });
        }

        this.nodeService.sidebarProcName = element;
        this.selectedName = element;
        if (element === this.showSubOfMenu) {
            this.showSubOfMenu = '0';
        } else {
            this.showSubOfMenu = element;
        }
    }


    onLoggedout() {
        localStorage.removeItem('isLoggedin');
    }

    //refactor
    navigateToProcess(process){
        ProcessManagementService.openProcessDetailsArray.forEach(procData => {
             if (procData.s1ProcessInfo.nameDisplay === process.s1ProcessInfo.nameDisplay) {
                 this.nodeService.setSelectedProcessData(procData);
                 this.nodeService.openProcessNode = this.nodeService.currentProcessDetails.s1ProcessInfo;
                 localStorage.getItem('whatIfIsEnabled') == 'true'? this.nodeService.getSelectedProcessInfo(this.nodeService.openProcessNode.id): null;
                 this.router.navigate(['process-management/process'])
             }
         });
     }

    checkIfThisRouteIsActive(menuItemUrl: string) : boolean{
        this.userDetails.getUserRole()
        return this.router.url.includes(menuItemUrl);
    }

}
