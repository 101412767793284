import { Component, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import { FileUploader } from 'ng2-file-upload';
import { v4 as uuid } from 'uuid';
import { StorageDetails } from 'app/core/services/user-details.service';
import { ServerManagementService } from 'app/core/services/server-management.service';
import { ProcessManagementService } from 'app/core/services/process-management.service';
import { S1WhatifScheduleInfo } from 'app/entities/s1-whatifScheduleInfoArray';
import { WhatIfExtractMode } from 'app/entities/s1-whatifExtractStatus.enum';
import { S1UserInfo } from 'app/entities/s1-userInfo';
import { S1ScheduleStatus } from 'app/entities/s1-scheduleStatus.enum';
import { S1WhatifScheduleID } from 'app/entities/s1-scheduledId';
import { S1DataTransferInfo } from 'app/entities/s1-datatransferinfo';
import { WhatIfTableClass } from 'app/entities/bac-whatIfModule';
import { S1DownloadRequestInfo } from 'app/entities/s1-whatifDownloadInfo';
import { SpringErrorPayload } from 'app/entities/SpringErrorPayload';
import { S1DataTransferFolder } from 'app/entities/S1DataTransferFolder.enum';
import { S1ProcessID } from 'app/entities/s1-processid';

import { NgbCalendar, NgbDateParserFormatter, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmService } from 'app/shared/confirm/confirm.service';
import { AlertsService } from 'app/shared/alert/alert-service';
const MODAL_OPTIONS: NgbModalOptions = { size: 'lg', backdrop: 'static', keyboard: true, windowClass: 'modal-full-height modal-full-width' };

@Component({
  selector: 'app-whatif',
  templateUrl: './whatif.component.html',
  styleUrls: ['./whatif.component.scss']
})

export class WhatifComponent implements OnInit {

  searchText: string;
  whatIfData: S1WhatifScheduleInfo[] = [];
  whatIfParentTblData: Array<WhatIfTableClass> = [];
  subInfoOfWhatIfData: S1WhatifScheduleInfo = {
    numberOfProcessedRecords: 0,
    numberOfRegularRecords: 0,
    numberOfIrregularRecords: 0,
    executionTimeInSeconds: 0,
    id: new S1WhatifScheduleID,
    processId: new S1ProcessID,
    processVersion: 0,
    processName: '',
    userInfo: undefined,
    submitDate: undefined,
    executionDate: undefined,
    notes: '',
    progress: 0,
    recordsNumber: 0,
    status: S1ScheduleStatus.STATUS_PENDING,
    inputFileName: '',
    numberOfProcessedRecordsRef: 0,
    numberOfRegularRecordsRef: 0,
    numberOfIrregularRecordsRef: 0,
    executionTimeInSecondsRef: 0,
    saveInputs: false,
    reporting: false,
    enableOutputReference: false,
    enableInputWriter: false,
    enableOutputWriter: false,
    enableSimulationData: false,
    inputSampling: 0,
    extractDownloadMode: false,
    numThread: 0
  };

  whatIfName: number;
  currentStatus: string = '';
  whatIfForm: UntypedFormGroup;
  modalRef: NgbModalRef;
  currentObj: S1WhatifScheduleInfo;
  requestStatusOptions: any;
  processVFlag: boolean = false;
  requestCFlag: boolean = false;
  requestSFlag: boolean = false;
  execTFlag: boolean = false;
  loadWhatIf: boolean = false;
  months: any = { "JAN": 1, "FEB": 2, "MAR": 3, "APR": 4, "MAY": 5, "JUN": 6, "JUL": 7, "AUG": 8, "SEP": 9, "OCT": 10, "NOV": 11, "DEC": 12 };
  fileExtType: any;
  processVersion: number;
  public uploader: FileUploader = new FileUploader({url:'', maxFileSize: 100000000000 });

  //File upload
  bacFileUpload: S1DataTransferInfo[] = [];
  progress: number = 0;
  uuidFileName: any;
  processName: string;
  isActiveWhatifSuccessFlag: boolean = true;
  isActiveWhatifUnsuccessFlag: boolean = true;
  isActiveWhatifBothFlag: boolean = true;
  isContinueToLoad: boolean = false;
  btnName: string;
  subscriptions: Subscription[] = [];
  isSaveFlag: boolean = true;
  fileErrorMessage: string;
  isDownloadFile: boolean = true;
  whatIfRecordCount: number;
  fileType: string;
  isDateValidation: boolean = false;
  showAppLoader: boolean = false;
  isErrorFlag: boolean = false;
  scheduleId: S1WhatifScheduleID;
  whatIfLoadBkpForm: UntypedFormGroup;
  text_confirmDeleteMessage: string;
  text_deletePassMessage: string;
  text_selectWhatifFileNotPresentMessage: string;
  text_badRequestMessage: string;
  text_abortMessage: string;
  text_okMessage: string;
  text_cancelMessage: string;
  text_uploadPassMessage: string;
  text_invalidFileMessage: string;
  text_noResultFoundMessage: string;
  text_startedWhatif: string;
  text_noFileFound: string;
  isPermissionError: boolean = true;
  errorMessage: string = "";
  acceptType: string;
  extractBeforeCount: number;
  isBenchmarkFlag = true;
  whatIfLoadForm: UntypedFormGroup;
  formData: FormData;
  fb: UntypedFormBuilder;
  lastCallPerRequestFlag:boolean = false;
  extractOutputFlag: boolean= false

  whatIfIsEnabled: boolean = true;

  constructor(private userDetails: StorageDetails, private translate: TranslateService,
    private serverService: ServerManagementService, public nodeService: ProcessManagementService, private calendar: NgbCalendar,
    private modalService: NgbModal, private datePipe: DatePipe, private router: Router, private parserFormatter: NgbDateParserFormatter, 
    private confirmService: ConfirmService, private alert: AlertsService) {
    this.requestStatusOptions = [{ value: WhatIfExtractMode.DONE, viewValue: "Only Succeeded" }, { value: WhatIfExtractMode.ERRORS, viewValue: "Only With Errors" }, { value: WhatIfExtractMode.BOTH, viewValue: "All" }];
    this.fb = new UntypedFormBuilder();
    this.processName = nodeService.currentProcessName;
    this.initForm();
    this.translate.get(['Are you sure to delete Whatif?', 'What-if deleted successfully', "Selected What-if doesn't have file for download", 'Bad Request',
      'Are you sure you wish to abort this whatif?', 'Ok', 'Cancel', 'File uploaded successfully.', 'Invalid file: xml or json format allowed only',
      'No result found', "Whatif started successfully", "No file found."]).subscribe((text: any) => {
        this.text_confirmDeleteMessage = text['Are you sure to delete Whatif?'];
        this.text_deletePassMessage = text['What-if deleted successfully'];
        this.text_selectWhatifFileNotPresentMessage = text["Selected What-if doesn't have file for download"];
        this.text_badRequestMessage = text['Bad Request'];
        this.text_abortMessage = text['Are you sure you wish to abort this whatif?'];
        this.text_okMessage = text['Ok'];
        this.text_cancelMessage = text['Cancel'];
        this.text_uploadPassMessage = text['File uploaded successfully.'];
        this.text_invalidFileMessage = text['Invalid file: xml or json format allowed only'];
        this.text_noResultFoundMessage = text['No result found'];
        this.text_startedWhatif = text["Whatif started successfully"];
        this.text_noFileFound = text["No file found."];
      });
  }

  ngOnInit() {
    this.whatIfIsEnabled = localStorage.getItem('whatIfIsEnabled') == 'true'? true: false;
    if (!this.nodeService.currentProcessDetails) {
      this.router.navigate(['/process-management']);
    }
    if (this.userDetails.getUserRole() !== "USER"
      || (this.userDetails.getUserRole() === "USER"
        && this.nodeService.currentProcessDetails
        && this.nodeService.currentProcessDetails.permission
        && this.nodeService.currentProcessDetails.permission
          .filter(val => (val.permission === "WHATIF" && val.level === "LEVEL_EXEC")).length > 0
      )
    ) {
      this.isPermissionError = false;
      this.refreshWhatIf();
      this.processVersion = this.nodeService.currentProcessVersion;
    } else {
      this.isPermissionError = true;
      this.errorMessage = this.nodeService.errMsgPermissionDeniedDetails;
    }
  }

  initSubTable() {
    this.subInfoOfWhatIfData.numberOfProcessedRecords=0;
    this.subInfoOfWhatIfData.numberOfRegularRecords=0;
    this.subInfoOfWhatIfData.numberOfIrregularRecords=0;
    this.subInfoOfWhatIfData.executionTimeInSeconds=0;
    this.isActiveWhatifUnsuccessFlag = true;
    this.isActiveWhatifBothFlag = true;
    this.isActiveWhatifSuccessFlag = true;
    this.isBenchmarkFlag = true;
  }

  initForm() {
    this.whatIfForm = this.fb.group({
      "fileName": ['', Validators.compose([Validators.required, Validators.pattern('^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$')])],
      "contentType": ['', Validators.compose([Validators.required])]
    });

    let currentFromDt = this.calendar.getToday();
    let days = 1;
    var currentToDt = this.calendar.getToday();

    this.whatIfLoadForm = this.fb.group({
      "notes": '',
      "fromDate": currentFromDt,
      "toDate": currentToDt,
      "processVersion": [{value: '', disabled: !this.processVFlag }, Validators.compose([Validators.pattern('^[0-9]*$')])],
      "requestID": [{value: '', disabled: !this.requestCFlag }],
      "lastCallPerRequest": '',
      "extractMode": [{value: WhatIfExtractMode.DONE, disabled: !this.requestSFlag}],
      "fromExecTime": [{value: '', disabled: !this.execTFlag }, Validators.compose([Validators.pattern('^[0-9]*$')])],
      "toExecTime": [{value: '', disabled: !this.execTFlag }, Validators.compose([Validators.pattern('^[0-9]*$')])],
      "sampleInterval": ['100', Validators.compose([Validators.pattern('^[0-9]*$')])],
      "maxRequest": ['', Validators.compose([Validators.pattern('^[0-9]*$')])],
      "enableDyanamicSimulation": '',
      "extractOutput": this.extractOutputFlag,
      "whatifFileName": '',
      "processInfo": '',
      "outRefFileName": '', // append data with whatifFileName + '-OUT-REF'
      "numberOfRecords": '',
      "numberOfRecordsFailed": '',
      "layoutVersion": '',
      "source": '',
      "elabTime": '',
      "requestStatusFlag": false
    });
  }

  showVariablesDetails(valueObj) {
    this.whatIfName = valueObj.id;
    this.currentStatus = valueObj.status;
    this.subInfoOfWhatIfData = valueObj;

    this._showVariablesDetails_setSubInfoWhatifData(valueObj);

    if (this.subInfoOfWhatIfData.numberOfRegularRecords > 0 && this.subInfoOfWhatIfData.numberOfIrregularRecords > 0) {
      this.isActiveWhatifBothFlag = false;
      this.isActiveWhatifSuccessFlag = false;
      this.isActiveWhatifUnsuccessFlag = false;
      this.isBenchmarkFlag = true;
    } else if (this.subInfoOfWhatIfData.numberOfProcessedRecords > 0 && this.subInfoOfWhatIfData.numberOfProcessedRecords === this.subInfoOfWhatIfData.numberOfIrregularRecords) {
      this.isActiveWhatifUnsuccessFlag = false;
      this.isActiveWhatifBothFlag = true;
      this.isActiveWhatifSuccessFlag = true;
      this.isBenchmarkFlag = true;
    } else if (this.subInfoOfWhatIfData.numberOfProcessedRecords > 0 && this.subInfoOfWhatIfData.numberOfProcessedRecords === this.subInfoOfWhatIfData.numberOfRegularRecords) {
      this.isActiveWhatifSuccessFlag = false;
      this.isActiveWhatifUnsuccessFlag = true;
      this.isActiveWhatifBothFlag = true;
      this.isBenchmarkFlag = true;
    } else if (valueObj.benchmarkFlag) {
      this.isActiveWhatifSuccessFlag = true;
      this.isActiveWhatifUnsuccessFlag = true;
      this.isActiveWhatifBothFlag = true;
      this.isBenchmarkFlag = false;
    } else {
      this.isActiveWhatifSuccessFlag = true;
      this.isActiveWhatifUnsuccessFlag = true;
      this.isActiveWhatifBothFlag = true;
      this.isBenchmarkFlag = true;
    }
  }
  private _showVariablesDetails_setSubInfoWhatifData(valueObj) {
    this.whatIfData.forEach(element => {
      if (element.id.scheduleID === valueObj.id) {
        this.subInfoOfWhatIfData.numberOfIrregularRecords = element.numberOfIrregularRecords ? element.numberOfIrregularRecords : 0;
        this.subInfoOfWhatIfData.numberOfProcessedRecords = element.numberOfProcessedRecords ? element.numberOfProcessedRecords : 0;
        this.subInfoOfWhatIfData.numberOfRegularRecords = element.numberOfRegularRecords ? element.numberOfRegularRecords : 0;
        this.subInfoOfWhatIfData.executionTimeInSeconds = element.executionTimeInSeconds ? element.executionTimeInSeconds : 0;
      }
    });
  }
  startWhatIf(whatIfName: number) {
    let scheduleId = { "scheduleID": whatIfName };
    this.serverService.serverStartWhatifSchedule(scheduleId).subscribe({
      next: _response => {
        this.nodeService.showSuccessResposeMessage(this.text_startedWhatif, null);
        this.refreshWhatIf();
      }, error: err => {
        console.log(err);
        this.nodeService.showErrorResposeMessageWithoutRedirect('What-if failed to start', null);
      }
    });
  }

  stopWhatIf(whatIfName: number) {
    let scheduleId = { "scheduleID": whatIfName };
    this.serverService.serverStopWhatifSchedule(scheduleId).subscribe({
      next: _response => {
        this.nodeService.showSuccessResposeMessage("What-if stopped successfully", null);
        this.refreshWhatIf();
      }, error: err => {
        console.log(err);
        this.nodeService.showErrorResposeMessageWithoutRedirect("What-if failed to stop", null);
      }
    });
  }

  refreshWhatIf() {
    let oldSearchText = this.searchText;
    this.searchText = '';

    if (undefined !== ProcessManagementService.openProcessDetailsArray && null !== ProcessManagementService.openProcessDetailsArray) {
      let processId = this.nodeService.getProcessID();
      if (processId) {

        this.serverService.serverGetWhatIfScheduleList(processId).subscribe({
          next: data => {
            //this.errorMessage = "Connector" + ": WhatIf" + " not reachable.";
            if (data !== undefined) {
              this.whatIfData = [];
              this.whatIfParentTblData = [];
              this.whatIfData = data;
              this.whatIfData.forEach(val => {
                let tempDate = new Date(val.submitDate);
                this.whatIfParentTblData.push({
                  id: val.id.scheduleID,
                  notes: val.notes,
                  status: val.status,
                  progress: val.progress,
                  updatedDt: this.datePipe.transform(tempDate, 'yyyy-MM-dd HH:mm:ss'),
                  user: val.userInfo.loginCredentials.userId,
                  inputFileName: val.inputFileName,
                  benchmarkFlag: val.enableOutputReference
                });

                if (val.id.scheduleID === this.whatIfName) {
                  this.subInfoOfWhatIfData = val;
                  this.showVariablesDetails(this.whatIfParentTblData[this.whatIfParentTblData.length - 1]);
                  this.currentObj = val;
                }
              });
            } else {
              console.log("Unexpected result");
              console.log(data);
            }
            this.searchText = oldSearchText;
          }, error: err => {            
            let exceptionMsg: string = err['error']['exception'];
            if (exceptionMsg.includes('Connection refused') || exceptionMsg.includes('UnknownHostException')) {
              this.errorMessage = "Connector" + ": WhatIf" + " not reachable.";
            } else {
              this.errorMessage = err['error']['reason'];
            }
            this.searchText = oldSearchText;
          }
        });
      }
    }
  }

  deleteWhatIf(whatIfName: number) {
    let scheduleId = { "scheduleID": whatIfName };
    this.confirmService.confirm(this.text_confirmDeleteMessage + scheduleId.scheduleID,'',this.text_okMessage,this.text_cancelMessage).subscribe(confirmed=>{
      if(confirmed){
        this.serverService.serverDeleteWhatifSchedule(scheduleId).subscribe({
          next: _response => {
            this.nodeService.showSuccessResposeMessage(this.text_deletePassMessage, null);
            this.refreshWhatIf();
            this.initSubTable();
            this.isActiveWhatifBothFlag = true;
            this.isActiveWhatifSuccessFlag = true;
            this.isActiveWhatifUnsuccessFlag = true;
            this.isBenchmarkFlag = true;
            this.whatIfName = null;
          }, error: err => {
            console.log(err);
            this.nodeService.showErrorResposeMessageWithoutRedirect('operation failed.', null);
          }
        });
      }
    })
  }

  saveInputFile(template: TemplateRef<any>, subInfoOfWhatIfData: S1WhatifScheduleInfo, type: string) {
    this.currentObj = subInfoOfWhatIfData;
    this.btnName = type;
    if (this.nodeService.ioType === "COBOL") {
      this.fileExtType = [{ value: "xml", viewValue: "XML" }, { value: "json", viewValue: "JSON" }];
    } else if (this.nodeService.ioType === "XMLEVO" || this.nodeService.ioType === "XML") {
      this.fileExtType = [{ value: "xml", viewValue: "XML" }];
      this.whatIfForm.controls['contentType'].setValue("xml");
    } else if (this.nodeService.ioType === "JSONEVO") {
      this.fileExtType = [{ value: "json", viewValue: "JSON" }];
      this.whatIfForm.controls['contentType'].setValue("json");
    } else if (this.nodeService.ioType === "FLAT") {
      this.fileExtType = [{ value: "in", viewValue: "IN" }];
      this.whatIfForm.controls['contentType'].setValue("in");
    }
    this.modalRef = this.modalService.open(template,MODAL_OPTIONS);
  }

  downloadTheWhatIf() {
    if (this.whatIfForm.invalid) {
      this.isDownloadFile = true;
      this.isErrorFlag = true;
      return;
    }
    this.isErrorFlag = false;
    this.isDownloadFile = false;
    let s1datatransferinfo: S1DataTransferInfo = new S1DataTransferInfo();
    s1datatransferinfo.filename = this._downloadTheWhatIf_filename();
    s1datatransferinfo.folder = S1DataTransferFolder.FTP;
    if (s1datatransferinfo.filename) {
      let fileType = '';
      if (this.whatIfForm.controls['contentType'].value === 'xml') fileType = 'xml';
      else if (this.whatIfForm.controls['contentType'].value.toLowerCase() === 'json') fileType = 'json';
      else fileType = 'in';
      this.showAppLoader = true;
      this.serverService.downloadTheFile(s1datatransferinfo, fileType).subscribe({
        next: response => {
          this.downloadWhatIf(response);
        }, error: err => {
          if (err.statusText === 'Not Found') {
            this.nodeService.showErrorResposeMessageWithoutRedirect(err.statusText, this.text_noFileFound);
          } else {
            this.nodeService.showErrorResposeMessageWithoutRedirect(this.text_badRequestMessage, this.text_selectWhatifFileNotPresentMessage);
          }
          this.showAppLoader = false;
          this.cancel();
        }
      });
    } else {
      this.cancel();
      this.nodeService.showErrorResposeMessageWithoutRedirect(this.text_badRequestMessage, this.text_selectWhatifFileNotPresentMessage);
    }

  }

  private downloadWhatIf(response: any) {
    let blobdata = new Blob([new Uint8Array(response)]);
    let filename4saveAs: string = this.whatIfForm.controls['fileName'].value + '.' + this.whatIfForm.controls['contentType'].value;
    saveAs(blobdata, filename4saveAs);
    this.cancel();
  }
  private _downloadTheWhatIf_filename(): string {
    let filename: string = '';
    if (this.btnName === 'SaveCompleteInputFile') {
      filename = this.currentObj.inputFileName + ".in";
    } else if (this.btnName === 'SaveInputSuccessReq') {
      filename = this.currentObj.inputFileName + ".in.ok";
    } else if (this.btnName === 'SaveInputUnsuccessReq') {
      filename = this.currentObj.inputFileName + ".in.ko";
    } else if (this.btnName === 'SaveOutputUnsuccessReqErrorLog') {
      filename = this.currentObj.inputFileName + ".err";
    } else if (this.btnName === 'SaveOutputSuccessReq') {
      filename = this.currentObj.inputFileName + ".out";
    } else if (this.btnName === 'SaveBenchmarkReq') {
      filename = this.currentObj.inputFileName + ".out.ref";
    } else {
      filename = this.currentObj.inputFileName;
    }
    return filename;
  }
  closeWithAlert() {
    this.confirmService.confirm(this.text_abortMessage,'',this.text_okMessage,this.text_cancelMessage).subscribe(confirmed=>{
      if(confirmed){
        this.cancelOutstandingRequests();
        this.initForm();
        this.modalRef.close();
        this.progress = 0;
        this.isContinueToLoad = false;
      }
    })
  }

  cancelOutstandingRequests() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  close() {
    this.modalRef.close();
    this.initForm();
    this.uploader = new FileUploader({url:'', maxFileSize: 51200 * 1024 * 100 });
  }

  cancel() {
    this.modalRef.close();
    this.showAppLoader = false;
    this.isErrorFlag = false;
    this.initForm();
  }

  loadTheWhatIf(templateLoad: TemplateRef<any>, val: boolean) {
    this.isSaveFlag = val ? false : true;
    this.loadWhatIf = val ? true : false;
    this.isDateValidation = false;
    if (this.nodeService.ioType === "XMLEVO") {
      this.acceptType = ".xml,.json";
    } else if (this.nodeService.ioType === "JSONEVO") {
      this.acceptType = ".json,.xml";
    } else if (this.nodeService.ioType === "FLAT") {
      this.acceptType = ".IN,.txt";
    }
    this.modalRef = this.modalService.open(templateLoad,MODAL_OPTIONS);
  }

  checkProcessVersion() {
    this.processVFlag = !this.processVFlag;
    this.processVFlag? this.whatIfLoadForm.controls['processVersion'].enable() : this.whatIfLoadForm.controls['processVersion'].disable();
  }

  checkRequestCode() {
    this.requestCFlag = !this.requestCFlag;
    this.requestCFlag? this.whatIfLoadForm.controls['requestID'].enable() : this.whatIfLoadForm.controls['requestID'].disable();

  }

  checkRequestStatus() {
    this.requestSFlag = !this.requestSFlag;
    this.requestSFlag? this.whatIfLoadForm.controls['extractMode'].enable() : this.whatIfLoadForm.controls['extractMode'].disable();
  }

  checkExecutionTime() {
    this.execTFlag = !this.execTFlag;
    this.execTFlag ? this.whatIfLoadForm.controls['fromExecTime'].enable() : this.whatIfLoadForm.controls['fromExecTime'].disable();
    this.execTFlag ? this.whatIfLoadForm.controls['toExecTime'].enable() : this.whatIfLoadForm.controls['toExecTime'].disable();
  }

  getFileForLoad() {

    if (!this.uploader || !this.uploader.queue || this.uploader.queue.length !== 1) {
      this._getFileForLoad_err();
      return;
    }
    var xmlfile: string;
    this.isSaveFlag = false;
    this.fileErrorMessage = null;
    let file: any = this.uploader.queue[0].file.rawFile;

    if ((this.nodeService.ioType === "XMLEVO" || this.nodeService.ioType === "XML") && this.uploader.queue[0].file.type === "text/xml") {

      this._getFileForLoad_fileReading(file);
      this.fileType = 'xml';

    } else if (this.nodeService.ioType === "JSONEVO" && (this.uploader.queue[0].file.type === "application/json")) {

      this._getFileForLoad_fileReading(file);
      this.fileType = 'json';

    } else if (this.nodeService.ioType === "FLAT" && this.uploader.queue[0].file.type === "") {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        xmlfile = fileReader.result.toString().replace(/\\n/g, '');
        this.whatIfRecordCount = (((xmlfile.match(/\n/g) || []).length) + 2) / 5;
      }
      fileReader.readAsText(file);
      this.fileType = 'xml';
    } else {
      this._getFileForLoad_err();
    }
  }

  private _getFileForLoad_err() {
    this.isSaveFlag = true;
    this.fileErrorMessage = "Invalid file format. Please upload the correct file according to process type";
    this.uploader = new FileUploader({ url:'', maxFileSize: 51200 * 1024 * 100 });
  }
  private _getFileForLoad_fileReading(file) {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      const uploadFileData = fileReader.result.toString().split('\n');
      let count = 0;
      uploadFileData.forEach(element => {
        if (element.trim().length > 0)
          count++;
      });
      this.whatIfRecordCount = count;
    }
    fileReader.readAsText(file);
  }

  fileChanged() {
    this.showAppLoader = true;
    if (this.uploader.queue.length > 0) {
      this.formData = new FormData();
      for (const item of this.uploader.queue) {
        this.formData.append("file", item._file, item.file.name);
        this.uploader.queue.splice(0, 1);
      }
      //console.log(this.formData);
      this.bacFileUpload = [];
      var fileUniqueId = uuid();
      this.bacFileUpload.push({
        filename: fileUniqueId,
        fileSize: 0,
        overwrite: false,
        folder: null,
        fileHash: undefined,
        processCode: ''
      });

      this.isContinueToLoad = true;

      this.bacFileUpload.forEach(element => {
        const subscription = this.serverService.uploadTheFile(this.formData, element, this.fileType).subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / event.total * 100);
              break;
            case HttpEventType.Response:
              let response = event.body;
              this.execute(response, fileUniqueId);
              setTimeout(() => {
                this.progress = 0;
              }, 1500);
          }
        });
        this.subscriptions.push(subscription);
      });
    }
  }

  private execute(response: any, fileUniqueId: any) {
    if (response === 'OK') {
      this.saveWhatIf(fileUniqueId);
      this.nodeService.showSuccessResposeMessage("Success", this.text_uploadPassMessage);
      this.close();
    }
    else {
      this.nodeService.showSuccessResposeMessage("Error", this.text_invalidFileMessage);
      this.close();
    }
  }

  closeForPopup() {
    if (this.isContinueToLoad) {
      this.closeWithAlert();
    } else {
      this.close();
    }
    this.fileErrorMessage = null;
  }

  saveWhatIf(uuidArg) {
    let fromDt = new Date(this.parserFormatter.format(this.whatIfLoadForm.controls['fromDate'].value)).toISOString();
    let toDt = this.datePipe.transform(new Date(this.parserFormatter.format(this.whatIfLoadForm.controls['toDate'].value)), "yyyy-MM-ddT23:59:59.999");
    if (this.nodeService.currentProcessDetails) {
      this.whatIfLoadForm.controls['processInfo'].setValue(this.nodeService.currentProcessDetails.s1ProcessInfo);
    }

    let { whatIfScheduleInfo } = this.setWhatIfData(fromDt, toDt);

    whatIfScheduleInfo.inputFileName = undefined !== uuidArg ? uuidArg : whatIfScheduleInfo.inputFileName;
    whatIfScheduleInfo.recordsNumber = this.whatIfRecordCount;

    if (this.loadWhatIf === true) {
      //this.whatIfLoadBkpForm.controls.toDate.setValue(this.parserFormatter.format(this.whatIfLoadBkpForm.controls.toDate.value)) 
      //this.whatIfLoadBkpForm.controls.fromDate.setValue(this.parserFormatter.format(this.whatIfLoadBkpForm.controls.fromDate.value)) 
      this.serverService.getServerCount(this.whatIfLoadBkpForm).subscribe(res => {
        let response: S1DownloadRequestInfo = res;
        if (response.numberOfRecords !== 0) {
          this.extractBeforeCount = response.numberOfRecords;
          whatIfScheduleInfo.extractDownloadMode = true;
          whatIfScheduleInfo.recordsNumber = this.extractBeforeCount;
          this.createWhatIf(whatIfScheduleInfo);
          this.isContinueToLoad = false;
          this.whatIfLoadForm.controls['extractOutput'].value == true? this.isBenchmarkFlag = false: this.isBenchmarkFlag = true;
          this.close();
        } else if (response.numberOfRecords === 0) {
          this.nodeService.showErrorResposeMessageWithoutRedirect("ERROR", this.text_noResultFoundMessage);
          this.close();
        } else {
          this.nodeService.showErrorResposeMessageWithoutRedirect("error", null);
          this.close();
        }
      });
    } else {
      this.createWhatIf(whatIfScheduleInfo);
      this.isContinueToLoad = false;
      this.showAppLoader = false;
      this.close();
    }
  }

  private createWhatIf(whatIfScheduleInfo:
    {
      notes: any;
      processId: { "processID": number; "versionID": number; };
      processName: string;
      executionDate: Date;
      submitDate: Date;
      inputFileName: string;
      executionTimeInSeconds: any;
      numberOfIrregularRecords: any;
      numberOfProcessedRecords: any;
      numberOfRegularRecords: any;
      executionTimeInSecondsRef: any;
      numberOfIrregularRecordsRef: any;
      numberOfProcessedRecordsRef: any;
      numberOfRegularRecordsRef: any;
      processVersion: any;
      progress: number;
      recordsNumber: any;
      reporting: any;
      saveInputs: boolean;
      status: S1ScheduleStatus;
      userInfo: S1UserInfo;
      id: S1WhatifScheduleID;
      enableInputWriter: boolean;
      enableOutputReference: boolean;
      enableOutputWriter: boolean;
      enableSimulationData: boolean;
      extractDownloadMode: boolean;
      inputSampling: number;
      numThread: number;
    }) {
    
    whatIfScheduleInfo.userInfo = null
    whatIfScheduleInfo.submitDate = null
    this.serverService.serverCreateWhatifSchedule(whatIfScheduleInfo).subscribe(data => {
      if(data){
        this.scheduleId = data.id;
        if (this.loadWhatIf === true) {
          this.whatIfLoadBkpForm.controls['numberOfRecords'].setValue(this.extractBeforeCount);
          this.serverService.serverExtractDownload(this.whatIfLoadBkpForm, this.scheduleId).subscribe({
            next: _response => {
              // do nothing for now. Check later.
            },
            error: err => {
              let sep: SpringErrorPayload = err['error'];
              this.nodeService.showErrorResposeMessageWithoutRedirect(sep.error, sep.reason);
  
              this.refreshWhatIf();
            }
          });
        }
        this.refreshWhatIf();
      }else{
        this.alert.error('Generic Error')
      }
    });
  }

  private setWhatIfData(fromDt, toDt) {
    let uuidFileName: string = this.getUuid();

    this.whatIfLoadBkpForm = this.assignTheValueToTheForm(fromDt, toDt, uuidFileName);

    const processId = this.nodeService.getProcessID(); //{ "processID": ProcessManagementService.openProcessDetailsArray['id']['processID'], "versionID": ProcessManagementService.openProcessDetailsArray['id']['versionID'] };
    let submitDt = this.assignSubmitDt();

    let userInfo: S1UserInfo = JSON.parse(this.userDetails.getUserInfo());
    let pwdPolicy = [];

    if (userInfo.loginCredentials.passwordPolicies.changeAtLogin === true) {
      pwdPolicy.push("Change Password to Next Login");
    }
    if (userInfo.loginCredentials.passwordPolicies.neverExpire === true) {
      pwdPolicy.push("Password Never Expire");
    }
    if (userInfo.loginCredentials.passwordPolicies.enforcePolicies === false) {
      pwdPolicy.push("Exclude from password security policies");
    }

    let user = this.assignUserInfo(userInfo, pwdPolicy);
    let whatIfScheduleInfo = this.setWhatIfForm(processId, submitDt, user, uuidFileName);

    return { whatIfScheduleInfo };
  }

  private getUuid() {
    return uuid();
  }

  private setWhatIfForm(processId: { "processID": number; "versionID": number; }, submitDt: UntypedFormGroup, user: UntypedFormGroup, uuidFileName: string) {
    return {
      notes: this.whatIfLoadForm.controls['notes'].value,
      processId: processId,
      processName: this.processName.split("")[0],
      executionDate: null,
      submitDate: new Date(submitDt.value.nYear, submitDt.value.nMonth, submitDt.value.nDay,
        submitDt.value.nHour, submitDt.value.nMinute, submitDt.value.nSecond),
      inputFileName: uuidFileName,
      executionTimeInSeconds: null,
      numberOfIrregularRecords: null,
      numberOfProcessedRecords: null,
      numberOfRegularRecords: null,
      executionTimeInSecondsRef: null,
      numberOfIrregularRecordsRef: null,
      numberOfProcessedRecordsRef: null,
      numberOfRegularRecordsRef: null,
      processVersion: this.whatIfLoadForm.controls['processVersion'].value,
      progress: 0,
      recordsNumber: null !== this.whatIfLoadForm.controls['numberOfRecords'].value ? this.whatIfLoadForm.controls['numberOfRecords'].value : 0,
      reporting: null,
      saveInputs: true,
      status: S1ScheduleStatus.STATUS_PENDING,
      userInfo: new S1UserInfo(user),
      id: new S1WhatifScheduleID,
      enableInputWriter: true,
      enableOutputReference: this.whatIfLoadForm.controls['extractOutput'].value,
      enableOutputWriter: true,
      enableSimulationData: false,
      extractDownloadMode: false,
      inputSampling: 0,
      numThread: 1
    };
  }

  private assignSubmitDt() {
    let currentDt = new Date();
    let submit = currentDt + "";
    let subDt = submit.split(" ");
    let time = subDt[4].split(":");
    return this.fb.group({
      "nDay": parseInt(subDt[2]),
      "nMonth": parseInt(this.months[subDt[1].toUpperCase()]),
      "nYear": parseInt(subDt[3]),
      "nHour": parseInt(time[0]),
      "nMinute": parseInt(time[1]),
      "nSecond": parseInt(time[2]),
      "nMillisecond": 0
    });
  }

  private assignUserInfo(userInfo: S1UserInfo, pwdPolicy: any[]) {
    return this.fb.group({
      "id": { "userId": userInfo.id.userId },
      "role": userInfo.role,
      "name": userInfo.name,
      "surName": userInfo.surname,
      "language": userInfo.language,
      "company": userInfo.company,
      "organization": userInfo.organization,
      "expiredOn": userInfo.expiredOn,
      "authMode": userInfo.loginCredentials.authMode,
      "newPassword": userInfo.loginCredentials.newPassword,
      "password": userInfo.loginCredentials.password,
      "userId": userInfo.loginCredentials.userId,
      "passwordPolicies": [pwdPolicy],
      "notes": userInfo.notes,
      "logged": userInfo.logged,
      "loggedAt": userInfo.loggedAt
    });
  }

  private assignTheValueToTheForm(fromDt, toDt, fileName: any) {
    if (this.whatIfLoadForm.controls['processVersion'].value === "") {
      this.whatIfLoadForm.controls['processVersion'].setValue(null);
    }
    return this.fb.group({
      "notes": this.whatIfLoadForm.controls['notes'].value,
      "fromDate": fromDt,
      "toDate": toDt,
      "processVersion": this.whatIfLoadForm.controls['processVersion'].value,
      "requestID": this.whatIfLoadForm.controls['requestID'].value,
      "lastCallPerRequest": this.whatIfLoadForm.controls['lastCallPerRequest'].value,
      "extractMode": this.whatIfLoadForm.controls['extractMode'].value,
      "fromExecTime": this.whatIfLoadForm.controls['fromExecTime'].value,
      "toExecTime": this.whatIfLoadForm.controls['toExecTime'].value,
      "sampleInterval": this.whatIfLoadForm.controls['sampleInterval'].value,
      "maxRequest": this.whatIfLoadForm.controls['maxRequest'].value,
      "enableDyanamicSimulation": this.whatIfLoadForm.controls['enableDyanamicSimulation'].value,
      "extractOutput": this.whatIfLoadForm.controls['extractOutput'].value,
      "whatifFileName": fileName,
      "processInfo": this.whatIfLoadForm.controls['processInfo'].value,
      "outRefFileName": fileName + '.out.ref',
      "numberOfRecords": this.whatIfLoadForm.controls['numberOfRecords'].value,
      "numberOfRecordsFailed": this.whatIfLoadForm.controls['numberOfRecordsFailed'].value,
      "layoutVersion": null,
      "source": this.whatIfLoadForm.controls['source'].value,
      "elabTime": this.whatIfLoadForm.controls['elabTime'].value,
      "requestStatusFlag": this.whatIfLoadForm.controls['requestStatusFlag'].value,
    });
  }

  private setFromAndToDate() {
    let prevDate = this.whatIfLoadForm.controls['fromDate'].value;
    let from = prevDate + "";
    let data = from.split(" ");
    let currDate = this.whatIfLoadForm.controls['toDate'].value;
    let to = currDate + "";
    let data1 = to.split(" ");
    let fromDt = this.fb.group({
      "nDay": parseInt(data[2]),
      "nMonth": parseInt(this.months[data[1].toUpperCase()]),
      "nYear": parseInt(data[3]),
      "nHour": 0,
      "nMinute": 0,
      "nSecond": 0,
      "nMillisecond": 0
    });
    let toDt = this.fb.group({
      "nDay": parseInt(data1[2]),
      "nMonth": parseInt(this.months[data1[1].toUpperCase()]),
      "nYear": parseInt(data1[3]),
      "nHour": 23,
      "nMinute": 59,
      "nSecond": 59,
      "nMillisecond": 999
    });

    return { fromDt, toDt };
  }

  dateChanged(ev: Event) {
    if (new Date(this.parserFormatter.format(this.whatIfLoadForm.controls['fromDate'].value)).getTime() > new Date(this.parserFormatter.format(this.whatIfLoadForm.controls['toDate'].value)).getTime()) {
      this.isDateValidation = true;
      this.isSaveFlag = true;
    } else {
      this.isDateValidation = false;
      this.isSaveFlag = false;
    }
  }

  lastRequest() {
    this.lastCallPerRequestFlag = !this.lastCallPerRequestFlag;
    this.whatIfLoadForm.controls['lastCallPerRequest'].setValue(this.lastCallPerRequestFlag);
  }

  changeExtractOutput() {
    this.extractOutputFlag = !this.extractOutputFlag;
    this.whatIfLoadForm.controls['extractOutput'].setValue(this.extractOutputFlag);
  }
}