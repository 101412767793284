import { Injectable } from '@angular/core';
import { S1ClientInfo } from 'app/entities/s1-clientinfo';


@Injectable({
  providedIn: 'root'
})
export class StorageDetails {

  //ROLE
  getUserRole() {
    return localStorage.getItem('role');
  }
  setUserRole(role) {
    return localStorage.setItem('role', role);
  }

  //sessionUID
  getUserToken() {
    return localStorage.getItem('sessionToken');
  }
  setUserToken(token) {
    return localStorage.setItem('sessionToken', token);
  }

  //userId numeric value
  getUserId(): number {
    return +localStorage.getItem('userId'); //convert string->number
  }
  setUserId(numberUserId: number) {
    return localStorage.setItem('userId', numberUserId.toString());
  }
  //userId string value
  getLoginUserId(): string {
    return localStorage.getItem('loginUserId');
  }
  setLoginUserId(stringUserId: string) {
    return localStorage.setItem('loginUserId', stringUserId);
  }

  setLanguage(language: string) {
    return localStorage.setItem("language", language);
  }

  getLanguage(): string {
    return localStorage.getItem("language");
  }

  getInputData(): string {
    return localStorage.getItem("inputdata");
  }

  setInputData(inputdata: string) {
    return localStorage.setItem("inputdata", inputdata);
  }

  //User info
  getUserInfo(): string {
    return localStorage.getItem("userInfo");
  }

  setUserInfo(userInfo: string) {
    return localStorage.setItem("userInfo", userInfo);
  }

  setSyncRoles(syncRoles: string){
    return localStorage.setItem("syncRoles", syncRoles);
  }

  setPageNumber(pageNumber: number) {
    return localStorage.setItem("pageNumber", pageNumber + "");
  }

  getPageNumber() {
    return localStorage.getItem("pageNumber");
  }

  setDataLength(rowsOnPage: number) {
    return localStorage.setItem("dataLength", rowsOnPage + "");
  }

  getDataLength() {
    return localStorage.getItem("dataLength");
  }

  clearStorage() {
    localStorage.clear();
  }

  setClientInfo(clientInfo: S1ClientInfo) {
    return localStorage.setItem("clientInfo", JSON.stringify(clientInfo));
  }
  getClientInfo() {
    return localStorage.getItem("clientInfo");
  }
  getLoginName(): string {
    return localStorage.getItem('name');
  }
  setName(name: string) {
    return localStorage.setItem("name", name);
  }
  getLoginSurname(): string {
    return localStorage.getItem('surname');
  }
  setSurname(surname: string) {
    return localStorage.setItem("surname", surname);
  }
}
