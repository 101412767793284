<div class="col-sm-12" *ngIf="isPermissionError">
    <div class="alert cds-alert alert-danger" role="alert">
        <div class="cds-alert-icon">
          <i class="fa-solid fa-triangle-exclamation"></i>
        </div>
        <div class="cds-alert-content">{{"You do not have permission to access this page." | translate}}</div>
    </div>
</div>

<appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>

<ng-container *ngIf="!nodeService.showAppLoader && !isPermissionError">
    <h6 class=" mb-1">{{'Available pmml models for process :' | translate}} 
        <h6 class="badge cds-badge active text-bg-info">{{nodeService.currentProcessName}}</h6>
    </h6>
    
    <div class="card cds-card-shadow mb-4">
        <div class="card-body">
            <div class="col-12" *ngIf="error">
                <div class="alert cds-alert alert-danger" role="alert">
                    <div class="cds-alert-icon">
                      <i class="fa-solid fa-triangle-exclamation"></i>
                    </div>
                    <div class="cds-alert-content">{{errorMessage}}</div>
                </div>
            </div>
    
            <div class="col-12 mb-1">
                <input class="form-control searchBox" placeholder="{{ 'Quick Filter' | translate }}"[(ngModel)]="searchText">
            </div>
            <!-- pmml table START -->
            <div class="col-sm-12">
                <table aria-describedby="table-proc-pmmlParent" class="table table-striped"
                    [mfData]="pmmlPageModels | filter : 'pmmlModel.id,pmmlModel.description,pmmlModel.fileName' :searchText :false"
                    #mf="mfDataTable" [mfRowsOnPage]="15" mfSortBy="pmmlModel.id">
                    <thead>
                        <tr>
                            <th class="center-align">
                                <mfDefaultSorter by="pmmlModel.id">{{ 'Code' | translate }}</mfDefaultSorter>
                            </th>
                            <th class="center-align">
                                <mfDefaultSorter by="pmmlModel.description">{{ 'Notes' | translate }}</mfDefaultSorter>
                            </th>
                            <th class="center-align">
                                <mfDefaultSorter by="pmmlModel.fileName">{{ 'Pmml File Name' | translate }}
                                </mfDefaultSorter>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr ng-disabled='showAppLoader === true' *ngFor="let pmmlPageModel of mf.data let index"
                            (click)="enableButton(pmmlPageModel)"
                            [ngStyle]="{'background-color': pmmlPageModel.pmmlModel.id === clickedPmmlId ? 'antiquewhite' : '' }">
                            <td class="center-align">{{pmmlPageModel.pmmlModel.id}}</td>
                            <td class="center-align">{{pmmlPageModel.pmmlModel.description}}</td>
                            <td class="center-align">{{pmmlPageModel.pmmlModel.fileName}}</td>
                        </tr>
                    </tbody>
                    <tfoot
                        *ngIf="(pmmlPageModels | filter : 'pmmlModel.id,pmmlModel.description,pmmlModel.fileName' : searchText).length > 15">
                        <tr>
                            <td colspan="6">
                                <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <div class="col-sm-12" *ngIf="pmmlPageModels.length===0">
                    <div class="alert cds-alert alert-danger" role="alert">
                        <div class="cds-alert-icon">
                          <i class="fa-solid fa-triangle-exclamation"></i>
                        </div>
                        <div class="cds-alert-content">{{ 'No records found.' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</ng-container>


<ng-container *ngIf="currentRow.pInputVariables.length>0">
    <h6 class=" mb-1">{{ 'Details of pmml model :' | translate }}
        <h6 class="badge cds-badge active text-bg-primary">{{currentRow.pmmlModel.id}}</h6>
    </h6>
    <div class="card cds-card-shadow">
        <div class="card-body">
            <div class="col-3 float-end">
                <button [disabled]='!isEnableExport' class="btn btn-primary btn-sm m-1 float-end" (click)="open(template, 'Export')"
                    title="{{ 'Export Package' | translate }}">
                    <i class="fa fa-upload p-1"></i>{{'Export' | translate}}
                </button> 
                <button *ngIf="notSlave"  [disabled]='!isEnableImport' class="btn btn-primary btn-sm m-1 float-end" (click)="open(template, 'Import')"
                    title="{{ 'Import Package' | translate }}">
                    <i class="fa fa-download p-1"></i>{{ 'Import' | translate }}
                </button>
            </div>
            <div class="col-12" *ngIf="isExportRunning === true">
                <appspinner [startSpinner]="showAppLoader"></appspinner>
            </div>
             <!-- selected model's input variables table START -->
            <ng-container> 
                <div class="col-12 mt-4">
                    <h6 class=" mb-1">{{ 'Input Variables' | translate }} :</h6>
                    <hr>
                </div>
    
                <div class="col-12">
                    <table aria-describedby="table-proc-pmmlChildIp"
                        class="table table-striped" [mfData]="currentRow.pInputVariables"
                        #mf="mfDataTable" [mfRowsOnPage]="15">
                        <thead>
                            <tr>
                                <th scope="col" class="center-align" colspan="3">{{ 'PMML' | translate }}</th>
                                <th scope="col" class="center-align" colspan="2">{{ 'StrategyOne' | translate }}</th>
                            </tr>
                            <tr>
                                <th scope="col" class="center-align">
                                    <mfDefaultSorter by="pmmlDataField.name">{{ 'Name' | translate }}</mfDefaultSorter>
                                </th>
                                <th scope="col" class="center-align">
                                    <mfDefaultSorter by="pmmlDataField.opType">{{ 'Usage' | translate }}</mfDefaultSorter>
                                </th>
                                <th scope="col" class="center-align">
                                    <mfDefaultSorter by="pmmlDataField.dataType">{{ 'Type' | translate }}</mfDefaultSorter>
                                </th>
                                <th scope="col" class="center-align">
                                    <mfDefaultSorter by="pmmlVariable.objectsVariableId">{{ 'Name' | translate }}
                                    </mfDefaultSorter>
                                </th>
                                <th scope="col" class="center-align">
                                    <mfDefaultSorter by="pmmlVariable.objectsVariableType">{{ 'Type' | translate }}
                                    </mfDefaultSorter>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr ng-disabled='showAppLoader === true' *ngFor="let variable of mf.data let index">
                                <td class="center-align">{{variable.pmmlDataField.name}}</td>
                                <td class="center-align">{{variable.pmmlDataField.opType}}</td>
                                <td class="center-align">{{variable.pmmlDataField.dataType}}</td>
                                <td *ngIf='!isEVO()' class="center-align">
                                    {{variable.pmmlVariable.objectsVariableId}}</td>
                                <td *ngIf='isEVO()' class="center-align">
                                    {{variable.pmmlVariable.categoryId}}.{{variable.pmmlVariable.objectsVariableId}}</td>
                                <td class="center-align">{{variable.pmmlVariable.objectsVariableType}}</td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="currentRow.pInputVariables.length > 15">
                            <tr>
                                <td colspan="6">
                                    <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </ng-container>
            <!-- selected model's output variables table START -->
            <ng-container>
                <div class="col-12 mt-5">
                    <h6 class="mb-1">{{ 'Output Variables' | translate }} :</h6>
                    <hr>
                </div>
                <div class="col-12" *ngIf="currentRow.pOutputVariables.length>0">
                    <table aria-describedby="table-proc-pmmlChildOp"
                        class="table table-striped" [mfData]="currentRow.pOutputVariables"
                        #mf="mfDataTable" [mfRowsOnPage]="15">
                        <thead>
                            <tr>
                                <th scope="col" class="center-align" colspan="3">{{ 'PMML' | translate }}</th>
                                <th scope="col" class="center-align" colspan="2">{{ 'StrategyOne' | translate }}</th>
                            </tr>
                            <tr>
                                <th scope="col" class="center-align">
                                    <mfDefaultSorter by="pmmlDataField.name">{{ 'Name' | translate }}</mfDefaultSorter>
                                </th>
                                <th scope="col" class="center-align">
                                    <mfDefaultSorter by="pmmlDataField.opType">{{ 'Usage' | translate }}</mfDefaultSorter>
                                </th>
                                <th scope="col" class="center-align">
                                    <mfDefaultSorter by="pmmlDataField.dataType">{{ 'Type' | translate }}</mfDefaultSorter>
                                </th>
                                <th scope="col" class="center-align">
                                    <mfDefaultSorter by="pmmlVariable.objectsVariableId">{{ 'Name' | translate }}
                                    </mfDefaultSorter>
                                </th>
                                <th scope="col" class="center-align">
                                    <mfDefaultSorter by="pmmlVariable.objectsVariableType">{{ 'Type' | translate }}
                                    </mfDefaultSorter>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr ng-disabled='showAppLoader === true' *ngFor="let variable of mf.data let index">
                                <td class="center-align">{{variable.pmmlDataField.name}}</td>
                                <td class="center-align">{{variable.pmmlDataField.opType}}</td>
                                <td class="center-align">{{variable.pmmlDataField.dataType}}</td>
                                <td *ngIf='!isEVO()' class="center-align">
                                    {{variable.pmmlVariable.objectsVariableId}}</td>
                                <td *ngIf='isEVO()' class="center-align">
                                    {{variable.pmmlVariable.categoryId}}.{{variable.pmmlVariable.objectsVariableId}}</td>
                                <td class="center-align">{{variable.pmmlVariable.objectsVariableType}}</td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="currentRow.pOutputVariables.length > 15">
                            <tr>
                                <td colspan="6">
                                    <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<!--import modal-->
<ng-template #template tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>{{ modalTitle | translate }} :</h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <h6 class="mb-1">{{'Pmml File Name' | translate}}:</h6>
                    </div>
                    <div class="col-12">
                        <input [disabled]='showAppLoader' name="fileImport" type="file" (change)="validateUpload($event)"accept=".xml,.pmml" />
                    </div>
                </div>
                <appspinner [startSpinner]="showAppLoader"></appspinner>
            </div>
            <div class="modal-footer">
                <button [disabled]='!isEnableFinalImport' type="submit" class="btn btn-primary" (click)="uploadAttachment()">
                    {{'Import' | translate }}
                </button>
                <button [disabled]='showAppLoader' type="button" class="btn btn-default" (click)="cancelWindow()">
                    {{ 'Close' |translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>