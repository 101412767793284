import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
@Pipe({
    name: 'logTracerMsgfilter'
})
export class LogTracerMessageFilterPipe implements PipeTransform {
    noneVal: string;
    processCreateVal: string;
    processSaveVal: string;
    checkoutProcessVal: string;
    checkinProcessVal: string;
    checkoutUndoneVal: string;
    deployProcessVal: string;
    deleteProcessVal: string;
    reassignProcessVal: string;
    checkoutFromHistVal: string;
    createPythonModelVal: string;
    dropPythonModelVal: string;
    undoPythonPendingVal: string;
    processPermissionChangeVal: string;
    loginVal: string;
    logoffVal: string;
    userCreatedVal: string;
    userDeletedVal: string;
    userUpdatedVal: string;
    organizationCreatedVal: string;
    organizationUpdatedVal: string;
    organizationDeletedVal: string;
    groupCreatedVal: string;
    groupUpdatedVal: string;
    groupDeletedVal: string;
    installationOptionModifiedVal: string;
    groupGrantedToUserVal: string;
    orgGrantedToUserVal: string;
    templateGrantedToUserVal: string;
    installationGrantedToUserVal: string;
    groupRevokeFromUserVal: string;
    orgRevokeFromUserVal: string;
    tempRevokeFromUserVal: string;
    installationRevokeFromUserVal: string;
    executeDebugVal: string;
    createWhatifVal: string;
    startWhatifVal: string;
    stopWhatifVal: string;
    deleteWhatifVal: string;
    designerPrintedObjVal: string;
    designerPrintedStrategyVal: string;
    designerPrintedSegmentationVal: string;
    designerPrintedProcessVal: string;
    serverStartupVal: string;
    serverShutdownVal: string;
    premissionChangeVal: string;

    constructor(public translate: TranslateService) {
        translate.get(["None", "Process created", "Process saved", "Check-out process", "Check-in process", "Check-out undone", "Process deployed", "Process deleted",
            "Process reassigned", "Check-out from history", "Create Python Model", "Drop Python Model", "Undo Python Pending", "Process permissions changed", "Login",
            "Logoff", "User created", "User deleted", "User updated", "Organization created", "Organization updated", "Organization deleted", "Group created", "Group updated",
            "Group deleted", "Installation options modified", "Group granted to user", "Organization granted to user", "Template granted to user", "Installation granted to user",
            "Group revoked from user", "Organization revoked from user", "Template revoked from user", "Installation revoked from user", "Executed debug", "Created What-if", "Started What-if",
            "Stopped What-if", "Deleted What-if", "Designer - Printed Object", "Designer - Printed Strategy", "Designer - Printed Segmentation", "Designer - Printed Process", "Server startup",
            "Server shutdown", "Permission changed, profile Configure & Deploy revoked from user"]).subscribe((text: any) => {
                this.noneVal = text["None"];
                this.processCreateVal = text["Process created"];
                this.processSaveVal = text["Process saved"];
                this.checkoutProcessVal = text["Check-out process"];
                this.checkinProcessVal = text["Check-in process"];
                this.checkoutUndoneVal = text["Check-out undone"];
                this.deployProcessVal = text["Process deployed"];
                this.deleteProcessVal = text["Process deleted"];
                this.reassignProcessVal = text["Process reassigned"];
                this.checkoutFromHistVal = text["Check-out from history"];
                this.createPythonModelVal = text["Create Python Model"];
                this.dropPythonModelVal = text["Drop Python Model"];
                this.undoPythonPendingVal = text["Undo Python Pending"];
                this.processPermissionChangeVal = text["Process permissions changed"];
                this.loginVal = text["Login"];
                this.logoffVal = text["Logoff"];
                this.userCreatedVal = text["User created"];
                this.userDeletedVal = text["User deleted"];
                this.userUpdatedVal = text["User updated"];
                this.organizationCreatedVal = text["Organization created"];
                this.organizationUpdatedVal = text["Organization updated"];
                this.organizationDeletedVal = text["Organization deleted"];
                this.groupCreatedVal = text["Group created"];
                this.groupUpdatedVal = text["Group updated"];
                this.groupDeletedVal = text["Group deleted"];
                this.installationOptionModifiedVal = text["Installation options modified"];
                this.groupGrantedToUserVal = text["Group granted to user"];
                this.orgGrantedToUserVal = text["Organization granted to user"];
                this.templateGrantedToUserVal = text["Template granted to user"];
                this.installationGrantedToUserVal = text["Installation granted to user"];
                this.groupRevokeFromUserVal = text["Group revoked from user"];
                this.orgRevokeFromUserVal = text["Organization revoked from user"];
                this.tempRevokeFromUserVal = text["Template revoked from user"];
                this.installationRevokeFromUserVal = text["Installation revoked from user"];
                this.executeDebugVal = text["Executed debug"];
                this.createWhatifVal = text["Created What-if"];
                this.startWhatifVal = text["Started What-if"];
                this.stopWhatifVal = text["Stopped What-if"];
                this.deleteWhatifVal = text["Deleted What-if"];
                this.designerPrintedObjVal = text["Designer - Printed Object"];
                this.designerPrintedStrategyVal = text["Designer - Printed Strategy"];
                this.designerPrintedSegmentationVal = text["Designer - Printed Segmentation"];
                this.designerPrintedProcessVal = text["Designer - Printed Process"];
                this.serverStartupVal = text["Server startup"];
                this.serverShutdownVal = text["Server shutdown"];
                this.premissionChangeVal = text["Permission changed, profile Configure & Deploy revoked from user"];
            });
    }
    transform(strVar: string) {
        let translateLogTracerMsg: string;
        switch (strVar) {
            case "Process created":
                translateLogTracerMsg = this.processCreateVal;
                break;
            case "Process saved":
                translateLogTracerMsg = this.processSaveVal;
                break;
            case "Check-out process":
                translateLogTracerMsg = this.checkoutProcessVal;
                break;
            case "Check-in process":
                translateLogTracerMsg = this.checkinProcessVal;
                break;
            case "Check-out undone":
                translateLogTracerMsg = this.checkoutUndoneVal;
                break;
            case "Process deployed":
                translateLogTracerMsg = this.deployProcessVal;
                break;
            case "Process deleted":
                translateLogTracerMsg = this.deleteProcessVal;
                break;
            case "Process reassigned":
                translateLogTracerMsg = this.reassignProcessVal;
                break;
            case "Check-out from history":
                translateLogTracerMsg = this.checkoutFromHistVal;
                break;
            case "Create Python Model":
                translateLogTracerMsg = this.createPythonModelVal;
                break;
            case "Drop Python Model":
                translateLogTracerMsg = this.dropPythonModelVal;
                break;
            case "Undo Python Pending":
                translateLogTracerMsg = this.undoPythonPendingVal;
                break;
            case "Process permissions changed":
                translateLogTracerMsg = this.processPermissionChangeVal;
                break;
            case "Login":
                translateLogTracerMsg = this.loginVal;
                break;
            case "Logoff":
                translateLogTracerMsg = this.logoffVal;
                break;
            case "User created":
                translateLogTracerMsg = this.userCreatedVal;
                break;
            case "User deleted":
                translateLogTracerMsg = this.userDeletedVal;
                break;
            case "User updated":
                translateLogTracerMsg = this.userUpdatedVal;
                break;
            case "Organization created":
                translateLogTracerMsg = this.organizationCreatedVal;
                break;
            case "Organization updated":
                translateLogTracerMsg = this.organizationUpdatedVal;
                break;
            case "Organization deleted":
                translateLogTracerMsg = this.organizationDeletedVal;
                break;
            case "Group created":
                translateLogTracerMsg = this.groupCreatedVal;
                break;
            case "Group updated":
                translateLogTracerMsg = this.groupUpdatedVal;
                break;
            case "Group deleted":
                translateLogTracerMsg = this.groupDeletedVal;
                break;
            case "Installation options modified":
                translateLogTracerMsg = this.installationOptionModifiedVal;
                break;
            case "Group granted to user":
                translateLogTracerMsg = this.groupGrantedToUserVal;
                break;
            case "Organization granted to user":
                translateLogTracerMsg = this.orgGrantedToUserVal;
                break;
            case "Template granted to user":
                translateLogTracerMsg = this.templateGrantedToUserVal;
                break;
            case "Installation granted to user":
                translateLogTracerMsg = this.installationGrantedToUserVal;
                break;
            case "Group revoked from user":
                translateLogTracerMsg = this.groupRevokeFromUserVal;
                break;
            case "Organization revoked from user":
                translateLogTracerMsg = this.orgRevokeFromUserVal;
                break;
            case "Template revoked from user":
                translateLogTracerMsg = this.tempRevokeFromUserVal;
                break;
            case "Installation revoked from user":
                translateLogTracerMsg = this.installationRevokeFromUserVal;
                break;
            case "Executed debug":
                translateLogTracerMsg = this.executeDebugVal;
                break;
            case "Created What-if":
                translateLogTracerMsg = this.createWhatifVal;
                break;
            case "Started What-if":
                translateLogTracerMsg = this.startWhatifVal;
                break;
            case "Stopped What-if":
                translateLogTracerMsg = this.stopWhatifVal;
                break;
            case "Deleted What-if":
                translateLogTracerMsg = this.deleteWhatifVal;
                break;
            case "Designer - Printed Object":
                translateLogTracerMsg = this.designerPrintedObjVal;
                break;
            case "Designer - Printed Strategy":
                translateLogTracerMsg = this.designerPrintedStrategyVal;
                break;
            case "Designer - Printed Segmentation":
                translateLogTracerMsg = this.designerPrintedSegmentationVal;
                break;
            case "Designer - Printed Process":
                translateLogTracerMsg = this.designerPrintedProcessVal;
                break;
            case "Server startup":
                translateLogTracerMsg = this.serverStartupVal;
                break;
            case "Server shutdown":
                translateLogTracerMsg = this.serverShutdownVal;
                break;
            default:
                translateLogTracerMsg = this.noneVal;
        }
        return translateLogTracerMsg;
    }
}
