<div class="row">
    <div class="col-12">
        <div class="card cds-card-shadow">
            <div class="card-body">
                <div class="alert cds-alert alert-danger" role="alert" *ngIf="isError && errorMessage">
                    <div class="cds-alert-icon">
                      <i class="fa-solid fa-triangle-exclamation"></i>
                    </div>
                    <div class="cds-alert-content">{{errorMessage | translate}}</div>
                </div>
                 <!-- options for python management START -->
                <div class="col-12 mb-4">
                    <button class="btn btn-primary btn-sm" (click)="install()" title="{{ 'Install' | translate }}" [disabled]="!isEnableInstall">
                        <i class="fa fa-download"></i>
                    </button>
                    &nbsp;
                    <button class="btn btn-primary btn-sm" (click)="uninstall()" title="{{ 'Uninstall' | translate }}" [disabled]="!isEnableUninstall">
                        <i class="fa fa-upload"></i>
                    </button>
                    &nbsp;
                    <button class="btn btn-primary btn-sm" (click)="start()" title="{{ 'Start' | translate }}" [disabled]="!isEnableStart">
                        <i class="fa fa-play"></i>
                    </button>
                    &nbsp;
                    <button class="btn btn-primary btn-sm" (click)="stop()" title="{{ 'Stop' | translate }}" [disabled]="!isEnableStop">
                        <i class="fa fa-stop-circle"></i>
                    </button>
                    &nbsp;
                    <button class="btn btn-primary btn-sm" (click)="testConnection()" title="{{ 'Test Connection' | translate }}" [disabled]="!isEnableTestConnection">
                        <i class="fa fa-filter"></i>
                    </button>
                </div>
                <!-- python table START -->
                <div class="col-12 mb-4">
                    <input class="form-control searchBox" placeholder="{{ 'Quick Filter' | translate }}" [disabled]="showAppLoader" [(ngModel)]="searchText">
                    <appspinner [startSpinner]="showAppLoader"></appspinner>
                </div>
                <div class="col-12">
                    <table aria-describedby="table-pythonManagement"
                        class="table table-striped"
                        [mfData]="pythonModelArray | filter :'processDisplayName,processDisplayVersion,port,viewState,autoStart' :searchText :false"
                        #mf="mfDataTable" [mfRowsOnPage]="15">
                        <thead>
                            <tr>
                                <th class="center-align">
                                    {{ 'Action' | translate }}
                                </th>
                                <th class="center-align">
                                    <mfDefaultSorter by="processDisplayName">{{ 'Process Code' | translate }}
                                    </mfDefaultSorter>
                                </th>
                                <th class="center-align">
                                    <mfDefaultSorter by="processDisplayVersion">{{ 'Process Version' | translate }}
                                    </mfDefaultSorter>
                                </th>
                                <th class="center-align">
                                    <mfDefaultSorter by="port">{{ 'Package Code' | translate }}</mfDefaultSorter>
                                </th>
                                <th class="center-align">
                                    <mfDefaultSorter by="viewState">{{ 'Status' | translate }}</mfDefaultSorter>
                                </th>
                                <th class="center-align">
                                    <mfDefaultSorter by="autoStart">{{ 'Auto Start' | translate }}</mfDefaultSorter>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr ng-disabled='showAppLoader === true' *ngFor="let pythonModel of mf.data let index"
                                (click)="enableButton(pythonModel)"
                                [ngStyle]="{'background-color': pythonModel.port === clickedPort ? 'antiquewhite' : '' }">
                                <td class="center-align text-nowrap">
                                    <button class="btn btn-sm btn-primary" (click)="refresh(pythonModel)" title="Refresh"
                                        [disabled]='showAppLoader'>
                                        <span class="fa fa-refresh"></span>
                                    </button>
                                </td>
                                <td class="center-align">{{pythonModel.processDisplayName}}</td>
                                <td class="center-align">{{pythonModel.processDisplayVersion}}</td>
                                <td class="center-align">{{pythonModel.port}}</td>
                                <td class="center-align">{{pythonModel.viewState ==  "DELETING"? "STOPPING" : pythonModel.viewState}}</td>
                                <td class="center-align">
                                    <input type="checkbox" [disabled]='showAppLoader' class="form-check-input" [(ngModel)]="pythonModel.autoStart" (change)="onChange(pythonModel)" />
                                </td>
                            </tr>
                        </tbody>
                        <tfoot
                            *ngIf="(pythonModelArray | filter :'processDisplayName,processDisplayVersion,port,viewState,autoStart': searchText)?.length > 15">
                            <tr>
                                <td colspan="6">
                                    <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="alert cds-alert alert-danger" role="alert" *ngIf="pythonModelArray?.length===0 && !showAppLoader">
                        <div class="cds-alert-icon">
                          <i class="fa-solid fa-triangle-exclamation"></i>
                        </div>
                        <div class="cds-alert-content">{{ 'No records found.' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>