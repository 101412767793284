import { UntypedFormGroup } from "@angular/forms";
export class S1PasswordPolicies {
    changeAtLogin: boolean;
    neverExpire: boolean;
    enforcePolicies: boolean;

    constructor(formVal: UntypedFormGroup) {
        if (formVal.value.passwordPolicies != null) {
            this.neverExpire = (formVal.value.passwordPolicies == "Password Never Expire" || 
            formVal.value.passwordPolicies[0] == "Password Never Expire" || 
            formVal.value.passwordPolicies[1] == "Password Never Expire" || 
            formVal.value.passwordPolicies[2] == "Password Never Expire") ? true : false;
            this.changeAtLogin = (formVal.value.passwordPolicies == "Change Password to Next Login" || 
            formVal.value.passwordPolicies[0] == "Change Password to Next Login" || 
            formVal.value.passwordPolicies[1] == "Change Password to Next Login" || 
            formVal.value.passwordPolicies[2] == "Change Password to Next Login") ? true : false;
            this.enforcePolicies = (formVal.value.passwordPolicies == "Exclude from password security policies" || 
            formVal.value.passwordPolicies[0] == "Exclude from password security policies" || 
            formVal.value.passwordPolicies[1] == "Exclude from password security policies" || 
            formVal.value.passwordPolicies[2] == "Exclude from password security policies") ? false : true;
        }
    }
}