import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'opsNamePipe'
})
export class OperatorNameMappingFilter implements PipeTransform {
    public transform(dbvalue) {
        let operator: string;
        switch (dbvalue) {
            case "=":
                operator = "EQ";
                break;
            case ">=":
                operator = "GE";
                break;
            case ">":
                operator = "GT";
                break;
            case "<=":
                operator = "LE";
                break;
            case "<":
                operator = "LT";
                break;
            case "<>":
                operator = "NE";
                break;
            default:
                operator = "(NONE)";
        }
        return operator;
    }
}