import { Component, OnInit } from '@angular/core';
import { StorageDetails } from 'app/core/services/user-details.service';
import { TranslateConfigService } from 'app/core/services/translate-config.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

    collapedSideBar: boolean;

    constructor(private userDetails : StorageDetails, private translate : TranslateConfigService) {
        translate.setLanguage(userDetails.getLanguage());
    }

    ngOnInit() {
        //do nothing
    }

    receiveCollapsed($event) {
        this.collapedSideBar = $event;
    }
    
    onActivate(event) {
        window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
        });
    }
}
