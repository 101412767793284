<div class="col-12" *ngIf="isPermissionError">
    <div class="alert cds-alert alert-danger" role="alert">
        <div class="cds-alert-icon">
          <i class="fa-solid fa-triangle-exclamation"></i>
        </div>
        <div class="cds-alert-content">{{"You do not have permission to access this page." | translate}}</div>
    </div>
</div>

<div class="col-12" *ngIf="!isPermissionError && !isProcessEVO">
    <div class="alert cds-alert alert-danger" role="alert">
        <div class="cds-alert-icon">
          <i class="fa-solid fa-triangle-exclamation"></i>
        </div>
        <div class="cds-alert-content">{{errorMessage}}</div>
    </div>
</div>

<appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>

<ng-container *ngIf="isProcessEVO && !nodeService.showAppLoader && !isPermissionError">
    <h6 class="mb-1">{{ 'Available python models for process :' | translate }}
        <h6 class="badge cds-badge active text-bg-info">{{nodeService.currentProcessName}}</h6>
    </h6>
    
    <div class="card cds-card-shadow mb-4">
        <div class="card-body">
            <div class="col-12" *ngIf="error">
                <div class="alert cds-alert alert-danger" role="alert">
                    <div class="cds-alert-icon">
                      <i class="fa-solid fa-triangle-exclamation"></i>
                    </div>
                    <div class="cds-alert-content">{{ errorMessage | translate }}</div>
                </div>
            </div> 

            <div class="col-12 mb-1">
                <input class="form-control searchBox" placeholder="{{ 'Quick Filter' | translate }}" [disabled]="showAppLoader" [(ngModel)]="searchText">
            </div>

            <div class="col-12">
                <table aria-describedby="table-proc-pythonParent"
                    class="table table-striped"
                    [mfData]="pythonModels | filter : 'pConnector.id,pConnector.description,pConnector.resourceID,pConnector.url' :searchText :false"
                    #mf="mfDataTable" [mfRowsOnPage]="15" mfSortBy="pConnector.id">
                    <thead>
                        <tr>
                            <th class="center-align">
                                <mfDefaultSorter by="pConnector.id">{{ 'Code' | translate }}</mfDefaultSorter>
                            </th>
                            <th class="center-align">
                                <mfDefaultSorter by="pConnector.description">{{ 'Notes' | translate }}</mfDefaultSorter>
                            </th>
                            <th class="center-align">
                                <mfDefaultSorter by="pConnector.resourceID">{{ 'Package File Name' | translate }}
                                </mfDefaultSorter>
                            </th>
                            <th class="center-align">
                                <mfDefaultSorter by="pConnector.url">{{ 'Package Code' | translate }}</mfDefaultSorter>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr ng-disabled='showAppLoader === true' *ngFor="let pythonModel of mf.data"
                            (click)="enableButton(pythonModel)"
                            [ngStyle]="{'background-color': pythonModel.pConnector.id === clickedPythonId ? 'antiquewhite' : '' }">
                            <td class="center-align">{{pythonModel.pConnector.id}}</td>
                            <td class="center-align">{{pythonModel.pConnector.description}}</td>
                            <td class="center-align">{{pythonModel.pConnector.resourceID}}</td>
                            <td class="center-align">{{pythonModel.pConnector.url}}</td>
                        </tr>

                    </tbody>
                    <tfoot
                        *ngIf="(pythonModels | filter : 'pConnector.id,pConnector.description,pConnector.resourceID,pConnector.url' : searchText).length > 15">
                        <tr>
                            <td colspan="6">
                                <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <div class="col-12" *ngIf="pythonModels.length===0">
                    <div class="alert cds-alert alert-danger" role="alert">
                        <div class="cds-alert-icon">
                          <i class="fa-solid fa-triangle-exclamation"></i>
                        </div>
                        <div class="cds-alert-content">{{ 'No records found.' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="currentRow.pInputVariables.length>0">
    <h6 class="mb-1">{{ 'Details of python model :' | translate }}
        <h6 class="badge cds-badge active text-bg-primary">{{currentRow.pConnector.id}}</h6>
    </h6>

    <div class="card cds-card-shadow">
        <div class="card-body">
            <div class="col-3 float-end">
                <button [disabled]='!isEnableExport' class="btn btn-primary btn-sm float-end m-1" (click)="open(template, 'Export')"
                    title="{{ 'Export Package' | translate }}">
                    <i class="fa fa-upload p-1"></i>{{ 'Export' | translate }}
                </button>
                <button *ngIf="notSlave" [disabled]='!isEnableImport' class="btn btn-primary btn-sm float-end m-1" (click)="open(template, 'Import')"
                    title="{{ 'Import Package' | translate }}">
                    <i class="fa fa-download p-1"></i>{{ 'Import' | translate }}
                </button>
            </div>
            <appspinner [startSpinner]="showAppLoader"></appspinner>
            <!-- selected model's input variables table START -->
            <ng-container>
                <div class="col-12 mt-4">
                    <h6 class="mb-1">{{ 'Input Variables' | translate }}:</h6>
                    <hr>
                </div>
                <div class="col-12">
                    <table aria-describedby="table-proc-pythonIp" class="table table-striped"
                        [mfData]="currentRow.pInputVariables" #mf="mfDataTable" [mfRowsOnPage]="15">
                        <thead>
                            <tr>
                                <th class="center-align">
                                    <mfDefaultSorter by="objectsVariableId">{{ 'Code' | translate }}</mfDefaultSorter>
                                </th>
                                <th class="center-align">
                                    <mfDefaultSorter by="objectsVariableDescription">{{ 'Notes' | translate }}
                                    </mfDefaultSorter>
                                </th>
                                <th class="center-align">
                                    <mfDefaultSorter by="objectsVariableType">{{ 'Type' | translate }}</mfDefaultSorter>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let variable of mf.data let index">
    
                                <td class="center-align">{{variable.categoryId}}.{{variable.objectsVariableId}}</td>
                                <td class="center-align">{{variable.objectsVariableDescription}}</td>
                                <td class="center-align">{{variable.objectsVariableType}}</td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="currentRow.pInputVariables.length > 15">
                            <tr>
                                <td colspan="6">
                                    <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </ng-container>
            <!-- selected model's output variables table START -->
            <ng-container>
                <div class="col-12 mt-4">
                    <h6 class="mb-1">{{ 'Output Variables' | translate }}:</h6>
                    <hr>
                </div>
                <div class="col-12" *ngIf="currentRow.pOutputVariables.length>0">
                    <table aria-describedby="table-proc-pythonOp" class="table table-striped"
                        [mfData]="currentRow.pOutputVariables" #mf="mfDataTable" [mfRowsOnPage]="15">
                        <thead>
                            <tr>
                                <th class="center-align">
                                    <mfDefaultSorter by="objectsVariableId">{{ 'Code' | translate }}</mfDefaultSorter>
                                </th>
                                <th class="center-align">
                                    <mfDefaultSorter by="objectsVariableDescription">{{ 'Notes' | translate }}
                                    </mfDefaultSorter>
                                </th>
                                <th class="center-align">
                                    <mfDefaultSorter by="objectsVariableType">{{ 'Type' | translate }}</mfDefaultSorter>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let variable of mf.data let index">
    
                                <td class="center-align">{{variable.categoryId}}.{{variable.objectsVariableId}}</td>
                                <td class="center-align">{{variable.objectsVariableDescription}}</td>
                                <td class="center-align">{{variable.objectsVariableType}}</td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="currentRow.pOutputVariables.length > 15">
                            <tr>
                                <td colspan="6">
                                    <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-template #template tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>{{ modalTitle | translate }}</h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <div class="col-12">
                   <h6>{{ 'Package filename' | translate }} :</h6> 
                </div>
                <div class="col-12">
                    <input id="file1" [disabled]='showAppLoader' type="file" class="hidden" accept=".zip" ng2FileSelect
                    [uploader]="uploader" (change)="validateUpload($event)" />
                </div>
                <appspinner [startSpinner]="showAppLoader"></appspinner>
            </div>
            <div class="modal-footer">
                <button [disabled]='!isEnableFinalImport || showAppLoader' type="submit" class="btn btn-primary" (click)="uploadZip()">
                    {{'Import' | translate }}
                </button>
                <button [disabled]='showAppLoader' type="button" class="btn btn-default" (click)="cancelWindow()">
                    {{ 'Close' |translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
