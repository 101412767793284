<div class="col-sm-12">
    <div *ngIf="isEnableShowAppLoader">
        <appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>
    </div>
    <div class="card cds-card-shadow p-3">
        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-5">
                    <button class="btn btn-sm  btn-primary m-b-0 btn-width" [disabled]="!nodeService.selectedNode || nodeService.showAppLoader
                        || (nodeService.selectedNode && nodeService.selectedNode.data.type === 'process')
                        || (userDetails.getUserRole() === 'USER')"
                        (click)="openDialog(templateCreate, false)" title="{{'Create the process' | translate}}">
                        {{'Create' | translate}}
                    </button>
                </div>
                <div class="col-sm-7 samelineellipsis">
                    <span>{{'Create the process' | translate}}</span>
                    <p></p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5">
                    <button class="btn btn-sm  btn-primary m-b-0 btn-width" [disabled]="isOpenDisabled()"
                        (click)="beforeOpenProcess()" title="{{'Open and configure the process' | translate}}">
                        {{'Open' | translate}}
                    </button>
                </div>
                <div class="col-sm-7 samelineellipsis">
                    <span>{{'Open and configure the process' | translate}}</span>
                    <p></p>
                    <!--req empty p to maintain slight space between 2 rows. br doesnt work.-->
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5">
                    <button class="btn btn-sm  btn-primary m-b-0 btn-width"
                        [disabled]="!checkIfExist() || nodeService.showAppLoader"
                        (click)="openDialog(templateClose, false)" title="{{'Close the process' | translate}}">
                        {{'Close' | translate}}
                    </button>
                </div>
                <div class="col-sm-7 samelineellipsis">
                    <span>{{'Close the process' | translate}}</span>
                    <p></p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5">
                    <button class="btn btn-sm  btn-primary m-b-0 btn-width"
                        [disabled]="isHistoryDisable() || nodeService.showAppLoader"
                        (click)="openDialog(templateHistory, true)"
                        title="{{'Display process history table' | translate}}">
                        {{'History' | translate}}
                    </button>
                </div>
                <div class="col-sm-7 samelineellipsis">
                    <span>{{'Display process history table' | translate}}</span>
                    <p></p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5">
                    <button class="btn btn-sm  btn-primary m-b-0 btn-width"
                        [disabled]="isSaveCheckinUndoDisable() || nodeService.showAppLoader" (click)="callSaveProcess()"
                        title="{{'Save the process on the repository.' | translate}}">
                        {{'Save' | translate}}
                    </button>
                </div>
                <div class="col-sm-7 samelineellipsis">
                    <span>{{'Save the process on the repository.' | translate}}</span>
                    <p></p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5">
                    <button class="btn btn-sm  btn-primary m-b-0 btn-width"
                        [disabled]="isCheckoutDisabled() || nodeService.showAppLoader" (click)="checkOut()"
                        title="{{'Check out the process to edit it.' | translate}}">
                        {{'Check Out' | translate}}
                    </button>
                </div>
                <div class="col-sm-7 samelineellipsis">
                    <span>{{'Check out the process to edit it.' | translate}}</span>
                    <p></p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5">
                    <button class="btn btn-sm  btn-primary m-b-0 btn-width"
                        [disabled]="isSaveCheckinUndoDisable() || nodeService.showAppLoader"
                        (click)="openDialogCheckIn(templateCheckIn)"
                        title="{{'Check in the process to version changes.' | translate}}">
                        {{'Check In' | translate}}
                    </button>
                </div>
                <div class="col-sm-7 samelineellipsis">
                    <span>{{'Check in the process to version changes.' | translate}}</span>
                    <p></p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5">
                    <button class="btn btn-sm  btn-primary m-b-0 btn-width"
                        [disabled]="isSaveCheckinUndoDisable() || nodeService.showAppLoader"
                        (click)="openDialog(undoCheckoutTemplate, false)"
                        title="{{'Delete changes since latest check out.' | translate}}">
                        {{'Undo Check Out' | translate}}
                    </button>
                </div>
                <div class="col-sm-7 samelineellipsis">
                    <span>{{'Delete changes since latest check out.' | translate}}</span>
                    <p></p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5">
                    <button class="btn btn-sm  btn-primary m-b-0 btn-width"
                        [disabled]="isImportDisable() || nodeService.showAppLoader"
                        (click)="openDialog(templateImport, false)"
                        title="{{ 'Import process from file.' | translate}}">
                        {{'Import' | translate}}
                    </button>
                </div>
                <div class="col-sm-7 samelineellipsis">
                    <span>{{ 'Import process from file.' | translate}}</span>
                    <p></p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5">
                    <button class="btn btn-sm  btn-primary m-b-0 btn-width"
                        [disabled]="isExportDisable() || nodeService.showAppLoader" (click)="docExportProcess()"
                        title="{{'Export process into a file.' | translate}}">
                        {{'Export' | translate}}
                    </button>
                </div>
                <div class="col-sm-7 samelineellipsis">
                    <span>{{'Export process into a file.' | translate}}</span>
                    <p></p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5">
                    <button class="btn btn-sm  btn-primary m-b-0 btn-width"
                        [disabled]="isDeployDisable() || nodeService.showAppLoader"
                        (click)="openDialogDeploy(templateDeploy)"
                        title="{{'Deploy in production environment.' | translate}}">
                        {{'Deploy' | translate}}
                    </button>
                </div>
                <div class="col-sm-7 samelineellipsis">
                    <span>{{'Deploy in production environment.' | translate}}</span>
                    <p></p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5">
                    <button class="btn btn-sm  btn-primary m-b-0 btn-width"
                        [disabled]="isReassignDisable() || nodeService.showAppLoader"
                        (click)="openDialogReassignProcess(templateReassign)"
                        title="{{'Change the process owner.' | translate}}">
                        {{'Reassign' | translate}}
                    </button>
                </div>
                <div class="col-sm-7 samelineellipsis">
                    <span>{{'Change the process owner.' | translate}}</span>
                    <p></p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12"><br /></div>
    <h6 class="mb-1">{{ 'Properties of process :' | translate }} 
        <h6 class="badge cds-badge active text-bg-info m-0" *ngIf="nodeService.currentProcessName">
            {{nodeService.currentProcessName}}
        </h6>
    </h6>
    <div class="card cds-card-shadow mt-1 p-3">
        <div class="col-sm-12">
            <div class="col-sm-12">
                <div class="scroll-only-x">
                    <table aria-describedby="table-proc-properties" class="table table-striped">
                        <thead>
                            <tr>
                                <th>{{ 'Properties' | translate }}</th>
                                <th>{{ 'Values' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="nodeService && nodeService.currentProcessDetails">
                            <tr>
                                <td>{{'Code' | translate}}</td>
                                <td>{{nodeService.currentProcessDetails.s1ProcessInfo.name}}</td>
                            </tr>
                            <tr>
                                <td>{{'Version' | translate}}</td>
                                <td>{{nodeService.currentProcessDetails.s1ProcessInfo.revision}}</td>
                            </tr>
                            <tr>
                                <td>{{'Layout Version' | translate}}</td>
                                <td>{{nodeService.currentProcessDetails.s1ProcessInfo.layoutRevision}}</td>
                            </tr>
                            <tr>
                                <td>{{'Notes' | translate}}</td>
                                <td>{{nodeService.currentProcessDetails.s1ProcessInfo.comment}}</td>
                            </tr>
                            <tr>
                                <td>{{'Type' | translate}}</td>
                                <td>{{nodeService.currentProcessDetails.s1ProcessInfo.processType === "APPLICATION" ?
                                    'Application Processing' : 'Behavioural Processing'}}
                                </td>
                            </tr>
                            <tr>
                                <td>{{'IO Type' | translate}}</td>
                                <td>{{nodeService.currentProcessDetails.s1ProcessInfo.ioType}}</td>
                            </tr>
                            <tr>
                                <td>{{'Repository Status' | translate}}</td>
                                <td>{{nodeService.currentProcessDetails.s1ProcessInfo.lock === 'LOCKEDBYME' ? 'Locked by me'
                                    : nodeService.currentProcessDetails.s1ProcessInfo.lock === 'UNLOCKED' ? 'Unlocked' :
                                    'Locked by other'}}
                                </td>
                            </tr>
                            <tr>
                                <td>{{'Locked By' | translate}}</td>
                                <td>{{nodeService.currentProcessDetails.s1ProcessInfo.lockedBy}}</td>
                            </tr>
                            <tr>
                                <td>{{'Locked At' | translate}}</td>
                                <td>{{nodeService.currentProcessDetails.s1ProcessInfo.sinceDisplay}}
                                </td>
                            </tr>
                            <tr>
                                <td>{{'Updated By' | translate}}</td>
                                <td>{{nodeService.currentProcessDetails.s1ProcessInfo.updatedBy}}</td>
                            </tr>
                            <tr>
                                <td>{{'Updated At' | translate}}</td>
                                <td>{{nodeService.currentProcessDetails.s1ProcessInfo.updatedAtDisplay}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-sm-12 text-center"
                        *ngIf="!nodeService.currentProcessDetails && !nodeService.showAppLoader">
                        <h5>
                            <mat-error>{{ 'No records found.' | translate}}</mat-error>
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--Check In  Popup -->
<ng-template #templateCheckIn tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>Check In</h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="checkinForm">
                    <appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>
                    <div class='col-md-12'>
                        <label for="comment" class="form-label">{{ 'Comment' | translate }}</label>
                        <input type="text" id="comment" placeholder="{{ 'Comment' | translate }}" class="form-control" [(ngModel)]="checkInComment" [ngModelOptions]="{standalone: true}">
                        <!-- <mat-form-field appearance="outline" class="wrapper-without-bottom">
                            <mat-label>{{ 'Comment' | translate }}</mat-label>
                            <input matInput placeholder="{{ 'Comment' | translate }}" [(ngModel)]="checkInComment"
                                [ngModelOptions]="{standalone: true}">
                        </mat-form-field> -->
                        <label for="layoutversion" class="form-label">{{ 'Layout Version' | translate }}</label>
                        <input type="text" id="layoutversion" placeholder="{{ 'Layout Version' | translate }}" formControlName="layoutVersionField" class="form-control" required>
                        <div class="cds-form-control-error" *ngIf="checkinForm.controls['layoutVersionField'].errors && checkinForm.controls['layoutVersionField'].touched">                                
                            {{checkinForm.controls['layoutVersionField'].hasError('required') ? text_layoutVersion_required
                            : checkinForm.controls['layoutVersionField'].value === '0' ? text_layoutVersion_nonzero
                            : text_layoutVersion_invalid}}
                        </div>
<!--        <mat-form-field appearance="outline" class="wrapper-without-bottom">
                            <mat-label>{{ 'Layout Version' | translate }}</mat-label>
                            <input matInput formControlName="layoutVersionField"
                                placeholder="{{ 'Layout Version' | translate }}">
                            <mat-error>
                                {{checkinForm.controls['layoutVersionField'].hasError('required') ? text_layoutVersion_required
                                : checkinForm.controls['layoutVersionField'].value === '0' ? text_layoutVersion_nonzero
                                : text_layoutVersion_invalid}}
                            </mat-error>
                        </mat-form-field> -->
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm  btn-primary m-b-0" (click)="checkIn()">{{ 'Check In' | translate}}</button>
                <button type="button" class="btn btn-sm  btn-light m-b-0" (click)="modal.dismiss()">{{ 'Close' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<!--Close   Popup -->
<ng-template #templateClose tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>Close Proces</h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>
                <h4>{{ 'Would you like to save the process before close?' | translate }}</h4>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm  btn-primary m-b-0" [disabled]="nodeService.showAppLoader"(click)="closeProcessWithSave()">
                    {{ 'Yes' | translate }}
                </button>
                <button type="button" class="btn btn-sm  btn-light m-b-0" [disabled]="nodeService.showAppLoader" (click)="closeProcessWithoutSave()">
                    {{ 'No' | translate }}
                </button>
                <button type="button" class="btn btn-sm  btn-primary m-b-0" [disabled]="nodeService.showAppLoader" (click)="modal.dismiss()">
                    {{ 'Cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<!--Import   Popup -->
<ng-template #templateImport tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>Import</h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>
                <div class="row col-sm-12">
                    <h5> {{ 'Choose file:' | translate }} &nbsp; </h5>
                    <input #fileImport name="fileImport" type="file" (change)="fileChanged($event)" accept=".xml" />
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm  btn-primary m-b-0" [disabled]="nodeService.showAppLoader" (click)="uploadFile()">
                    {{ 'Import' | translate }}
                </button>
                <button type="button" class="btn btn-sm  btn-light m-b-0" [disabled]="nodeService.showAppLoader" (click)="modal.dismiss()">
                    {{ 'Close' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<!--Deploy   Popup -->
<ng-template #templateDeploy tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>Deploy Process</h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="deployForm" (ngSubmit)="deploy()" novalidate>
                    <appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>
                    <div class="row col-sm-12">
                        <h4>{{ 'Would you like to deploy this process?' | translate }}</h4>
                    </div>
                    <div class="row col-sm-12">
                        <label>{{ 'Environment' | translate }}</label>
                        <ng-select [items]="deployEnvArray"
                            bindLabel="id"
                            bindValue="id"
                            [multiple]="true"
                            placeholder="{{ 'Environment' | translate }}"
                            formControlName="fcEnv">
                        </ng-select>
                        <div class="cds-form-control-error" *ngIf="deployForm.controls['fcEnv'].errors && deployForm.controls['fcEnv'].touched">
                            {{'Environment value is required.' | translate }}
                        </div>

                        <!-- <mat-form-field appearance="outline">
                            <mat-label>{{ 'Environment' | translate }} </mat-label>
                            <mat-select placeholder="{{ 'Environment' | translate }}" formControlName="fcEnv" multiple>
                                <mat-option *ngFor="let deployenv of deployEnvArray" [value]="deployenv.id">
                                    {{deployenv.id}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="deployForm.controls['fcEnv'].hasError('required')">
                                {{'Environment value is required.' | translate }}
                            </mat-error>
                        </mat-form-field> -->
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-sm  btn-primary m-b-0" [disabled]="nodeService.showAppLoader">
                                {{'Deploy' | translate }}
                            </button>
                            <button type="button" class="btn btn-sm  btn-primary m-b-0" [disabled]="nodeService.showAppLoader" (click)="modal.dismiss()">
                                {{ 'Close' | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>


<!--Reassign   Popup -->
<ng-template #templateReassign tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>Reassign Process</h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>
                <label>{{ 'Reassign process to' | translate }}</label>
                <ng-select [items]="users"
                    bindLabel="loginCredentials.userId"
                    bindValue="loginCredentials.userId"
                    [(ngModel)]="selectedUser"
                    placeholder="{{ 'Assigned user' | translate }}">
                </ng-select>
            
                <!-- <mat-form-field appearance="outline" class="wrapper-without-bottom">
                    <mat-label>{{ 'Reassign process to' | translate }}</mat-label>
                    <mat-select placeholder="{{ 'Assigned user' | translate }}" [(value)]="selectedUser">
                        <mat-option *ngFor="let user of users" [value]="user">
                            {{user.loginCredentials.userId}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm  btn-primary m-b-0" [disabled]="nodeService.showAppLoader" (click)="reassign()">
                    {{'Reassign' | translate }}
                </button>
                <button type="button" class="btn btn-sm  btn-primary m-b-0" [disabled]="nodeService.showAppLoader" (click)="modal.dismiss()">
                    {{ 'Close' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>


<!--Undo Check out   Popup -->
<ng-template #undoCheckoutTemplate tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>
                <div class="row col-sm-12">
                    <h4>{{ 'Are you sure you wish to undo checkout?' | translate}}</h4>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm  btn-primary m-b-0" [disabled]="nodeService.showAppLoader"(click)="undoCheckOut()">
                    {{'Yes' | translate }}
                </button>
                <button type="button" class="btn btn-sm  btn-light m-b-0" [disabled]="nodeService.showAppLoader" (click)="decline()">
                    {{ 'No' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<!-- History Page Modal -->
<ng-template #templateHistory tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>History of process : {{nodeService.currentProcessName}}</h5>
                <button type="button" class="btn-close" (click)="unsetHistoryData()"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12">
                        <appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>
                    </div>
                    <div class="col-sm-12">
                        <div class="scroll-only-x">
                            <table aria-describedby="table-proc-history"
                                class="table" [mfData]="processHistoryData"
                                #mf="mfDataTable" [mfRowsOnPage]="15" mfSortBy="s1ProcessInfo.committedAtDisplay">
                                <thead>
                                    <tr>
                                        <th>
                                            <mfDefaultSorter by="s1ProcessInfo.nameDisplay">{{ 'Process' | translate }}
                                            </mfDefaultSorter>
                                        </th>
                                        <th>
                                            <mfDefaultSorter by="s1ProcessInfo.layoutRevision">{{ 'Layout' | translate }}
                                            </mfDefaultSorter>
                                        </th>
                                        <th>
                                            <mfDefaultSorter by="s1ProcessInfo.committedBy">{{ 'User' | translate }}
                                            </mfDefaultSorter>
                                        </th>
                                        <th>
                                            <mfDefaultSorter by="s1ProcessInfo.committedAtDisplay">{{ 'Date' | translate }}
                                            </mfDefaultSorter>
                                        </th>
                                        <th>
                                            <mfDefaultSorter by="s1ProcessInfo.comment">{{ 'Comment' | translate }}
                                            </mfDefaultSorter>
                                        </th>
                                        <th>
                                            <mfDefaultSorter by="s1ProcessInfo.revisionDeployed">
                                                {{'Deploy Status' | translate}}</mfDefaultSorter>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="processHistoryData.length > 0">
                                    <tr class="cursor" *ngFor="let value of mf.data" (click)="setHistoryData(value)"
                                        [ngStyle]="{'background-color': value.s1ProcessInfo.nameDisplay === selectedRowNameDisplay ? 'antiquewhite' : ''}">
                                        <td>{{value.s1ProcessInfo.nameDisplay}}</td>
                                        <td>{{value.s1ProcessInfo.layoutRevision}}</td>
                                        <td>{{value.s1ProcessInfo.committedBy}}</td>
                                        <td>{{value.s1ProcessInfo.committedAtDisplay}}</td>
                                        <td>{{value.s1ProcessInfo.comment}}</td>
                                        <td>{{value.s1ProcessInfo.revisionDeployed ? text_yesValue : text_noValue}}</td>
                                    </tr>
                                </tbody>
                                <tfoot *ngIf="processHistoryData.length > 15">
                                    <tr>
                                        <td colspan="10">
                                            <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                            <div class="col-sm-12 text-center"
                                *ngIf="processHistoryData.length === 0 && !nodeService.showAppLoader">
                                <h5>
                                    <mat-error>{{ 'No records found.' | translate }}</mat-error>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm  btn-primary m-b-0" [disabled]="!isEnableHistoryOpen" (click)="beforeOpenHistoryProcess()">
                    {{'Open' | translate }}
                </button>
                <button type="button" class="btn btn-sm  btn-light m-b-0" [disabled]="nodeService.showAppLoader" (click)="unsetHistoryData()">
                    {{ 'Cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>


<!--Create   Popup -->
<ng-template #templateCreate tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>New Decision Process</h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="createProcForm" (ngSubmit)="createProcess()" novalidate>
                        <div class="row">
                            <div class="col-sm-12">
                                <div>
                                    <div *ngIf="isCreateProcErr" class="alert cds-alert alert-danger" role="alert">
                                        <div class="cds-alert-icon">
                                          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                        </div>
                                        <div class="cds-alert-content" translate>{{text_createProc_fail | translate}}</div>
                                    </div>
                                    <h5>
                                        <appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>
                                    </h5>
                                </div>
                                <div class="scroll-only-x">
                                    <table aria-describedby="table-proc-create"
                                        class="table">
                                        <thead>
                                            <tr>
                                                <th>{{ 'Properties' | translate }}</th>
                                                <th>{{ 'Values' | translate }}</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="nodeService">
                                            <tr>
                                                <td>{{'Code' | translate}}<span class="text-danger">*</span></td>
                                                <td>
                                                    <input type="text" id="pname" placeholder="" formControlName="pname" maxlength="30" class="form-control" required>
                                                    <div class="cds-form-control-error" *ngIf="createProcForm.controls['pname'].errors && createProcForm.controls['pname'].touched">
                                                        {{text_createProc_name}}
                                                    </div>
                                                    <!-- <mat-form-field appearance="standard">
                                                        <input matInput formControlName="pname" maxlength="30">
                                                        <mat-error>{{text_createProc_name}}</mat-error>
                                                    </mat-form-field> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{'Notes' | translate}}</td>
                                                <td>
                                                    <input type="text" id="pcomment" placeholder="" formControlName="pcomment" maxlength="150" class="form-control">
                                                    <!-- <mat-form-field appearance="standard">
                                                        <input matInput formControlName="pcomment" maxlength="150">
                                                    </mat-form-field> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{'Version' | translate}}</td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td>{{'Layout Version' | translate}}</td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td>{{'Type' | translate}}<span class="text-danger">*</span></td>
                                                <td>
                                                    <ng-select [items]="pTypeArray"
                                                        bindLabel="viewValue"
                                                        bindValue="value"
                                                        placeholder="{{ 'Type' | translate }}"
                                                        formControlName="pprocessType">
                                                    </ng-select>
                                                    
                                                    <!-- <mat-form-field appearance="outline">
                                                        <mat-select placeholder="{{ 'Type' | translate }}"
                                                            formControlName="pprocessType">
                                                            <mat-option *ngFor="let ptype of pTypeArray" [value]="ptype.value">
                                                                {{ptype.viewValue}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{'IO Type' | translate}}<span class="text-danger">*</span></td>
                                                <td>
                                                    <ng-select [items]="pIoTypeArray"
                                                        bindLabel="viewValue"
                                                        bindValue="value"
                                                        placeholder="{{ 'IO Type' | translate }}"
                                                        (change)="setCreateProcMissingType()"
                                                        formControlName="pioType">
                                                    </ng-select>
                                                    <!-- <mat-form-field appearance="outline">
                                                        <mat-select placeholder="{{ 'IO Type' | translate }}"
                                                            formControlName="pioType"
                                                            (selectionChange)="setCreateProcMissingType()">
                                                            <mat-option *ngFor="let ptype of pIoTypeArray" [value]="ptype.value">
                                                                {{ptype.viewValue}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{'Missing' | translate}}<span class="text-danger">*</span></td>
                                                <td>
                                                    <ng-select [items]="pMissingTypeArray"
                                                        bindLabel="viewValue"
                                                        bindValue="value"
                                                        placeholder="{{ 'Type' | translate }}"
                                                        formControlName="pmissingType"
                                                        [disabled]="isCreateProcDisableMissing">
                                                    </ng-select>
                                                    <!-- <mat-form-field appearance="outline">
                                                        <mat-select placeholder="{{ 'Type' | translate }}"
                                                            formControlName="pmissingType" [disabled]="isCreateProcDisableMissing">
                                                            <mat-option *ngFor="let ptype of pMissingTypeArray"
                                                                [value]="ptype.value">
                                                                {{ptype.viewValue}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{'Shared Process' | translate}}</td>
                                                <td>
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" formControleName="pshared" (change)="setCheckbox4Shared($event)">
                                                    </div>
                                                    <!-- <mat-checkbox color="primary" formControleName="pshared"
                                                        (change)="setCheckbox4Shared($event)"></mat-checkbox> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{'Encrypted Process' | translate}}</td>
                                                <td>
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" formControleName="pencrypted" (change)="setCheckbox4Encrypted($event)">
                                                    </div>
                                                    <!-- <mat-checkbox color="primary" formControleName="pencrypted"
                                                        (change)="setCheckbox4Encrypted($event)"></mat-checkbox> -->
                                                </td>
                                            </tr>
            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-sm  btn-primary m-b-0" [disabled]="nodeService.showAppLoader || createProcForm.invalid">
                            {{'Create' | translate }}</button>
                        <button type="button" class="btn btn-sm  btn-light m-b-0" [disabled]="nodeService.showAppLoader"
                            (click)="modal.dismiss()">
                            {{ 'Cancel' | translate }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
