import { S1ProcessID } from "./s1-processid";
import { S1ProcessContainerID } from "./S1ProcessContainerID";

export class S1ProcessInfo {
    id?: S1ProcessID;
    name: string;
    guid?: string;
    lock: string;
    lockedBy?: string;
    lockedByID?: string;
    since?: Date;
    comment?: string;
    containerId: S1ProcessContainerID;
    revision: number;
    layoutRevision: number;
    updatedBy: string;
    updatedAt: Date;
    committedBy: string;
    committedAt: Date;
    deployedTimes: number;
    revisionDeployed: boolean;
    processType: string;
    ioType: string;
    clientVersion: string;
    archetype: string;
    archived?: boolean;

    //custom
    historic?: boolean;
    nameDisplay?: string;
    sinceDisplay?: string;
    updatedAtDisplay?: string;
    committedAtDisplay?: string;
}