import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { DataTableModule } from './angular2-datatable/DataTableModule';
import { ImagePipe } from './custom-pipe/image.pipe';
import { ShowValuePipe } from './custom-pipe/showValue.pipe';
import { RoleFilterPipe  } from './custom-pipe/roleFilter-pipe';
import { DataFilterPipe } from './custom-pipe/customfilter.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ExpressionFilterPipe } from './custom-pipe/expression.pipe';
import { DataInputFilterPipe } from './custom-pipe/inputfilter.pipe';
import { OperatorMappingFilter } from './custom-pipe/operatorFilter-pipe';
import { VariableExpressionFilterPipe } from './custom-pipe/variableName.pipe';
import { StatusMappingFilter } from './custom-pipe/statusFilter-pipe';
import { FileUploadModule } from 'ng2-file-upload';
import { DateFilterPipe } from './custom-pipe/dateFilter.pipe';
import { OnlynumberDirective } from './custom-directive/onlynumber.directive';
import { ExcelService } from 'app/core/services/excel.service';
import { AppStandardInfoComponent } from './app-standardinfo/appstandardinfo.component';
import { LogTracerMessageFilterPipe } from './custom-pipe/logTracerMessageFilterPipe';
import { OperatorNameMappingFilter } from './custom-pipe/operationNameFilter-pipe';
import { IOTypeValuePipe } from './custom-pipe/ioTypeFilter.pipe';
import { ProcessTypeValuePipe } from './custom-pipe/processTypeFilter.pipe';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ProcessManagementService } from 'app/core/services/process-management.service';
import { HttpLoaderFactory } from 'app/app.module';
import { AppSpinnerModule } from './app-loader/appspinner.module';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule,
  //   TranslateModule.forRoot({
  //     loader: {
  //         provide: TranslateLoader,
  //         useFactory: HttpLoaderFactory,
  //         deps: [HttpClient]
  //     }
  // }),
    //DataTableModule,
    //HttpClientModule,
    AppSpinnerModule
  ],
  exports: [
    NgbModule,
    //DataTableModule,
    FileUploadModule,
    ImagePipe,
    ShowValuePipe,
    DataFilterPipe,
    RoleFilterPipe,
    IOTypeValuePipe,
    ProcessTypeValuePipe,
    DateFilterPipe,
    DataInputFilterPipe,
    ExpressionFilterPipe,
    LogTracerMessageFilterPipe,
    VariableExpressionFilterPipe,
    StatusMappingFilter,
    OperatorMappingFilter,
    OperatorNameMappingFilter,
    AppStandardInfoComponent,
    OnlynumberDirective
  ],
  declarations: [
    ImagePipe,
    ShowValuePipe,
    DataFilterPipe,
    DataInputFilterPipe,
    RoleFilterPipe,
    ExpressionFilterPipe,
    IOTypeValuePipe,
    ProcessTypeValuePipe,
    DateFilterPipe,
    LogTracerMessageFilterPipe,
    VariableExpressionFilterPipe,
    StatusMappingFilter,
    OperatorMappingFilter,
    OperatorNameMappingFilter,
    AppStandardInfoComponent,
    OnlynumberDirective
  ],
  providers: [ExcelService, ProcessManagementService, DatePipe]
})
export class  SharedModule { }
