import { Injectable } from "@angular/core";

import { UserPermissionComponent } from "app/screens/installation-management/user-permission/user-permission.component";
import { OperationsComponent } from "app/screens/process-management/operations/operations.component";

@Injectable({ providedIn: 'root' })
export class RouteCheck {
    
    canDeactivate(component: UserPermissionComponent): boolean {
        if (OperationsComponent.url && OperationsComponent.scrollenable && OperationsComponent.isCheckProc) {
            if (confirm("Running activities will be interrupted and changes (if any) will be lost. Leave anyway?")) {
                OperationsComponent.finalElement= OperationsComponent.selectedElement;
                OperationsComponent.scrollenable = false;
                OperationsComponent.url = false;
                OperationsComponent.isCheckProc = false;
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
}