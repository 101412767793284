import { S1ProcessID } from './s1-processid';

export class S1DeployRequest {
    private processID: S1ProcessID;
    private envIds: string[];

    constructor(processId: S1ProcessID, envs: string[]) {
        this.processID = processId;
        this.envIds = envs;
    }
}