import { Component, OnInit, TemplateRef, VERSION } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { StorageDetails } from 'app/core/services/user-details.service';
import { S1PublicService } from 'app/core/services/s1public.service';
import { S1SystemInfo } from 'app/entities/s1-systeminfo';
import { S1SystemVersion } from 'app/entities/s1-systemversion';
import { S1ClientInfo } from 'app/entities/s1-clientinfo';
import { LoginResponse } from 'app/entities/loginresponse';
import { SpringErrorPayload } from 'app/entities/SpringErrorPayload';
import { S1AuthMode } from 'app/entities/s1-authmode.enum';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { S1PrivateService } from 'app/core/services/s1private.service';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
const MODAL_OPTIONS: NgbModalOptions = { size: 'lg', backdrop: 'static', keyboard: true, windowClass: 'modal-full-height modal-full-width' };


export enum BROWSERNAME {
  IE = "Internet Explorer",
  CHROME = "Google Chrome",
  FIREFOX = "Mozilla Firefox",
  EDGE = "Microsoft Edge",
  SAFARI = "Apple Safari",
  OPERA = "Opera",
  BLINK = "Blink"
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  valForm: UntypedFormGroup;
  errorMessage: string = '';
  version: string;
  isResetPassword: boolean = false;
  showAppLoader: boolean = false;
  isPlatformBrowserOk: boolean = false;
  isEnableSubmit: boolean = false;
  modalRef: NgbModalRef;
  isImgToggle: boolean = true;
  isEnableEndpoints: boolean = false;
  isShowFieldError: boolean = false;
  localS1AuthMode = S1AuthMode;
  keysAuthMode = Object.values(S1AuthMode).filter(item => typeof (item) !== "string");
  isDomainRequired: boolean;
  isError: boolean;
  authenticationMode: string = '';
  configId:string;

  constructor(private fb: UntypedFormBuilder, private userDetails: StorageDetails, private modalService: NgbModal, private s1PrivateService: S1PrivateService,
    private s1PublicService: S1PublicService, private loginService: AuthenticationService, public oidcSecurityService: OidcSecurityService, private router: Router) {
      this.resetForm();
  }

  resetForm() {
    this.valForm = this.fb.group({
      'authmode': [1, Validators.required],
      'domain': [],
      'username': ['', Validators.required],
      'password': ['', Validators.required],
      'newPassword': [],
      'confirmPassword': []
    });
  }

  ngOnInit() {
    //recover authentication mode
    this.recoverAuthenticationMode();
    //console.log("15feb Angular: " + VERSION.full);
    this.userDetails.clearStorage();
    this.showAppLoader = true;
    this.valForm.disable();
    //check platform/browser compatibility
    if (this.isPlatformBrowserValid()) {
      //set WSD server version
      this.s1PublicService.serverGetSystemVersion().subscribe({
        next: (data) => {
          if (data['versions']) {
            let resObj: S1SystemVersion = new S1SystemVersion(data['versions']);
            this.version = resObj.versions.get("WSD");
            let clientInfo: S1ClientInfo = new S1ClientInfo();
            clientInfo.clientName = "S1BAC"; //this is const
            clientInfo.clientVersion = this.version;
            this.userDetails.setClientInfo(clientInfo);
            this.valForm.enable();
            this.isEnableSubmit = true;
            this.showAppLoader = false;
          } else {
            this.errorMessage = "Unexpected http 200 response. Check console log.";
            console.log("serverGetSystemVersion = ");
            console.log(data);

            this.isEnableSubmit = false;
            this.showAppLoader = false;
          }
        },
        error: (err) => {
          console.log(err);
          this.errorMessage = err.name + ': ' + err.status + '.'
            + '\nServer communication error occurred. Please reload the page or Try again later.';
          this.isEnableSubmit = false;
          this.showAppLoader = false;
        }
      });
    }
    this.showAppLoader = false;
  }

  recoverAuthenticationMode(){
    this.s1PrivateService.getparams().subscribe(params=> {
      if(params['s1.wsd.auth.mode']){
        localStorage.setItem("AuthenticationMode", atob(params['s1.wsd.auth.mode']))
        //to menage logout
        localStorage.setItem("IdpType", atob(params['s1.oauth2.idp.type']))
      }else{
          localStorage.setItem("AuthenticationMode", 'STRATEGYONE');
      }
      this.authenticationMode =  localStorage.getItem("AuthenticationMode")
      this.checkAuthenticationMode()
    })
  }

  checkAuthenticationMode(){
      //check if user isAuthenticated / recover Bearer token and accessToken
      if(this.authenticationMode == 'OAUTH2'){
        this.oidcSecurityService.checkAuth().subscribe((loginResponse) => {
          const { isAuthenticated, userData, accessToken, idToken, configId } = loginResponse;
          this.configId = configId;
          if(isAuthenticated){
            this.showAppLoader = true;
            this.loginService.authLoginOIDC('Bearer '+accessToken, idToken, {  clientName: 'BAC', clientVersion: this.version}).subscribe(response=>{
              if(response)
              this.userDetails.setUserInfo(JSON.stringify(response.userInfo));
              this.userDetails.setSyncRoles(response.systemInfo.syncRoles);
              this.userDetails.setUserRole(response.userInfo.role);
              this.userDetails.setUserToken(accessToken);
              this.userDetails.setUserId(response.userInfo.id.userId);
              this.userDetails.setLoginUserId(response.userInfo.loginCredentials.userId);
              this.userDetails.setLanguage(response.userInfo.language);
              this.loginService.nodeServiceSet();
              this.showAppLoader = false;
            })
          }
        });
      }
  }



  isPlatformBrowserValid(): boolean {
    let BROWSERERROR: string = "Error: Unsupported Browser. (Supported browser - Google Chrome)";
    let browserName: BROWSERNAME;

    // Opera 8.0+
    let isOpera = window.navigator.userAgent.toLowerCase().indexOf('opr') > -1
      && window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1
      && window.navigator.userAgent.toLowerCase().indexOf('safari') > -1;
    if (isOpera) {
      browserName = BROWSERNAME.OPERA;
    }
    // Edge 20+
    let isEdge = window.navigator.userAgent.toLowerCase().indexOf('edge') > -1;
    if (isEdge) {
      browserName = BROWSERNAME.EDGE;
    }
    // Chrome 1+
    let isChrome = window.navigator.userAgent.toLowerCase().indexOf('safari') > -1
      && window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    if (!isOpera && !isEdge && isChrome) {
      this.isPlatformBrowserOk = true;
      browserName = BROWSERNAME.CHROME;
    }
    // Firefox 1.0+
    let isFirefox: boolean = window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if (isFirefox) {
      browserName = BROWSERNAME.FIREFOX;
    }
    // Internet Explorer 6-11
    let isIE = window.navigator.userAgent.toLowerCase().indexOf('trident') > -1;
    if (!isOpera && isIE) {
      browserName = BROWSERNAME.IE;
    }

    /* Safari 3.0+ Safari is available on Mac but not for Linux and Windows.
    let isSafari = window.navigator.userAgent.toLowerCase().indexOf('safari') > -1
    && window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1
    && !window.navigator.platform.toLowerCase().includes("win");
    if (isSafari) {
      browserName = BROWSERNAME.SAFARI;
    }*/
    if (!this.isPlatformBrowserOk) {
      this.errorMessage = BROWSERERROR;
    }
    console.log(browserName.valueOf() + " : " + window.navigator.userAgent.toLowerCase());

    return this.isPlatformBrowserOk;
  }

  loginOIDC(){
    const redirectUrl = window.location.origin;
    this.oidcSecurityService.authorize(this.configId, {redirectUrl: redirectUrl })
  }

  login() {
      this.errorMessage = '';
      if (this.valForm.invalid ||
        (this.valForm.controls['authmode'].value === 0
          && (this.valForm.value.domain === null || (String(this.valForm.value.domain)).trim().length === 0))
      ) {
        this.errorMessage = 'Please fill all the fields.';
        this.isShowFieldError = true;
      } else {
        this.isShowFieldError = false;
        if (this.isResetPassword) {
          if (this.valForm.controls['newPassword'].value === this.valForm.controls['confirmPassword'].value) {
            this.authLoginUser();
          } else {
            this.errorMessage = "The new password and confirmation password do not match.";
          }
        } else {
          this.authLoginUser();
        }
      }
  }

  private authLoginUser() {
    this.showAppLoader = true;
    this.isError = false;
    this.loginService.authLogin(this.valForm).subscribe({
      next: async (resObject) => {
        if (resObject && resObject['userInfo'] && resObject['systemInfo'] && resObject['authorization']) {
          let loginResp: LoginResponse = resObject;
          //license
          if (!loginResp.systemInfo.bacEnabled) {
            this.isError = true;
            this.errorMessage = "BAC is not enabled in S1 license.";
            return;
          }
          //storage
          this.userDetails.setName(loginResp.userInfo.name);
          this.userDetails.setSurname(loginResp.userInfo.surname);
          this.userDetails.setUserInfo(JSON.stringify(loginResp.userInfo));
          this.userDetails.setUserRole(loginResp.userInfo.role);
          this.userDetails.setUserToken(loginResp.authorization);
          this.userDetails.setUserId(loginResp.userInfo.id.userId);
          this.userDetails.setLoginUserId(loginResp.userInfo.loginCredentials.userId);
          this.userDetails.setLanguage(loginResp.userInfo.language);
          //check multi-tenant condition
          await this.checkMultiTenantCondition().then(_value => {
            //even when isError is true the valForm doesnt disable. ignored.
          }).catch(_error => {
            this.isError = true;
          });

          //check if compression mode enabled
          if(loginResp.systemInfo.defaultBufferContentType !== "Plain") {
            this.isError = true;
            this.errorMessage = "BAC is not available in compression mode.";
          }
          if (this.isError) {
            this.userDetails.clearStorage();
            this.showAppLoader = false;
            this.valForm.disable();
            return;
          }
          this.showAppLoader = false;
          this.isEnableSubmit = false;
          this.loginService.nodeServiceSet();
        } else {
          this.errorMessage = "Unexpected http 200 response. Check console log.";
          console.log("authLogin = ");
          console.log(resObject);
          this.showAppLoader = false;
          this.isEnableSubmit = false;
        }
      },

      error: (err) => {
        console.log(err);

        if (err['error'] && err['error']['code'] && err['error']['exception']) {
          let resultObj: SpringErrorPayload = err['error'];
          if (resultObj.code === 'PASSWORD_EXPIRED' || resultObj.code === 'WRONG_PASSWORD_POLICIES') {
            this.isResetPassword = true;
            this.errorMessage = resultObj.code === 'WRONG_PASSWORD_POLICIES' ? "The password does not meet the password policy requirements." : 'Password expired. Please reset the password.';
            this.valForm.controls['newPassword'].setValue('');
            this.valForm.controls['confirmPassword'].setValue('');
          } else if (resultObj.code === 'LICENSE_NOT_AUTHORIZED') {
            this.isResetPassword = false;
            this.errorMessage = 'License not active!';
          } else if (resultObj.code === 'LICENSE_ERROR') {
            this.isResetPassword = false;
            this.errorMessage = 'License error!';
          } else {
            this.isResetPassword = false;
            this.errorMessage = 'User not recognized!';
          }
          this.showAppLoader = false;
          this.isEnableSubmit = true;
        } else if (err.status === 400 || err.status === 401) {
          this.isResetPassword = false;
          this.errorMessage = 'User not recognized!';
        } else {
          this.errorMessage = err.name + ': ' + err.status + '.'
            + '\nServer communication error occurred. Please reload the page or Try again later.';
          this.isEnableSubmit = false;
        }
        this.showAppLoader = false;
      }
    });

  }
  checkMultiTenantCondition(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loginService.getSystemInfo().subscribe({
        next: (innerResObject) => {
          if (innerResObject && innerResObject['customerId']) {
            let sysInfo: S1SystemInfo = innerResObject;
            if (sysInfo.multiTenantEnabled) {
              this.isError = true;
              this.errorMessage = "BAC is not available with multi tenant installation.";
            }
          } else {
            this.errorMessage = "Unexpected http 200 response. Check console log.";
            console.log("getSystemInfo = ");
            console.log(innerResObject);
            this.isError = true;
          }
          resolve(true);
        },
        error: (err) => {
          //todo err cant be printed.
          //log(err) gives TypeError: You provided 'undefined' where a stream was expected.
          //flow comes here correctly though.
          //troubleshoot - same issue - tried with serverGetSystemVersion() instead of getSystemInfo()
          //troubleshoot - no issue - if created same promise obj around serverGetSystemVersion() without async
          //troubleshoot - seems issue with async + promise combo
          reject(err);
        }
      });
    });
  }

  getOpenPage(templateInfoPage: TemplateRef<any>) {
    if (this.showAppLoader) return;
    this.modalRef = this.modalService.open(templateInfoPage,MODAL_OPTIONS)
  }

  cancelWindow() {
    this.modalRef.close();
    this.showAppLoader = false;
    this.errorMessage = '';
  }

  clearFormErr() {
    this.errorMessage = '';
    this.isShowFieldError = false;
  }

}
