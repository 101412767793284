<!-- <nav class="sidebar" [ngClass]="{sidebarPushRight: isActive, collapsed: collapsed}">
    <div class="list-group">
        <ng-template ngFor let-asideItems [ngForOf]="menuItems.getAll()">
            <div class="nested-menu" *ngFor="let asideItem of asideItems.main">
                <a class="list-group-item" (click)="addExpandClass(asideItem.name)" *ngIf="asideItem.name">
                    <img src="assets/img/{{ asideItem.icon }}" alt="asideItem">&nbsp;
                    <span>{{asideItem.name | translate}}</span>
                </a>
                <ul class="list-group">
                    <li class="nested" [class.expand]="getVariable() === asideItem.name">
                        <ul class="submenu">
                            <li *ngFor="let asideChildren of asideItem.children">
                                <a class="underline" [routerLinkActive]="['router-link-active']"
                                    (click)="addExpandSubClass(asideChildren.name, asideChildren.state)"
                                    [routerLink]="[asideChildren.state]"
                                    *ngIf="asideChildren.name && authorizationService.hasPermission(asideChildren.expectedRole)">
                                    <img src="assets/img/{{ asideChildren.icon }}" alt="asideChildren">&nbsp;
                                    <span>{{ asideChildren.name | translate }}</span>
                                </a>
                                <div [ngClass]="nodeService && nodeService.showAppLoader ? 'disabled' : 'active'">
                                    <div class="nested-menu" *ngIf="asideChildren"
                                        [class.expand]="showSubMenu === asideChildren.name">
                            <li class="nested" [class.expand]="showSubMenu === asideChildren.name">
                                <ul class="submenu">
                                    <li *ngFor="let asideSubChildren of asideChildren.children">
                                        <a class="underline"
                                            [ngClass]="{ 'router-link-active' : nodeService.sidebarProcName == asideSubChildren.name }"
                                            (click)="addExpandOfSubClass(asideSubChildren.name,asideSubChildren.state)"
                                            [routerLink]="[asideSubChildren.state]"
                                            *ngIf="asideSubChildren.name && authorizationService.hasPermission(asideSubChildren.expectedRole)"
                                            title="{{asideSubChildren.name}}">
                                            <img src="{{getLogoAsPerType(asideSubChildren)}}"
                                                alt="asideSubChildren">&nbsp;
                                            <span>{{ asideSubChildren.name | translate }}</span>
                                        </a>
                                        <div class="nested-menu" *ngIf="asideSubChildren"
                                            [class.expand]="showSubOfMenu === asideSubChildren.name">
                                    <li class="nested">
                                        <ul class="submenu">
                                            <li *ngFor="let asideSubOfChildren of asideSubChildren.children">
                                                <a class="underline" [routerLinkActive]="['router-link-active']"
                                                    [routerLink]="[asideSubOfChildren.state]"
                                                    (click)="addScrollEnable(asideSubChildren.state)"
                                                    *ngIf="asideSubOfChildren.name && authorizationService.hasPermission(asideSubOfChildren.expectedRole)">
                                                    <img src="assets/img/{{ asideSubOfChildren.icon }}"
                                                        alt="asideSubOfChildren">&nbsp;
                                                    <span>{{ asideSubOfChildren.name | translate }}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
            </div>
            </li>
            </ul>
            </li>
    </div>
    </div>
    </li>
    </ul>
    </li>
    </ul>
    </div>
    </ng-template>
    </div>
</nav> -->

<!-- <nav class="sidebar" [ngClass]="{sidebarPushRight: isActive, collapsed: collapsed}">
    <div class="list-group">
        <ng-template ngFor let-asideItems [ngForOf]="menuItems.getAll()">
            <div class="nested-menu" *ngFor="let asideItem of asideItems.main">
                <a class="list-group-item" (click)="addExpandClass(asideItem.name)" *ngIf="asideItem.name">
                    <img src="assets/img/{{ asideItem.icon }}" alt="asideItem">&nbsp;
                    <span>{{asideItem.name | translate}}</span>
                </a>
                <ul class="list-group">
                    <li class="nested" [class.expand]="getVariable() === asideItem.name">
                        <ul class="submenu">
                            <li *ngFor="let asideChildren of asideItem.children">
                                <a class="underline" [routerLinkActive]="['router-link-active']"
                                    (click)="addExpandSubClass(asideChildren.name, asideChildren.state)"
                                    [routerLink]="[asideChildren.state]"
                                    *ngIf="asideChildren.name && authorizationService.hasPermission(asideChildren.expectedRole)">
                                    <img src="assets/img/{{ asideChildren.icon }}" alt="asideChildren">&nbsp;
                                    <span>{{ asideChildren.name | translate }}</span>
                                </a>
                                <div [ngClass]="nodeService && nodeService.showAppLoader ? 'disabled' : 'active'">
                                    <div class="nested-menu" *ngIf="asideChildren"
                                        [class.expand]="showSubMenu === asideChildren.name">
                                        <li class="nested" [class.expand]="showSubMenu === asideChildren.name">
                                            <ul class="submenu">
                                                <li *ngFor="let asideSubChildren of asideChildren.children">
                                                    <a class="underline"
                                                        [ngClass]="{ 'router-link-active' : nodeService.sidebarProcName == asideSubChildren.name }"
                                                        (click)="addExpandOfSubClass(asideSubChildren.name,asideSubChildren.state)"
                                                        [routerLink]="[asideSubChildren.state]"
                                                        *ngIf="asideSubChildren.name && authorizationService.hasPermission(asideSubChildren.expectedRole)"
                                                        title="{{asideSubChildren.name}}">
                                                        <img src="{{getLogoAsPerType(asideSubChildren)}}"
                                                            alt="asideSubChildren">&nbsp;
                                                        <span>{{ asideSubChildren.name | translate }}</span>
                                                    </a>
                                                    <div class="nested-menu" *ngIf="asideSubChildren" [class.expand]="showSubOfMenu === asideSubChildren.name">
                                                        <li class="nested">
                                                            <ul class="submenu">
                                                                <li *ngFor="let asideSubOfChildren of asideSubChildren.children">
                                                                    <a class="underline" [routerLinkActive]="['router-link-active']"
                                                                        [routerLink]="[asideSubOfChildren.state]"
                                                                        (click)="addScrollEnable(asideSubChildren.state)"
                                                                        *ngIf="asideSubOfChildren.name && authorizationService.hasPermission(asideSubOfChildren.expectedRole)">
                                                                        <img src="assets/img/{{ asideSubOfChildren.icon }}"
                                                                            alt="asideSubOfChildren">&nbsp;
                                                                        <span>{{ asideSubOfChildren.name | translate }}</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </ng-template>
    </div>
</nav> -->

<div class="cds-sidebar-menu">
    <div class="cds-sidebar-menu-item">
        <button type="button" data-bs-toggle="collapse" 
            data-bs-target="#collapseSample" aria-expanded="false" 
            aria-controls="collapseSample" class="cds-sidebar-menu-item-toggle collapsed" (click)="processMContainerIsCollapsed = !processMContainerIsCollapsed">
            <i class="fa fa-caret-right" *ngIf="!processMContainerIsCollapsed"></i>
            <i class="fa fa-caret-up" *ngIf="processMContainerIsCollapsed"></i>
        </button>
        <div class="cds-sidebar-menu-item-icon">
            <img class="mr-2" src="assets/img/icon-coloured-world.svg">
        </div>
        <div class="cds-sidebar-menu-item-text" translate>Process Management</div>
    </div>
    <div id="collapseSample" #myDiv class="collapse">
        <a [routerLink]="['/process-management/process-list']" class="cds-sidebar-menu-item"
            [ngClass]="{active: checkIfThisRouteIsActive('/process-management/process-list')}">
            <div class="cds-sidebar-menu-item-spacer"></div>
            <div class="cds-sidebar-menu-item-icon">
                <img class="mr-2" src="assets/img/icon-coloured-users.svg">
            </div>
            <div class="cds-sidebar-menu-item-text" translate>My Processes</div>
        </a>
        <div *ngFor="let process of getCurrentProcessArray(); let i = index" >
            <a class="cds-sidebar-menu-item submenu" data-bs-toggle="collapse" href="#collapseExample{{i}}" role="button" 
            aria-expanded="false" aria-controls="collapseExample{{i}}" (click)="navigateToProcess(process)"
            [ngClass]="{active: !checkIfThisRouteIsActive('/process-management/process-list')  && !checkIfThisRouteIsActive('installation-management') && (getCurrentProcessName() == process.s1ProcessInfo.nameDisplay )}" translate>
                <div class="cds-sidebar-menu-item-spacer"></div>
                <div class="cds-sidebar-menu-item-icon">
                    <img class="mr-2" src="{{getImageAgainstProcessStatus(process.s1ProcessInfo)}}">
                </div>
                <div class="cds-sidebar-menu-item-text">{{process.s1ProcessInfo.nameDisplay}}</div>
            </a>
            <div class="collapse" id="collapseExample{{i}}">
                <a [routerLink]="['/process-management/operation']" class="cds-sidebar-menu-item submenu"
                    [ngClass]="{'active submenu-element': checkIfThisRouteIsActive('/process-management/operation')}">
                    <div class="cds-sidebar-menu-item-spacer"></div>
                    <div class="cds-sidebar-menu-item-spacer"></div>
                    <div class="cds-sidebar-menu-item-icon">
                        <img class="mr-2" src="assets/img/icon-coloured-operations.svg">
                    </div>
                    <div class="cds-sidebar-menu-item-text" translate>Operations</div>
                </a>
                <a [routerLink]="['/process-management/liabraries']" class="cds-sidebar-menu-item submenu"
                    [ngClass]="{'active submenu-element': checkIfThisRouteIsActive('/process-management/liabraries')}">
                    <div class="cds-sidebar-menu-item-spacer"></div>
                    <div class="cds-sidebar-menu-item-spacer"></div>
                    <div class="cds-sidebar-menu-item-icon">
                        <img class="mr-2" src="assets/img/icon-coloured-library.svg">
                    </div>
                    <div class="cds-sidebar-menu-item-text" translate>Libraries</div>
                </a>
                <a [routerLink]="['/process-management/tables']" class="cds-sidebar-menu-item submenu"
                    [ngClass]="{'active submenu-element': checkIfThisRouteIsActive('/process-management/tables')}">
                    <div class="cds-sidebar-menu-item-spacer"></div>
                    <div class="cds-sidebar-menu-item-spacer"></div>
                    <div class="cds-sidebar-menu-item-icon">
                        <img class="mr-2" src="assets/img/icon-coloured-tables.svg">
                    </div>
                    <div class="cds-sidebar-menu-item-text" translate>Tables</div>
                </a>
                <a [routerLink]="['/process-management/campaign']" class="cds-sidebar-menu-item submenu"
                    [ngClass]="{'active submenu-element': checkIfThisRouteIsActive('/process-management/campaign')}">
                    <div class="cds-sidebar-menu-item-spacer"></div>
                    <div class="cds-sidebar-menu-item-spacer"></div>
                    <div class="cds-sidebar-menu-item-icon">
                        <img class="mr-2" src="assets/img/icon-coloured-campaign.svg">
                    </div>
                    <div class="cds-sidebar-menu-item-text" translate>Campaign</div>
                </a>
                <a [routerLink]="['/process-management/pmml']" class="cds-sidebar-menu-item submenu"
                    [ngClass]="{'active submenu-element': checkIfThisRouteIsActive('/process-management/pmml')}">
                    <div class="cds-sidebar-menu-item-spacer"></div>
                    <div class="cds-sidebar-menu-item-spacer"></div>
                    <div class="cds-sidebar-menu-item-icon">
                        <img class="mr-2" src="assets/img/icon-coloured-pmml-models.svg">
                    </div>
                    <div class="cds-sidebar-menu-item-text" translate>PMML Models</div>
                </a>
                <a [routerLink]="['/process-management/python']" class="cds-sidebar-menu-item submenu"
                    [ngClass]="{'active submenu-element': checkIfThisRouteIsActive('/process-management/python')}">
                    <div class="cds-sidebar-menu-item-spacer"></div>
                    <div class="cds-sidebar-menu-item-spacer"></div>
                    <div class="cds-sidebar-menu-item-icon">
                        <img class="mr-2" src="assets/img/icon-coloured-python.svg">
                    </div>
                    <div class="cds-sidebar-menu-item-text" translate>Python Models</div>
                </a>
                <a [routerLink]="['/process-management/whatif']" class="cds-sidebar-menu-item submenu"
                    [ngClass]="{'active submenu-element': checkIfThisRouteIsActive('/process-management/whatif')}">
                    <div class="cds-sidebar-menu-item-spacer"></div>
                    <div class="cds-sidebar-menu-item-spacer"></div>
                    <div class="cds-sidebar-menu-item-icon">
                        <img class="mr-2" src="assets/img/icon-coloured-whatif.svg">
                    </div>
                    <div class="cds-sidebar-menu-item-text" translate>Whatif</div>
                </a>
            </div>
        </div>
    </div>
    <div class="cds-sidebar-menu-item">
        <button type="button" data-bs-toggle="collapse" 
            data-bs-target="#collapseSample2" aria-expanded="false" 
            aria-controls="collapseSample2" class="cds-sidebar-menu-item-toggle collapsed" (click)="installationMContainerIsCollapsed = !installationMContainerIsCollapsed">
            <i class="fa fa-caret-right" *ngIf="!installationMContainerIsCollapsed"></i>
            <i class="fa fa-caret-up" *ngIf="installationMContainerIsCollapsed"></i>
        </button>
        <div class="cds-sidebar-menu-item-icon">
            <img class="mr-2" src="assets/img/icon-coloured-skyscrapers.svg">
        </div>
        <div class="cds-sidebar-menu-item-text" translate>Installation Management</div>
    </div>
    <div id="collapseSample2" class="collapse">
        <a [routerLink]="['/installation-management/user-creation']" class="cds-sidebar-menu-item"
            [ngClass]="{active: checkIfThisRouteIsActive('/installation-management/user-creation')}"
            *ngIf="authorizationService.hasPermission('SYSTEM_ADMIN,MANAGER,SECURITY_ADMIN')">
            <div class="cds-sidebar-menu-item-spacer"></div>
            <div class="cds-sidebar-menu-item-icon">
                <img class="mr-2" src="assets/img/icon-coloured-users.svg">
            </div>
            <div class="cds-sidebar-menu-item-text" translate>User Management</div>
        </a>
        <a [routerLink]="['/installation-management/user-permission']" class="cds-sidebar-menu-item"
            [ngClass]="{active: checkIfThisRouteIsActive('/installation-management/user-permission')}"
            *ngIf="authorizationService.hasPermission('SYSTEM_ADMIN,MANAGER,SECURITY_ADMIN')">
            <div class="cds-sidebar-menu-item-spacer"></div>
            <div class="cds-sidebar-menu-item-icon">
                <img class="mr-2" src="assets/img/icon-coloured-permission.svg">
            </div>
            <div class="cds-sidebar-menu-item-text" translate>User Permission</div>
        </a>
        <a [routerLink]="['/installation-management/log-tracer']" class="cds-sidebar-menu-item"
            [ngClass]="{active: checkIfThisRouteIsActive('/installation-management/log-tracer')}"
            *ngIf="authorizationService.hasPermission('USER,SYSTEM_ADMIN,MANAGER,SECURITY_ADMIN')">
            <div class="cds-sidebar-menu-item-spacer"></div>
            <div class="cds-sidebar-menu-item-icon">
                <img class="mr-2" src="assets/img/icon-coloured-logtracer.svg">
            </div>
            <div class="cds-sidebar-menu-item-text" translate>Log Tracer</div>
        </a>
        <a [routerLink]="['/installation-management/python-management']" class="cds-sidebar-menu-item"
            [ngClass]="{active: checkIfThisRouteIsActive('/installation-management/python-management')}"
            *ngIf="authorizationService.hasPermission('SYSTEM_ADMIN,SECURITY_ADMIN')">
            <div class="cds-sidebar-menu-item-spacer"></div>
            <div class="cds-sidebar-menu-item-icon">
                <img class="mr-2" src="assets/img/icon-coloured-python.svg">
            </div>
            <div class="cds-sidebar-menu-item-text" translate>Python Management</div>
        </a>
    </div>
</div> 

