import { UsageType } from "./bac-usagetype.enum";
import { DataType } from "./bac-datatype.enum";
import { PmmlOpType } from "./bac-pmmloptype.enum";
import { PmmlSourceType } from "./bac-pmmlsourcetype.enum";

export class PmmlDataField{
    variableRefId?:string;
    name:string;
    usage:UsageType;
    dataType:DataType;
    opType:PmmlOpType;
    source:PmmlSourceType;
    //initialize required variables to default values
    constructor() {
        this.name="";
        this.usage=UsageType.NULL_VALUE;
        this.dataType=DataType.NULL_VALUE;  
        this.opType=PmmlOpType.NULL_VALUE; 
        this.source =PmmlSourceType.NULL_VALUE;
    }
}