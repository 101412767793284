import { Component, OnInit, Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserName, UsersCombobox } from "app/entities/username";
import { S1LogQueryType } from "app/entities/s1-logquerytype.enum";
import { LogTracerService } from "app/core/services/log-tracer.service";
import { ProcessNames } from "app/entities/processname";
import { StorageDetails } from 'app/core/services/user-details.service';
import { LogTracerDescription } from 'app/entities/logtracer.enum';
import { S1LogTracer } from 'app/entities/logtracer';
import { UserManagementService } from 'app/core/services/user-management.service';
import { S1EntityManagementService } from 'app/core/services/s1entity-management.service';
import { DatePipe, formatDate } from '@angular/common';
//import 'sweetalert2/src/sweetalert2.scss';
import { TranslateService } from '@ngx-translate/core';
//import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { S1UserInfo } from 'app/entities/s1-userInfo';
import { S1UserRole } from 'app/entities/s1-userrole.enum';
import { S1ProcessInfo } from 'app/entities/s1-processInfo';
import { TreeObject } from 'app/entities/treeNode.model';
import { LogTracerMessageFilterPipe } from 'app/shared/custom-pipe/logTracerMessageFilterPipe';
import { S1InstituteInfo } from 'app/entities/s1-instituteInfo';
import { S1InstallationInfo } from 'app/entities/s1-installationInfo';
import { NgbDateStruct, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ProcessManagementService } from 'app/core/services/process-management.service';
import {S1InstallationFull} from "../../../entities/s1-installationFull";
import * as XLSX from "xlsx";

export const PICK_FORMATS = {
    parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' }
    }
};

// @Injectable()
// export class PickDateAdapter extends NativeDateAdapter {
//     format(date: Date, displayFormat: Object): string {
//         if (displayFormat === 'input') {
//             return formatDate(date, 'yyyy-MM-dd', this.locale);
//         } else {
//             return date.toDateString();
//         }
//     }
// }

@Component({
    selector: 'app-log-tracer',
    templateUrl: './log-tracer.component.html',
    styleUrls: ['./log-tracer.component.scss'],
    // providers: [
    //     { provide: DateAdapter, useClass: PickDateAdapter },
    //     { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }
    // ]
})
export class LogTracerComponent implements OnInit {
  date: { year: number; month: number }
    data: any;
    userForm: UntypedFormGroup;
    operationType: any;
    processDetails: ProcessNames[] = [];
    flag: string;
    processData: any = [];
    results: any;
    userId: any = [];
    process: any = [];
    months: any = { "JAN": 1, "FEB": 2, "MAR": 3, "APR": 4, "MAY": 5, "JUN": 6, "JUL": 7, "AUG": 8, "SEP": 9, "OCT": 10, "NOV": 11, "DEC": 12 };
    logsData: any;
    userBackEndForm: UntypedFormGroup;
    public logDesc: Array<S1LogTracer> = [];
    msg: LogTracerDescription;
    isValidDate: boolean;
    errorMessage: string;
    error: boolean = false;
    isEnabledClearBtn: boolean = false;
    selected: any = { firstName: '', lastName: '', userIdDb: 0, userIdString: '', viewValue: 'All' }; //for drop-down mat-select-trigger
    users: UsersCombobox[] = [];
    isPageLoad: boolean;
    searchText: string = "";
    showAppLoader: boolean = false;
    calculatedInstituteCount: number = 0;

    //log-tracer values
    userVal: string;
    modifiedVal: string;
    forVal: string;
    reassignProcVal: string;
    checkInVal: string;
    commentVal: string;
    permissionVal: string;
    grantedVal: string;
    revokedVal: string;
    toVal: string;
    statusVal: string;
    errorVal: string;
    text_invalidDateRange: string;

    //selected.viewValue
  private violationLog: any[];
  private administrationLog: any[];
  logDaStampareProva= [];
  logDaStampareViolation= [];
  logDaStampareAdministration= [];
  private usersList: any;

  constructor(private userService: UserManagementService, private fb: UntypedFormBuilder,
        private logTracerService: LogTracerService, private userDetail: StorageDetails
        , private entityManagementService: S1EntityManagementService, private calendar: NgbCalendar, private parserFormatter: NgbDateParserFormatter
        , private datePipe: DatePipe, private translate: TranslateService, private processManagementService: ProcessManagementService, private loggedUserInfo: StorageDetails) {

        let role = this.userDetail.getUserRole();
        this.operationType = (role === 'SYSTEM_ADMIN' || role === 'MANAGER') ?
            [{ value: S1LogQueryType[S1LogQueryType.NONE], viewValue: "All" }, { value: S1LogQueryType[S1LogQueryType.REPOSITORY], viewValue: "REPOSITORY" }, { value: S1LogQueryType[S1LogQueryType.AUTHORIZATION], viewValue: "AUTHORIZATION" }, { value: S1LogQueryType[S1LogQueryType.TESTING], viewValue: "TESTING" }, { value: S1LogQueryType[S1LogQueryType.DESIGNER], viewValue: "DESIGNER" }]
            : [{ value: S1LogQueryType[S1LogQueryType.REPOSITORY], viewValue: "REPOSITORY" }];

        this.users.push({
            viewValue: 'All',
            value: 'All',
            userID: 0
        })
        this.isPageLoad = true;

        translate.get([
            'User'
            , 'modified'
            , 'For'
            , 'Reassigned process from user'
            , 'Check-in process, version'
            , 'comment', 'Permission changed, profile'
            , 'granted to user'
            , "revoked from user"
            , 'to'
            , "Status"
            , "ERROR"
            , 'INVALID DATE RANGE : From date should not be greater than to date.']).subscribe((text: any) => {
                this.userVal = text['User'];
                this.modifiedVal = text['modified'];
                this.forVal = text['For'];
                this.reassignProcVal = text['Reassigned process from user'];
                this.checkInVal = text['Check-in process, version'];
                this.commentVal = text['comment'];
                this.permissionVal = text['Permission changed, profile'];
                this.grantedVal = text['granted to user'];
                this.revokedVal = text["revoked from user"];
                this.toVal = text['to'];
                this.statusVal = text["Status"];
                this.errorVal = text["ERROR"];
                this.text_invalidDateRange = text["INVALID DATE RANGE : From date should not be greater than to date."];
            });
    }

    ngOnInit() {
        if (this.userDetail.getUserRole() !== "USER") { //fetches all procs for manager too.
            this.processDetails.push({
                name: 'All',
                processId: 0,
                versionId: 0,
                userIdDb: 0
            });
            this.clear();
            this.fetchAllUser();
        }
        else{
          let userInfo: S1UserInfo = JSON.parse(this.userDetail.getUserInfo());
          this.users = [];

          this.users.push({
            viewValue: userInfo.name + ' ' + userInfo.surname + '(' + userInfo.loginCredentials.userId + ')',
            value: userInfo.name + ' ' + userInfo.surname + '(' + userInfo.loginCredentials.userId + ')',
            userID: userInfo.id.userId
          })
          this.selected = this.users[0].viewValue; //for drop-down mat-select-trigger
          this.clear();
        }

      //only processes with permissions
      this.entityManagementService.getProcessTypeList().subscribe({
        next: response => {
          let s1InstallationFull: S1InstallationFull = response;
          let processList = this.traverseInstallation(s1InstallationFull);
          this.setProcessObject(processList);
          this.showAppLoader = false;
          this.clear();
          if (this.userDetail.getUserRole() == "USER")
            this.userForm.disable();
        }, error: err => {
          console.log(err);
          this.showAppLoader = false;
        }
      });
    }

  private traverseInstallation(installation: S1InstallationFull, processList?: S1ProcessInfo[]) {
    if(!processList){
      processList = [];
    }
    processList = processList.concat(installation.processes);
    installation.groups.forEach(group =>{
      processList = processList.concat(group.processes);
      group.institues.forEach(institute => processList = processList.concat(institute.processes))
    } )
    return processList;
  }
    fetchAllUser() {
        this.showAppLoader = true;
        this.userForm.disable();
        this.userService.authGetAllUsers().subscribe({
            next: data => {
                if (data && data['s1UserInfo'])
                    data['s1UserInfo'].forEach(element => {
                        this.users.push({
                            viewValue: element['name'] + ' ' + element['surname'] + '(' + element['loginCredentials']['userId'] + ')',
                            value: element['name'] + ' ' + element['surname'] + '(' + element['loginCredentials']['userId'] + ')',
                            userID: Number(element['id']['userId'])
                        })
                    });
                this.usersList = data['s1UserInfo'];
                this.setUserId('', this.users);

            }, error: err => {
                console.log(err);
            }
        });
    }

    stringToDate(_date, _format, _delimiter) {
        var formatLowerCase = _format.toLowerCase();
        var formatItems = formatLowerCase.split(_delimiter);
        var dateItems = _date.split(_delimiter);
        var monthIndex = formatItems.indexOf("mm");
        var dayIndex = formatItems.indexOf("dd");
        var yearIndex = formatItems.indexOf("yyyy");
        var month = parseInt(dateItems[monthIndex]);
        month -= 1;
        return new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
    }

    validateDates(sDate: string, eDate: string) {
        this.isValidDate = true;
        if (this.stringToDate(eDate, "dd-MM-yyyy", "-") < this.stringToDate(sDate, "dd-MM-yyyy", "-")) {
            this.error = true;
            this.errorMessage = this.text_invalidDateRange;
            this.isValidDate = false;
            this.logDesc = [];
            this.isEnabledClearBtn = true;
        }
        return this.isValidDate;
    }

    search() {
        this.logDaStampareViolation = [];
        this.logDaStampareProva = [];
        this.logDaStampareAdministration = [];
        this.isPageLoad = false;
        let endDate =  this.parserFormatter.format(this.userForm.controls['toDate'].value);
        let startDate = this.parserFormatter.format(this.userForm.controls['fromDate'].value);
        let modelStartDate = this.datePipe.transform(startDate, "yyyy-MM-ddT00:00:00.000");
        let modelEndDate = this.datePipe.transform(endDate, "yyyy-MM-ddT23:59:59.999");
        let isValidDate = this.validateDates(modelStartDate, modelEndDate);
        if (isValidDate) {
            this.errorMessage = '';
            this.userBackEndForm = this.fb.group({
                "processes": [],
                "type": this.userForm.controls['type'].value,
                "name": [],
                "fromDate": modelStartDate,
                "toDate": modelEndDate
            });

            this.userBackEndForm.controls["processes"].setValue(this.process);
            this.userBackEndForm.controls["name"].setValue(this.userId);

            this.getLogs();
            this.getAdministrationLogs();
            this.getViolationLogs();

        }
    }


    private getAdministrationLogs() {
      this.showAppLoader = true;
      this.userForm.disable();
      let oldSearchText = this.searchText;
      this.searchText = '';
      this.administrationLog = [];
      this.logTracerService.docGetLogReport(this.userBackEndForm).subscribe({
        next: response => {
          if (response) {
            this.isPageLoad = true;
            response.forEach(element => {

              if(element.operation === 'USER_EDIT'||element.operation === 'USER_CREATE'||element.operation === 'PERMISSION_EDIT'){
               let user =  this.getUserById(element['user']);
                  const newLog = this.createAdministrationLog(
                  element['user'],
                    user.name,
                    user.surname,
                  element['clientIP'],
                  this.datePipe.transform(new Date(element['time']),'yyyy-MM-dd HH:mm:ss'),
                  this.setAffectedUser(element),
                  this._getLogs_setLogMsg(element),
                  this.setLogMsgOld(element),
                  this.setLogMsg(element)
                  )
                  this.logDaStampareAdministration.push(newLog);

              }

            });

          } else {
            this.error = true;
            this.errorMessage = response['error']['reason'];
          }
          this.searchText = oldSearchText;
          this.showAppLoader = false;
          this.enableForm();
        }, error: err => {
          console.log(err);
          this.error = true;
          this.errorMessage = err['error']['reason'];
          this.searchText = oldSearchText;
          this.showAppLoader = false;
          this.userForm.enable();
        }
      });
    }

    private getLogs() {
        this.showAppLoader = true;
        this.userForm.disable();
        let oldSearchText = this.searchText;
        this.searchText = '';
        this.logDesc = [];
        console.log('CHIAMTA -',this.userBackEndForm );
        this.logTracerService.docGetLogReport(this.userBackEndForm).subscribe({
            next: response => {
              console.log('RESPONSE -',response );

              if (response) {
                    this.isPageLoad = true;
                    response.forEach(element => {
                     this.logDesc.push({
                       logType: element['type'],
                       logOperation: element['operation'],
                       logProcess: element['process'],
                       logDate: element['time'],
                       logUser: element['user'],
                       logMessage: this._getLogs_setLogMsg(element)
                     });
                    });
                    this.logDesc.forEach(element => {
                        let tempdate: Date = new Date(element.logDate);
                        element.logDateDisplay = this.datePipe.transform(tempdate, 'yyyy-MM-dd HH:mm:ss');
                    });

                    if (this.logDesc.length > 0) {
                        this.isEnabledClearBtn = true;
                    }
                } else {
                    this.error = true;
                    this.errorMessage = response['error']['reason'];
                }
                this.searchText = oldSearchText;
                this.showAppLoader = false;
                this.enableForm();
            }, error: err => {
                console.log(err);
                this.error = true;
                this.errorMessage = err['error']['reason'];
                this.searchText = oldSearchText;
                this.showAppLoader = false;
                this.userForm.enable();
            }
        });
    }

  private enableForm() {
    if (this.userDetail.getUserRole() !== "USER") this.userForm.enable();
  }
  private setAffectedUser(element): string {
    let user: string;
    let s1LogProperty: { name: string, value: string }[] = [];
    s1LogProperty = element['properties'];
    if (s1LogProperty !== null) {
      s1LogProperty.forEach(prop => {
         if(prop.name === 'USER' ) {
           user = prop.value
        }

      });
    }
   return user
  }
  //private setLogMsgOld(element): string {
 //  let logMsg: string = '';
 //  let user: string = '';
 //  let staffId: string = '';
 //  let email: string = '';
 //  let first_Name: string;
 //  let last_Name: string = '';
 //  let organization: string = '';
 //  let role: string = '';
 //  let expiration_Date: string = '';
 //  let lenguage: string = '';
 //  let description: string = '';
 //  let s1LogProperty: { name: string, value: string }[] = [];
 //  s1LogProperty = element['properties'];
 //  if (s1LogProperty !== null) {
 //    s1LogProperty.forEach(prop => {
 //      if(prop.name === '{{IDF_StaffID}}'){
 //        staffId =  prop.value.split(',')[1].split(']')[0]
 //      }
 //      if(prop.name === '{{IDF_FirstName}}'){
 //        first_Name =   prop.value.split(',')[1].split(']')[0]
 //      }
 //      if(prop.name === '{{IDF_LastName}}'){
 //        last_Name =   prop.value.split(',')[1].split(']')[0]
 //      }
 //      if(prop.name === '{{IDF_Organization}}'){
 //        organization =  prop.value.split(',')[1].split(']')[0]
 //      }
 //      if(prop.name === '{{IDF_Role}}'){
 //        role =   prop.value.split(',')[1].split(']')[0]
 //      }
 //      if(prop.name === '{{IDF_ExpiredOn}}'){
 //        expiration_Date =  prop.value.split(',')[1].split(']')[0]
 //      }
 //      if(prop.name === '{{IDF_Lenguage}}'){
 //        lenguage =   prop.value.split(',')[1].split(']')[0]
 //      }
 //      if(prop.name === '{{IDF_Notes}}'){
 //        description =   prop.value.split(',')[1].split(']')[0]
 //      }
 //      if(prop.name === '{{IDF_EMail}}'){
 //        email =   prop.value.split(',')[1].split(']')[0]
 //      }
 //       if(prop.name === 'USER' ) {
 //         user = prop.value
 //  }
 //      logMsg=
 //        "Login Id: "+ user+
 //        ", Staff Number: "+staffId
 //        +", First Name: "+first_Name
 //        +", Last Name: "+last_Name
 //        +", email: "+email
 //        +", Organization: "+organization
 //        +", Role: "+role
 //        +", Expiration Date: "+expiration_Date
 //        +", Language: "+lenguage
 //        +", Description: "+description
 //        +","
 //    });
 //  }
 //  return  logMsg
 //}
  private setLogMsgOld(element): string {
    const s1LogProperty = element['properties'] || [];
    let user;
    const propertiesMap = new Map<string, string>();
    if (s1LogProperty !== null) {
          s1LogProperty.forEach(prop => {
    if(prop.name === 'USER' ) {
      user = prop.value
       }})}


    s1LogProperty.forEach(prop => {
      propertiesMap.set(prop.name, prop.value.split(',')[1]?.split(']')[0] || '');
    });
    const staffId = propertiesMap.get('{{IDF_StaffID}}') || '';
    const firstName = propertiesMap.get('{{IDF_FirstName}}') || '';
    const lastName = propertiesMap.get('{{IDF_LastName}}') || '';
    const organization = propertiesMap.get('{{IDF_Organization}}') || '';
    const role = propertiesMap.get('{{IDF_Role}}') || '';
    const company = propertiesMap.get('{{IDF_Company}}') || '';
    const expirationDate = propertiesMap.get('{{IDF_ExpiredOn}}') || '';
    const language = propertiesMap.get('{{IDF_Lenguage}}') || '';
    const description = propertiesMap.get('{{IDF_Notes}}') || '';
    const email = propertiesMap.get('{{IDF_EMail}}') || '';

    const logParts = [
        `Login Id: ${user}`,
      staffId && `Staff Number: ${staffId}`,
      firstName && `First Name: ${firstName}`,
      lastName && `Last Name: ${lastName}`,
      email && `email: ${email}`,
      organization && `Organization: ${organization}`,
      company && `Company: ${company}`,
      role && `Role: ${role}`,
      expirationDate && `Expiration Date: ${expirationDate}`,
      language && `Language: ${language}`,
      description && `Description: ${description}`
    ];

    const logMsg = logParts.filter(Boolean).join(', ');
    return logMsg;
  }
  private setLogMsg(element): string {
    const s1LogProperty = element['properties'] || [];
    let user;
    const propertiesMap = new Map<string, string>();
    if (s1LogProperty !== null) {
      s1LogProperty.forEach(prop => {
        if(prop.name === 'USER' ) {
          user = prop.value
        }})}

    s1LogProperty.forEach(prop => {
      propertiesMap.set(prop.name, prop.value.split('[')[1]?.split(',')[0] || '');
    });

    const staffId = propertiesMap.get('{{IDF_StaffID}}') || '';
    const firstName = propertiesMap.get('{{IDF_FirstName}}') || '';
    const lastName = propertiesMap.get('{{IDF_LastName}}') || '';
    const organization = propertiesMap.get('{{IDF_Organization}}') || '';
    const role = propertiesMap.get('{{IDF_Role}}') || '';
    const company = propertiesMap.get('{{IDF_Company}}') || '';
    const expirationDate = propertiesMap.get('{{IDF_ExpiredOn}}') || '';
    const language = propertiesMap.get('{{IDF_Lenguage}}') || '';
    const description = propertiesMap.get('{{IDF_Notes}}') || '';
    const email = propertiesMap.get('{{IDF_EMail}}') || '';
    const password = propertiesMap.has('{{IDF_Password}}') ? 'modify' : '';

    const logParts = [
      `Login Id: ${user}`,
      staffId && `Staff Number: ${staffId}`,
      firstName && `First Name: ${firstName}`,
      lastName && `Last Name: ${lastName}`,
      email && `email: ${email}`,
      organization && `Organization: ${organization}`,
      company && `Company: ${company}`,
      role && `Role: ${role}`,
      expirationDate && `Expiration Date: ${expirationDate}`,
      language && `Language: ${language}`,
      description && `Description: ${description}`,
      password && `Password: ${password}`
    ];

    const logMsg = logParts.filter(Boolean).join(', ');
    return logMsg;
  }  private _getLogs_setLogMsg(element): string {
        let logMsg: string;
        let s1LogProperty: { name: string, value: string }[] = [];
        s1LogProperty = element['properties'];
        let logMsg_userVal: string = "";
        if (s1LogProperty !== null) {
            s1LogProperty.forEach(prop => {
                if (prop.name === 'USER') logMsg_userVal = prop.value;
            });
        }
        if (element['operation'].endsWith('_WHATIF'))
            logMsg = new LogTracerMessageFilterPipe(this.translate).transform(LogTracerDescription[element['operation']])
                + " " + element['properties'][0]['value'];
        else if (element['operation'] === 'PROCESS_REMOVE_PROFILE')
            logMsg = this.permissionVal + " '" + element['properties'][1]['value'] + "' " + this.revokedVal
                + " " + logMsg_userVal;
        else if (element['operation'] === 'PROCESS_ADD_PROFILE')
            logMsg = this.permissionVal + " '" + element['properties'][1]['value'] + "' " + this.grantedVal
                + " " + logMsg_userVal;
        else if (element['operation'] === 'USER_EDIT')
            logMsg = this.userVal + " " + logMsg_userVal + " " + this.modifiedVal;
        else if (element['operation'] === 'LOGIN' && element['properties'][0]['value'] === 'SUCCESS')
            logMsg = new LogTracerMessageFilterPipe(this.translate).transform(LogTracerDescription[element['operation']]);
        else if (element['operation'] === 'LOGIN' && element['properties'][0]['value'] === 'FAILED')
            logMsg = new LogTracerMessageFilterPipe(this.translate).transform(LogTracerDescription[element['operation']])
                + " " + this.forVal.toLocaleLowerCase() + " " + this.userVal.toLocaleLowerCase() + " "
                + logMsg_userVal + " - " + this.statusVal + ":" + element['properties'][0]['value']
                + ", " + this.errorVal.toLocaleLowerCase() + ":"
                + element['properties'][1]['value'];
        else if (element['operation'] === 'REASSIGN_PD')
            logMsg = this.reassignProcVal + " " + element['properties'][0]['value'] + " " + this.toVal
                + " " + element['properties'][1]['value'];
        else if (element['operation'] === 'CHECKIN_PD')
            logMsg = this.checkInVal + ": " + element['properties'][0]['value'] + ", " + this.commentVal + ": "
                + element['properties'][1]['value'];
        else
            logMsg = new LogTracerMessageFilterPipe(this.translate).transform(LogTracerDescription[element['operation']]);

        return logMsg;
    }

    private setProcessObject(data) {
      this.processDetails = this.processDetails.filter(process => process.name == 'All');
        for (const element of data) {
          this.processDetails.push({
            name: element['name'],
            processId: Number(element['id']['processID']),
            versionId: Number(element['id']['versionID']),
            userIdDb: Number(element['containerId']['id'])
            });
            this.setProcesses(this.processDetails);
        }
    }

    setUserId(userViewValue, usersObj) {
        this.userId = [];
        if ('All' === this.userForm.controls['name'].value) {
            usersObj.forEach(element => {
                if (element['userID'] !== 0) {
                    this.userId.push(element['userID']);
                }
            });
        } else {
            this.userId.push(this.users.find(user => user.viewValue == userViewValue)?.userID);
        }
    }

    setProcesses(obj) {
        this.process = [];
        if ('All' === this.userForm.controls['processes'].value) {
            if (obj) {
                obj.forEach(element => {
                    this.process.push(element['name']);
                });
            }
        } else {
            this.process.push(this.userForm.controls['processes'].value);
        }
    }

    clear() {
        this.userForm = this.fb.group({
            'type': '',
            'name': '',
            'processes': '',
            'fromDate': this.calendar.getToday(),
            'toDate': this.calendar.getToday()
        });
        this.userForm.controls['type'].setValue(this.operationType[0]?.value);
        this.userForm.controls['name'].setValue(this.users[0]?.viewValue)
        this.userForm.controls['processes'].setValue(this.processDetails[0]?.name);
        this.selected = this.users[0].viewValue;
        this.setUserId('', this.users);
        this.setProcesses(this.processDetails);
        this.logDesc = [];
        this.isEnabledClearBtn = false;
        this.error = false;
    }
  createLog(logDateDisplay: string,
            logUser: string,
            logProcess: string,
            logType: string,
            logOperation: string,
            logMessage: string,
  ): UserLog {

    return {
      logDateDisplay,
      logUser,
      logProcess,
      logType,
      logOperation,
      logMessage,
    };
  }

  exportAsXLSXAdministration() {
    console.log('PROVA administrationLog ',this.administrationLog);

    let endDate =  this.parserFormatter.format(this.userForm.controls['toDate'].value);
    let startDate = this.parserFormatter.format(this.userForm.controls['fromDate'].value);
    const name = this.loggedUserInfo.getLoginName()
    const lastName = this.loggedUserInfo.getLoginSurname()
    const userName = this.loggedUserInfo.getLoginUserId()
    const arraydiArray= this.logDaStampareAdministration.map(obj => Object.values(obj));
    const workbook = XLSX.utils.book_new();
    const data =  [
      ['Administration Audit Trail Report'],
      [''],
      ['Generate By:',userName+' ' +'('+name+',' + lastName+')'],
      ['Generate Date:',formatDate(new Date(), 'dd/MM/yyyy, hh:mm:ss a', 'en-US')],
      ['Date From', startDate],
      ['Date To', endDate],
      ['Processes',  this.userForm.controls['processes'].value],
      ['Users',   this.userForm.controls['name'].value],
      [''],
      // funzione Date	User	Process	Type	Operation	Message
      ['User_ID', 'Name', 'Last_Name', 'IP_Address', 'Date', 'Affected_User','Description','Previous_Value','Final_Value'],
      ...arraydiArray
    ]


    const worksheet = XLSX.utils.aoa_to_sheet(data);

    var wscols = [
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:20},
      {wch:15},
      {wch:30},
      {wch:35},
      {wch:35},
    ];
    worksheet['!cols'] =wscols  ;
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Administration Audit Report');
    XLSX.writeFile(workbook, 'Administration Audit Trail Report.xlsx');

    // this.ascAndDescOrderData(null, null, null);
  }

  exportAsXLSXLogTracer() {
    let userLoggedInfo = this.userDetail.getUserInfo();
    this.logDesc.forEach(element => {
      const newLog = this.createLog(element.logDateDisplay,element.logUser,element.logProcess,element.logType,element.logOperation,element.logMessage)
      this.logDaStampareProva.push(newLog);
    });
    let endDate =  this.parserFormatter.format(this.userForm.controls['toDate'].value);
    let startDate = this.parserFormatter.format(this.userForm.controls['fromDate'].value);
    const arraydiArray= this.logDaStampareProva.map(obj => Object.values(obj));
    const workbook = XLSX.utils.book_new();
    const data =  [
      ['StrategyOne by CRIF Credit Solutions','Print Date: '+ formatDate(new Date(), 'dd/MM/yyyy, hh:mm:ss a', 'en-US')],
      [''],
      ['LogTracer'],
      [''],
      ['Type',  this.userForm.controls['type'].value === 'NONE'?'All':this.userForm.controls['type'].value],
      ['Date From', startDate],
      ['Date To', endDate],
      ['Processes',  this.userForm.controls['processes'].value],
      ['Users',   this.userForm.controls['name'].value],
      [''],
      // funzione Date	User	Process	Type	Operation	Message
      ['Date', 'User', 'Process', 'Type', 'Operation', 'Message'],
      ...arraydiArray
    ]
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    var wscols = [
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:25},
      {wch:15},

    ];
    worksheet['!cols'] =wscols  ;
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Log Tracer');
    XLSX.writeFile(workbook, 'Log Tracer.xlsx');
  }

  private getViolationLogs() {
    this.showAppLoader = true;
    this.userForm.disable();
    let oldSearchText = this.searchText;
    this.searchText = '';
    this.violationLog = [];
    this.logTracerService.docGetLogReport(this.userBackEndForm).subscribe({
      next: response => {
        console.log('RESPONSE VIOLAIION-',response );
        if (response) {
          this.isPageLoad = true;
          this.findInvalidPasswordError(response);
          if (this.violationLog.length > 0) {
            this.isEnabledClearBtn = true;
          }
        } else {
          this.error = true;
          this.errorMessage = response['error']['reason'];
        }
        this.searchText = oldSearchText;
        this.showAppLoader = false;
        this.enableForm();
      }, error: err => {
        console.log(err);
        this.error = true;
        this.errorMessage = err['error']['reason'];
        this.searchText = oldSearchText;
        this.showAppLoader = false;
        this.userForm.enable();
      }
    });
  }
  createViolationLog(
    logUserId: string,
    logName: string,
    logLastName: string,
    logIPAddress: string,
    logDateDisplay: string,
    logErrorMessage: string,
    logDescription: string,
  ): ViolationLog {

    return {
      logUserId,
      logName,
      logLastName,
      logIPAddress,
      logDateDisplay,
      logErrorMessage,
      logDescription,
    };
  }

  createAdministrationLog(
     logUserId: string,
     logName: string,
     logLastName: string,
     logIPAddress: string,
     logDateDisplay: string,
     logAffectedUserId: string,
     logDescription: string,
     logPreviousValue: string,
     logFinalValue: string,
  ): AdministrationLog {

    return {
     logUserId,
     logName,
     logLastName,
     logIPAddress,
     logDateDisplay,
     logAffectedUserId,
     logDescription,
     logPreviousValue,
     logFinalValue,
    };
  }
  findInvalidPasswordError(logs: any[]): string | null {
    for (const log of logs) {
      if (log.properties){
        for (const property of log.properties) {
          if (property.value === "ERR_AUTH_INVALID_PASSWORD" || property.value === "ERR_AUTH_LOGINFAILED" || property.value === "ERR_AUTH_LOGINTIMEOUT" || property.value === "ERR_AUTH_EXPIRED_ACCOUNT" || property.value === "ERR_AUTH_INVALIDCREDENTIALS" || property.value === "UPDATE_CLIENT"|| property.value === "ERR_AUTH_PASSWORD_EXPIRED" || property.value === "ERR_AUTH_USER_INACTIVE") {
            this.violationLog.push({
              logType: log.type,
              logOperation: log.operation,
              logProcess: log.process,
              logDate: log.time,
              logUser: log.user,
              logMessage: this._getLogs_setLogMsg(log)
            });
            let user = log.user ? this.getUserById(log.user) : '';
            const newLog = this.createViolationLog(log.user, user ? user.name : '' ,user?user.surname: '', log.clientIP, this.datePipe.transform(new Date(log.time), 'yyyy-MM-dd HH:mm:ss'), log.process, this._getLogs_setLogMsg(log))
            this.logDaStampareViolation.push(newLog);
          }
        }
      }
    }
    return null;
  }
  getUserById(userId) {
    return this.usersList.find(user => user.loginCredentials.userId === userId);
  }
  exportAsXLSXViolation() {
    let endDate =  this.parserFormatter.format(this.userForm.controls['toDate'].value);
    let startDate = this.parserFormatter.format(this.userForm.controls['fromDate'].value);
    const name = this.loggedUserInfo.getLoginName()
    const lastName = this.loggedUserInfo.getLoginSurname()
    const userName = this.loggedUserInfo.getLoginUserId()
    const arraydiArray= this.logDaStampareViolation.map(obj => Object.values(obj));
    const workbook = XLSX.utils.book_new();
    const data =  [
      ['Violation Report'],
      [''],
      ['Generate By:',userName+' ' +'('+name+',' + lastName+')'],
      ['Generate Date:',formatDate(new Date(), 'dd/MM/yyyy, hh:mm:ss a', 'en-US')],
      ['Date From', startDate],
      ['Date To', endDate],
      // ['Processes',  this.userForm.controls['processes'].value],
      ['Users', this.userForm.controls['name'].value],
      [''],
      // User_ID	Name	Last_Name	IP_Address	Date	Error_Message	Description
      // funzione Date	User	Process	Type	Operation	Message
      ['User_ID', 'Name', 'Last_Name', 'IP_Address', 'Date', 'Error_Message','Description'],
      ...arraydiArray
    ]


    const worksheet = XLSX.utils.aoa_to_sheet(data);

    var wscols = [
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:15},
      {wch:25},
      {wch:15},

    ];
    worksheet['!cols'] =wscols  ;
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Violation Report');
    XLSX.writeFile(workbook, 'Violation Report.xlsx');

  }
}

interface UserLog {
  logDateDisplay:string;
  logUser:string;
  logProcess:string;
  logType:string;
  logOperation:string;
  logMessage:string;
  logUserName?: string;
  logDate?: string;
  logOperationID?: string;
  logClientIP?: string;
}
interface ViolationLog {
  logUserId:string;
  logName?: string;
  logLastName?: string;
  logIPAddress?: string;
  logDateDisplay:string;
  logErrorMessage:string;
  logDescription:string;
}
interface AdministrationLog {
  logUserId:string;
  logName?: string;
  logLastName?: string;
  logIPAddress?: string;
  logDateDisplay:string;
  logAffectedUserId:string;
  logDescription:string;
  logPreviousValue:string;
  logFinalValue:string;
}
