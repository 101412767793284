<h6 class=" mb-1">{{ 'Available campaigns for process :' | translate }}
    <h6 class="badge cds-badge active text-bg-info">{{nodeService.currentProcessName}}</h6>
</h6>
<div class="card cds-card-shadow mb-4">
    <div class="card-body">
        <div class="row" *ngIf="isPermissionError">
            <div class="col-12">
                <div class="alert cds-alert alert-danger" role="alert">
                    <div class="cds-alert-icon">
                      <i class="fa-solid fa-triangle-exclamation"></i>
                    </div>
                    <div class="cds-alert-content">{{"You do not have permission to access this page." | translate}}</div>
                </div>
            </div>
        </div>
        <appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>
        <div class="row" *ngIf="!nodeService.showAppLoader && !isPermissionError">
            <div class="col-12 mb-1">
                <input class="form-control searchBox" placeholder="{{ 'Quick Filter' | translate}}"
                    [(ngModel)]="searchText">
            </div>
            <div class="col-sm-12">
                <div class="scroll-only-x">
                    <table aria-describedby="table-proc-campaignParent"
                        class="table table-striped"
                        [mfData]="campaignData | filter :'id,description,inputVariableRefID': searchText" #mf="mfDataTable"
                        [mfRowsOnPage]="15" mfSortBy="id">
                        <thead>
                            <tr>
                                <th>
                                    <mfDefaultSorter by="id">{{ 'Code' | translate }}</mfDefaultSorter>
                                </th>
                                <th>
                                    <mfDefaultSorter by="description">{{ 'Notes' | translate }}</mfDefaultSorter>
                                </th>
                                <th>
                                    <mfDefaultSorter by="inputVariableRefID">{{ 'Input Variable' | translate }}
                                    </mfDefaultSorter>
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="campaignData.length > 0">
                            <tr class="cursor" *ngFor="let value of mf.data let index" (click)="showVariablesDetails(value)"
                                [ngStyle]="{'background-color': value.id === campaignsName ? 'antiquewhite' : ''}">
                                <td>{{value.id}}</td>
                                <td>{{value.description}}</td>
                                <td>{{findInputVariableName(value.inputVariableRefID)}}</td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="(campaignData | filter :'id,description,inputVariableRefID': searchText).length > 15">
                            <tr>
                                <td colspan="10">
                                    <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="col-sm-12 text-center" *ngIf="campaignData.length === 0">
                        <h5>
                            <mat-error>{{ 'No records found.' | translate }}</mat-error>
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="inputValueData.length > 0">
    <h6 class=" mb-1">{{ 'Details of campaign :' | translate }}
        <h6 class="badge cds-badge active text-bg-primary">{{campaignsName}}</h6>
    </h6>
    <div class="card cds-card-shadow">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12">
                    <div class="scroll-only-x">
                        <table aria-describedby="table-proc-campaignChild"
                            class="table table-striped" [mfData]="inputValueData" #mf="mfDataTable"
                            [mfRowsOnPage]="15">
                            <thead>
                                <tr>
                                    <th>
                                        <mfDefaultSorter by="from">{{ 'From' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="to">{{ 'To' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="outputCode">{{ 'Output Code' | translate }}</mfDefaultSorter>
                                    </th>
                                    <th>
                                        <mfDefaultSorter by="description">{{ 'Description' | translate }}</mfDefaultSorter>
                                    </th>
                                </tr>
                            </thead>
                            <tbody [ngClass]="{active: isEnableEdit(),disabled: !isEnableEdit()}">
                                <tr *ngFor="let subValue of mf.data let i = index"
                                    (click)="editValue(i, subValue, template, $event)"
                                    [ngStyle]="{'background-color': i === index ? 'antiquewhite' : ''}">
                                    <td>{{ subValue.always === true ? 'Else' : subValue.fromDisplay}}</td>
                                    <td>{{ subValue.always === true ? '' : subValue.toDisplay}}</td>
                                    <td>{{ subValue.outputCode }}</td>
                                    <td>{{ subValue.description }}</td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="inputValueData.length > 15">
                                <tr>
                                    <td colspan="10">
                                        <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>



<!-- <div class="col-sm-12" *ngIf="isPermissionError">
    <br />
    <h5>
        <mat-error>{{"You do not have permission to access this page." | translate}}</mat-error>
    </h5>
</div>
<appspinner [startSpinner]="nodeService.showAppLoader"></appspinner>
<div class="row" *ngIf="!nodeService.showAppLoader && !isPermissionError">
    <div class="col-sm-12">
        <span class="heading-text">{{ 'Available campaigns for process :' | translate }}
            {{nodeService.currentProcessName}}</span>
    </div>
    <div class="col-12 pull-left">
        <input class="form-control searchBox" placeholder="{{ 'Quick Filter' | translate}}"
            [(ngModel)]="searchText">
    </div>
    <div class="col-sm-12">
        <div class="scroll-only-x">
            <table aria-describedby="table-proc-campaignParent"
                class="table table-striped"
                [mfData]="campaignData | filter :'id,description,inputVariableRefID': searchText" #mf="mfDataTable"
                [mfRowsOnPage]="15" mfSortBy="id">
                <thead>
                    <tr>
                        <th>
                            <mfDefaultSorter by="id">{{ 'Code' | translate }}</mfDefaultSorter>
                        </th>
                        <th>
                            <mfDefaultSorter by="description">{{ 'Notes' | translate }}</mfDefaultSorter>
                        </th>
                        <th>
                            <mfDefaultSorter by="inputVariableRefID">{{ 'Input Variable' | translate }}
                            </mfDefaultSorter>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="campaignData.length > 0">
                    <tr class="cursor" *ngFor="let value of mf.data let index" (click)="showVariablesDetails(value)"
                        [ngStyle]="{'background-color': value.id === campaignsName ? 'antiquewhite' : ''}">
                        <td>{{value.id}}</td>
                        <td>{{value.description}}</td>
                        <td>{{findInputVariableName(value.inputVariableRefID)}}</td>
                    </tr>
                </tbody>
                <tfoot *ngIf="(campaignData | filter :'id,description,inputVariableRefID': searchText).length > 15">
                    <tr>
                        <td colspan="10">
                            <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <div class="col-sm-12 text-center" *ngIf="campaignData.length === 0">
                <h5>
                    <mat-error>{{ 'No records found.' | translate }}</mat-error>
                </h5>
            </div>
        </div>
    </div>


    <div class="row" *ngIf="inputValueData.length > 0">
        <hr/>
        <div class="col-sm-12">
            <span class="heading-text">{{ 'Details of campaign :' | translate }} {{campaignsName}}</span>
        </div>

        <div class="col-sm-12">
            <div class="scroll-only-x">
                <table aria-describedby="table-proc-campaignChild"
                    class="table table-striped" [mfData]="inputValueData" #mf="mfDataTable"
                    [mfRowsOnPage]="15">
                    <thead>
                        <tr>
                            <th>
                                <mfDefaultSorter by="from">{{ 'From' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="to">{{ 'To' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="outputCode">{{ 'Output Code' | translate }}</mfDefaultSorter>
                            </th>
                            <th>
                                <mfDefaultSorter by="description">{{ 'Description' | translate }}</mfDefaultSorter>
                            </th>
                        </tr>
                    </thead>
                    <tbody [ngClass]="{active: isEnableEdit(),disabled: !isEnableEdit()}">
                        <tr *ngFor="let subValue of mf.data let i = index"
                            (click)="editValue(i, subValue, template, $event)"
                            [ngStyle]="{'background-color': i === index ? 'antiquewhite' : ''}">
                            <td>{{ subValue.always === true ? 'Else' : subValue.fromDisplay}}</td>
                            <td>{{ subValue.always === true ? '' : subValue.toDisplay}}</td>
                            <td>{{ subValue.outputCode }}</td>
                            <td>{{ subValue.description }}</td>
                        </tr>
                    </tbody>
                    <tfoot *ngIf="inputValueData.length > 15">
                        <tr>
                            <td colspan="10">
                                <mfBootstrapPaginator [rowsOnPageSet]="[15,50,100]"></mfBootstrapPaginator>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div> -->

<!-- Add/Edit Input/Output parameter for Tables -->
<ng-template #template tabindex="-1" class="modal fade cds-modal" let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" translate>{{ "Edit Campaign" | translate }} :<h5 class="badge cds-badge active text-bg-info m-1">{{campaignsName}}</h5></h5>
                <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="campaignForm" novalidate>
                    <div class="row">
                        <div class="col-4">
                            <label class="form-label">{{ 'Date from' | translate }}</label>
                            <div class="input-group">
                                <input
                                [min]="minDate"
                                    [disabled]="isDisabledFlag"
                                    [readonly]="true"
                                    (ngModelChange)="dateChanged($event)"
                                    class="form-control"
                                    placeholder="yyyy-mm-dd"
                                    name="dp"
                                    formControlName="fromDate"
                                    standalone="true"
                                    ngbDatepicker
                                    #d="ngbDatepicker"
                                />
                                <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button">
                                    <i class="fa fa-calendar" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-4">
                            <label class="form-label">{{ 'Date to' | translate }}</label>
                            <div class="input-group">
                                <input
                                    [min]="minDate"
                                    [disabled]="isDisabledFlag"
                                    [readonly]="true"
                                    (ngModelChange)="dateChanged($event)"
                                    class="form-control"
                                    placeholder="yyyy-mm-dd"
                                    name="dp"
                                    formControlName="toDate"
                                    standalone="true"
                                    ngbDatepicker
                                    #d2="ngbDatepicker"
                                />
                                <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d2.toggle()" type="button">
                                    <i class="fa fa-calendar" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-4">
                            <label class="form-label">{{ 'Description' | translate }}</label>
                            <input type="text" placeholder="{{'Description' | translate }}" class="form-control"  formControlName="description" maxlength="255">
                        </div>
                        <div class="cds-form-control-error"   *ngIf="isSaveDisable">
                            {{ "INVALID DATE RANGE : From date should not be greater than to date." | translate }}
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button (click)="saveSelected()" [disabled]='isSaveDisable' type="submit" class="btn btn-primary">
                    {{'Save' | translate }}
                </button>
                <button  type="button" class="btn btn-default" (click)="cancel()">
                    {{ 'Close' |translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>